import { useEffect } from "react";

const loadNoora = () => {
  const onLoad = () => {
    (window as any)?.Noora("initChangelog", {
      workspaceId: "5fd13a2ad274730008de8276",
      position: "right",
      align: "bottom",
      // ssoToken: '', // optional: ssoToken generated on your back-end to identify this user
      theme: "light", // optional: one of 'light, 'dark'. Remove this option to auto detect light/dark based on the user's preference
    });
  };
  if (document.getElementById("noora-script")) {
    onLoad();
    // dont load script again
    return;
  }
  const script = document.createElement("script");
  script.id = "noora-script";
  script.src = "https://noorahq.com/sdk.js";
  script.async = true;
  script.onload = () => {
    onLoad();
  };
  document.body.appendChild(script);
};

const ChangelogWidget = () => {
  useEffect(loadNoora, []);
  return (
    <div className="changelog-button-container">
      <button id="changelog-button" data-noora-changelog>
        Changelog
      </button>
    </div>
  );
};
export default ChangelogWidget;
