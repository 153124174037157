import { Component } from "react";
// import PropTypes from "prop-types";
import Button, { ButtonProps } from "reactstrap/lib/Button";
import Spinner from "./Spinner";
import _ from "lodash";

// const propTypes = {
//   bsStyle: PropTypes.string,
//   children: PropTypes.node,
//   disabled: PropTypes.bool,
//   icon: PropTypes.node,
//   loading: PropTypes.bool,
//   spinColor: PropTypes.string,
//   spinAlignment: PropTypes.string
// };
interface Props extends ButtonProps {
  loading?: boolean;
}
class ButtonLoader extends Component<Props> {
  renderIcon() {
    const spinColor = this.props.color === "link" ? "#777" : "#fff";
    const spinAlignment = "left";
    if (this.props.loading) {
      return <Spinner spinColor={spinColor} spinAlignment={spinAlignment} />;
    }

    return null;
  }
  render() {
    const buttonDisabled = this.props.disabled || this.props.loading;

    return (
      <Button
        {..._.omit(this.props, "disabled", "loading")}
        // color={this.props.color}
        disabled={buttonDisabled}
      >
        {this.renderIcon()} {this.props.children}
      </Button>
    );
  }
}

// ButtonLoader.propTypes = propTypes;

export default ButtonLoader;

export { ButtonLoader, Spinner };
