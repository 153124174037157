import { apiBase } from "../../constants";
import request, { AxiosResponse } from "axios";
import { IFieldsSchema } from "encharge-domain/definitions/JSONSchema6";
import { handleForbiddenRead } from "./handleForbidden";

export const createImport = async ({
  name,
  peopleCSV,
  numPeople,
  fieldMapping,
  tags,
  existingPeopleStrategy,
}: {
  name: string;
  peopleCSV: string;
  numPeople: number;
  fieldMapping: IFieldsSchema;
  tags: string[];
  existingPeopleStrategy: "update" | "ignore";
}) => {
  try {
    const resUploadURL: AxiosResponse<{
      url: string;
      key: string;
    }> = await request({
      url: `${apiBase}/v1/imports/upload-url`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    const presignedS3Url = resUploadURL.data.url;
    if (!presignedS3Url) {
      throw new Error("Couldn't get import file upload URL.");
    }

    const CSVBlob = new Blob([peopleCSV], { type: "text/csv" });

    // Upload CSV
    await request({
      url: presignedS3Url,
      method: "PUT",
      data: CSVBlob,
      headers: {
        "Content-Type": CSVBlob.type,
      },
      timeout: 1000 * 60 * 5, // 5 minutes
      withCredentials: false,
    });
    // URL of the uploaded file for the s3 bucket
    const peopleFileKey = resUploadURL.data.key;

    let res: AxiosResponse<{ id: string }>;
    res = await request({
      url: `${apiBase}/v1/imports`,
      method: "POST",
      data: {
        name,
        peopleFileKey,
        numPeople,
        fieldMapping,
        tags,
        existingPeopleStrategy,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    const jsonBody = res.data;
    return jsonBody.id;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't process the import. ";
    throw new Error(msg);
  }

  // Creating new import
};

export const getImports = async () => {
  // Creating new link
  try {
    let res: AxiosResponse<{ imports: IImportRecord[] }>;
    res = await request({
      url: `${apiBase}/v1/imports`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.imports;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];
    throw e;
  }
};

export const getImport = async (id: IImportRecord["id"]) => {
  try {
    let res: AxiosResponse<{ import: IImportRecord }>;
    res = await request({
      url: `${apiBase}/v1/imports/${id}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.import;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return;
    throw e;
  }
};
