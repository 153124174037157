import { Component } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastMessageDuration } from "../../constants";

export default class ToastMessagesContainer extends Component {
  render() {
    return (
      <ToastContainer
        position="top-right"
        autoClose={toastMessageDuration}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        draggable={false}
        pauseOnHover={true}
      />
    );
  }
}
