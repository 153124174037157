import { PropsWithChildren } from "react";

interface Props {
  title: string;
  subtitle: string;
  showPlaceholder?: "company" | "email";
}

const GenerateFlowsCarouselGeneratedItem = (
  props: PropsWithChildren<Props>
) => {
  const companyInfoPlaceholderIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <g opacity="0.7">
        <path
          d="M46 21H30C29.447 21 29 21.447 29 22V46C29 46.553 29.447 47 30 47H46C46.553 47 47 46.553 47 46V22C47 21.447 46.553 21 46 21ZM41 41H35C34.447 41 34 40.553 34 40C34 39.447 34.447 39 35 39H41C41.553 39 42 39.447 42 40C42 40.553 41.553 41 41 41ZM41 35H35C34.447 35 34 34.553 34 34C34 33.447 34.447 33 35 33H41C41.553 33 42 33.447 42 34C42 34.553 41.553 35 41 35ZM41 29H35C34.447 29 34 28.553 34 28C34 27.447 34.447 27 35 27H41C41.553 27 42 27.447 42 28C42 28.553 41.553 29 41 29Z"
          fill="#AFB8D7"
        />
        <path
          d="M36 5H25V2C25 1.447 24.553 1 24 1C23.447 1 23 1.447 23 2V5H12C11.448 5 11 5.448 11 6V25H19C20.105 25 21 25.895 21 27V47H27V21C27 19.895 27.895 19 29 19H37V6C37 5.448 36.552 5 36 5ZM28 15H20C19.447 15 19 14.553 19 14C19 13.447 19.447 13 20 13H28C28.553 13 29 13.447 29 14C29 14.553 28.553 15 28 15Z"
          fill="#AFB8D7"
        />
        <path
          d="M18 27H2C1.447 27 1 27.447 1 28V46C1 46.553 1.447 47 2 47H18C18.553 47 19 46.553 19 46V28C19 27.447 18.553 27 18 27ZM13 41H7C6.447 41 6 40.553 6 40C6 39.447 6.447 39 7 39H13C13.553 39 14 39.447 14 40C14 40.553 13.553 41 13 41ZM13 35H7C6.447 35 6 34.553 6 34C6 33.447 6.447 33 7 33H13C13.553 33 14 33.447 14 34C14 34.553 13.553 35 13 35Z"
          fill="#AFB8D7"
        />
      </g>
    </svg>
  );

  const emailPlaceholderIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M42 5H6C5.34339 5 4.69321 5.12933 4.08658 5.3806C3.47995 5.63188 2.92876 6.00017 2.46447 6.46447C1.52678 7.40215 1 8.67392 1 10V38C1 39.3261 1.52678 40.5979 2.46447 41.5355C3.40215 42.4732 4.67392 43 6 43H42C43.3261 43 44.5979 42.4732 45.5355 41.5355C46.4732 40.5979 47 39.3261 47 38V10C47 8.67392 46.4732 7.40215 45.5355 6.46447C44.5979 5.52678 43.3261 5 42 5ZM17 28.414L8.414 37C8.2254 37.1822 7.9728 37.283 7.7106 37.2807C7.4484 37.2784 7.19759 37.1732 7.01218 36.9878C6.82677 36.8024 6.7216 36.5516 6.71933 36.2894C6.71705 36.0272 6.81784 35.7746 7 35.586L15.586 27C15.7746 26.8178 16.0272 26.717 16.2894 26.7193C16.5516 26.7216 16.8024 26.8268 16.9878 27.0122C17.1732 27.1976 17.2784 27.4484 17.2807 27.7106C17.283 27.9728 17.1822 28.2254 17 28.414ZM41.061 37.061C40.8735 37.2485 40.6192 37.3538 40.354 37.3538C40.0888 37.3538 39.8345 37.2485 39.647 37.061L31 28.414C30.8178 28.2254 30.717 27.9728 30.7193 27.7106C30.7216 27.4484 30.8268 27.1976 31.0122 27.0122C31.1976 26.8268 31.4484 26.7216 31.7106 26.7193C31.9728 26.717 32.2254 26.8178 32.414 27L41.06 35.646C41.153 35.7388 41.2269 35.849 41.2773 35.9704C41.3277 36.0918 41.3537 36.2219 41.3538 36.3533C41.3539 36.4847 41.3281 36.6149 41.2778 36.7363C41.2276 36.8577 41.1539 36.9681 41.061 37.061ZM41 12.414L24.707 28.707C24.5195 28.8945 24.2652 28.9998 24 28.9998C23.7348 28.9998 23.4805 28.8945 23.293 28.707L7 12.414C6.81784 12.2254 6.71705 11.9728 6.71933 11.7106C6.7216 11.4484 6.82677 11.1976 7.01218 11.0122C7.19759 10.8268 7.4484 10.7216 7.7106 10.7193C7.9728 10.717 8.2254 10.8178 8.414 11L24 26.586L39.586 11C39.7746 10.8178 40.0272 10.717 40.2894 10.7193C40.5516 10.7216 40.8024 10.8268 40.9878 11.0122C41.1732 11.1976 41.2784 11.4484 41.2807 11.7106C41.283 11.9728 41.1822 12.2254 41 12.414Z"
        fill="#AFB8D7"
      />
    </svg>
  );
  return (
    <div className="generate-flows-carousel-item">
      <div className="generate-flows-carousel-item-inner-container">
        {props.showPlaceholder && !props.children && (
          <div className="generate-flows-carousel-item-background-icon">
            {props.showPlaceholder === "company" && companyInfoPlaceholderIcon}
            {props.showPlaceholder === "email" && emailPlaceholderIcon}
          </div>
        )}
        {props.children}
      </div>
      <div>
        <span className="title">{props.title}</span>
        <span className="subtitle">{props.subtitle}</span>
      </div>
    </div>
  );
};
export default GenerateFlowsCarouselGeneratedItem;
