import AppNavDropdown from "components/AppNav/AppNavDropdown";
import AppNavIcon from "components/AppNav/AppNavIcon";
import _ from "lodash";
import { useState } from "react";

interface Props {
  items: {
    label: string;
    path: string;
  }[];
}

/**
 * User navigation for mobile
 */
const AppNavMobile = (props: Props) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState(false);

  const options = _.map(props.items, (item) => ({
    contents: (
      <>
        <span className="mr-3">
          <AppNavIcon type={item.label as any} />
        </span>
        {item.label}
      </>
    ),
    to: item.path,
  }));
  return (
    <div
      className="sidebar-nav-item cursor-pointer mr-3"
      onClick={() => setMobileNavIsOpen(!mobileNavIsOpen)}
    >
      <span>
        <AppNavIcon type={"Navigation"} active={false} />
        <AppNavDropdown
          isOpen={mobileNavIsOpen}
          setIsOpen={setMobileNavIsOpen}
          options={options}
        />
      </span>
    </div>
  );
};
export default AppNavMobile;
