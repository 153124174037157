import { useScript } from "hooks/useScript";
import { useEffect } from "react";

interface Props {
  accountId: number;
}

const ExternalOnboardingInner = (props: Props) => {
  const id = "034431822ID";
  const src =
    "https://static.userguiding.com/media/user-guiding-" + id + "-embedded.js";
  const scriptStatus = useScript(src);

  const accountId = props.accountId;

  useEffect(() => {
    if (scriptStatus === "ready" && accountId) {
      setupUserguiding();
      identifyUser(accountId);
    }
  }, [scriptStatus, accountId]);
  return null;
};

const setupUserguiding = () => {
  const win = window as any;
  win.userGuidingLayer = win.userGuidingLayer || [];
  // var f = document.getElementsByTagName('script')[0];
  // var k = document.createElement('script');
  if (win.userGuiding) return;
  var userguiding = {
    q: [],
  } as any;
  win.userGuiding = userguiding;
  userguiding.c = function (n: any) {
    return function () {
      userguiding.q.push([n, arguments]);
    };
  };
  var methods = [
    "previewGuide",
    "finishPreview",
    "track",
    "identify",
    "triggerNps",
    "hideChecklist",
    "launchChecklist",
  ];
  for (var j = 0; j < methods.length; j += 1) {
    userguiding[methods[j]] = userguiding.c(methods[j]);
  }
};

const identifyUser = (accountId: number) => {
  (window as any).userGuiding.identify(String(accountId));
};

export default ExternalOnboardingInner;
