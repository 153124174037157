import classNames from "classnames";
import { OnboardingEmailFrame } from "components/AIOnboarding/OnboardingEmailFrame";
import CompanyAvatar from "components/Companies/CompanyAvatar";
import { getDomainFromURL } from "encharge-domain/lib/helpers/email_helper";
import _ from "lodash";
import { Row, Col } from "reactstrap";

interface Props {
  fromName: string;
  fromEmail: string;
  subject: string;
  text: string;

  tinyMode?: boolean;
}

const GenerateFlowsGeneratedEmail = (props: Props) => {
  const emailText = props.text || "";
  return (
    <OnboardingEmailFrame
      maxHeight={props.tinyMode ? 250 : "unset"}
      classNames={classNames("generated-email", props.tinyMode && "tiny-mode")}
    >
      <div>
        <Row className="generate-email-preview-header">
          <Col xs={12}>
            <span className="mr-1 email-label">From name:</span>
            <span className="email-detail">{props.fromName || "..."}</span>
          </Col>
          <Col xs={12}>
            <span className="mr-1 email-label">From email:</span>
            <span className="email-detail">{props.fromEmail || "..."}</span>
          </Col>
        </Row>
        <Row className="generate-email-preview-header">
          <Col xs={24}>
            <span className="mr-1 email-label">Subject:</span>
            <span className="email-detail">{props.subject || "..."}</span>
          </Col>
        </Row>

        <Row className="">
          <Col className="email-text">
            {emailText.split("\n").map((chunk) => {
              // replace new lines with <br /> tags
              return (
                <span>
                  {chunk}
                  <br />
                </span>
              );
            })}
          </Col>
        </Row>
      </div>
    </OnboardingEmailFrame>
  );
};
export default GenerateFlowsGeneratedEmail;
