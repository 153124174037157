import { useStores } from "../../store/useStore";
import { observer } from "mobx-react";
import useDisableScrolling from "../../hooks/useDisableScrolling";
import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import { Button } from "reactstrap";

interface Props {
  className?: string;
}

const FullScreenMessage = observer((props: PropsWithChildren<Props>) => {
  const { accountStore } = useStores();

  const [closed, setClosed] = useState(false);

  // Disable scrolling underneath
  const setContainerRef = useDisableScrolling();

  if (closed) {
    return null;
  }

  // Admins should be able to close the message
  const isAdmin = accountStore.account?.isAdmin;
  return (
    <div
      className={classNames("full-screen-message col", props.className)}
      ref={setContainerRef}
    >
      {isAdmin && (
        <Button
          color="link"
          className="full-screen-message-close-button"
          onClick={() => setClosed(true)}
        >
          Close
        </Button>
      )}
      {props.children}
    </div>
  );
});

export default FullScreenMessage;
