import { useCallback, useEffect } from "react";
import { Alert, Label } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";
import React from "react";
import {
  CardComponent,
  CardNumber,
  CardCVV,
  CardExpiry,
  /// @ts-ignore - chargebee types are not available
} from "@chargebee/chargebee-js-react-wrapper";
import { useStores } from "store/useStore";
import { useCreateSubscription } from "components/Registration/RegistrationStepPayment/queries";

const defaultPlan = "encharge-growth-monthly-v4-trial";
const defaultPlanPeople = 2000;

interface Props {
  submit: () => void;
  loading?: boolean;
  error?: string;
}
const RegistrationStepPaymentView = (props: Props) => {
  const {
    uiStore: { accountRegister },
    registrationStore,
  } = useStores();

  const {
    mutate: createSubscription,
    isLoading,
    isSuccess,
    error,
  } = useCreateSubscription();

  const submitProp = props.submit;
  useEffect(() => {
    if (isSuccess) {
      submitProp();
    }
  }, [isSuccess, submitProp]);

  const cardRef = React.createRef<any | undefined>();

  const onSubmit = useCallback(() => {
    accountRegister.startLoading();
    // Use card component ref to call tokenize method
    cardRef.current
      ?.tokenize({})
      .then(async (data: { token: string }) => {
        const token = data.token;
        console.log("tokenized", data);
        // Send ajax calls to create subscription
        await createSubscription({
          token,
          planName: defaultPlan,
          planPeople: defaultPlanPeople,
        });
        // note registration as finished
        await registrationStore.registrationFinished();
        accountRegister.finishLoading();
      })
      .catch((error: any) => {
        accountRegister.finishLoading(
          `Problem while tokenizing your card details: ${error.message}`
        );
      });
  }, [accountRegister, cardRef, createSubscription, registrationStore]);

  const styles = React.useMemo(
    () => ({
      base: {
        paddingTop: "calc(0.5rem + 1px)",
        paddingBottom: "calc(0.5rem + 1px)",
        fontSize: "1.25rem",
        lineHeight: "1.5",
      },
    }),
    []
  );

  const openSupport = () => {
    const beacon = (window as any)?.Beacon;
    beacon?.("config", {
      messaging: {
        chatEnabled: true,
      },
      mode: "askFirst",
    });
    (window as any)?.openHelpChat?.();
  };
  const heading = `Start your 14-day trial`;
  const subheading = "Get access to all tools and features during trial";
  // const subsubheading = "You won't be charged until your trial ends.";

  const errorDisplay =
    props.error || error?.message ? (
      <Alert color="warning" className="mt-3">
        Error: {props.error || error?.message}
        <br />
        <span className="font-weight-medium">
          Please reach out to{" "}
          <a href="#" onClick={openSupport}>
            support
          </a>{" "}
          if you need assistance.
        </span>
      </Alert>
    ) : null;

  return (
    <>
      <RegistrationStepFormCommon
        heading={heading}
        subheading={subheading}
        onSubmit={onSubmit}
        loading={props.loading || isLoading}
        error={errorDisplay}
        buttonText="Start Trial"
      >
        <div className="registration-payment-wrap">
          <div className="registration-payment-fieldset">
            {/* Card component */}
            <CardComponent
              ref={cardRef}
              className="fieldset field"
              styles={styles}
              // classes={this.state.classes}
            >
              <div className="registration-payment-field">
                {/* Card number field */}
                <Label className="registration-payment-label mt-3 col-form-label-lg">
                  Card Number
                </Label>
                <CardNumber className="registration-payment-input mb-3 mt-2" />
                <i className="registration-payment-bar"></i>
              </div>
              <div className="registration-payment-fields">
                <div className="registration-payment-field">
                  {/* Card expiry field */}
                  <Label className="registration-payment-label mt-3 col-form-label-lg">
                    Expiry
                  </Label>
                  <CardExpiry className="registration-payment-input mb-3 mt-2" />
                  <i className="registration-payment-bar"></i>
                </div>
                <div className="registration-payment-field">
                  {/* Card cvv field */}
                  <Label className="registration-payment-label mt-3 col-form-label-lg">
                    CVC
                  </Label>
                  <CardCVV className="registration-payment-input mb-3 mt-2" />
                  <i className="registration-payment-bar"></i>
                </div>
              </div>
            </CardComponent>
          </div>
        </div>
      </RegistrationStepFormCommon>
      <div
        className="text-muted mt-4"
        style={{
          fontSize: "0.9rem",
        }}
      >
        <div className="mb-3">
          By clicking “Start Trial”, you agree to our{" "}
          <a
            href="https://encharge.io/terms-of-service/"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{" "}
          and{" "}
          <a
            href="https://encharge.io/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
        <div className="mb-3">
          <b>
            You won’t be charged until your trial ends. You can cancel at any
            time.
          </b>
        </div>
        <div className="mb-3">
          Encharge supports all major credit and debit cards:
          <br />
          <img
            src={`${process.env.PUBLIC_URL}/assets/img/register-cards.png`}
            alt="Supported cards: Visa, Mastercard, American Express"
            style={{ maxHeight: "35px" }}
            className="mt-2"
          />
        </div>
      </div>
    </>
  );
};

export default observer(RegistrationStepPaymentView);
