import { useState } from "react";
import humanizeString from "encharge-domain/lib/helpers/humanizeString";
import Loading from "../Loading";
import { UncontrolledAlert } from "reactstrap";
import GoogleSigninInit from "components/Login/GoogleSigninInit";

interface Props {
  onSuccess: (idToken: string) => void;
  mode: "signin" | "signup";
  loading?: boolean;
}
const GoogleSignin = (props: Props) => {
  const [error, setError] = useState("");
  if (props.loading) {
    return <Loading />;
  }

  return (
    <>
      <GoogleSigninInit
        mode={props.mode}
        onSuccess={props.onSuccess}
        onError={(error) => {
          // this error is thrown in Incognito, since cookies cant be set
          if (error.message === "idpiframe_initialization_failed") {
            // so promptly ignore it
            return;
          }
          setError(
            humanizeString(error.message || "Unknown error. Please try again.")
          );
        }}
      />
      {error && (
        <UncontrolledAlert className="mt-3" color="danger">
          {error}
        </UncontrolledAlert>
      )}
    </>
  );
};

export default GoogleSignin;
