import { useStores } from "../../../../../store/useStore";
import { observer } from "mobx-react";
import _ from "lodash";

interface Props {
  flowId: string | number | undefined | null;
  stepId: string | number | undefined | null;
  prependText?: string;
  appendText?: string;
  nullText?: string;
}
const StepLink = observer((props: Props) => {
  const { flowId, stepId } = props;
  const { flowsStore } = useStores();
  const noStepResult = props.nullText ? <>{props.nullText}</> : null;

  if (!flowId || !stepId) {
    return noStepResult;
  }
  const flow = flowsStore.getFlowById(Number(flowId));
  if (flow) {
    // get step
    const step = _.find(flow.steps, (step) => step.id === Number(stepId));
    if (step) {
      const stepName = step.display.label;
      return (
        <span>
          {props.prependText && <>{props.prependText} </>}
          <a href={`/flows/${flowId}?step=${stepId}`}>{stepName}</a>
          {props.appendText && <> {props.appendText}</>}
        </span>
      );
    }
  }
  return noStepResult;
});

export default StepLink;
