type Order = "asc" | "desc" | undefined;

export const sortCaret = (order: Order) => {
  return <SortCaret order={order} />;
};

export const SortCaret = ({ order }: { order: Order }) => {
  const activeClass = order !== undefined ? "active" : "";
  const upwardCaretClass = order === "asc" ? "active-alt" : "";
  return (
    <span className={["sort-caret", activeClass, upwardCaretClass].join(" ")}>
      {" "}
      <svg
        className="icn icn-btn-toggle-10 icn-btn-toggle-table-main"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10.8 6.8"
      >
        <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" data-name="Path 108" />
      </svg>
    </span>
  );
};
