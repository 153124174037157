import { QueryClient } from "react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // refetch data in 1 minute (in ms)
      staleTime: 1000 * 60,
      // disable retries
      retry: 0,
    },
    mutations: {
      // disable retries
      retry: 0,
    },
  },
});

export const getQueryClient = () => queryClient;

// @ts-ignore
window.queryClient = queryClient;
