import SpinnerIcon from "react-loader";

// tslint:disable-next-line:function-name
function Spinner({
  spinColor = "#fff",
  spinConfig = {
    length: 4,
    lines: 15,
    radius: 3,
    width: 2,
  },
  spinAlignment = "left",
  ...rest
}) {
  const style = {
    display: "inline-block",
    height: "11px",
    position: "relative" as "relative",
    width: "16px",
  };

  const spinAlignmentStyle = {
    display: "inline-block",
    float: `${spinAlignment} !important`,
    padding: "0 10px",
  };

  return (
    /// @ts-ignore - style definition is incompatible
    <div style={spinAlignmentStyle} {...rest}>
      <div style={style}>
        <SpinnerIcon {...spinConfig} color={spinColor} loaded={false} />
      </div>
    </div>
  );
}

export default Spinner;
