import { useState } from "react";
import AppNavIcon from "./AppNavIcon";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

/**
 * Settings dropdown for app navigation
 */
const AppNavSettings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);

  return (
    <>
      {divRef && (
        <UncontrolledTooltip target={divRef} placement={"bottom"} delay={0}>
          Settings
        </UncontrolledTooltip>
      )}
      <div
        ref={setDivRef}
        className="sidebar-nav-item cursor-pointer mr-3"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Link to="/apps">
          <AppNavIcon type={"Settings"} active={false} />
        </Link>
      </div>
    </>
  );
};
export default AppNavSettings;
