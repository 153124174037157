import { useCallback } from "react";

function compareIgnoreCase(a: any, b: any) {
  let r1 = String(a).toLowerCase();
  let r2 = String(b).toLowerCase();
  if (r1 < r2) {
    return -1;
  }
  if (r1 > r2) {
    return 1;
  }
  return 0;
}

export const useAlphanumericSort = () => {
  return useCallback((row1, row2, columnName) => {
    return compareIgnoreCase(row1.values[columnName], row2.values[columnName]);
  }, []);
};
