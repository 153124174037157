import AIWriterGeneratedContentSingle from "components/AIWriter/AIWriterGeneratedContentSingle";
import { AIContentSuggestion } from "encharge-domain/definitions/AIContentSuggestion";
import _ from "lodash";
import { observer } from "mobx-react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

interface Props {
  suggestions: AIContentSuggestion[];
  generateSamples?: (example: string) => void;
  hideButtons?: React.ComponentProps<
    typeof AIWriterGeneratedContentSingle
  >["hideButtons"];
}

const AIWriterGeneratedContent = (props: Props) => {
  const contentSuggestions = _.map(
    props.suggestions.reverse(),
    (contentSuggestion) => {
      return (
        <AIWriterGeneratedContentSingle
          key={contentSuggestion.id}
          contentSuggestion={contentSuggestion}
          generateSamples={props.generateSamples}
          hideButtons={props.hideButtons}
        />
      );
    }
  );
  return (
    <div>
      <ReactCSSTransitionGroup
        className="div"
        transitionName="transition"
        transitionEnter={true}
        transitionEnterTimeout={300}
        transitionLeave={false}
        transitionLeaveTimeout={300}
        transitionAppear={true}
        transitionAppearTimeout={300}
      >
        {contentSuggestions}
      </ReactCSSTransitionGroup>
    </div>
  );
};
export default observer(AIWriterGeneratedContent);
