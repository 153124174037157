import { Component } from "react";
import { Route, Link } from "react-router-dom";

interface Props {
  label: string;
  path: string;
  fullPageReload?: boolean;
}

class TopBarNavRoute extends Component<Props & { active: boolean }> {
  render() {
    const isActiveRouteClass = this.props.active ? "active" : "";
    return (
      <div>
        {this.props.fullPageReload === true ? (
          // for full page refresh
          <a
            href={this.props.path}
            className={`full-page main-bar-nav-item ${isActiveRouteClass}`}
          >
            <span>{this.props.label}</span>
          </a>
        ) : (
          // for single page link use
          <Link
            to={this.props.path}
            className={`main-bar-nav-item ${isActiveRouteClass}`}
            data-testid={`app-nav-${this.props.label}`}
          >
            <span>{this.props.label}</span>
          </Link>
        )}
      </div>
    );
  }
}
class AppNavItem extends Component<Props> {
  render() {
    return (
      <Route
        key={this.props.label}
        path={this.props.path}
        children={({ match, location }) => {
          // if complete match (as specified by react router)
          // OR the current path begins the same string
          const pathStartsTheSameWay =
            location.pathname.indexOf(this.props.path) === 0;
          const activeRoute = Boolean(match) || pathStartsTheSameWay;

          return (
            <TopBarNavRoute
              active={activeRoute}
              path={this.props.path}
              label={this.props.label}
              fullPageReload={this.props.fullPageReload}
            />
          );
        }}
      />
    );
  }
}

export default AppNavItem;
