import _ from "lodash";
import { toJS, runInAction } from "mobx";
import { compare, applyPatch } from "fast-json-patch";
import { IStep, StepBase } from "../components/FlowEditor/Step/StepBase";
import { toastError } from "../domain/errorHandling/toaster";
import { log } from "../domain/errorHandling/logToBackend";
import { IFlow } from "components/Flows/FlowRoute";

/**
 * Perform an action that can be confirmed or rejected.
 *
 * @param {*} state
 * @param {() => any} performAction - Function that modifies the state.
 * @param {() => Promise<any>} confirmAction - Function that confirms the modifications to the
 * state if it resolves, or cancels them if it throws.
 */
// TODO - replace with confirmableAction
export const confirmableActionToDepreciate = async (
  state: any,
  performAction: () => any,
  confirmAction: () => Promise<any>
) => {
  // Make a reverse patch of the changes we do in the action
  // don't overwrite newer changes if we undo
  const prevState = toJS(state);
  // Modifies state
  runInAction(performAction);
  const newState = toJS(state);
  const reversePatch = compare(newState, prevState);

  try {
    // Confirm the action
    const asyncResult = await confirmAction();
    return asyncResult;
  } catch (e) {
    // Reverse the effects of the patch
    console.log("Reversing action", e);
    // Show message to the user
    toastError({
      message: "Error while saving.",
      extra: e,
    });

    runInAction(() => {
      try {
        const restoredState = applyPatch(state, reversePatch).newDocument;
        _.merge(state, restoredState);
      } catch (e) {
        // Show message to the user
        toastError(
          "There was an error while reversing a failed save. Please refresh the page. "
        );
        log({ message: "Error while reversing action", extra: { error: e } });
        console.log("Error while reversing action", e);
      }
    });
  }
};

export const findStepByIdOrTempId = (
  steps: Dictionary<IStep>,
  ids: {
    id?: IStep["id"];
    tempId?: IStep["tempId"];
  }
) => {
  if (ids.id) {
    const opById = _.find(steps, (step) => step.id === ids.id);
    if (opById !== undefined) {
      return opById;
    }
  }
  if (ids.tempId) {
    return _.find(steps, (step) => step.tempId === ids.tempId);
  }
  return undefined;
};

export function hexToRgb(hex: string) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      /// @ts-ignore
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")
    );
  }
  return "";
}
