import React from "react";

// Adapted from https://react-table.tanstack.com/docs/examples/row-selection
export const ItemsSelectableCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }: any, ref: any) => {
    // Typed ref for the input element
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef: React.RefObject<HTMLInputElement> | null =
      ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef?.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return (
      <div
        className="table-main-select"
        onClick={(e) => {
          // A click anywhere on the cell, should trigger the checkbox
          e.preventDefault();
          e.stopPropagation();
          if (resolvedRef?.current) {
            resolvedRef.current.click();
          }
        }}
      >
        <div className="custom-control custom-checkbox custom-checkbox-sm custom-control-no-label">
          <input
            type="checkbox"
            className="custom-control-input"
            ref={resolvedRef}
            {...rest}
          />
          <label className="custom-control-label" />
        </div>
      </div>
    );
  }
);
