import { ILink } from "../../components/FlowEditor/Link/Link";
import { apiBase } from "../../constants";
import request, { AxiosResponse } from "axios";

export const createLink = async (link: ILink) => {
  // Creating new link
  let res: AxiosResponse<{ link: IIntegrationLink }>;
  res = await request({
    url: `${apiBase}/v1/links/`,
    method: "POST",
    data: link,
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  const jsonBody = res.data;
  return jsonBody.link;
  // So that we dont fire a delete AJAX request
  // this.errorWhileCreating = true;
  // this.removeElement();
};

export const deleteLinkBySourceAndTarget = async (
  sourceId: IIntegrationStep["id"],
  targetId: IIntegrationStep["id"]
) => {
  return request({
    url: `${apiBase}/v1/links/${sourceId}/${targetId}`,
    method: "DELETE",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
};

export const deleteLink = async (id: NonNullable<ILink["id"]>) => {
  await request({
    url: `${apiBase}/v1/links/${id}`,
    method: "DELETE",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
};
