import _ from "lodash";
import AppNavMobile from "./AppNavMobile";
import AppNavItem from "./AppNavItem";
import AppNavManage from "./AppNavManage";
import AppNavObjects from "components/AppNav/AppNavObjects";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";
import AppNavMetrics from "components/AppNav/AppNavMetrics";

const navItemsFirst = [
  { label: "Dashboard", path: "/dashboard" },
  { label: "Flows", path: "/flows" },
];
const navItemsLast = [
  { label: "Broadcasts", path: "/broadcasts" },
  { label: "Emails", path: "/emails" },
  { label: "Forms", path: "/forms" },
  { label: "Events", path: "/events" },
];
const mobileMidItems = [{ label: "People", path: "/segments" }];
const mobileExtraItems = [
  { label: "Flow Metrics", path: "/metrics/flows" },
  { label: "Email Metrics", path: "/metrics/emails" },
  { label: "Tags", path: "/settings/tags" },
  { label: "Custom Fields", path: "/settings/custom-fields" },
];

const AppNav = () => {
  const { customObjectsStore } = useStores();
  const schemas = customObjectsStore.schemas.current();
  const customObjectsItems = _.map(schemas, (schema) => ({
    label: schema.displayNamePlural,
    path: `/objects/${schema.name}`,
  }));
  const renderItem = (item: typeof navItemsFirst[0]) => (
    <AppNavItem label={item.label} path={item.path} key={item.label} />
  );

  return (
    <>
      {/* Show full or mobile version */}
      <div className="d-none d-lg-flex">
        {_.map(navItemsFirst, renderItem)}
        <AppNavObjects schemas={schemas} />
        {_.map(navItemsLast, renderItem)}
        <AppNavMetrics />
        <AppNavManage />
      </div>
      <div className="d-block d-lg-none">
        <AppNavMobile
          items={[
            ...navItemsFirst,
            ...mobileMidItems,
            ..._.filter(customObjectsItems, (item) => item.label !== "People"),
            ...navItemsLast,
            ...mobileExtraItems,
          ]}
        />
      </div>
    </>
  );
};

export default observer(AppNav);
