import { useScript } from "hooks/useScript";

const ProactiveReachoutChatInner = () => {
  const w = window as any;
  w.ChatraID = "NBmL6wpzdpzDwzNyK";
  w.ChatraSetup = {
    disabledOnMobile: true,
  };
  useScript("https://call.chatra.io/chatra.js");
  return null;
};
export default ProactiveReachoutChatInner;
