import { action, observable, runInAction, when } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import {
  getDomains,
  addDomain,
  verifyDomain,
  deleteDomain,
  getCommunicationPreferencesSettings,
  saveCommunicationPreferencesSettings,
} from "./persistence/persistEmailSettings";
import enchargeAPI from "./persistence/enchargeAPI";
import { IEmailAddressVerification } from "encharge-domain/lib/definitions/EmailAddress";
import { ICommunicationCategory } from "encharge-domain/lib/definitions/CommunicationCategory";
import {
  getDomainFromURL,
  isValidDomain,
} from "encharge-domain/lib/helpers/email_helper";
import { updateAccount } from "./persistence/persistAccount";
import { shouldSkipResouces } from "domain/helpers/shouldSkipResouces";

export class EmailSettingsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
    if (shouldSkipResouces()) return;
    // Trigger loading of the resources upon loading
    this.emails.current();
    this.domains.current();
  }

  @observable
  firstVerifiedEmail = () => {
    const emails = this.emails.current();
    if (!emails) return undefined;
    return _.find(emails, (email) => email.status === "verified")?.email;
  };

  @observable
  emails = lazyObservable<IEmailAddressVerification[]>((sink, onError) => {
    enchargeAPI
      .getEmailVerifications()
      .then(({ emails }) => {
        sink(emails);
      })
      .catch((e) => {
        toastError({
          message: "Error while loading email addresses.",
          extra: e,
        });
        onError(e);
      });
  });
  @observable
  domains = lazyObservable<IEmailDomain[]>((sink, onError) => {
    getDomains()
      .then((items) => {
        sink(observable(items));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading email domains.",
          extra: e,
        });
        onError(e);
      });
  });

  @observable
  categories = lazyObservable<ICommunicationCategory[]>((sink, onError) => {
    enchargeAPI
      .getCommunicationCategories()
      .then(({ items }) => {
        sink(observable(items));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading categories.",
          extra: e,
        });
        onError(e);
      });
  });

  @observable
  communicationPreferencesSettings = lazyObservable<
    CommunicationPreferencesSettings
  >((sink, onError) => {
    getCommunicationPreferencesSettings()
      .then((items) => {
        sink(observable(items));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading email domains.",
          extra: e,
        });
        onError(e);
      });
  });

  getDomainById(domainId: number) {
    const domains = this.domains.current();
    if (!domains) {
      return undefined;
    }
    return _.find(domains, (domain) => domain.id === domainId);
  }

  getDomainByDomainName(domainName: string) {
    const domains = this.domains.current();
    if (!domains) {
      return undefined;
    }
    return _.find(domains, (domain) => domain.domain === domainName);
  }

  @action
  async addDomain(domainString: string) {
    const domains = this.domains.current();
    if (!domains) {
      return undefined;
    }
    this.rootStore.uiStore.emailDomainAdd.startLoading();
    const strippedDomain = getDomainFromURL(domainString);
    try {
      if (!isValidDomain(strippedDomain)) {
        toastError("Invalid domain name");
        return;
      }

      const created = await addDomain({ domain: strippedDomain });
      runInAction(() => {
        this.domains.put([...this.domains.current(), created]);
      });
      return created;
    } catch (e) {
      toastError({
        message: "Error while saving the email domain.",
        extra: e,
      });
    } finally {
      runInAction(() => this.rootStore.uiStore.emailDomainAdd.finishLoading());
    }
    return;
  }

  @action
  async verifyDomain(id: IEmailDomain["id"]) {
    this.rootStore.uiStore.emailVerify.startLoading();
    try {
      const verified = await verifyDomain(id);

      // replace the domain we just verified
      runInAction(() => {
        this.domains.put(
          _.map(this.domains.current(), (domain) => {
            if (domain.id === verified.id) return verified;
            return domain;
          })
        );
      });
      // refresh the emails
      this.emails.refresh();
    } catch (e) {
      toastError({
        message: "Error while verifying the email domain.",
        extra: e,
      });
    } finally {
      runInAction(() => this.rootStore.uiStore.emailVerify.finishLoading());
    }
  }

  @action
  async deleteDomain(id: number) {
    if (window.confirm("Really delete?")) {
      this.rootStore.uiStore.emailDomainDelete.startLoading();
      try {
        await deleteDomain(id);

        // replace the domain we just verified
        runInAction(() => {
          this.domains.put(
            _.filter(this.domains.current(), (domain) => domain.id !== id)
          );
        });
        return true;
      } catch (e) {
        toastError({
          message: "Error while deleting the email domain.",
          extra: e,
        });
      } finally {
        runInAction(() =>
          this.rootStore.uiStore.emailDomainDelete.finishLoading()
        );
      }
    }
    return false;
  }

  getEmailAddressVerification(email: string) {
    const emails = this.emails.current();
    if (!emails || !email) {
      return undefined;
    }
    const emailVerification = _.find(
      emails,
      (current) => current.email === email
    );
    return emailVerification;
  }

  @action
  async verifyEmailAddress(email: string) {
    this.rootStore.uiStore.emailVerify.startLoading();
    try {
      const created = await enchargeAPI.createEmailVerification({ email });
      // if email is not already being verified, add it to list of emails
      if (
        !_.find(
          this.emails.current(),
          (current) => current?.email === created?.email?.email
        )
      ) {
        runInAction(() => {
          this.emails.put([created.email, ...this.emails.current()]);
        });
      }
      if (created.email.status !== "verified") {
        toastSuccess("Verification email sent 💌 ");
      }
    } catch (e) {
      toastError({
        message: "Error while verifying the email address.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.emailVerify.finishLoading();
    }
  }

  @action
  async deleteEmailAddress(email: string) {
    try {
      this.rootStore.uiStore.emailVerify.startLoading();
      await enchargeAPI.deleteEmailVerification(email);
      // Remove from local store
      this.emails.put(
        _.filter(this.emails.current(), (current) => current.email !== email)
      );
    } catch (e) {
      toastError({
        message: "Error while delete the email address.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.emailVerify.finishLoading();
    }
  }

  get emailFonts() {
    return this.rootStore?.accountStore?.account?.emailFonts;
  }

  @action
  async addEmailFont(font: IEmailFont) {
    this.rootStore.uiStore.emailFontLoading.startLoading();
    const account = this.rootStore.accountStore.account;
    if (!account) return;
    if (!account.emailFonts) {
      account.emailFonts = [];
    }
    account.emailFonts.push(font);
    try {
      await updateAccount({
        data: { emailFonts: account.emailFonts },
      });
    } catch {
      toastError("Couldn't save the font.");
    } finally {
      this.rootStore.uiStore.emailFontLoading.finishLoading();
    }
  }

  @action
  async removeEmailFont(font: IEmailFont) {
    this.rootStore.uiStore.emailFontLoading.startLoading();
    const account = this.rootStore.accountStore.account;
    if (!account) return;
    account.emailFonts = _.filter(
      account.emailFonts,
      (current) => !_.isEqual(font, current)
    );
    try {
      await updateAccount({
        data: { emailFonts: account.emailFonts },
      });
    } catch {
      toastError("Couldn't delete the font.");
    } finally {
      this.rootStore.uiStore.emailFontLoading.finishLoading();
    }
  }

  getCategory(id: number) {
    const categories = this.categories.current();
    if (!categories) {
      return undefined;
    }
    return _.find(categories, (current) => current.id === id);
  }

  async createCategory(name: string, description: string) {
    const categories = this.categories.current();
    if (!categories) {
      return undefined;
    }
    this.rootStore.uiStore.communicationCategoryLoading.startLoading();
    try {
      const { item } = await enchargeAPI.createCommunicationCategory({
        name,
        description,
      });
      runInAction(() => {
        this.categories.put([...this.categories.current(), observable(item)]);
      });
      return item;
    } catch (e) {
      toastError({
        message: "Error while saving the category.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.communicationCategoryLoading.finishLoading();
    }
    return;
  }

  async editCategory({
    id,
    name,
    description,
    isArchived,
  }: {
    id: number;
    name: string;
    description: string;
    isArchived?: boolean;
  }) {
    try {
      this.rootStore.uiStore.communicationCategoryLoading.startLoading();
      const { item } = await enchargeAPI.editCommunicationCategory(id, {
        name,
        description,
        // update isArchived if specified
        ...(isArchived === undefined ? {} : { isArchived }),
      });
      if (item) {
        runInAction(() => {
          const categories = this.categories.current();
          let category = _.find(categories, (current) => current.id === id);
          if (category) {
            category.name = item.name;
            category.description = item.description;
            category.isArchived = item.isArchived;
          }
        });
        toastSuccess("✅ Category updated. ");
      }
    } catch (e) {
      toastError({
        message: "Error while updating category.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.communicationCategoryLoading.finishLoading();
    }
    return;
  }

  async deactivateCategory(id: number) {
    const category = this.getCategory(id);
    if (!category) return;
    return this.editCategory({ ...category, isArchived: true });
  }
  async activateCategory(id: number) {
    const category = this.getCategory(id);
    if (!category) return;
    return this.editCategory({ ...category, isArchived: false });
  }

  editCommunicationPreferencesSettings({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) {
    this.communicationPreferencesSettings.put({
      ...(this.communicationPreferencesSettings.current() || {}),
      [key]: value,
    });
  }

  async saveCommunicationPreferencesSettings() {
    const settings = this.communicationPreferencesSettings.current();
    if (!settings) return;
    try {
      this.rootStore.uiStore.communicationPreferencesSettings.startLoading();
      await saveCommunicationPreferencesSettings(settings);
      toastSuccess("✅ Settings updated. ");
    } catch (e) {
      toastError({
        message: "Error while updating settings.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.communicationPreferencesSettings.finishLoading();
    }
  }
}

export type CommunicationPreferencesSettings = Dictionary<string>;
