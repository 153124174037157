import { objectsPageSize } from "../../../constants";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  getEmailsEvents,
  getFlowsEvents,
} from "store/persistence/persistMetrics";
import { getNextPageParam } from "store/react-query/getNextPageParam";
import { metricsEventsKeys } from "store/react-query/queryKeys";
import { IMetricsType } from "components/Charts/SingleMetricChart";

export function useGetListMetricEvents({
  emailIds,
  type,
  endDate,
  startDate,
  flowIds,
  broadcastId,
  abTestVariantId,
  stepId,
}: {
  emailIds?: IEmailContent["id"][];
  type: IMetricsType;
  endDate?: Date;
  startDate?: Date;
  flowIds?: IIntegration["id"][];
  broadcastId?: number;
  abTestVariantId?: string;
  stepId?: number;
}) {
  let fetchEvents;
  if (type === "entered" || type === "reachedGoal") {
    fetchEvents = async ({ pageParam = 0 }) =>
      getFlowsEvents({
        type,
        endDate,
        startDate,
        flowIds,
        offset: pageParam * objectsPageSize,
      });
  } else {
    fetchEvents = async ({ pageParam = 0 }) =>
      getEmailsEvents({
        emailIds,
        type,
        endDate,
        startDate,
        flowIds,
        broadcastId,
        abTestVariantId,
        stepId,
        offset: pageParam * objectsPageSize,
      });
  }
  return useInfiniteQuery(
    metricsEventsKeys.events({
      emailIds,
      type: type,
      endDate,
      startDate,
      flowIds,
      abTestVariantId,
      broadcastId,
      stepId,
    }),
    fetchEvents,
    {
      getNextPageParam,
    }
  );
}
