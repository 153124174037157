import { toastError } from "domain/errorHandling/toaster";
import { getUserTimezone } from "domain/helpers/asDateTime";
import { segmentIdentify, segmentTrack } from "domain/helpers/segment.io";
import _ from "lodash";
import { action, runInAction } from "mobx";
import { DomainStore } from "store/domainStore";
import {
  registerRegistrationFinished,
  streamAccountOnboarding,
  updateAccount,
} from "store/persistence/persistAccount";
import {
  registerAccount,
  setIntialPassword,
} from "store/persistence/persistRegistration";

export type RegistrationData = Partial<{
  name: string;
  password: string;

  phone: string;
  site: string;
  industry: string;
  goals: string;
  previousTool: string;
}>;

export class RegistrationStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @action
  async register({
    email,
    idToken,
    source,
    botProtectionToken,
  }: {
    email?: string;
    idToken?: string;
    source?: string;
    botProtectionToken?: string;
  }) {
    const ui = this.rootStore.uiStore.accountRegister;
    try {
      if (!email && !idToken) {
        throw new Error("Missing registration email.");
      }
      ui.startLoading();
      const accountInfo = await registerAccount({
        idToken,
        email,
        source,
        timezone: getUserTimezone(),
        botProtectionToken,
      });
      this.rootStore.accountStore.populateAccountData(accountInfo);
      this.trackRegistrationAction({
        traits: { email: this.rootStore.accountStore.account?.email },
        eventName: "Account Registration Set Email",
      });
      ui.finishLoading();
    } catch (e) {
      toastError({
        message: "Error while creating your account.",
        extra: e,
      });
      ui.finishLoading((e as Error).message);
    }
  }

  @action
  trackRegistrationAction({
    traits,
    eventName,
  }: {
    traits?: Dictionary<any>;
    eventName?: string;
  }) {
    const teamMemberId = this.rootStore.accountStore.account?.teamMemberId;
    const accountId = this.rootStore.accountStore.account?.accountId;
    if (traits) {
      segmentIdentify({ accountId, teamMemberId, traits });
    }
    if (eventName) {
      segmentTrack({ name: eventName });
    }
  }

  @action
  async saveAccountRegistrationData(accountData: RegistrationData) {
    this.rootStore.uiStore.accountEdit.startLoading();
    try {
      if (accountData.name) {
        // strip any html from the name
        accountData.name = accountData.name.replace(/<[^>]*>/g, "");
        // strip any http links from the name
        accountData.name = accountData.name.replace(/http[^ ]*/g, "");

        this.trackRegistrationAction({
          traits: { name: accountData.name },
          eventName: "Account Registration Set Name",
        });
        await this.rootStore.accountStore.setTeamMemberName(accountData.name);
      }
      if (accountData.password) {
        this.trackRegistrationAction({
          eventName: "Account Registration Set Password",
        });
        await this.rootStore.accountStore.setAccountFlag(
          "hasSetInitialPassword",
          true
        );
        await setIntialPassword(accountData.password);
      } else if (accountData.phone) {
        this.trackRegistrationAction({
          traits: { phone: accountData.phone },
          eventName: "Account Registration Set Phone",
        });
        await this.rootStore.accountStore.setPhone(accountData.phone);
      } else if (accountData.site || accountData.industry) {
        if (accountData.site) {
          // format as url
          if (!accountData.site.startsWith("http")) {
            accountData.site = `https://${accountData.site}`;
          }
        }
        runInAction(() => {
          if (this.rootStore.accountStore.account) {
            this.rootStore.accountStore.account.site = accountData.site;
            this.rootStore.accountStore.account.industry = accountData.industry;
          }
        });
        this.trackRegistrationAction({
          traits: {
            site: accountData.site,
            industry: accountData.industry,
          },
          eventName: "Account Registration Set Company Info",
        });

        try {
          const firstPromoterScript = (window as any)?.$FPROM;
          firstPromoterScript?.trackSignup?.(
            {
              email: this.rootStore.accountStore.account?.email,
              uid: this.rootStore.accountStore.account?.accountId,
            },
            function () {
              console.log(`FirstPromoter: Signup tracked`);
            }
          );
        } catch (e) {
          console.error("FirstPromoter: Error tracking signup", e);
        }

        await this.rootStore.accountStore.setAccountFlag(
          "providedSiteAndName",
          true
        );

        await updateAccount({
          data: _.omit(accountData, "password", "name"),
        });
        // registration finished at this point
        await this.registrationFinished();
      } else {
      }
    } catch {
      toastError("Couldn't save your account.");
    } finally {
      this.rootStore.uiStore.accountEdit.finishLoading();
    }
  }

  @action
  async registrationFinished() {
    try {
      this.rootStore.uiStore.accountEdit.startLoading();
      this.trackRegistrationAction({
        eventName: "Account Registration Finished",
      });
      await registerRegistrationFinished();
    } catch {
      toastError("Couldn't save your account.");
    } finally {
      this.rootStore.uiStore.accountEdit.finishLoading();
    }
  }

  async streamAccountOnboarding({
    streamResponse,
    onDone,
    onError,
  }: {
    streamResponse: (response: string) => void;
    onDone: () => void;
    onError: (message: string) => void;
  }) {
    try {
      const accountToken = this.rootStore.accountStore.account?.apiKeys?.[0];
      if (!accountToken) {
        throw new Error("No account token");
      }
      await streamAccountOnboarding({
        streamResponse,
        accountToken,
        onDone,
      });
    } catch (e) {
      toastError((e as any).message);
      onError((e as any).message);
    }
  }
}
