export const itemsSortDates = (rowA: any, rowB: any, columnId: string) => {
  // We keep the original Date object columnDate,
  // e.g in createdAtDate for createdAt column
  const rawDateA =
    rowA?.original?.[`${columnId}Date`] || rowA?.original?.[columnId];
  const rawDateB =
    rowB?.original?.[`${columnId}Date`] || rowB?.original?.[columnId];
  const dateA = Number(new Date(rawDateA));
  const dateB = Number(new Date(rawDateB));
  return dateA > dateB ? 1 : -1;
};

export const itemsSortFormattedNumber = (
  rowA: any,
  rowB: any,
  columnId: string
) => {
  const rawNumberA = Number(
    String(rowA?.original?.[columnId]).replace(/,/g, "")
  );
  const rawNumberB = Number(
    String(rowB?.original?.[columnId]).replace(/,/g, "")
  );
  return rawNumberA > rawNumberB ? 1 : -1;
};

export const itemsSortFolderType = (rowA: any, rowB: any) => {
  const folderTypeA = rowA?.original?.folderNameSort;
  const folderTypeB = rowB?.original?.folderNameSort;
  if (!folderTypeA) return 1;
  if (!folderTypeB) return -1;
  return folderTypeA > folderTypeB ? 1 : -1;
};
