import { useStores } from "../../store/useStore";
import { observer } from "mobx-react";
import { useCallback } from "react";
import {
  defaultPremiumPlan,
  getPlanByPeople,
  getPlanFromNameAndPeople,
} from "encharge-domain/lib/values/plans";
import LoadingCentered from "components/LoadingCentered";
import { openChargebeeCheckout } from "components/Billing/ChargebeeCheckout";
import UpgradeToPremiumDialogView from "components/Upgrade/UpgradeToPremiumDialogView";

/**
 * Upgrade to premium button
 */
const UpgradeToPremiumDialog = () => {
  const {
    accountStore,
    uiStore: { upgradeToPremium },
  } = useStores();

  const closeCallback = useCallback(() => upgradeToPremium.close(), [
    upgradeToPremium,
  ]);

  const currentPlan = accountStore.account?.payment?.plan;

  let premiumPlan = getPlanByPeople({
    name: defaultPremiumPlan,
    people:
      currentPlan?.peopleUpperLimit ||
      accountStore.account?.peopleCount ||
      2000,
  });

  const namePremium = currentPlan?.name?.replace("growth", "premium");
  if (currentPlan && namePremium) {
    // get the same plan but premium
    try {
      premiumPlan = getPlanFromNameAndPeople({
        name: namePremium,
        peopleUpperLimit: currentPlan?.peopleUpperLimit,
      });
    } catch (e) {
      console.log("Error getting premium plan", e);
    }
  }

  const hasPaymentDetails =
    accountStore.account?.payment?.paymentMethod === "chargebee";

  const upgradeToPremiumCallback = useCallback(() => {
    if (hasPaymentDetails) {
      if (premiumPlan) {
        accountStore.changePlan({
          planName: premiumPlan.name,
          planPeople: premiumPlan.peopleUpperLimit,
        });
      }
    } else {
      if (premiumPlan) {
        openChargebeeCheckout({
          plan: premiumPlan.name,
          people: premiumPlan.peopleUpperLimit,
          accountId: accountStore.account!.accountId,
          email: accountStore.account?.email,
        });
      }
    }
  }, [accountStore, hasPaymentDetails, premiumPlan]);

  if (!accountStore.account) {
    return null;
  }

  const isPremium = accountStore.accountTier === "premium";
  if (isPremium) {
    return null;
  }

  return (
    <UpgradeToPremiumDialogView
      premiumPlan={premiumPlan}
      hasPaymentDetails={hasPaymentDetails}
      upgradeToPremium={upgradeToPremiumCallback}
      onClose={closeCallback}
      isOpen={upgradeToPremium.isOpen}
      isLoading={upgradeToPremium.loading}
    />
  );
};

export default observer(UpgradeToPremiumDialog);
