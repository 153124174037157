import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import { useStores } from "store/useStore";
import RegistrationStepGenerateFlowsView from "./RegistrationStepGenerateFlowsView";

import {
  aiOnboardingCompanyInfoCompletedMessage,
  aiOnboardingCompanyInfoStartMessage,
  aiOnboardingEmailStartMessage,
  aiOnboardingEmailCompletedMessage,
  aiOnboardingNotSaasMessage,
  doneMessage,
} from "encharge-domain/lib/service_layer/AI/ai_onboarding_helper";
import { redirectWithReload } from "domain/helpers/redirect";
import _ from "lodash";

const totalEmails = 6;

export type GeneratedCompanyInfo = { title: string; description: string }[];

const RegistrationStepGenerateFlows = observer(() => {
  const { registrationStore, accountStore } = useStores();

  const [streamedData, setStreamedData] = useState("");

  const [error, setError] = useState<string>("");
  const [isDone, setIsDone] = useState(false);
  const [isGeneratingCompanyInfo, setIsGeneratingCompanyInfo] = useState(true);
  const [companyInfo, setCompanyInfo] = useState<GeneratedCompanyInfo>([]);
  const [isSaaS, setIsSaaS] = useState<boolean>();
  const [isB2B, setIsB2B] = useState<boolean>();

  const [isGeneratingEmailIndex, setIsGeneratingEmailIndex] = useState<
    undefined | number
  >(undefined);
  const [isCurrentStepCompleted, setIsCurrentStepCompleted] = useState(false);
  const [emails, setEmails] = useState<{ subject: string; text: string }[]>([]);

  const parseResponse = (response: string) => {
    if (response.includes(aiOnboardingCompanyInfoCompletedMessage)) {
      setIsGeneratingCompanyInfo(false);
      setIsCurrentStepCompleted(true);
    }

    // get company info
    const { companyInfo: companyInfoObject, isB2B, isSaaS } = parseCompanyInfo(
      response
    );
    setCompanyInfo(companyInfoObject);
    setIsSaaS(isSaaS);
    setIsB2B(isB2B);

    // check if it's SaaS
    if (response.includes(aiOnboardingNotSaasMessage)) {
      setIsSaaS(false);
    }

    // parse emails
    const {
      emails,
      isGeneratingEmailIndex,
      isCurrentStepCompleted,
    } = parseEmails(response);
    setEmails(emails);
    setIsGeneratingEmailIndex(isGeneratingEmailIndex);
    setIsCurrentStepCompleted(isCurrentStepCompleted);

    if (response.includes(doneMessage)) {
      setIsDone(true);
    }
  };

  useEffect(() => {
    registrationStore?.streamAccountOnboarding({
      streamResponse: (data) => {
        // instead of useState use a reducer for adding to streamedData
        setStreamedData((current) => current + data);
      },
      onDone: () => {
        setIsDone(true);
      },
      onError: (error) => {
        setError(error);
        setIsDone(true);
      },
    });
  }, [registrationStore]);

  // parse the streamed data on every change
  useEffect(() => {
    parseResponse(streamedData);
  }, [streamedData]);

  const account = accountStore.account;
  const site = account?.site || "";
  const fromName = account?.teamMemberName || "";
  const fromEmail = account?.email || "";

  const onSkip = useCallback(
    (path) => {
      accountStore.setAccountFlag("aiFlowsGenerated", true).then(() => {
        redirectWithReload(path || "/get-started?register=1");
      });
    },
    [accountStore]
  );

  // const emails = [
  //   {
  //     subject: "Welcome to Encharge!",
  //     text:
  //       "Welcome to Encharge! We're excited to have you on board. We'll be sending you a few emails to help you get started.",
  //   },
  //   {
  //     subject: "Welcome to Encharge 2!",
  //     text:
  //       "Welcome to Encharge! We're excited to have you on board. We'll be sending you a few emails to help you get started.",
  //   },
  // ];

  let title = isGeneratingCompanyInfo
    ? "Analyzing website..."
    : !isCurrentStepCompleted
    ? `Generating Email ${isGeneratingEmailIndex || 0 + 1}...`
    : `Email ${isGeneratingEmailIndex || 0 + 1} completed`;

  if (isDone) {
    title = "Your account is ready!";
  }
  let subtitle = isDone
    ? "Access your Encharge account with bespoke emails created for your business."
    : "Encharge will create unique emails for your business. You can preview the emails below. This can take up to 5 minutes. You can leave this page, we’ll notify you via email when your account is ready.";

  return (
    <RegistrationStepGenerateFlowsView
      title={title}
      subtitle={subtitle}
      site={site}
      isSaaS={isSaaS}
      isB2B={isB2B}
      companyInfo={companyInfo}
      totalEmails={totalEmails}
      isGeneratingCompanyInfo={isGeneratingCompanyInfo}
      isGeneratingEmailIndex={
        isGeneratingCompanyInfo ? undefined : isGeneratingEmailIndex
      }
      isCurrentStepCompleted={isCurrentStepCompleted}
      isDone={isDone}
      emailsFromName={fromName}
      emailsFromEmail={fromEmail}
      emails={emails}
      onSkip={onSkip}
      error={error}
    />
  );
});

/**
 * Parses the response from the server and returns the emails
 */
function parseEmails(response: string) {
  let responseCurrent = response;

  let emails: {
    subject: string;
    text: string;
  }[] = [];

  let emailCompletedIndex = -1;

  do {
    const aiOnboardingEmailStartMessageIndex = responseCurrent.indexOf(
      aiOnboardingEmailStartMessage
    );
    if (aiOnboardingEmailStartMessageIndex === -1) {
      break;
    }
    const startIndex =
      aiOnboardingEmailStartMessageIndex +
      // exclude the message itself
      aiOnboardingEmailStartMessage.length;
    // end of string
    emailCompletedIndex = responseCurrent.indexOf(
      aiOnboardingEmailCompletedMessage
    );
    const endIndex =
      emailCompletedIndex === -1 ? responseCurrent.length : emailCompletedIndex;

    const emailText = responseCurrent.substring(startIndex, endIndex);
    // find email subject (Subject: ...)
    const subjectIndex = emailText.indexOf("Subject:");
    // find email text (Text: ...)
    const textIndex = emailText.indexOf("Text:");
    const subject = emailText
      .substring(subjectIndex + "Subject:".length, textIndex)
      .trim();
    const text = emailText.substring(textIndex + "Text:".length).trim();

    emails.push({ subject, text });

    responseCurrent = responseCurrent.substring(
      endIndex + aiOnboardingEmailCompletedMessage.length
    );
  } while (responseCurrent.includes(aiOnboardingEmailStartMessage));

  const isGeneratingEmailIndex = emails.length - 1;
  const isCurrentStepCompleted = emailCompletedIndex !== -1;

  return {
    emails: emails,
    isGeneratingEmailIndex,
    isCurrentStepCompleted,
  };
}

/**
 * Parses the response from the server and returns the company info
 */
export function parseCompanyInfo(response: string) {
  const startIndex =
    response.indexOf(aiOnboardingCompanyInfoStartMessage) +
    // exclude the message itself
    aiOnboardingCompanyInfoStartMessage.length;
  // end of string
  const endIndex =
    response.indexOf(aiOnboardingCompanyInfoCompletedMessage) === -1
      ? response.length
      : response.indexOf(aiOnboardingCompanyInfoCompletedMessage);
  const companyInfoText = response.substring(startIndex, endIndex);

  // parse company info by splitting by new line
  const companyInfo = companyInfoText
    .split("\n")
    .filter((line) => line.trim() !== "");

  let isSaaS: boolean | undefined = undefined;
  let isB2B: boolean | undefined = undefined;
  // each line should have title: description
  // eg Company Details: Advanced behavioral emails, detailed company profiles, billing integrations, and dynamic CRM syncing.
  const companyInfoObject = companyInfo.reduce((acc, line) => {
    let [title, ...rest] = line.split(":");
    // if (split.length !== 2) {
    //   return acc;
    // }
    if (title === "https") {
      return acc;
    }
    title = _.trim(title, ` \t\n\r"{}()[],`);
    const description = _.trim(rest.join(":"), ` \t\n\r"{}()[],`);
    if (title.toLowerCase().includes("is saas")) {
      isSaaS =
        description.toLowerCase().includes("yes") ||
        description.toLowerCase().includes("true");
      return acc;
    }
    if (title.toLowerCase().includes("is b2b")) {
      isB2B =
        description.toLowerCase().includes("yes") ||
        description.toLowerCase().includes("true");
      return acc;
    }
    acc.push({ title, description });
    return acc;
  }, [] as { title: string; description: string }[]);
  return { companyInfo: companyInfoObject, isSaaS, isB2B };
}

export default RegistrationStepGenerateFlows;
