import { getGraph } from "./getJointjsSingletons";
import * as joint from "jointjs";
import _ from "lodash";

import { IStep } from "../../components/FlowEditor/Step/StepBase";

/**
 * Find the JointJS element for a flow step.
 *
 * @param {IStep["id"]} stepId
 * @returns joint.shapes.app.Step | undefined;
 */
const getElementByStepId = (stepId: IStep["id"]) => {
  const graph = getGraph();
  if (!graph) return;
  const allElements = graph.getElements();
  return _.find(
    allElements,
    (el: joint.shapes.app.Step) =>
      el?.isElement() && el?.attributes?.step?.id === stepId
  ) as joint.shapes.app.Step | undefined;
};

/**
 * Trigger a redraw of the JointJS element underneath a step in the flow editor.
 *
 * @param {IStep["id"]} stepId
 */
const redrawStepElement = (stepId: IStep["id"]) => {
  // Update step element to match the step body
  const stepElement = getElementByStepId(stepId);
  if (stepElement) {
    stepElement.trigger("app:redraw");
  }
};

export { getElementByStepId, redrawStepElement };
