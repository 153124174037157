import _ from "lodash";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import AccountLogoView from "./AccountLogoView";

/**
 * Logo of current account
 */
const AccountLogo = observer(() => {
  const { accountStore } = useStores();
  return (
    <AccountLogoView
      logo={accountStore.account?.logo}
      name={accountStore?.account?.accountName}
      site={accountStore?.account?.site}
    />
  );
});

export default AccountLogo;
