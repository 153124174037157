import * as React from "react";
import {
  useTable,
  Column,
  CellProps,
  useSortBy,
  useResizeColumns,
  useFlexLayout,
  useRowSelect,
  Row,
  TableBodyProps,
  usePagination,
} from "react-table";
import * as _ from "lodash";
import LazyLoad from "react-lazyload";

import { Pagination, PaginationItem, PaginationLink, Table } from "reactstrap";
import { useRowContents } from "./Item";
import ItemActions from "./ItemsActions/ItemActionsView";
import { observer } from "mobx-react";
import { SortCaret } from "components/Segments/PeopleTable/PeopleTableSortCaret";
import { useAlphanumericSort } from "components/Items/useAlphanumericSort";
import {
  itemsSortDates,
  itemsSortFormattedNumber,
} from "components/Items/itemsSortHelper";
import { ItemsSelectableCheckbox } from "components/Items/ItemsSelectable/ItemsSelectableCheckbox";
import LoadingCenteredHorizontally from "components/LoadingCenteredHorizontally";
import InfiniteScroll from "react-infinite-scroller";
import { areShallowEqual } from "domain/helpers/arePropsEqual";
import { useDeepCompareEffect, useWindowSize } from "react-use";
import useWhyDidYouUpdate from "domain/helpers/whyDidYouUpdate";
import { classNames } from "react-select/lib/utils";

export interface ItemsTableRowActionGenericHandler<T> {
  type: string;
  title: string | React.FC<{ item: T }>;
  handler: (item: T, ...args: any[]) => void;
  shouldDisplay?: (arg: { item: T }) => boolean;
}

interface Props<T> {
  columns: {
    id: string;
    title: string;
    render?: (args: {
      row: { original: T };
      column?: {
        extra: any;
      };
      value?: any;
    }) => React.ReactElement | string | null;
    renderHeader?: (args: {
      column: {
        extra: any;
      };
    }) => React.ReactElement | string | null;
    expandCell?: boolean;
    width?: number;
    maxWidth?: number;
    minWidth?: number;
    cellStyle?: React.CSSProperties;
    dataType?: "string" | "number" | "datetime";
    sortType?:
      | "string"
      | "number"
      | "basic"
      | "datetime"
      | "alphanumeric"
      | ((
          rowA: { original: T },
          rowB: { original: T },
          columnId: string
        ) => 1 | -1);
    extra?: any;
    disableSortBy?: boolean;
  }[];
  items: T[];
  actions?: ItemsTableRowActionGenericHandler<T>[];
  onClick?: (item: T, event?: React.MouseEvent) => void;
  linkRow?: (item: T) => string;
  openLinkInNewTab?: boolean;

  resizeColumns?: {
    enabled: boolean;
  };
  infiniteLoading?:
    | undefined
    | {
        loadMoreItems: () => void | Promise<any>;
        isLoading?: boolean;
        error?: string;
        hasNextPage?: boolean;
      };

  pagination?: {
    pageSize: number;
    pageIndex: number;
  };
  manualSort?: (sortField: string, sortOrder: "asc" | "desc") => void;
  selectable?: {
    onSelect: (ids: (number | string)[]) => void;
  };
  useLazyLoad?: boolean;
  // draggable?:
  //   | undefined
  //   | {
  //       onDragEnd: OnDragEndResponder;
  //     };
  onColumnResize?: (widths: { [key: string]: number }) => void;
}

const ItemsTable = observer(<T extends unknown>(props: Props<T>) => {
  useWhyDidYouUpdate("ItemsTable", props);
  const getRowId = React.useCallback((row) => row.id, []);

  const columns = useColumns(props);

  const manualSortProps = React.useMemo(() => {
    return props.manualSort
      ? {
          // enable manual sort
          manualSortBy: props.manualSort,
          // Do not reset on sorting manually, as the underlying data will change
          autoResetSortBy: false,
        }
      : {};
  }, [props.manualSort]);

  const paginationInitialState = React.useMemo(
    () => props.pagination || {},
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      // @ts-ignore - state is too generic
      sortBy,
      // @ts-ignore - state is too generic
      selectedRowIds,
      // @ts-ignore - state is too generic
      columnResizing,
      // @ts-ignore - state is too generic
      pageSize,
      // @ts-ignore - state is too generic
      pageIndex,
    },
    ...paginationOptions
  } = useTable(
    {
      data: props.items as any[],
      columns: columns as any,
      getRowId,
      /// @ts-ignore
      sortTypes: {
        alphanumeric: useAlphanumericSort(),
      },
      ...manualSortProps,
      initialState: {
        ...paginationInitialState,
      },
    },
    useFlexLayout,
    ...(props.resizeColumns?.enabled ? [useResizeColumns] : []),
    useSortBy,
    ...(props.selectable?.onSelect ? [useRowSelect] : []),
    ...(props.pagination ? [usePagination] : [])
  );

  const {
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = (paginationOptions as any) || {};

  useDeepCompareEffect(() => {
    if (
      columnResizing?.columnWidths &&
      _.keys(columnResizing?.columnWidths)?.length
    ) {
      props.onColumnResize?.(columnResizing?.columnWidths);
    }
  }, [columnResizing?.columnWidths]);

  const RowContents = useRowContents({
    onClick: props.onClick,
    linkRow: props.linkRow,
    openLinkInNewTab: props.openLinkInNewTab,
  });

  const manualSort = props.manualSort;
  const manualSortCallback = React.useCallback(
    (sortField: string, sortOrder: "asc" | "desc") => {
      manualSort?.(sortField, sortOrder);
    },
    [manualSort]
  );

  React.useEffect(() => {
    const sortField = sortBy?.[0]?.id;
    const sortOrder = sortBy?.[0]?.desc ? "desc" : "asc";
    if (sortField && sortOrder) {
      manualSortCallback(sortField, sortOrder);
    }
  }, [sortBy, manualSortCallback]);

  const onSelect = props.selectable?.onSelect;
  const onSelectCallback = React.useCallback(
    (ids: any[]) => {
      onSelect?.(ids);
    },
    [onSelect]
  );

  React.useEffect(() => {
    onSelectCallback(selectedRowIds);
  }, [selectedRowIds, onSelectCallback]);

  const infiniteLoading = props.infiniteLoading;
  // const loadingNewItemsFooter = React.useMemo(() => {
  //   return <LoadingCenteredHorizontally />;
  //   const loadingNewItemsError = infiniteLoading?.error ? (
  //     <Alert color="danger">{infiniteLoading?.error}</Alert>
  //   ) : (
  //     <></>
  //   );
  //   return infiniteLoading?.isLoading ? (
  //     <LoadingCenteredHorizontally />
  //   ) : (
  //     loadingNewItemsError
  //   );
  // }, [infiniteLoading?.error, infiniteLoading?.isLoading]);

  // Disable animation as it works weirdly with the virtuoso component
  // I.e. the table would be stuck halfway invisible
  // const springProps = useSpring({ opacity: 1, from: { opacity: 0 } });
  const trClassName = props.onClick ? "clickable" : "";

  const fixedHeaderCallback = React.useCallback(() => {
    return headerGroups.map((headerGroup) => (
      <tr
        {...headerGroup.getHeaderGroupProps()}
        className="table-main-header sticky"
      >
        {headerGroup.headers.map((column) => {
          const headerPropsStyle = column.getHeaderProps().style;
          const columnStyle = (column as any).cellStyle;
          let sortOrder = undefined;
          if ((column as any).isSorted) {
            if ((column as any).isSortedDesc) {
              sortOrder = "desc" as const;
            } else {
              sortOrder = "asc" as const;
            }
          }

          return (
            <th
              className="sortable"
              {...column.getHeaderProps((column as any).getSortByToggleProps())}
              style={{
                ...headerPropsStyle,
                ...columnStyle,
              }}
            >
              {column.render("Header")}
              {(column as any).canSort ? (
                <SortCaret order={sortOrder as any} />
              ) : null}
              {props.resizeColumns?.enabled && (column as any).canResize ? (
                <div
                  {...(column as any).getResizerProps()}
                  className={`resizer ${
                    (column as any).isResizing ? "isResizing" : ""
                  }`}
                  onClick={(e) => {
                    // stop propagation to not trigger sort on resizing
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              ) : null}
            </th>
          );
        })}
      </tr>
    ));
  }, [headerGroups, props.resizeColumns?.enabled]);

  const [
    tableMainContainerRef,
    setTableMainContainerRef,
  ] = React.useState<HTMLDivElement | null>(null);
  const windowSize = useWindowSize();
  const tableMainContainerStyle = React.useMemo(() => {
    const element = tableMainContainerRef;
    if (element) {
      const offsetTop = element.offsetTop;
      const height = windowSize.height - offsetTop;
      return { height };
    }
    return {};
  }, [tableMainContainerRef, windowSize.height]);

  const getScrollParentCallback = React.useCallback(() => {
    return tableMainContainerRef;
  }, [tableMainContainerRef]);

  // // Infinite scrolling
  const tableContainerRef = React.useRef<HTMLTableSectionElement>(null);
  // //we need a reference to the scrolling element for logic down below
  // const hasNextPage = Boolean(props.infiniteLoading?.loadMoreItems);
  // const { getVirtualItems } = useVirtualizer({
  //   count: rows.length,
  //   getScrollElement: () => tableContainerRef.current,
  //   estimateSize: () => 45, // px
  //   overscan: 20,
  // });
  // const virtualItems = getVirtualItems();
  // console.log("virtualItems", virtualItems.length);
  // React.useEffect(() => {
  //   console.log("In Virtual items effect", {
  //     hasNextPage,
  //     isLoading: infiniteLoading?.isLoading,
  //   });
  //   if (
  //     virtualItems.length >= rows.length - 1 &&
  //     hasNextPage &&
  //     !infiniteLoading?.isLoading
  //   ) {
  //     console.log("Loading more items");
  //     infiniteLoading?.loadMoreItems();
  //   }
  // }, [hasNextPage, rows.length, virtualItems.length, infiniteLoading]);
  // console.log("infiniteLoading", infiniteLoading);

  const [isLoadingMore, setIsLoadingMore] = React.useState(false);
  const loadMoreItems = infiniteLoading?.loadMoreItems;
  const loadMoreCallback = React.useCallback(async () => {
    console.log("Loading more items");
    if (!isLoadingMore && loadMoreItems) {
      setIsLoadingMore(true);
      await loadMoreItems();
      setIsLoadingMore(false);
    }
  }, [isLoadingMore, loadMoreItems]);

  const tableStyleMemo = React.useMemo(
    () => ({ tableLayout: "fixed", minWidth: "100%" }),
    []
  );
  const theadStyleMemo = React.useMemo(() => ({ zIndex: 10, top: 0 }), []);

  let hasMore = false;
  if (infiniteLoading) {
    // If the infinite loading object has a hasNextPage property, use that
    if (infiniteLoading.hasNextPage !== undefined) {
      hasMore = infiniteLoading.hasNextPage;
    } else {
      // if we are doing an infinite loading table, by default we assume we have more
      hasMore = true;
    }
  }

  console.log("infiniteloading", infiniteLoading);

  const table = (
    <Table
      {...getTableProps()}
      style={tableStyleMemo as any}
      className="table table-items table-main table-main-first-padding table-borderless sticky-th text-nowrap mb-5 position-relative"
      hover
    >
      <thead className="position-sticky bg-white" style={theadStyleMemo}>
        {fixedHeaderCallback()}
      </thead>
      <ItemsTableBody
        getTableBodyProps={getTableBodyProps}
        rows={props.pagination ? page : rows}
        prepareRow={prepareRow}
        trClassName={trClassName}
        tableContainerRef={tableContainerRef}
        RowContents={RowContents}
        isColumnResizing={Boolean(columnResizing?.isResizingColumn)}
        useLazyLoad={props.useLazyLoad}
      />
    </Table>
  );

  const tableComponent = infiniteLoading ? (
    <InfiniteScroll
      initialLoad={false}
      loadMore={loadMoreCallback}
      hasMore={hasMore}
      useWindow={false}
      loader={
        <div
          className={classNames(
            "mb-5",
            infiniteLoading?.isLoading || isLoadingMore
              ? "visible"
              : "invisible"
          )}
          key={"loader"}
        >
          <LoadingCenteredHorizontally key={Math.random()} />
        </div>
      }
      getScrollParent={getScrollParentCallback}
    >
      {table}
    </InfiniteScroll>
  ) : (
    table
  );

  const adjacentPagesNumber = 5;

  const paginationComponent = props.pagination ? (
    <div className="pagination-container">
      <Pagination aria-label="Pagination" className="mb-0 px-2 border-top">
        <PaginationItem disabled={!canPreviousPage}>
          <PaginationLink
            previous
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            Prev
          </PaginationLink>
        </PaginationItem>
        {/* // Display first page */}
        {/* // display ellipsis after first page */}
        {pageIndex - adjacentPagesNumber > 0 ? (
          <>
            <PaginationItem disabled={!canPreviousPage}>
              <PaginationLink
                previous
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                1
              </PaginationLink>
            </PaginationItem>
            {pageIndex - adjacentPagesNumber > 1 ? (
              <PaginationItem disabled={true}>
                <PaginationLink disabled={true}>...</PaginationLink>
              </PaginationItem>
            ) : null}
          </>
        ) : null}
        {/* // Display adjacent pages */}
        {_.range(
          Math.max(
            pageIndex - adjacentPagesNumber,
            // - Math.abs(
            //   Math.min(pageCount - pageIndex - adjacentPagesNumber, 0)
            // ),
            0
          ),
          Math.min(
            pageIndex + adjacentPagesNumber,
            // + Math.abs(Math.min(pageIndex - adjacentPagesNumber, 0)),
            pageCount - 1
          ) + 1
        ).map((page) => {
          const pageNumber = page + 1;
          const isCurrentPage = pageNumber === pageIndex + 1;
          return (
            <PaginationItem key={page} active={isCurrentPage}>
              <PaginationLink onClick={() => gotoPage(page)}>
                {pageNumber}
              </PaginationLink>
            </PaginationItem>
          );
        })}
        {/* // Display ellipsis before last page */}
        {pageIndex + adjacentPagesNumber < pageCount - 1 ? (
          <>
            {pageIndex + adjacentPagesNumber < pageCount - 2 ? (
              <PaginationItem disabled={true}>
                <PaginationLink disabled={true}>...</PaginationLink>
              </PaginationItem>
            ) : null}
            <PaginationItem disabled={!canNextPage}>
              <PaginationLink
                next
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {pageCount}
              </PaginationLink>
            </PaginationItem>
          </>
        ) : null}
        {/* // Display last page */}
        <PaginationItem disabled={!canNextPage}>
          <PaginationLink
            next
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            Next
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    </div>
  ) : null;

  return (
    <>
      <div
        className="table-main-container pb-0 px-0 overflow-auto"
        ref={setTableMainContainerRef}
        style={tableMainContainerStyle}
      >
        {tableComponent}

        {/* {loadingNewItemsFooter} */}

        {/* 
      // For integrating Draggable and Virtual
      <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => {
              prepareRow(rows[rubric.source.index]);
              return (
                <Item
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  item={rows[rubric.source.index]}
                />
              );
            }}
          >
            {(provided) => (
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={totalItemCount}
                loadMoreItems={() => {
                  throttledLoadMoreItems(setItems, items);
                }}
              >
                {({ onItemsRendered, ref: infiniteLoaderRef }) => (
                  <DynamicSizeList
                    height={500}
                    itemCount={items.length}
                    // itemSize={35}
                    width={totalColumnsWidth}
                    outerRef={provided.innerRef}
                    itemData={rows}
                    onItemsRendered={onItemsRendered}
                    ref={infiniteLoaderRef}
                  >
                    {Row}
                  </DynamicSizeList>
                )}
              </InfiniteLoader>
            )}
          </Droppable>
        </DragDropContext> */}
        {/* </div>
      </div> */}
      </div>
      {paginationComponent}
    </>
  );
});

// Memoize ItemsTableBody to prevent re-rendering when resizing columns,
// as that is unusably slow
// Using approach from https://github.com/TanStack/table/issues/1766#issuecomment-1054703281
const ItemsTableBody = React.memo(
  function ItemsTableBody<T extends object>({
    rows,
    prepareRow,
    trClassName,
    RowContents,
    getTableBodyProps,
    tableContainerRef,
    useLazyLoad,
  }: {
    rows: Row<T>[];
    prepareRow: (row: Row<T>) => void;
    trClassName: string;
    RowContents: React.FC<{ row: Row<T> }>;
    getTableBodyProps: () => TableBodyProps;
    tableContainerRef: React.RefObject<any>;
    isColumnResizing: boolean;
    useLazyLoad?: boolean;
  }) {
    // _.noop(RowContents);
    return (
      <tbody
        {...getTableBodyProps}
        className="tbody overflow-auto position-relative"
        ref={tableContainerRef}
      >
        {/* {virtualItems.map((virtualRow) => {
        const row = rows[virtualRow.index];
        if (!row) return null; */}
        {rows.map((row) => {
          prepareRow(row);
          const content = (
            <tr
              {...row.getRowProps()}
              className={`${trClassName} ${
                (row as any).isSelected ? "active" : ""
              }`}
            >
              <RowContents row={row} />
              {/* {row.cells.map((cell: any) => {
          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
        })} */}
            </tr>
          );
          return useLazyLoad ? (
            <LazyLoad
              key={row.id}
              height={50}
              scrollContainer={".table-main-container"}
            >
              {content}
            </LazyLoad>
          ) : (
            content
          );
        })}
      </tbody>
    );
  },
  (prevProps, nextProps) => {
    // dont rerender if column is resizing
    if (nextProps.isColumnResizing) {
      return true;
    }
    return areShallowEqual(prevProps, nextProps);
  }
);

const useColumns = <T extends unknown>(props: Props<T>) => {
  const renderActionsCell = useRenderActionsCell<T>(props.actions);
  const renderSelectionCell = useRenderSelectionCell();
  const renderSelectionCellHeader = useRenderSelectionCellHeader();
  const addActionsColumn = Boolean(props.actions?.length);
  const addSelectionColumn = Boolean(props.selectable?.onSelect);

  const itemsSortFormattedNumberMemo = React.useMemo(
    () => itemsSortFormattedNumber,
    []
  );
  const itemsSortDatesMemo = React.useMemo(() => itemsSortDates, []);

  return React.useMemo(() => {
    console.log("Recomputing columns");
    const numColumns = props.columns.length;

    const formattedColumns = _.map(props.columns, (column) => {
      // default styles for all columns
      const defaultCellStyle: React.CSSProperties = {
        textOverflow: "ellipsis",
        overflow: "hidden",
      };
      const largeColumnDefaultWidth = Math.round(
        Math.max(((window.innerWidth * 0.9) / numColumns) * 3, 200)
      );
      const otherColumnsDefaultWidth = Math.round(
        Math.max(
          (window.innerWidth * 0.9 - largeColumnDefaultWidth) /
            (numColumns - 1),
          200
        )
      );

      const defaultCellProps = {
        cellStyle: {
          ...defaultCellStyle,
          ...(column.expandCell ? { maxWidth: "unset" } : {}),
          ...(column.cellStyle || {}),
        },
      };

      let sortType = column.sortType;
      if (!sortType) {
        switch (column.dataType) {
          case "number":
            sortType = itemsSortFormattedNumberMemo;
            break;
          case "datetime":
            sortType = itemsSortDatesMemo;
            break;
          default:
            break;
        }
      }
      const columnProps: Column & {
        handlesOwnClicks?: boolean;
        cellStyle?: React.CSSProperties;
        sortType?: Props<T>["columns"][number]["sortType"];
        disableSortBy?: boolean;
        disableResizing?: boolean;
        extra?: any;
      } = {
        accessor: column.id,
        Header: column.title,
        // ...column.style,
        ...defaultCellProps,
        width:
          column.width ||
          (column.expandCell
            ? largeColumnDefaultWidth
            : otherColumnsDefaultWidth),
        maxWidth: column.maxWidth || 2000,
        minWidth: column.minWidth || 100,
        sortType: sortType || "alphanumeric",
        extra: column.extra,
        disableSortBy: column.disableSortBy || false,
      };
      if (column.render) {
        (columnProps as any).Cell = column.render;
      }
      if (column.renderHeader) {
        (columnProps as any).Header = column.renderHeader;
      }
      return columnProps;
    });
    if (addActionsColumn) {
      formattedColumns.splice(0, 0, {
        accessor: "encharge-actions",
        width: 25,
        cellStyle: {
          maxWidth: 25,
          width: 25,
        },
        Cell: renderActionsCell,
        handlesOwnClicks: true,
        disableSortBy: true,
        disableResizing: true,
      });
    }
    if (addSelectionColumn) {
      formattedColumns.splice(0, 0, {
        accessor: "selection",
        width: 50,
        cellStyle: {
          maxWidth: 50,
          width: 50,
          position: "relative",
        },
        Cell: renderSelectionCell,
        Header: renderSelectionCellHeader,
        handlesOwnClicks: true,
        disableSortBy: true,
        disableResizing: true,
      });
    }
    return formattedColumns;
    // Missing dependency T which is a type
  }, [
    addActionsColumn,
    addSelectionColumn,
    itemsSortDatesMemo,
    itemsSortFormattedNumberMemo,
    props.columns,
    renderActionsCell,
    renderSelectionCell,
    renderSelectionCellHeader,
  ]);
};

const useRenderActionsCell = <T extends unknown>(
  actions: Props<T>["actions"]
) => {
  const renderActionsCell = React.useMemo(() => {
    console.log("Recomputing renderActionsCell");
    return (cellProps: CellProps<any>) => (
      <ItemActions {...cellProps} actions={actions} />
    );
  }, [actions]);

  return renderActionsCell;
};

const useRenderSelectionCell = () => {
  return React.useMemo(
    () => ({ row }: any) => (
      <ItemsSelectableCheckbox {...row.getToggleRowSelectedProps()} />
    ),
    []
  );
};

const useRenderSelectionCellHeader = () => {
  return React.useMemo(
    () => ({ getToggleAllRowsSelectedProps }: any) => (
      <ItemsSelectableCheckbox {...getToggleAllRowsSelectedProps()} />
    ),
    []
  );
};

export default ItemsTable;
