import { useState } from "react";
import AppNavDropdown from "./AppNavDropdown";
import { ReactComponent as EmailsIcon } from "../../assets/img/nav/emails.svg";
import { ReactComponent as FlowsIcon } from "../../assets/img/nav/flows.svg";

/**
 * Dropdown for app navigation
 */
const AppNavMetrics = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className="sidebar-nav-item cursor-pointer"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span>
        <span className="main-bar-nav-item">
          Metrics
          <div className="ml-2 caret" />
        </span>
        <AppNavDropdown
          className="mt-0 main-bar-nav-item-manage"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placement={"bottom-start"}
          options={[
            {
              contents: (
                <>
                  <FlowsIcon className="nav-icon" />
                  Flow Metrics
                </>
              ),
              to: "/metrics/flows",
            },
            {
              contents: (
                <>
                  <EmailsIcon className="nav-icon" />
                  Email Metrics
                </>
              ),
              to: "/metrics/emails",
            },
          ]}
        />
      </span>
    </div>
  );
};
export default AppNavMetrics;
