import md5 from "blueimp-md5";
import { defaultEnchargeColors } from "./enchargeColors";
import _ from "lodash";

export const photoFromEmail = (
  email?: string,
  name?: string,
  id?: string,
  size: number = 64
) => {
  if (name === " ") {
    // tslint:disable-next-line: no-parameter-reassignment
    name = "";
  }
  if (name === "/") {
    // tslint:disable-next-line: no-parameter-reassignment
    name = "";
  }
  const bgColor = stringToColour(email || name || "");
  const textColor = "fff";
  // https://ui-avatars.com/

  const fallbackURL = `https://eu.ui-avatars.com/api/${encodeURIComponent(
    name || email || "JD"
  )}/${size}/${bgColor}/${textColor}`;
  const fallback =
    name || email ? encodeURIComponent(fallbackURL) : "identicon";

  const hash = md5(email || id || "");
  // https://en.gravatar.com/site/implement/images/
  // Use double size for hi-res screens
  return `https://www.gravatar.com/avatar/${hash}?s=${size * 2}&d=${fallback}`;
};

const stringToColour = (str: string) => {
  const hash = hashStringToInteger(str);

  // Kalo-sanctioned brand colors;
  const colors = _.filter(
    defaultEnchargeColors,
    (_color, name) =>
      ![
        "encharge-color-light-gray",
        "encharge-color-white",
        "encharge-color-yellow",
      ].includes(name)
  );
  // Select one of the colors
  const color = colors[hash % colors.length];
  // remove hash
  return color.replace("#", "");
};

const hashStringToInteger = (str: string) => {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export function onErrorSetDefaultAvatar(
  e: React.SyntheticEvent<HTMLImageElement>,
  size?: number
) {
  e.currentTarget.src = `${process.env.PUBLIC_URL}/assets/img/person-na.png`;
  if (size) {
    e.currentTarget.width = size;
  }
}
