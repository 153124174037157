import classNames from "classnames";
import { OnboardingEmailFrame } from "components/AIOnboarding/OnboardingEmailFrame";
import CompanyAvatar from "components/Companies/CompanyAvatar";
import { GeneratedCompanyInfo } from "components/Registration/GenerateFlows/RegistrationStepGenerateFlows";
import { getDomainFromURL } from "encharge-domain/lib/helpers/email_helper";
import _ from "lodash";
import { Row, Col } from "reactstrap";

interface Props {
  site?: string;
  isSaaSLabel: string;
  businessModelLabel?: string;
  companyInfo?: GeneratedCompanyInfo;

  tinyMode?: boolean;
}

const GenerateFlowsCompanyInfo = (props: Props) => {
  const imageSize = props.tinyMode ? 64 : 122;
  return (
    <OnboardingEmailFrame
      maxHeight={props.tinyMode ? 250 : "unset"}
      classNames={classNames(props.tinyMode && "tiny-mode")}
    >
      <div>
        <Row>
          <Col className="d-flex">
            <div
              style={{
                width: imageSize,
                height: imageSize,
                background: "#D9D9D9",
                borderRadius: 10,
                border: "0.50px black solid",
                overflow: "hidden",
              }}
              className={classNames(props.tinyMode ? "mr-2" : "mr-5")}
            >
              <CompanyAvatar
                domain={getDomainFromURL(props.site || "")}
                size={imageSize}
              />
            </div>
            <div className={classNames(props.tinyMode ? "my-1" : "my-3")}>
              <div className="company-info-detail">
                <span className="company-info-detail-label">Website:</span>
                <span>{props.site}</span>
              </div>
              <div className="company-info-detail">
                <span className="company-info-detail-label">Type: </span>
                <span>{props.isSaaSLabel}</span>
              </div>
              {props.businessModelLabel && (
                <div className="company-info-detail">
                  <span className="company-info-detail-label">Model:</span>
                  <span>{props.businessModelLabel}</span>
                </div>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            {_.map(props.companyInfo, ({ description, title }, index) => (
              <div key={index} className="company-info-detail mb-3">
                <span className="company-info-detail-label">{title}:</span>
                <span>{description}</span>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </OnboardingEmailFrame>
  );
};
export default GenerateFlowsCompanyInfo;
