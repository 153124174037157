import { action, observable } from "mobx";
import { DomainStore } from "./domainStore";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";
import { ILazyObservable, lazyObservable } from "domain/helpers/lazyLoad";
import { toastError } from "domain/errorHandling/toaster";
import enchargeAPI from "./persistence/enchargeAPI";
import { formatEventName } from "encharge-domain/lib/helpers/event_schema_helper";
export class EventManagementStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "eventManagement", limit: 1 });

  @observable
  eventSchemas: ILazyObservable<{
    eventSchemas: IEventSchema[];
    count: {
      source: string;
      count: number;
    }[];
  }> = lazyObservable<{
    eventSchemas: IEventSchema[];
    count: {
      source: string;
      count: number;
    }[];
  }>((sink, onError) => {
    enchargeAPI
      .getListEventSchema()
      .then((res) => {
        sink(
          observable({
            eventSchemas: res.eventSchemas,
            count: res.count,
          })
        );
      })
      .catch((e) => {
        toastError({
          message: "Error while loading event schemas.",
          extra: e,
        });
        onError(e);
        throw e;
      });
  });

  @action
  setEventSchemas = (data: {
    eventSchemas: IEventSchema[];
    count: {
      source: string;
      count: number;
    }[];
  }) => {
    this.eventSchemas.put(data);
  };

  getSchemaByNameAndSource = ({
    name,
    source,
  }: {
    name: string;
    source: string;
  }): IEventSchema | undefined => {
    const schemas = this.eventSchemas.current()?.eventSchemas || [];
    return getEventSchemaByNameAndSource({ schemas, name, source });
  };
}

export const getEventSchemaByNameAndSource = ({
  schemas,
  name,
  source,
}: {
  name: string;
  source: string;
  schemas?: IEventSchema[];
}): IEventSchema | undefined => {
  return _.find(schemas, (schema) => {
    return (
      formatEventName(schema.name) === formatEventName(name) &&
      schema.source === source
    );
  });
};
