import CopyButton from "components/Common/CopyButton";

const CopyButtonInline = (props: React.ComponentProps<typeof CopyButton>) => {
  return (
    <CopyButton
      {...props}
      className="btn-custom-outline-dark btn-with-icon-outline"
    />
  );
};

export default CopyButtonInline;
