import * as React from "react";
import _ from "lodash";
import { useStores } from "../../../store/useStore";
import { observer } from "mobx-react";
import Loading from "../../Loading";
import { getAccountName } from "components/AppNav/Account/AccountName";
import AppNavDropdown from "components/AppNav/AppNavDropdown";
import classNames from "classnames";
import AccountLogoView from "components/AppNav/Account/AccountLogoView";
import { ReactComponent as CheckMark } from "../../../assets/img/checkmark.svg";

interface Props {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
  dropdownRef?: HTMLDivElement;
}

/**
 * Render menu to switch to another account
 */
const AccountPicker = observer((props: Props) => {
  const { accountStore, uiStore } = useStores();
  const currentAccount = accountStore.account?.accountId;
  const accounts = accountStore.account?.allAccounts;

  if (!accounts || !currentAccount || accounts?.length <= 1) {
    // dont show account picker if person has only 1 account
    return null;
  }

  const options: React.ComponentProps<typeof AppNavDropdown>["options"] = _.map(
    accounts,
    (account) => {
      return {
        contents: (
          <span
            // Show which account is the current one
            className={classNames(
              "d-flex",
              "align-items-center",
              account.id === currentAccount && "text-primary"
            )}
          >
            {/* Show which account is the current one */}
            <AccountLogoView
              site={account.site}
              name={account.name}
              logo={account.logo}
              linkToHome={false}
            />
            {account.id === currentAccount && <CheckMark />}
            <span>{getAccountName(account)}</span>
          </span>
        ),
        onClick: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          // Switch to another account
          if (account.id !== currentAccount) {
            accountStore.switchAccount(account.id);
          }
        },
      };
    }
  );

  if (uiStore.switchAccount.isLoading) {
    return (
      <div className="w-100">
        <Loading size="tiny" />
      </div>
    );
  }

  return (
    <AppNavDropdown
      className="wide-popover"
      placement={"left-start"}
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      options={options}
      dropdownRef={props.dropdownRef}
      showCaret={true}
    />
  );
});

export default AccountPicker;
