import InfoDialog from "components/Common/InfoDialog/InfoDialog";
import { useCallback } from "react";

interface Props {
  isOpen: boolean;
  onConfirm: () => void;
}

const GenerateFlowsNonSaasWarning = (props: Props) => {
  const onConfirm = props.onConfirm;
  const onClickCallback = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  return (
    <InfoDialog
      isOpen={props.isOpen}
      title="AI-generated content unavailable"
      buttons={[
        {
          color: "primary",
          onClick: onClickCallback,
          label: "Proceed to my account",
        },
      ]}
    >
      Currently, the AI-generated content works only for SaaS businesses.
      <br />
      <span className="text-muted small mt-2">
        If we made a mistake in detecting your business model, please{" "}
        <a
          href="#"
          onClick={() => {
            (window as any)?.openHelpChat?.();
            return false;
          }}
          className="cursor-pointer link"
        >
          let us know
        </a>
        .
      </span>
    </InfoDialog>
  );
};
export default GenerateFlowsNonSaasWarning;
