import { observable, action, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";

import {
  ITeamMember,
  TeamMemberScopes,
} from "encharge-domain/lib/definitions/TeamMember";
import enchargeAPI from "./persistence/enchargeAPI";

export class TeamMembersStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "teamMembers", limit: 1 });

  @observable
  teamMembers = lazyObservable<ITeamMember[]>((sink, onError) => {
    enchargeAPI
      .getTeamMembers()
      .then((items) => {
        sink(observable(items.teamMembers) as any);
      })
      .catch((e) => {
        toastError({
          message: "Error while getting teamMembers.",
          extra: e,
        });
        onError(e);
      });
  });

  @action
  async create(name: string, email: string, scopes: TeamMemberScopes) {
    try {
      this.rootStore.uiStore.teamMemberEdit.startLoading();
      const { teamMember } = await enchargeAPI.createTeamMember({
        name,
        email,
        scopes: scopes as any,
      });
      if (teamMember) {
        runInAction(() => {
          this.teamMembers.put([
            teamMember as ITeamMember,
            ...this.teamMembers.current(),
          ]);
        });
        toastSuccess("Team member invited 💌 ");
      }
    } catch (e) {
      toastError({
        message: "Error while inviting team member.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.teamMemberEdit.finishLoading();
    }
    return;
  }

  async update({
    id,
    name,
    scopes,
    skipSuccessToast,
  }: {
    id: ITeamMember["id"] | "me";
    name: string;
    scopes?: TeamMemberScopes;
    skipSuccessToast?: boolean;
  }) {
    try {
      this.rootStore.uiStore.teamMemberEdit.startLoading();
      const { teamMember } = await enchargeAPI.updateTeamMember(id, {
        name,
        scopes,
      });
      if (teamMember) {
        runInAction(() => {
          const index = _.findIndex(
            this.teamMembers.current(),
            (current) => current.id === id
          );
          if (index !== -1) {
            this.teamMembers.current()[index] = teamMember as ITeamMember;
          }
        });
        if (!skipSuccessToast) {
          toastSuccess("✅ Team member updated. ");
        }
      }
    } catch (e) {
      toastError({
        message: "Error while updating team member.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.teamMemberEdit.finishLoading();
    }
    return;
  }

  get(id: ITeamMember["id"]) {
    return _.find(this.teamMembers.current(), (current) => current.id === id);
  }

  @action
  delete(id: ITeamMember["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.teamMembers.current(),
      performAction: () => {
        const items = this.teamMembers.current();
        const index = _.findIndex(items, (item) => item.id === id);
        if (index === -1) return;
        items.splice(index, 1);
        return async () => {
          await enchargeAPI.deleteTeamMember(id);
          toastSuccess("Team member removed.");
        };
      },
      confirmErrorMessage: "Couldn't remove team member.",
    });
  }
}
