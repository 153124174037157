import * as React from "react";
import arePropsEqual from "domain/helpers/arePropsEqual";
import { Row } from "react-table";

export type ItemRowType = Row<object>;
/**
 * Prepare memoized Item row
 */
export const useRowContents = ({
  onClick,
  linkRow,
  openLinkInNewTab,
}: {
  onClick?: (item: any, event?: React.MouseEvent) => void;
  linkRow?: (item: any) => string;
  openLinkInNewTab?: boolean;
}) => {
  return React.memo(({ row }: { row: ItemRowType }) => {
    const onClickHandler = React.useMemo(
      () => (e: React.MouseEvent) => {
        onClick?.(row.original, e);
      },
      [row]
    );

    return (
      <>
        {row?.cells?.map((cell) => {
          const cellHandlesOwnClicks = (cell.column as any).handlesOwnClicks;
          const cellPropsStyle = cell.getCellProps().style;
          const columnStyle = (cell.column as any).cellStyle;
          return (
            <td
              {...cell.getCellProps()}
              style={{ ...cellPropsStyle, ...columnStyle }}
              className="td position-relative d-flex align-items-center"
              onClick={cellHandlesOwnClicks ? undefined : onClickHandler}
            >
              {linkRow && !cellHandlesOwnClicks ? (
                <a
                  className="stretched-link"
                  href={linkRow(row.original as any)}
                  {...(openLinkInNewTab
                    ? { target: "_blank", rel: "noopener noreferrer" }
                    : {})}
                >
                  {cell.render("Cell")}
                </a>
              ) : (
                cell.render("Cell")
              )}
            </td>
          );
        })}
      </>
    );
  }, arePropsEqual);
};
