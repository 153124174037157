import GoogleSignin from "components/Login/GoogleSignin";
import { useState } from "react";
import { useStores } from "store/useStore";

interface Props {
  source?: string;
}

const GoogleRegistrationWidget = (props: Props) => {
  const {
    registrationStore,
    uiStore: { accountRegister: ui },
  } = useStores();

  const [hasBeenClicked, setHasBeenClicked] = useState(false);

  return (
    <GoogleSignin
      mode="signup"
      onSuccess={async (idToken) => {
        setHasBeenClicked(true);
        await registrationStore.register({ idToken, source: props.source });
        setHasBeenClicked(false);
      }}
      loading={hasBeenClicked && ui.isLoading}
    />
  );
};
export default GoogleRegistrationWidget;
