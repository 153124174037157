import * as _ from "lodash";
import { IntegrationStepCreate } from "encharge-domain/lib/definitions/api/IntegrationStepCreate";
import { IntegrationStepUpdate } from "encharge-domain/lib/definitions/api/IntegrationStepUpdate";
import { FieldsSchema } from "encharge-domain/lib/entities/fields_schema";
import { IntegrationStepBase } from "encharge-domain/lib/entities/integrationStepBase";
import { ServiceBase } from "encharge-domain/lib/entities/serviceBase";
import { getTool } from "../../Tools/currentTools";
import { IStepTool } from "../Toolbox/IStepTool";
import { domainStore } from "../../../store/domainStore";
import { when } from "mobx";

export type IStepPeople = { [state in IStepPeopleState]: IEndUser[] };
export interface IStep extends IStepTool {
  id?: IIntegrationStep["id"];
  tempId?: string;
  position?: IIntegrationStep["data"]["position"];
  service: IService;
  coordinates: StepCoordinates;
  description: string;
  preview?: NonNullable<IIntegrationStep["data"]["editor"]>["preview"];
  mustConfig?: {
    configStep?: boolean;
    mapInputFields?: boolean;
    mapOutputFields?: boolean;
    hideSaveButton?: boolean;
    mayMapOutputFields?: boolean;
  };
  producesEndUsers?: boolean;
  consumesEndUsers?: boolean;
  pendingDelete?: boolean;
  pendingUpdate?: boolean;

  metrics?: Dictionary<IStepMetrics>;

  peopleCounts?: IIntegrationStep["data"]["peopleCounts"];

  // Temporary storage for step data, not stored on the backend
  ephemeralData?: {
    [key: string]: any;
  };
  // Indicates to the backend if the step is being copied from another step
  isDuplicate?: boolean;
  // Indicates to the backend that it should recompute the delay for a wait step
  recomputeDelay?: boolean;
  // People can only enter this step once
  canEnterOnce?: boolean;
}

export interface SerializableStep extends IStep {
  flowId: IIntegration["id"];
}

export class StepBase {
  static serialize(
    step: SerializableStep
  ): IntegrationStepCreate | IntegrationStepUpdate {
    return {
      integrationId: step.flowId,
      serviceId: step.serviceId,
      data: {
        editor: {
          coordinates: step.coordinates,
          mustConfig: step.mustConfig,
        },
        operationKey: step.operationKey,
        inputFields: step.inputFields,
        outputFields: step.outputFields,
        position: step.position,
        isDuplicate: step.isDuplicate,
        recomputeDelay: step.recomputeDelay,
        canEnterOnce: step.canEnterOnce,
      },
    };
  }

  static async unserialize(
    step: IIntegrationStep & { service?: IService }
  ): Promise<IStep> {
    if (!step.data.editor) {
      step.data.editor = {} as any;
    }
    if (!step.data.editor!.coordinates) {
      step.data.editor!.coordinates = {
        x: 0,
        y: 0,
      };
    }
    // get display from service
    const tool = getTool(step.serviceId, step.data.operationKey);
    if (!tool) {
      throw new Error("Unknown step");
    }
    if (!step.service) {
      // make sure services are loaded
      await when(() => Boolean(domainStore.servicesStore.services.current()));
      step.service = new ServiceBase(
        domainStore.servicesStore.getById(step.serviceId)!
      );
    }
    const integrationStep = new IntegrationStepBase(step);

    return {
      operationKey: step.data.operationKey,
      coordinates: step.data.editor!.coordinates!,
      preview: step?.data?.editor?.preview || undefined,
      mustConfig: step?.data?.editor?.mustConfig || undefined,
      display: tool.display,
      id: step.id,
      inputFields: step.data.inputFields,
      outputFields: step.data.outputFields,
      producesEndUsers: await integrationStep.producesEndUsers,
      consumesEndUsers: await integrationStep.consumesEndUsers,
      type: (await integrationStep.type) as any,
      linkConditions: await integrationStep.linkConditions,
      description: await integrationStep.description,
      position: step.data.position,
      serviceId: step.serviceId,
      service: step.service,
      peopleCounts: step.data.peopleCounts,
      recomputeDelay: step.data.recomputeDelay,
      canEnterOnce: step.data.canEnterOnce,
    };
  }

  static isDelay(step: IStep) {
    return (
      step.serviceId === "encharge" &&
      (step.operationKey === "/delay" || step.operationKey === "/delay-v2")
    );
  }

  static isSendEmail(step: IStep) {
    return step.serviceId === "encharge" && step.operationKey === "/send";
  }

  static isWebhook(step: IStep) {
    return IntegrationStepBase.isWebhook({
      operationKey: step.operationKey,
      serviceId: step.serviceId,
    });
  }

  static isABTest(step: IStep) {
    return IntegrationStepBase.isABTest({
      operationKey: step.operationKey,
      serviceId: step.serviceId,
    });
  }

  static isEnteredOrLeftSegment(step: IStep) {
    return IntegrationStepBase.isEnteredOrLeftSegment({
      operationKey: step.operationKey,
      serviceId: step.serviceId,
    });
  }

  static getABTestLinkConditions(step: IStep) {
    const { variants } = StepBase.getInputFieldsValues(step) as {
      variants: [
        {
          name: string;
        }
      ];
    };
    const conditions = _.map(variants, (variant) => ({
      condition: variant.name,
      label: variant.name,
    }));
    const linkConditions: EnchargeOperationOptions["linkConditions"] = {
      conditions,
    };
    return linkConditions;
  }

  static linkFromDisabled(step: IStep) {
    const service = new ServiceBase(step.service);
    return service.getLinkFromDisabledForOperation(step.operationKey);
  }

  static mustConfigInputFields(step: IStep) {
    if (step?.mustConfig?.configStep || step?.mustConfig?.mapInputFields) {
      return true;
    }
    const fields = step.inputFields;
    if (fields.properties && _.keys(fields.properties).length > 0) {
      const fieldsSchema = new FieldsSchema(fields);
      const values = fieldsSchema.getValues();
      const coerceTypes = false;
      try {
        fieldsSchema.validate(values, coerceTypes);
      } catch {
        // schema not validated, so need to configure
        return true;
      }
      // validation ok, so no need to config
      return false;
    }
    return false;
  }

  static haveToConfigure(step: IStep) {
    return (
      step?.mustConfig?.configStep ||
      step?.mustConfig?.mapInputFields ||
      step?.mustConfig?.mapOutputFields
    );
  }

  static canMapOutputFields(step: IStep) {
    // Map output fields if explicitly requested
    const service = new ServiceBase(step.service);
    const alwaysMap = service.getAlwaysMapOutputFields(step.operationKey);
    if (alwaysMap) {
      return true;
    }

    // Map output fields if there are any
    const hasFields = _.keys(step.outputFields!.properties).length > 0;
    return (
      step?.mustConfig?.mapOutputFields ||
      step?.mustConfig?.mayMapOutputFields ||
      hasFields
    );
  }

  static shouldShowInputFields(step: IStep): boolean {
    // Map output fields if explicitly requested
    const service = new ServiceBase(step.service);
    const alwaysShow = service.getAlwaysShowInputFields(step.operationKey);
    if (alwaysShow) {
      return true;
    }
    return false;
  }

  static getSecurityScheme(step: IStep) {
    const securitySchemes =
      step?.service?.data?.schema?.components?.securitySchemes;
    // security schemes is a dict so get the first (and hopefully only) value
    if (securitySchemes) {
      const securityScheme = securitySchemes[Object.keys(securitySchemes)[0]];
      return securityScheme;
    }
    // otherwise the schema is missing
    return undefined;
  }

  static getHelpDocs(step: IStep) {
    const service = new ServiceBase(step.service);
    // get docs from the operation definition
    const operationDefinitionDocs = service.getHelpDocsForOperation(
      step.operationKey
    );
    // get docs from the tool definition
    const toolDocs = getTool(step.serviceId, step.operationKey)?.helpDocs;
    return operationDefinitionDocs || toolDocs;
  }

  static getInputFieldsValues(step: IStep) {
    return new FieldsSchema(step.inputFields).getValues();
  }
}
