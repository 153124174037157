import { observable, computed, action, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable, ILazyObservable } from "../domain/helpers/lazyLoad";
import {
  getFolders,
  saveFolder,
  createFolder,
  deleteFolder,
} from "./persistence/persistFolders";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";
import {
  GenericFolder,
  FolderType,
  GenericFoldersAndItems,
  GenericFolderItem,
  GenericFolderOrItem,
} from "encharge-domain/definitions/ambient/folder";
import { UnreachableCaseError } from "encharge-domain/lib/definitions/ambient/ts-essentials";
import uuidv4 from "uuid/v4";
import { isAllObjectsSegment, isEditableSegment } from "./segmentStore";
import { endUsersObjectType } from "encharge-domain/lib/helpers/sync_engine_helper";
import {
  getObjectTypeFromFolderType,
  isObjectSegmentFolderType,
} from "encharge-domain/lib/helpers/folders_helper";

export const getRoot = (folders: GenericFoldersAndItems) =>
  _.find(folders, (current) => current.type === "folder" && current.root) as
    | GenericFolder
    | undefined;

export class FoldersStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
    this.foldersAll = this.initFoldersLazyLoad();
  }
  queue = APIQueueFactory({ name: "folders", limit: 1 });

  getFolders(type: FolderType) {
    const allFolders = this.foldersAll.current();

    if (!allFolders || this.foldersAll.isLoading()) {
      return undefined;
    }
    return _.reduce(
      allFolders,
      (acc, folder) => {
        if (folder && folder.type === "folder" && folder.folderType === type) {
          acc.push(folder);
        }
        return acc;
      },
      [] as GenericFolder[]
    );
  }
  private initFoldersLazyLoad() {
    return lazyObservable<GenericFolder[]>((sink, onError) => {
      getFolders()
        .then((folders) => {
          sink(observable(folders));
        })
        .catch((e) => {
          toastError({
            message: "Error while loading folders.",
            extra: e,
          });
          onError(e);
        });
    });
  }

  @observable
  foldersAll: ILazyObservable<GenericFoldersAndItems>;

  // @computed
  get emailsFolders() {
    return this.getFoldersAndItems({ type: "emails" });
  }
  // @computed
  get flowsFolders() {
    return this.getFoldersAndItems({ type: "flows" });
  }
  // @computed
  get personFieldsFolders() {
    return this.getFoldersAndItems({ type: "personFields" });
  }
  // @computed
  get broadcastsFolders() {
    return this.getFoldersAndItems({ type: "broadcasts" });
  }
  get tagsFolders() {
    return this.getFoldersAndItems({ type: "tags" });
  }
  getObjectsFolders({
    folderType,
    excludeAllObjectsSegment,
  }: {
    folderType: FolderType;
    excludeAllObjectsSegment?: boolean;
  }) {
    return this.getFoldersAndItems({
      type: folderType,
      excludeAllObjectsSegment,
    });
  }

  get formsFolders() {
    return this.getFoldersAndItems({ type: "forms" });
  }

  get eventSchemasFolders() {
    return this.getFoldersAndItems({ type: "eventManagement" });
  }

  getFolderById(folderId: GenericFolder["id"]) {
    return _.find(
      this.foldersAll.current(),
      (current) => current.type === "folder" && current.id === folderId
    ) as GenericFolder | undefined;
  }

  private getFoldersAndItems({
    type,
    excludeAllObjectsSegment,
  }: {
    type: FolderType;
    excludeAllObjectsSegment?: boolean;
  }): GenericFoldersAndItems | undefined {
    const folders = this.getFolders(type);
    let items: any[] | undefined;
    let itemsLoading = false;

    const getSegments = (objectType: string) => {
      items = this.rootStore.segmentStore.getSegmentsForObject(objectType);
      if (excludeAllObjectsSegment) {
        items = items.filter((item) => !isAllObjectsSegment(item));
      }
      itemsLoading = this.rootStore.segmentStore.isLoadingSegments();
      return { items, itemsLoading };
    };

    switch (type) {
      case "flows":
        // items = this.rootStore.flowsStore.flows.current();
        // itemsLoading = this.rootStore.flowsStore.flows.isLoading();
        break;
      case "segments":
        ({ items, itemsLoading } = getSegments(endUsersObjectType));
        break;
      case "emails":
        // const itemsMap = this.rootStore.emailsStore.emails;
        // itemsLoading = itemsMap === undefined;
        // items = [...itemsMap?.values()];
        break;
      case "personFields":
        const personFields = this.rootStore.personFieldsStore.getObjectFields(
          endUsersObjectType
        );
        itemsLoading = personFields === undefined;
        items = personFields || [];
        break;
      case "broadcasts":
        const broadcasts = this.rootStore.broadcastsStore.broadcasts.current();
        itemsLoading = this.rootStore.broadcastsStore.broadcasts.isLoading();
        items = broadcasts || [];
        break;
      case "tags":
        const tags = this.rootStore.tagsStore.tags.current();
        itemsLoading = this.rootStore.tagsStore.tags.isLoading();
        items = tags || [];
        break;
      case "forms":
        const forms = this.rootStore.formsStore.forms.current();
        itemsLoading = this.rootStore.formsStore.forms.isLoading();
        items = forms || [];
        break;
      case "eventManagement":
        const eventSchemas = this.rootStore.eventManagementStore.eventSchemas.current()
          ?.eventSchemas;
        itemsLoading = this.rootStore.eventManagementStore.eventSchemas.isLoading();
        items = eventSchemas || [];
        break;
      default:
        if (isObjectSegmentFolderType(type)) {
          ({ items, itemsLoading } = getSegments(
            getObjectTypeFromFolderType(type)
          ));
          break;
        }
        throw new UnreachableCaseError(type);
    }
    if (itemsLoading || this.foldersAll.isLoading() || !folders) {
      return undefined;
    }
    const itemsFormatted: GenericFolderItem[] = _.map(items, (item) => {
      let id = item.id;
      let name = "";
      switch (type) {
        case "personFields":
          name = item.title;
          id = item.name;
          break;
        case "tags":
          name = item.tag;
          id = item.tag;
          break;
        case "emails":
        case "flows":
        case "segments":
        case "broadcasts":
        case "forms":
        case "eventManagement":
          name = item.name;
          break;
        default:
          if (isObjectSegmentFolderType(type)) {
            name = item.name;
            break;
          }
          throw new UnreachableCaseError(type);
      }
      const formatted: GenericFolderItem = {
        type: "item",
        name: name,
        id: id,
      };
      return formatted;
    });

    const combined = [...folders, ...itemsFormatted];
    return this.fixOrphaned({ items: combined, type });
  }

  private checkIfItemIsOrphaned({
    items,
    possibleOrphanId,
  }: {
    items: GenericFoldersAndItems;
    possibleOrphanId: GenericFolderOrItem["id"];
  }) {
    const isOrphaned = _.reduce(
      items,
      (acc, nestedItem) => {
        // if we haven't found parent yet, check
        if (acc) {
          if (
            nestedItem.type === "folder" &&
            nestedItem.childrenIds?.includes(possibleOrphanId)
          ) {
            // not orphaned
            return false;
          }
        }
        return acc;
      },
      true
    );
    return isOrphaned;
  }

  @action
  private fixOrphaned({
    items,
    type,
  }: {
    items: GenericFoldersAndItems;
    type: FolderType;
  }) {
    // find items that don't belong to any folder
    const orphanedItems = _.reduce(
      items,
      (acc, item) => {
        const isOrphan = this.checkIfItemIsOrphaned({
          items,
          possibleOrphanId: item.id,
        });
        if (
          !(item as GenericFolder).root &&
          !(item as GenericFolder).autoFolderType &&
          isOrphan
        ) {
          acc.push(item);
        }
        return acc;
      },
      [] as GenericFoldersAndItems
    );
    if (orphanedItems?.length) {
      const rootFolder = this.getRoot({ folders: items, type });
      if (!rootFolder) return;
      if (rootFolder && !rootFolder.childrenIds) {
        rootFolder.childrenIds = [];
      }
      rootFolder!.childrenIds = rootFolder!.childrenIds?.concat(
        ..._.map(orphanedItems, "id")
      );
      this.queue.addConfirmableAction({
        state: () => {},
        performAction: () => {
          return () =>
            saveFolder({ folder: _.pick(rootFolder, "id", "childrenIds") });
        },
      });
    }
    return items;
  }

  @action
  setFolderExpanded({
    expanded,
    folderId,
    type,
  }: {
    type: FolderType;
    expanded: boolean;
    folderId: GenericFolder["id"];
  }) {
    const folders = this.getFolders(type);
    if (!folders) return;
    this.queue.addConfirmableAction({
      state: () => this.getFolders(type),
      performAction: (folders) => {
        const folder = _.find(
          folders,
          (current) => current.id === folderId
        ) as GenericFolder;
        if (folder?.type === "folder" && folder.expanded !== expanded) {
          folder.expanded = expanded;
        }
        // persist action
        return () => {
          return saveFolder({
            folder: _.pick(folder, "id", "expanded"),
          });
        };
      },
      confirmErrorMessage: "Couldn't open folder.",
    });
  }

  @action
  deleteFolder({
    folderId,
    type,
  }: {
    type: FolderType;
    folderId: GenericFolderOrItem["id"];
  }) {
    this.queue.addConfirmableAction({
      state: () => this.foldersAll.current(),
      performAction: (folders) => {
        const folderOrItemIndex = _.findIndex(
          folders,
          (current) => current.id === folderId
        );
        // folder is missing
        if (folderOrItemIndex === -1) {
          // trying to delete unexistent folder
          return;
        }
        const folderOrItem = folders[folderOrItemIndex];
        // make sure we are deleting a folder
        if (folderOrItem?.type !== "folder") {
          return;
        }
        // remove item in place
        folders.splice(folderOrItemIndex, 1);

        const parent = _.find(
          folders,
          (current) =>
            current.type === "folder" &&
            current.childrenIds?.includes(folderOrItem.id)
        ) as GenericFolder | undefined;
        if (parent) {
          parent.childrenIds = _.filter(
            parent.childrenIds,
            (current) => current !== folderId
          );
        }

        return () => {
          const promises: Promise<any>[] = [];
          if (folderOrItem.type === "folder") {
            deleteFolder(folderOrItem.id);
          }
          if (parent) {
            promises.push(
              saveFolder({ folder: _.pick(parent, "id", "childrenIds") })
            );
          }
          // Remove children items
          if (folderOrItem.childrenIds) {
            // iterate over all children and delete them
            _.forEach(folderOrItem.childrenIds, (childId) => {
              const canDeleteSegment = () => {
                const segment = this.rootStore.segmentStore.getSegmentById(
                  Number(folderOrItem.id)
                );
                // delete only edittable segments
                if (segment && isEditableSegment(segment)) {
                  const showToastMessage = false;
                  this.rootStore.segmentStore.deleteSegment(
                    Number(childId),
                    showToastMessage
                  );
                }
              };
              switch (type) {
                case "personFields":
                  this.rootStore.personFieldsStore.deleteField(
                    endUsersObjectType,
                    String(childId)
                  );
                  break;
                case "emails":
                  this.rootStore.emailsStore.archiveEmail(Number(childId));
                  break;
                case "flows":
                  this.rootStore.flowsStore.archiveFlow(Number(childId));
                  break;
                case "broadcasts":
                  this.rootStore.broadcastsStore.archive(Number(childId));
                  break;
                case "tags":
                  this.rootStore.tagsStore.deleteTag(String(childId));
                  break;
                case "forms":
                case "eventManagement":
                  // TODO nguyen vu add logic here later
                  break;
                case "segments":
                  canDeleteSegment();
                  break;
                default:
                  if (isObjectSegmentFolderType(type)) {
                    canDeleteSegment();
                    break;
                  }
                  throw new UnreachableCaseError(type);
              }
            });
          }
          return Promise.all(promises);
        };
      },
      confirmErrorMessage: "Couldn't remove folder/item",
    });
  }

  @action
  addFolder({
    folder,
    selectFolder,
  }: {
    folder: Partial<GenericFolder> & {
      folderType: GenericFolder["folderType"];
    };
    selectFolder?: boolean;
  }) {
    const newFolder: GenericFolder = {
      ...folder,
      id: folder.id || uuidv4(),
      name: folder.name || "Unnamed folder",
      type: "folder",
    };
    this.queue.addConfirmableAction({
      state: () => this.foldersAll.current(),
      performAction: () => {
        // const folders = this.getFolders(type);
        // if (!folders) return;

        const folders = this.foldersAll.current();

        // add folder to list of folders
        folders.push(newFolder);

        // add this folder to the root folder
        const rootFolder = this.getRoot({ folders, type: folder.folderType });
        if (!rootFolder) {
          return;
        }
        if (!rootFolder?.childrenIds) {
          rootFolder.childrenIds = [];
        }
        rootFolder?.childrenIds?.push(newFolder.id);

        if (selectFolder) {
          this.setSelectedFolder(newFolder.id, newFolder.folderType);
        }

        return async () => {
          await Promise.all([
            createFolder({ folder: newFolder }),
            saveFolder({ folder: _.pick(rootFolder, "id", "childrenIds") }),
          ]);
        };
      },
      confirmErrorMessage: "Couldn't create folder.",
    });
  }

  @action
  addItemToRoot({
    type,
    itemId,
  }: {
    type: FolderType;
    itemId: GenericFolderItem["id"];
  }) {
    this.queue.addConfirmableAction({
      state: () => this.foldersAll.current(),
      performAction: () => {
        const folders = this.foldersAll.current();

        // add this folder to the root folder
        const rootFolder = this.getRoot({ folders, type });
        if (!rootFolder) {
          return;
        }
        if (!rootFolder?.childrenIds) {
          rootFolder.childrenIds = [];
        }
        rootFolder?.childrenIds?.push(itemId);

        return () => {
          return Promise.all([
            saveFolder({ folder: _.pick(rootFolder, "id", "childrenIds") }),
          ]);
        };
      },
      confirmErrorMessage: "Couldn't create item in folder.",
    });
  }

  getRoot({
    folders,
    type,
  }: {
    folders?: GenericFoldersAndItems;
    type: FolderType;
  }) {
    return _.find(
      folders,
      (folder) =>
        folder.type === "folder" && folder.root && folder.folderType === type
    ) as GenericFolder | undefined;
  }

  @action
  moveFolderOrItem({
    itemId,
    parentId,
    siblingIds,
    type,
  }: {
    type: FolderType;
    itemId: GenericFolderOrItem["id"];
    parentId: GenericFolder["id"];
    siblingIds: GenericFolderOrItem["id"][];
  }) {
    // Use promise so we can await this operation if needed
    return new Promise((resolve) => {
      this.queue.addConfirmableAction({
        state: () => this.getFolders(type),
        performAction: () => {
          const folders = this.getFolders(type);
          if (!folders) return;

          // remove item or folder from prev parent
          const prevParent = _.find(
            folders,
            (folder) =>
              folder.type === "folder" &&
              !folder.autoFolderType &&
              folder.childrenIds?.includes(itemId)
          ) as GenericFolder | undefined;

          const newParent: GenericFolder | undefined = parentId
            ? // find destination folder by id
              (_.find(
                folders,
                (folder) => folder.id === parentId
              ) as GenericFolder)
            : // find root folder
              this.getRoot({ folders, type });
          // make sure the parent exists
          if (!newParent) {
            return;
          }
          newParent.childrenIds = siblingIds;
          newParent.expanded = true;

          // if we moved from another folder
          if (prevParent?.id && prevParent?.id !== newParent.id) {
            prevParent.childrenIds = _.filter(
              prevParent.childrenIds,
              (childId) => childId !== itemId
            );
            //  save changes to both new and prev parent
            return async () => {
              await saveFolder({
                folder: _.pick(newParent, "id", "childrenIds", "expanded"),
              });
              // This is handled at the backend now
              // await saveFolder({
              //   folder: _.pick(prevParent, "id", "childrenIds"),
              // });
              resolve(undefined);
            };
          }
          //  save changes to new parent
          return async () => {
            await saveFolder({
              folder: _.pick(newParent, "id", "childrenIds", "expanded"),
            });
            resolve(undefined);
          };
        },
        confirmErrorMessage: "Couldn't move item.",
      });
    });
  }

  // Add item as the last child in a folder
  @action
  pushItemToFolder({
    folderId,
    itemId,
    type,
  }: {
    folderId: GenericFolder["id"];
    itemId: GenericFolderItem["id"];
    type: FolderType;
  }) {
    const folder = this.getFolderById(folderId);
    if (!folder) return;
    const siblingIds = [...(folder.childrenIds || []), itemId];
    return this.moveFolderOrItem({
      itemId,
      parentId: folderId,
      type,
      siblingIds,
    });
  }

  @action
  editFolderNameColor({
    folderId,
    name,
    color,
  }: {
    folderId: string;
    name: string;
    color?: string;
  }) {
    this.queue.addConfirmableAction({
      state: () => this.foldersAll.current(),
      performAction: (folders) => {
        const folder = _.find(
          folders,
          (current) => current.id === folderId
        ) as GenericFolder;
        if (folder?.type === "folder") {
          folder.name = name;
          if (color) {
            folder.color = color;
          }
        }
        // persist action
        return () => {
          return saveFolder({
            folder: _.pick(folder, "id", "name", "color"),
          });
        };
      },
      confirmErrorMessage: "Couldn't open folder.",
    });
  }

  getSelectedFolder(type: FolderType) {
    const idString = this.rootStore.locationStore.getQueryParameter(
      getFolderTypeForQuery(type)
    );
    console.log("getSelectedFolder", idString, type);
    return Number(idString) ? Number(idString) : idString;
  }

  @action
  setSelectedFolder(
    id: GenericFolderItem["id"],
    type: FolderType,
    push?: boolean
  ) {
    this.rootStore.locationStore.addQueryParameter({
      parameter: getFolderTypeForQuery(type),
      value: String(id),
      push,
    });
  }

  @action
  removeItemFromFolder(itemId: GenericFolderItem["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.foldersAll.current(),
      performAction: (folders) => {
        const parent = _.find(
          folders,
          (current) =>
            current.type === "folder" && current.childrenIds?.includes(itemId)
        ) as GenericFolder | undefined;
        if (parent) {
          parent.childrenIds = _.filter(
            parent.childrenIds,
            (current) => current !== itemId
          );
        }
        // persist action
        return () => {
          if (parent) {
            return saveFolder({
              folder: _.pick(parent, "id", "childrenIds"),
            });
          }
          return;
        };
      },
      confirmErrorMessage: "Couldn't remove item from folder.",
    });
  }

  getFavoritesFolder(type: FolderType) {
    const folders = this.getFolders(type);
    return _.find(folders, (current) => current.autoFolderType === "favorites");
  }

  addRemoveFavorites({
    type,
    itemId,
  }: {
    type: FolderType;
    itemId: GenericFolderItem["id"];
  }) {
    this.queue.addConfirmableAction({
      state: () => this.getFavoritesFolder(type),
      performAction: (favoriteFolder) => {
        if (!favoriteFolder) {
          const id = uuidv4();
          // create favorite folder
          // and add item to it

          toastSuccess("✅ Added to favorites.");
          return async () => {
            await this.addFolder({
              folder: {
                folderType: type,
                id,
                name: "Favorites",
                autoFolderType: "favorites",
                childrenIds: [itemId],
              },
            });
          };
        }

        const favorites = favoriteFolder.childrenIds;
        // Remove item from favorites
        if (_.find(favorites, (current) => current === itemId)) {
          favoriteFolder.childrenIds = _.filter(
            favoriteFolder.childrenIds,
            (current) => current !== itemId
          );
          toastSuccess("✅ Removed to favorites.");
          return async () => {
            await saveFolder({
              folder: _.pick(favoriteFolder!, "id", "childrenIds"),
            });
          };
        }
        if (!favoriteFolder.childrenIds) {
          favoriteFolder.childrenIds = [];
        }
        favoriteFolder.childrenIds?.push(itemId);
        toastSuccess("✅ Added to favorites.");
        return async () => {
          await saveFolder({
            folder: _.pick(favoriteFolder!, "id", "childrenIds"),
          });
        };
      },
    });
  }
}

export const getFolderTypeForQuery = (type: FolderType) =>
  `${type.toLowerCase()}-folder-item`;
