import { Button } from "reactstrap";
import useCopyToClipboard from "react-use/lib/useCopyToClipboard";
import { ReactComponent as CheckMark } from "../../assets/img/checkmark.svg";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";

interface Props {
  content: string;
  buttonText?: string | React.ReactElement;

  size?: React.ComponentProps<typeof Button>["size"];
  className?: string;
}
const CopyButton = (props: Props) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  const className = useMemo(
    () =>
      classNames(
        "copy-button",
        props.className || "mx-auto d-block btn-custom-outline-dark"
      ),
    [props.className]
  );

  // Hide the "Copied" message in a few seconds
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  }, [state]);

  const size = props.size || "sm";
  return (
    <Button
      className={className}
      color="link"
      size={size}
      onClick={() => {
        setCopied(true);
        copyToClipboard(props.content);
      }}
    >
      {state.value && copied ? <CheckMark /> : props.buttonText || "Copy"}
    </Button>
  );
};

export default CopyButton;
