import redirect from "domain/helpers/redirect";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { useState } from "react";
import { Button } from "reactstrap";
import { useStores } from "store/useStore";
import CopyFlowDialog from "./CopyFlowDialog";

const TopBarReadOnlyFlow = () => {
  const { accountStore, uiStore, flowsStore } = useStores();

  const loadingAccount = accountStore.isLoadingAccount();

  const copyFlow = useCallback(() => {
    // If not logged in, as the user to login
    if (!accountStore.account) {
      uiStore.registerDialog.open();
    } else {
      if (flowsStore.currentFlowId) {
        uiStore.copyFlowDialog.open({
          additionalData: { flowId: flowsStore.currentFlowId },
        });
      }
    }
  }, [
    accountStore.account,
    flowsStore.currentFlowId,
    uiStore.copyFlowDialog,
    uiStore.registerDialog,
  ]);

  return (
    <>
      <div className="main-bar d-flex main-bar-nav align-content-center justify-content-center">
        {!loadingAccount && (
          <Button
            color="success"
            onClick={copyFlow}
            data-testid="copy-flow-button"
          >
            Copy this flow to your account
          </Button>
        )}
      </div>
      <CopyFlowDialog />
    </>
  );
};
export default observer(TopBarReadOnlyFlow);
