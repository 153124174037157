import { getQueryClient } from "EnchargeQueryClient/getQueryClient";
import { PropsWithChildren } from "react";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

export const EnchargeQueryClient = (props: PropsWithChildren<{}>) => {
  const skipDevTools = Boolean((window as any).Cypress);

  return (
    <QueryClientProvider client={getQueryClient()}>
      {props.children}
      {!skipDevTools && (
        <ReactQueryDevtools initialIsOpen={false} position={"top-left"} />
      )}
    </QueryClientProvider>
  );
};
