import { observer } from "mobx-react";
import { useStores } from "store/useStore";

const GetHelpLabel = () => {
  const { accountStore } = useStores();

  if (accountStore.isExtendedSupportAvailable()) {
    return <>Live Chat</>;
  }
  return <>Get Help</>;
};
export default observer(GetHelpLabel);
