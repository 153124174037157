import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { GenericFolder } from "encharge-domain/definitions/ambient/folder";

export const getFolders = async () => {
  try {
    const res: AxiosResponse<{ folders: GenericFolder[] }> = await request({
      url: `${apiBase}/v1/folders`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.folders;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve folders. ";
    throw new Error(msg);
  }
};

export const createFolder = async ({
  folder,
}: {
  folder: Omit<GenericFolder, "children">;
}) => {
  try {
    const res: AxiosResponse<{ folder: GenericFolder }> = await request({
      url: `${apiBase}/v1/folders/`,
      method: "POST",
      data: folder,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.folder;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create folder. ";
    throw new Error(msg);
  }
};

export const saveFolder = async ({
  folder,
}: {
  folder: Partial<Omit<GenericFolder, "children">> & {
    id: GenericFolder["id"];
  };
}) => {
  try {
    const res: AxiosResponse<{ folder: GenericFolder }> = await request({
      url: `${apiBase}/v1/folders/${folder.id || ""}`,
      method: "PATCH",
      data: folder,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.folder;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save folder. ";
    throw new Error(msg);
  }
};

export const deleteFolder = async (folderId: GenericFolder["id"]) => {
  try {
    const res: AxiosResponse<{ folder: GenericFolder }> = await request({
      url: `${apiBase}/v1/folders/${folderId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.folder;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save folder. ";
    throw new Error(msg);
  }
};
