import ProactiveReachoutChatInner from "components/Onboarding/ProactiveReachoutChat/ProactiveReachoutChatInner";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";

const ProactiveReachoutChat = observer(() => {
  const { accountStore } = useStores();

  return accountStore.account?.status === "trial" &&
    !accountStore.account?.testAccount ? (
    <ProactiveReachoutChatInner />
  ) : null;
});
export default ProactiveReachoutChat;
