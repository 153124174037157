import { useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  type: string;
  isActive: boolean;
  icon: React.ReactElement;
  onClick: () => void;
  tooltipText: string;
  tooltipTitle: string;
}

const AIWriterTabsNavItem = (props: Props) => {
  const [ref, setRef] = useState<HTMLLIElement | null>(null);
  return (
    <li
      ref={(ref) => setRef(ref)}
      className="step-nav-item"
      onClick={props.onClick}
      data-testid={`ai-writer-step-sidebar-navigation-${props.type}`}
    >
      <span
        className={`step-nav-link ${props.isActive ? "active" : ""}`}
        role="tab"
        aria-controls={`step-${props.type}`}
        data-testid={`step-tabs-${props.type}`}
      >
        <span>{props.icon}</span>
      </span>
      {ref && (
        <UncontrolledTooltip
          target={ref}
          placement="left"
          delay={{ show: 250, hide: 0 }}
        >
          <h5 className="tooltip-title">{props.tooltipTitle}</h5>
          {props.tooltipText}
        </UncontrolledTooltip>
      )}
    </li>
  );
};
export default AIWriterTabsNavItem;
