import { observable, action, _allowStateChanges } from "mobx";

export interface ILazyObservable<T> {
  current(): T;
  refresh(): T;
  reset(): T;
  put(newVal: T): void;
  isLoading(): boolean;
  isFailed(): Error | undefined;
}

type Pagination = Dictionary<any> | undefined;

/**
 * `lazyObservable` creates an observable around a `fetch` method that will not be invoked
 * until the observable is needed the first time.
 * The fetch method receives a `sink` callback which can be used to replace the
 * current value of the lazyObservable. It is allowed to call `sink` multiple times
 * to keep the lazyObservable up to date with some external resource.
 *
 * Note that it is the `current()` call itself which is being tracked by MobX,
 * so make sure that you don't dereference to early.
 *
 * @example
 * const userProfile = lazyObservable(
 *   sink => fetch("/myprofile").then(profile => sink(profile))
 * )
 *
 * // use the userProfile in a React component:
 * const Profile = observer(({ userProfile }) =>
 *   userProfile.current() === undefined
 *   ? <div>Loading user profile...</div>
 *   : <div>{userProfile.current().displayName}</div>
 * )
 *
 * // triggers refresh the userProfile
 * userProfile.refresh()
 *
 * @param {(sink: (newValue: T) => void) => void} fetch method that will be called the first time the value of this observable is accessed. The provided sink can be used to produce a new value, synchronously or asynchronously
 * @param {T} [initialValue=undefined] optional initialValue that will be returned from `current` as long as the `sink` has not been called at least once
 * @returns {{
 *     current(): T,
 *     refresh(): T,
 *     reset(): T
 * }}
 */

export function lazyObservable<T>(
  fetch: (
    sink: (newValue: T, resultPaginationInfo?: Pagination) => void,
    onError: (e: Error) => void,
    pagination?: Pagination
  ) => void,
  initialValue: T | undefined = undefined
): ILazyObservable<T> {
  let started = false;
  const loading = observable.box(false);
  let error: Error | undefined;
  let pagination: Pagination = {};
  const value = observable.box(initialValue, { deep: false });

  const currentFnc = () => {
    if (!started) {
      started = true;
      _allowStateChanges(true, () => {
        loading.set(true);
      });
      try {
        fetch(
          (newValue: T, resultPaginationInfo?: Pagination) => {
            _allowStateChanges(true, () => {
              pagination = resultPaginationInfo;
              value.set(newValue);
              loading.set(false);
              error = undefined;
            });
          },
          (e: Error) => {
            _allowStateChanges(true, () => {
              loading.set(false);
            });
            error = e;
          },
          pagination
        );
      } catch (e) {
        error = e;
        _allowStateChanges(true, () => {
          loading.set(false);
        });
      }
    }
    return value.get();
  };
  const resetFnc = action("lazyObservable-reset", () => {
    started = false;
    value.set(initialValue as T);
    return value.get();
  });
  return {
    current: currentFnc,
    refresh: () => {
      if (started) {
        started = false;
        return currentFnc();
      }
      return value.get();
    },
    put: (newVal: T) => {
      _allowStateChanges(true, () => {
        value.set(newVal);
      });
    },
    reset: () => {
      return resetFnc();
    },
    isLoading: () => {
      return loading.get();
    },
    isFailed: () => {
      return error;
    },
  };
}
