import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../../routes";
import { observer } from "mobx-react";
import TrialExpiredMessage from "../../components/Billing/TrialExpiredMessage";
import TopBar from "components/AppNav/TopBar";
import AccountBlockedMessage from "components/Billing/AccountBlockedMessage";
import { shouldSkipResouces } from "domain/helpers/shouldSkipResouces";
import RegistrationFinishedCheck from "components/Registration/RegistrationFinishedCheck";
import AIWriterDrawer from "components/AIWriter/AIWriterDrawer";
import ExternalOnboarding from "components/Onboarding/ExternalOnboarding/ExternalOnboarding";
import UpgradeToPremiumDialog from "components/Upgrade/UpgradeToPremiumDialog";
import { useStores } from "store/useStore";
import ProactiveReachoutChat from "components/Onboarding/ProactiveReachoutChat/ProactiveReachoutChat";

/* tslint:disable:jsx-no-multiline-js */
/* tslint:jsx-no-lambda */

const AuthenticatedRoute = observer(
  ({ component: Component, isAuthenticated, ...rest }: any) => {
    const render = (props: any) => {
      const isAuthOk =
        isAuthenticated ||
        // We dont need to auth on login and register
        shouldSkipResouces();

      const hideTopBar =
        shouldSkipResouces() || props.location.pathname.startsWith("/start");

      const shouldRemovePageMain = props.location.pathname.startsWith(
        "/view-form"
      );
      return isAuthOk ? (
        <>
          {!hideTopBar && <TopBar />}
          {/* <div className="col position-relative overflow-hidden"> */}
          {shouldRemovePageMain ? (
            <Component {...props} />
          ) : (
            <div className="page-main">
              <Component {...props} />
            </div>
          )}
          {/* </div> */}
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
          push={true}
        />
      );
    };
    useEffect(() => {
      if (rest.name) {
        document.title = `${rest.name} | Encharge Marketing Automation`;
      }
    }, [rest.name]);

    return <Route {...rest} render={render} />;
  }
);

const DefaultLayout = () => {
  const { authStore } = useStores();
  const routeComponents = routes.map((route) =>
    route.component ? (
      <AuthenticatedRoute
        isAuthenticated={authStore.loggedIn}
        component={route.component}
        key={route.path}
        path={route.path}
        exact={route.exact}
        name={route.name}
        // tslint:disable-next-line:jsx-no-lambda
        // render={props => <route.component {...props} />}
      />
    ) : null
  );
  return (
    <div className="app">
      {/* <AppHeader fixed={true}> */}
      {/* <DefaultHeader /> */}
      {/* </AppHeader> */}
      <div className="app-body">
        {/* <AppSidebar fixed={true} display="hidden"> */}
        {/* <AppSidebarHeader /> */}
        {/* <AppSidebarForm /> */}
        {/* <AppSidebarNav navConfig={navigation} {...this.props} /> */}
        {/* <AppSidebarFooter /> */}
        {/* <AppSidebarMinimizer /> */}
        {/* </AppSidebar> */}
        <main className="main">
          <TrialExpiredMessage />
          <AccountBlockedMessage />
          <RegistrationFinishedCheck />
          <ExternalOnboarding />
          <ProactiveReachoutChat />
          <UpgradeToPremiumDialog />
          <AIWriterDrawer />
          {/* <AppBreadcrumb appRoutes={routes} /> */}
          {/* <Container fluid={true} style={{ minHeight: "80%", height: "80%" }}> */}
          <Switch>
            <Route
              exact
              path="/view-form/:id"
              render={(props) => (
                <Redirect to={`/public/view-form/${props.match.params.id}`} />
              )}
            />
            {routeComponents}
            <Redirect from="/" to="/dashboard" />
          </Switch>
          {/* </Container> */}
        </main>
      </div>
    </div>
  );
};

export default observer(DefaultLayout);
