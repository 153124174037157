import AccountPickerSelect from "components/AppNav/Account/AccountPickerSelect";
import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog";
import LoadingCentered from "components/LoadingCentered";
import { toastSuccess } from "domain/errorHandling/toaster";
import { redirectWithReload } from "domain/helpers/redirect";
import _ from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useStores } from "store/useStore";
import ShowFlowResources from "./ShowFlowResources";

const CopyFlowDialog = () => {
  const { accountStore, flowsStore, uiStore, permissionsStore } = useStores();
  // If we are copying read only flow, include the current account in the list
  // of accounts
  const copyReadOnlyFlow = permissionsStore.readOnlyFlow;

  const [selectedAccount, setSelectedAccount] = useState<number | undefined>(
    copyReadOnlyFlow ? accountStore.account?.accountId : undefined
  );
  const accounts = accountStore.allAccounts.current();
  const filteredAccounts = copyReadOnlyFlow
    ? accounts
    : _.filter(accounts, (curr) => curr.id !== accountStore.account?.accountId);
  if (!selectedAccount && filteredAccounts?.[0]?.id) {
    setSelectedAccount(filteredAccounts[0].id);
  }
  let dialogBody = <></>;
  const accountsLoading = accountStore.allAccounts.isLoading();
  if (accountsLoading) {
    dialogBody = (
      <>
        <LoadingCentered />
        {/* Load ShowFlowResources so that it can retrive the flow resources 
        But don't display it to avoid double loading indicator*/}
        <div className="d-none">
          <ShowFlowResources />
        </div>
      </>
    );
  } else {
    if (accounts?.length > 1) {
      dialogBody = (
        <>
          <div>Copy this flow to:</div>
          <div className="mt-2" data-testid="account-picker-select">
            <AccountPickerSelect
              accounts={filteredAccounts}
              selectedAccount={selectedAccount}
              onChange={(id) => {
                setSelectedAccount(id);
              }}
            />{" "}
          </div>
          <ShowFlowResources />
        </>
      );
    } else {
      dialogBody = (
        <>
          <div>Are you sure you want to copy this flow to your account?</div>
          <ShowFlowResources />
        </>
      );
    }
  }
  return (
    <ConfirmDialog
      isOpen={uiStore.copyFlowDialog.isOpen}
      onClose={() => uiStore.copyFlowDialog.close()}
      body={dialogBody}
      buttons={[
        {
          label: "Cancel",
          onClick: () => {
            uiStore.copyFlowDialog.close();
          },
          color: "link",
        },
        {
          label: "Copy Flow",
          onClick: async () => {
            const existingFlowId =
              uiStore.copyFlowDialog.additionalData?.flowId;
            if (existingFlowId && selectedAccount) {
              const flow = await flowsStore.createFlow({
                existingFlowId,
                targetAccountId: selectedAccount,
                addToCurrentFlows: false,
              });

              if (flow?.id) {
                // if we created a flow in the current account
                if (
                  !selectedAccount ||
                  selectedAccount === accountStore.account?.accountId
                ) {
                  // redirect to the flow
                  redirectWithReload(`/flows/${flow.id}`);
                } else {
                  // notify the user the flow was created
                  toastSuccess("✅ Flow copied.");
                  uiStore.copyFlowDialog.close();
                }
              }
            }
          },
          color: "success",
          loading: uiStore.flowCreate.isCreating,
          type: "button",
          disabled: accountsLoading,
        },
      ]}
    />
  );
};
export default observer(CopyFlowDialog);
