import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";
import { handleForbiddenRead } from "./handleForbidden";

export const getBroadcasts = async () => {
  try {
    const res: AxiosResponse<{ items: Broadcast[] }> = await request({
      url: `${apiBase}/v1/broadcasts`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.items;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};

export const createBroadcast = async (name: string) => {
  try {
    const res: AxiosResponse<{ item: Broadcast }> = await request({
      url: `${apiBase}/v1/broadcasts`,
      method: "POST",
      data: { name },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};

export const updateBroadcast = async (broadcast: Broadcast) => {
  try {
    const res: AxiosResponse<{ item: Broadcast }> = await request({
      url: `${apiBase}/v1/broadcasts/${broadcast.id}`,
      method: "PATCH",
      data: broadcast,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};
export const sendBroadcast = async (id: Broadcast["id"]) => {
  try {
    const res: AxiosResponse<{ item: Broadcast }> = await request({
      url: `${apiBase}/v1/broadcasts/${id}/send`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};
export const cancelBroadcast = async (id: Broadcast["id"]) => {
  try {
    const res: AxiosResponse<{ item: Broadcast }> = await request({
      url: `${apiBase}/v1/broadcasts/${id}/cancel`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};

export const deleteBroadcast = async (id: Broadcast["id"]) => {
  try {
    const res = await request({
      url: `${apiBase}/v1/broadcasts/${id}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    throw new Error(msg);
  }
};

export const checkBroadcastsStatus = async (id: Broadcast["id"]) => {
  try {
    const res: AxiosResponse<{
      item: { id: Broadcast["id"]; status: Broadcast["status"] };
    }> = await request({
      url: `${apiBase}/v1/broadcasts/${id}/status`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
  }
  return;
};

export const getBroadcastPeopleCount = async (id: Broadcast["id"]) => {
  try {
    const res: AxiosResponse<{
      count: number;
    }> = await request({
      url: `${apiBase}/v1/broadcasts/${id}/entered`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.count;
  } catch (e) {
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";

    throw new Error(msg);
  }
};
