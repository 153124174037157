import AIWriterFavorites from "components/AIWriter/AIWriterFavorites";

const AIWriterFavoritesWrapper = () => {
  return (
    <div className="w-100">
      <div className="step-nav-tab-pane-header py-4 px-2 px-md-3 px-lg-5">
        AI Writer Favorites
      </div>
      <div className="py-4 px-2 px-md-3 px-lg-5 h-100 overflow-auto">
        <AIWriterFavorites />
      </div>
    </div>
  );
};
export default AIWriterFavoritesWrapper;
