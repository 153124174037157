import ExternalOnboardingInner from "components/Onboarding/ExternalOnboarding/ExternalOnboardingInner";
import { getNodeEnv } from "domain/helpers/getNodeEnv";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";

interface Props {}

const ExternalOnboarding = (_props: Props) => {
  const { accountStore } = useStores();

  const accountId = accountStore.account?.accountId;

  return accountId &&
    !accountStore.account?.testAccount &&
    getNodeEnv() === "production" ? (
    <ExternalOnboardingInner accountId={accountId} />
  ) : null;
};
export default observer(ExternalOnboarding);
