import { observable } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";

import { lazyObservable } from "../domain/helpers/lazyLoad";
import { toastError } from "../domain/errorHandling/toaster";
import { getImport, getImports } from "./persistence/persistImport";

export class PeopleImportsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  imports = lazyObservable<IImportRecord[]>((sink, onError) => {
    getImports()
      .then((imports) => {
        // if we haven't set the current segment id, make the first one current
        // if (!this.currentSegmentId) {
        //   runInAction(() => (this.currentSegmentId = segments[0].id));
        // }
        sink(imports);
      })
      .catch((e) => {
        toastError({
          message: "Error while loading your imports.",
          extra: e,
        });
        onError(e);
      });
  });

  async getFullImportById(id: IImportRecord["id"]) {
    try {
      const importData = await getImport(id);
      return importData;
    } catch (e) {
      toastError({
        message: "Error while loading your import.",
        extra: e,
      });
    }
    return;
  }
}
