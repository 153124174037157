import { Button, Container } from "reactstrap";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import {
  ISegment,
  SegmentCondition,
} from "encharge-domain/definitions/ambient/segment";
import { getEmailsUsedInFlows } from "../EmailMetrics/EmailMultiSelect/helper";
import { IFlow } from "components/Flows/FlowRoute";
import { v4 } from "uuid";
import { useEffect } from "react";
import { IMetricsType } from "components/Charts/SingleMetricChart";
import { toastError } from "domain/errorHandling/toaster";

const MetricEventActions = observer(
  ({
    emailIds,
    flowIds,
    type,
    startDate,
    endDate,
  }: {
    emailIds?: IEmailContent["id"][];
    flowIds?: IIntegration["id"][];
    type: IMetricsType;
    startDate?: Date;
    endDate?: Date;
  }) => {
    const { segmentStore, flowsStore, emailsStore } = useStores();
    useEffect(() => {
      segmentStore.getAllSegments();
    }, [segmentStore]);
    const createSegmentForEmailEvent = () => {
      const name = "Segment for people who " + type;
      const objectName = "person";
      const usedEmailIds: IEmailContent["id"][] = [...(emailIds || [])];
      let emailIdsUsedInFlow: IEmailContent["id"][] = [];
      if (flowIds?.length) {
        const flows = flowIds.map(
          (flowId) => flowsStore.getFlowById(flowId) as IFlow
        );
        emailIdsUsedInFlow = getEmailsUsedInFlows(flows);
        if (!usedEmailIds.length) {
          usedEmailIds.push(...emailIdsUsedInFlow);
        } else {
          usedEmailIds.push(
            ...usedEmailIds.filter((emailId) =>
              emailIdsUsedInFlow.includes(emailId)
            )
          );
        }
      }
      const segmentConditionGroup: SegmentCondition[] = [];
      if (usedEmailIds.length) {
        usedEmailIds.forEach((usedEmailId) => {
          segmentConditionGroup.push({
            uuid: v4(),
            type: "event",
            condition: "has",
            systemConditions: {
              conditions: [
                {
                  type: "field",
                  field: "type",
                  condition: "is",
                  value: "email",
                },
                {
                  type: "field",
                  field: "name",
                  condition: "is",
                  value: type,
                },
                {
                  type: "field",
                  field: "data.emailId",
                  condition: "is",
                  value: usedEmailId,
                },
              ],
              operator: "and",
              system: true,
              type: "group",
            },
          });
        });
      } else {
        segmentConditionGroup.push({
          uuid: v4(),
          type: "event",
          condition: "has",
          systemConditions: {
            conditions: [
              {
                type: "field",
                field: "type",
                condition: "is",
                value: "email",
              },
              {
                type: "field",
                field: "name",
                condition: "is",
                value: type,
              },
            ],
            operator: "and",
            system: true,
            type: "group",
          },
        });
      }
      const conditions: ISegment["conditions"] = {
        conditions: segmentConditionGroup,
        operator: "or",
        type: "group",
      };
      segmentStore.createSegment({
        name,
        objectName,
        conditions,
        color: "#000000",
      });
    };
    return (
      <div>
        {/* Comment until we have percise time for segment */}
        {/* <Button
          className="mr-2"
          onClick={() => {
            createSegmentForEmailEvent();
          }}
          color="primary"
        >
          Create segment
        </Button>{" "} */}
        <Button
          onClick={() => {
            if (type === "entered" || type === "reachedGoal") {
              if (!flowIds?.length) {
                toastError("No flows selected");
                return;
              }
              flowsStore.exportFlowPeopleEvents({
                flowIds,
                type,
                startDate,
                endDate,
              });
            } else {
              emailsStore.exportEmailEvents({
                emailIds,
                flowIds,
                type,
                startDate,
                endDate,
              });
            }
          }}
          className="btn-custom-outline-dark"
        >
          Save as CSV
        </Button>{" "}
      </div>
    );
  }
);

export default MetricEventActions;
