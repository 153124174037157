import { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

interface Props {
  duration?: number;
}

const Confetti = (props: Props) => {
  const { width, height } = useWindowSize();
  const numConfetti = 200;
  // Disable new confetti after duration (or 2 seconds by default)
  const duration = props.duration || 3000;
  const [confetti, setConfetti] = useState(numConfetti);
  useEffect(() => {
    const timer = setTimeout(() => setConfetti(0), duration);
    return () => clearTimeout(timer);
  });
  return (
    <ReactConfetti width={width} height={height} numberOfPieces={confetti} />
  );
};
export default Confetti;
