import { useState } from "react";
import * as React from "react";
import { useStores } from "../../store/useStore";
import { observer } from "mobx-react";
import PaypalPlansForm from "./PaypalPlansForm";
import ChargebeeCheckout from "components/Billing/ChargebeeCheckout";
import { Alert } from "reactstrap";

interface Props {
  button?: React.ReactElement;
}
const ChangePlan = observer((props: Props) => {
  const [displayPlans, setDisplayPlans] = useState(false);
  const { accountStore } = useStores();
  if (!accountStore.account) {
    return <div />;
  }
  const email = accountStore?.account?.email;
  if (!email) {
    return <Alert>Missing account email.</Alert>;
  }
  const peopleUpperLimit =
    accountStore?.account?.payment?.plan?.peopleUpperLimit || 0;

  const paypalCustomer =
    accountStore.account?.payment?.paymentMethod === "paypal";
  const plansForm = paypalCustomer ? (
    <PaypalPlansForm
      peopleUpperLimit={peopleUpperLimit}
      email={email}
      onCancel={() => setDisplayPlans(false)}
    />
  ) : (
    <ChargebeeCheckout
      account={accountStore.account}
      email={email}
      onCancel={() => setDisplayPlans(false)}
    />
  );
  const button = props.button ? (
    <props.button.type
      {...props.button.props}
      onClick={() => setDisplayPlans(true)}
    />
  ) : (
    <div>
      <span className="btn btn-link" onClick={() => setDisplayPlans(true)}>
        Upgrade
      </span>
    </div>
  );
  return (
    <>
      {displayPlans && plansForm}
      {button}
    </>
  );
});

export default ChangePlan;
