import LoadingCentered from "components/LoadingCentered";
import { toastError } from "domain/errorHandling/toaster";
import { useEffect, useState } from "react";
import { getFlowAttachedResources } from "store/persistence/persistFlow";
import { useStores } from "store/useStore";

interface Props {}

const ShowFlowResources = (_props: Props) => {
  const { flowsStore, permissionsStore } = useStores();

  const [isLoadingFlowResources, setIsLoadingFlowResources] = useState(false);
  const [flowResources, setFlowResources] = useState<{
    email: number[];
    segment: number[];
    field: string[];
  }>();
  const currentFlowId = flowsStore.currentFlowId;
  const readOnlyAuthToken = permissionsStore.readOnlyAuthToken;

  useEffect(() => {
    if (currentFlowId) {
      setIsLoadingFlowResources(true);
      getFlowAttachedResources(currentFlowId, readOnlyAuthToken)
        .then((resources) => {
          setFlowResources(resources);
          setIsLoadingFlowResources(false);
        })
        .catch(() => {
          setIsLoadingFlowResources(false);
          toastError("Couldn't retrieve resources attached to this flow.");
        });
    }
  }, [currentFlowId, readOnlyAuthToken]);

  const emailsCount = Number(flowResources?.email?.length);
  const fieldsCount = Number(flowResources?.field?.length);
  const segmentsCount = Number(flowResources?.segment?.length);
  const totalResourcesCount = emailsCount + fieldsCount + segmentsCount;
  return (
    <div>
      {isLoadingFlowResources && <LoadingCentered size="small" />}
      {totalResourcesCount > 0 && (
        <div className="mt-3">
          This will also copy:
          <ul>
            {Number(emailsCount) > 0 && <li>{emailsCount} Emails</li>}
            {Number(segmentsCount) > 0 && <li>{segmentsCount} Segments</li>}
            {Number(fieldsCount) > 0 && <li>{fieldsCount} Custom Fields</li>}
          </ul>
        </div>
      )}
    </div>
  );
};
export default ShowFlowResources;
