import { IStep } from "../../components/FlowEditor/Step/StepBase";
import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";
import { handleForbiddenRead } from "./handleForbidden";

export const getStepMetrics = async ({
  id,
  period,
  timezone,
  startDate,
  endDate,
}: {
  id: IStep["id"];
  period: IStatsPeriod;
  timezone: string;
  startDate?: Date;
  endDate?: Date;
}) => {
  if (!id) {
    throw new Error("Can't refresh step without ID.");
  }
  const res: AxiosResponse<{
    stats: IEmailsStats;
  }> = await request({
    url: `${apiBase}/v1/emails/stats`,
    params: {
      period,
      stepId: [id],
      timezone,
      startDate,
      endDate,
    },
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return res.data.stats;
};

export const getEmailsMetrics = async (params: {
  period: IStatsPeriod;
  timezone: string;
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  emailIds?: IEmailContent["id"][];
  broadcastId?: Broadcast["id"];
  broadcastIds?: Broadcast["id"][];
  groupByBroadcast?: boolean;
  abTest?: boolean;
  groupByEmail?: boolean;
}) => {
  try {
    const res: AxiosResponse<{
      stats: IEmailsStats;
      clicks: IEmailClicksMetrics;
    }> = await request({
      url: `${apiBase}/v1/emails/stats`,
      params,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return { stats: {}, clicks: [] };
    throw e;
  }
};

export const getPeopleMetrics = async (
  period: IStatsPeriod,
  startDate: Date
) => {
  try {
    const res: AxiosResponse<{
      metrics: IPeopleMetrics;
    }> = await request({
      url: `${apiBase}/v1/people/metrics/${period}`,
      params: {
        startDate: startDate.toJSON(),
      },
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    return res.data.metrics;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return undefined;
    throw e;
  }
};

export const exportEmailEvents = async (params: {
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  emailIds?: IEmailContent["id"][];
  type: IEmailStatsType;
  notifyEmail: string;
}) => {
  try {
    const res: AxiosResponse<{
      metrics: IPeopleMetrics;
    }> = await request({
      url: `${apiBase}/v1/emails/stats/events/export`,
      data: {
        startDate: params.startDate?.toJSON(),
        endDate: params.endDate?.toJSON(),
        flowIds: params.flowIds,
        emailIds: params.emailIds,
        type: params.type,
        notifyEmail: params.notifyEmail,
      },
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    return res.data.metrics;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return undefined;
    throw e;
  }
};

export const getEmailsEvents = async (params: {
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  emailIds?: IEmailContent["id"][];
  type: IEmailStatsType;
  offset: number;
  broadcastId?: Broadcast["id"];
  abTestVariantId?: string;
  stepId?: IStep["id"];
}) => {
  try {
    const res: AxiosResponse<{
      events: any;
    }> = await request({
      url: `${apiBase}/v1/emails/stats/events`,
      params: {
        ...params,
        limit: 100,
      },
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.events;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return { events: [] };
    throw e;
  }
};

export const getFlowsEvents = async (params: {
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  type: "entered" | "reachedGoal";
  offset: number;
}) => {
  try {
    const res: AxiosResponse<{
      events: any;
    }> = await request({
      url: `${apiBase}/v1/integrations/people/events`,
      params: {
        ...params,
        limit: 100,
      },
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.events;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return { events: [] };
    throw e;
  }
};

export const exportFlowPeopleEvents = async (params: {
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  type: "entered" | "reachedGoal";
  notifyEmail: string;
}) => {
  try {
    const res: AxiosResponse<{
      metrics: IPeopleMetrics;
    }> = await request({
      url: `${apiBase}/v1/integrations/people/events/export`,
      data: {
        startDate: params.startDate?.toJSON(),
        endDate: params.endDate?.toJSON(),
        flowIds: params.flowIds,
        type: params.type,
        notifyEmail: params.notifyEmail,
      },
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    return res.data.metrics;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return undefined;
    throw e;
  }
};
