import AIWriter from "components/AIWriter/AIWriter";
import AIWriterTabsNavItem from "components/AIWriter/AIWriterTabsNavItem";
import { useState } from "react";
import { ReactComponent as AIWriterIcon } from "../../assets/img/magic-icon.svg";
import { ReactComponent as HeartIcon } from "../../assets/img/heart-icon.svg";
import AIWriterFavoritesWrapper from "components/AIWriter/AIWriterFavoritesWrapper";

const AIWriterTabsNav = () => {
  const [tab, setTab] = useState<"generate" | "favorites">("generate");

  return (
    <>
      <div className="step-nav-container">
        <ul className="nav step-nav" id="stepNav" role="tablist">
          <AIWriterTabsNavItem
            icon={
              <AIWriterIcon
                className="icn icn-step-nav"
                width="16px"
                height="16px"
              />
            }
            type={tab}
            isActive={tab === "generate"}
            onClick={() => setTab("generate")}
            tooltipTitle="Magic Writer"
            tooltipText="Writes copy for you."
          />
          <AIWriterTabsNavItem
            icon={
              <HeartIcon
                className="icn icn-step-nav"
                width="16px"
                height="16px"
              />
            }
            type={tab}
            isActive={tab === "favorites"}
            onClick={() => setTab("favorites")}
            tooltipTitle="Saved"
            tooltipText="Copy that you've saved."
          />
        </ul>
      </div>
      {tab === "generate" && <AIWriter />}
      {tab === "favorites" && <AIWriterFavoritesWrapper />}
    </>
  );
};
export default AIWriterTabsNav;
