import _ from "lodash";
import { FullFlow } from "../../store/persistence/persistFlow";
import { StepBase, IStep } from "../../components/FlowEditor/Step/StepBase";

export const unserializeSteps = async (steps: FullFlow["steps"]) => {
  const stepsPromises = _.map(steps, (step) => {
    return StepBase.unserialize(step);
  });
  const stepsArray = await Promise.all(stepsPromises);

  return _.reduce(
    stepsArray,
    (acc, step) => {
      acc[step.id!] = step;
      return acc;
    },
    {} as Dictionary<IStep>
  );
};
