export const apiBase =
  process.env.REACT_APP_API_URL || "https://api.encharge.io";

export const appBase =
  process.env.REACT_APP_APP_URL || "https://app.encharge.io";

export const peoplePageSize = 100;

export const objectsPageSize = 100;

export const eventPageSize = 1000;

export const segmentConditionEmailStatusValue =
  "x-encharge-reserved-email-status";
export const segmentConditionTagValue = "x-encharge-reserved-tag";
export const segmentConditionTypeformSubmissionValue =
  "x-encharge-reserved-typeform-submission";
export const segmentConditionGenericEventValue = "x-encharge-reserved-event";

export const toastMessageDuration = 3000;

export const peopleEventsPageSize = 100;

export const segmentPreviewMaxPeople = 20;

export const testTeamMemberIds = [34, 262];

export const formServingApi =
  process.env.REACT_APP_FORM_SERVING_API || "https://forms.encharge.io";

export const supportedFonts = [
  {
    name: "Default",
    value: `"GT America", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
  },
  { name: "Roboto", value: "Roboto" },
  { name: "Roboto Mono", value: "Roboto Mono" },
  { name: "Roboto Slab", value: "Roboto Slab" },
  { name: "Open Sans", value: "Open Sans" },
  { name: "Arial", value: "Arial" },
  { name: "Verdana", value: "Verdana" },
  { name: "Tahoma", value: "Tahoma" },
  { name: "Trebuchet MS", value: "Trebuchet MS" },
  { name: "Times New Roman", value: "Times New Roman" },
  { name: "Georgia", value: "Georgia" },
  { name: "Garamond", value: "Garamond" },
  { name: "Courier New", value: "Courier New" },
  { name: "Brush Script MT", value: "Brush Script MT" },
];
