import { ReactElement, useCallback, useRef, useState } from "react";
import _ from "lodash";
import { ReactComponent as WarningIcon } from "../../assets/img/warning-icon.svg";
import {
  Button,
  Modal,
  UncontrolledTooltip,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";
import { emailsSentMetricName } from "encharge-domain/lib/helpers/emailSentMetricsHelper";

const AccountWarnings = () => {
  const iconRef = useRef<any>();
  const { accountStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen, setIsDialogOpen]);

  const account = accountStore.account;
  if (!account) return null;

  // Retrieve issues for this account
  const issues = accountStore.accountIssues.current();
  if (accountStore.accountIssues.isLoading()) {
    return null;
  }
  const hasIssues = issues?.length > 0;
  if (!hasIssues) {
    return null;
  }
  let warning: ReactElement | null = null;
  let title: string = "";

  const errors = _.filter(issues, (issue) => issue.priority === "error");
  const isSendingPausedForVolume = _.some(
    issues,
    (issue) =>
      issue.priority === "error" && issue.name.startsWith(emailsSentMetricName)
  );

  if (
    isSendingPausedForVolume &&
    // Make sure sending volume is the only error
    errors.length === 1
  ) {
    title = "Email Sending Warm Up";
    warning = (
      <div>
        Thank you for sending your first email with Encharge.
        <br />
        <br />
        Since this is your first email, we have to manually review the content
        of your email before it goes out. Thank you for your patience.
        <br />
        <br />
        If you need immediate assistance, you can{" "}
        <a href="mailto:support@encharge.io">write to us</a>.
      </div>
    );
  } else if (errors.length > 0) {
    title = "Email Sending Paused";
    warning = (
      <div>
        <p>
          Due to unusual activity on your account, we have temporarily disabled
          email sending.
        </p>
        <p>Our team will review it manually. </p>
        <p>
          Please note that your emails are currently paused but should go out
          shortly.
        </p>
        <p>
          If you need immediate assistance, you can{" "}
          <a href="mailto:support@encharge.io">write to us</a>.
        </p>
      </div>
    );
  }

  if (!warning) {
    return null;
  }
  return (
    <>
      <div className="account-warnings-container mr-3">
        <span className="cursor-pointer warning-active" onClick={toggleDialog}>
          <WarningIcon ref={iconRef} />
        </span>
        {iconRef.current && (
          <UncontrolledTooltip target={iconRef.current}>
            There are issues with your account. <br />
            Click for more information.
          </UncontrolledTooltip>
        )}
        <Modal
          isOpen={isDialogOpen}
          toggle={toggleDialog}
          zIndex={1400}
          size={"lg"}
        >
          <ModalHeader>{title}</ModalHeader>
          <ModalBody>{hasIssues && warning}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleDialog}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default observer(AccountWarnings);
