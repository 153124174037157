import Loading from "components/Loading";

export const LoadingCenteredHorizontally = () => {
  return (
    <div className="loading-centered-horizontally">
      <Loading />
    </div>
  );
};

export default LoadingCenteredHorizontally;
