import GenerateFlowsCarouselGeneratedItem from "components/Registration/GenerateFlows/GenerateFlowsCarouselGeneratedItem";
import GenereateFlowsCarouselGeneratedEmail from "components/Registration/GenerateFlows/GenerateFlowsGeneratedEmail";
import _ from "lodash";
import generatedEmailsBackground from "../../../assets/img/blue-gradient.jpeg";

interface Props {
  isGeneratingCompanyInfo?: boolean;
  isGeneratingEmailIndex?: number;
  isDone?: boolean;
  totalEmails: number;
  emails: {
    subject: string;
    text: string;
  }[];
  companyInfoPreview?: React.ReactNode;
  fromName: string;
  fromEmail: string;

  footer?: React.ReactNode;
}

const GenerateFlowsCarousel = (props: Props) => {
  const emailItems = _.map(_.range(props.totalEmails), (index) => {
    let subtitle = "";
    if (index === props.isGeneratingEmailIndex) subtitle = "Generating...";
    else if (index < (props.isGeneratingEmailIndex || 0))
      subtitle = "Completed";
    else subtitle = "To generate";
    if (props.isDone) {
      subtitle = "Completed";
    }
    return (
      <GenerateFlowsCarouselGeneratedItem
        key={index}
        title={`Email ${index + 1}`}
        subtitle={subtitle}
        showPlaceholder={"email"}
      >
        {props.isGeneratingEmailIndex !== undefined &&
        index <= props.isGeneratingEmailIndex ? (
          <GenereateFlowsCarouselGeneratedEmail
            subject={props.emails?.[index]?.subject}
            text={props.emails?.[index]?.text}
            fromEmail={props.fromEmail}
            fromName={props.fromName}
            tinyMode={true}
          />
        ) : null}
      </GenerateFlowsCarouselGeneratedItem>
    );
  });
  return (
    <div className="generate-flows-carousel">
      <img
        className="generate-flows-carousel-background"
        src={generatedEmailsBackground}
        alt="background"
      />
      <div>
        <div className="generate-flows-carousel-scroll-container">
          <GenerateFlowsCarouselGeneratedItem
            title={"Business information"}
            subtitle={
              props.isGeneratingCompanyInfo ? "Generating..." : "Completed"
            }
            showPlaceholder={
              props.isGeneratingCompanyInfo ? "company" : undefined
            }
          >
            {props.companyInfoPreview}
          </GenerateFlowsCarouselGeneratedItem>
          {emailItems}
        </div>
      </div>
      {props.footer}
    </div>
  );
};
export default GenerateFlowsCarousel;
