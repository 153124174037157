import { observable, toJS } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError } from "../domain/errorHandling/toaster";
import {
  getPersonEvents,
  getLastSiteTrackingEvent,
} from "./persistence/persistEvents";
import { lazyObservable, ILazyObservable } from "../domain/helpers/lazyLoad";

export class EventsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  allEvents: ILazyObservable<IPersonEvent[]> = lazyObservable<IPersonEvent[]>(
    (sink, onError, pagination) => {
      getPersonEvents("all", pagination?.cursor)
        .then(({ events, cursor }) => {
          // append newly retrieved events to  events
          const currentEvents: IPersonEvent[] =
            this?.allEvents?.current?.() || [];
          sink(toJS(currentEvents).concat(...events), { cursor });
        })
        .catch((e) => {
          toastError({
            message: "Error while loading events.",
            extra: e,
          });
          onError(e);
        });
    }
  );

  @observable
  lastEventTime = lazyObservable<Date | undefined>((sink) => {
    getLastSiteTrackingEvent()
      .then((event) => {
        sink(
          // only use date if event is available
          event?.createdAt ? new Date(event.createdAt!) : undefined
        );
      })
      .catch((e) => {
        sink(undefined);
        toastError(`Error while checking for events: ${e.message}`);
      });
  });
}
