import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { ISegmentPreview } from "../segmentStore";
import {
  ISegmentV2,
  ISegment,
} from "encharge-domain/lib/definitions/ambient/segment";
import { handleForbiddenRead } from "./handleForbidden";

export const getSegments = async () => {
  try {
    const res: AxiosResponse<{ segments: ISegmentV2[] }> = await request({
      url: `${apiBase}/v1/segments`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segments;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];

    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const getSegmentPreview = async (id: ISegment["id"]) => {
  try {
    const res: AxiosResponse<{ segment: ISegmentPreview }> = await request({
      url: `${apiBase}/v1/segments/${id}/preview`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segment;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const getSegmentPeople = async (
  id: ISegment["id"],
  options: {
    limit: number;
    offset: number;
    attributes?: string[];
    sort?: string;
    order?: "asc" | "desc";
    ignoreAnonymous?: boolean;
  }
) => {
  try {
    const res: AxiosResponse<{
      people: IEndUser[];
    }> = await request({
      url: `${apiBase}/v1/segments/${id}/people`,
      params: options,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.people;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const updateSegment = async (segment: ISegmentV2) => {
  try {
    const res: AxiosResponse<{ segment: ISegmentV2 }> = await request({
      url: `${apiBase}/v1/segments/${segment.id}`,
      method: "PATCH",
      data: segment,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segment;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const createSegment = async (segment: {
  name: ISegment["name"];
  color: ISegment["color"];
  objectName: ISegment["objectName"];
  conditions?: ISegment["conditions"];
}) => {
  try {
    const res: AxiosResponse<{ segment: ISegmentV2 }> = await request({
      url: `${apiBase}/v1/segments/`,
      method: "POST",
      data: segment,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segment;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const deleteSegment = async (segmentId: ISegment["id"]) => {
  try {
    await request({
      url: `${apiBase}/v1/segments/${segmentId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};
