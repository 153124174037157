import { apiBase } from "../../constants";
import { EnchargeAPI } from "./api_client/generated/src";

const enchargeAPI = new EnchargeAPI(
  {
    getToken: () => null as any,
  },
  {
    endpoint: `${apiBase}/v1`,
    withCredentials: true,
    noRetryPolicy: true,
  }
);
export default enchargeAPI;

export const parseAPIErrorMessage = (error: any) => {
  try {
    const msg = JSON.parse(error?.message)?.error?.message;
    return msg;
  } catch (e) {
    return error?.message || error;
  }
};
