import { Button } from "reactstrap";
import { AIContentSuggestion } from "encharge-domain/definitions/AIContentSuggestion";
import TextareaAutosize from "react-textarea-autosize";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";
import _ from "lodash";
import CopyButtonInline from "components/Common/CopyButtonInline";
import { ReactComponent as InsertIcon } from "../../assets/img/insert-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/img/copy-icon.svg";
import { ReactComponent as MoreLikeThisIcon } from "../../assets/img/more-icon.svg";
import { ReactComponent as HeartIcon } from "../../assets/img/heart-icon.svg";
import { useCallback } from "react";
import { generate } from "fast-json-patch";

interface Props {
  contentSuggestion: AIContentSuggestion;
  generateSamples?: (string: string) => void;
  hideButtons?: ("insert" | "copy" | "more" | "favorite")[];
}

const AIWriterGeneratedContentSingle = (props: Props) => {
  const { aiWriterStore, uiStore } = useStores();
  // const contentSuggestion = _.find(aiWriterStore.contentSuggestions, {
  //   id: props.contentSuggestion.id,
  // });
  // if (!contentSuggestion) {
  //   return null;
  // }
  const generateMoreLikeThis = () => {
    props.generateSamples?.(props.contentSuggestion.content);
  };
  // More like this is disabled for now, until we can figure it out
  const canGenerateMoreLikeThis = false;
  // const canGenerateMoreLikeThis =
  //   props.generateSamples && !props.hideButtons?.includes("more");

  const canInsert = Boolean(uiStore.aiWriter.onInsert);
  const onInsert = useCallback(() => {
    uiStore.aiWriter?.onInsert?.(props.contentSuggestion.content);
  }, [props.contentSuggestion.content, uiStore.aiWriter]);
  const onSave = useCallback(() => {
    aiWriterStore?.favoriteContentSuggestion?.(props.contentSuggestion);
  }, [aiWriterStore, props.contentSuggestion]);
  return (
    <div className="generated-content-single-wrapper mb-1 py-3">
      <TextareaAutosize
        className="generated-content-single-textarea form-control"
        value={props.contentSuggestion.content}
        onChange={(e) => {
          aiWriterStore.updateContentSuggestion(
            props.contentSuggestion.id,
            e.currentTarget.value
          );
        }}
      />
      <div className="mt-4 generated-content-single-actions">
        {canInsert && !props.hideButtons?.includes("insert") && (
          <Button
            onClick={onInsert}
            className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2"
          >
            <InsertIcon className="mr-2" width="16" height="16" />
            Insert
          </Button>
        )}
        {!props.hideButtons?.includes("copy") && (
          <span className="mr-2">
            <CopyButtonInline
              content={props.contentSuggestion.content}
              buttonText={
                <>
                  <CopyIcon className="mr-2" width="16" height="16" />
                  Copy
                </>
              }
              size="sm"
            />
          </span>
        )}
        {canGenerateMoreLikeThis && (
          <Button
            className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2"
            onClick={generateMoreLikeThis}
            disabled={uiStore.aiWriterLoading.isLoading}
          >
            <MoreLikeThisIcon className="mr-2" width="16" height="16" />
            More like this
          </Button>
        )}
        {!props.hideButtons?.includes("favorite") && (
          <Button
            className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2"
            onClick={onSave}
          >
            <HeartIcon className="mr-2" width="16" height="16" />
            Save
          </Button>
        )}
      </div>
    </div>
  );
};
export default observer(AIWriterGeneratedContentSingle);
