import { useState } from "react";

interface Props {
  domain: string;
  size: number;
}

const CompanyAvatar = (props: Props) => {
  const [logoError, setLogoError] = useState(false);
  const [visible, setVisible] = useState(false);
  if (logoError) return null;
  const size = props.size || 128;
  return (
    <div
      style={{
        width: size,
        display: visible ? "flex" : "none",
        justifyContent: "center",
      }}
    >
      {props.domain ? (
        <img
          alt={`Company Logo`}
          src={`//logo.clearbit.com/${props.domain}?size=${size * 2}`}
          onError={() => setLogoError(true)}
          onLoad={() => setVisible(true)}
          style={{
            maxWidth: `${size}px`,
            maxHeight: `${size}px`,
          }}
        />
      ) : null}
    </div>
  );
};
export default CompanyAvatar;
