import { getNodeEnv } from "domain/helpers/getNodeEnv";

export const loadChargebee = (onload: () => void) => {
  if (
    document.getElementById("chargebee-script") &&
    (window as any).Chargebee
  ) {
    // script is loaded so trigger onload
    onload();
    // but dont load script again
    return;
  }
  const script = document.createElement("script");
  script.id = "chargebee-script";
  script.src = "https://js.chargebee.com/v2/chargebee.js";
  script.async = true;
  const siteName = getNodeEnv() === "production" ? "encharge" : "encharge-test";
  script["data-cb-site"] = siteName;
  script["data-cb-gtm-enabled"] = "true";
  // Key used to create subscriptions for trial
  const publishableKey = process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY;
  // open customer portal if script is loaded
  script.onload = () => {
    // init charge bee instance
    (window as any).Chargebee.init({
      site: siteName,
      enableGTMTracking: true,
      publishableKey,
    });
    // proceed with checkout or portal
    onload();
  };
  document.body.appendChild(script);
};
