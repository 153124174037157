/**
 * Performs a shallow comparison between two objects.
 * Returns true if any of the values are different.
 * Pulled from react-compat
 * https://github.com/developit/preact-compat/blob/7c5de00e7c85e2ffd011bf3af02899b63f699d3a/src/index.js#L349
 */
function shallowDiffers(prev: Object, next: Object): boolean {
  for (let attribute in prev) {
    if (!(attribute in next)) {
      return true;
    }
  }
  for (let attribute in next) {
    if (prev[attribute] !== next[attribute]) {
      return true;
    }
  }
  return false;
}

/**
 * Performs a shallow comparison between two objects.
 * Returns true if the values are the same.
 */
export function areShallowEqual(prev: Object, next: Object): boolean {
  return !shallowDiffers(prev, next);
}

// Custom comparison function for React.memo().
// It knows to compare individual style props and ignore the wrapper object.
// See https://reactjs.org/docs/react-api.html#reactmemo
export default function arePropsEqual(
  prevProps: Object,
  nextProps: Object
): boolean {
  const { style: prevStyle, ...prevRest } = prevProps as any;
  const { style: nextStyle, ...nextRest } = nextProps as any;

  return (
    !shallowDiffers(prevStyle, nextStyle) && !shallowDiffers(prevRest, nextRest)
  );
}
