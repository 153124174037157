import { GenericFolder } from "encharge-domain/lib/definitions/ambient/folder";
import _ from "lodash";
import { CustomObjectSchema } from "encharge-domain/definitions/CustomObjectSchema";
import { endUsersObjectType } from "encharge-domain/lib/helpers/sync_engine_helper";

export const combineSchemaAndFieldIds = (
  schemaName: string,
  fieldName: string
) => `${schemaName}___${fieldName}`;

export const splitSchemaAndFieldIds = (
  combinedId: string
): { schemaName: string; fieldName: string } => {
  const [schemaName, fieldName] = combinedId.split("___");
  return { schemaName, fieldName };
};

/**
 * Compute static folders for custom fields
 */
export const getPersonFieldsStaticFolders = (schemas: CustomObjectSchema[]) => {
  const foldersPerObject: GenericFolder[] = _.reduce(
    schemas,
    (acc, schema) => {
      const schemaFolder: GenericFolder = {
        type: "folder",
        folderType: "personFields",
        id: `schema-${schema.name}`,
        name: `${schema.displayNameSingular} Fields`,
        childrenIds: _.map(schema.fields, (field) =>
          combineSchemaAndFieldIds(schema.name, field.name)
        ),
      };
      acc.push(schemaFolder);
      return acc;
    },
    [] as GenericFolder[]
  );

  // combine all objects fields into one folder
  const allFolder: GenericFolder = {
    type: "folder",
    folderType: "personFields",
    id: "allPersonFields",
    name: "All Fields",
    childrenIds: _.flatten(_.map(foldersPerObject, "childrenIds")) as any,
  };

  // Create folders by fields that have dot in them
  const personFields = _.find(schemas, ["name", endUsersObjectType])?.fields;
  const servicesFolders = _.reduce(
    personFields,
    (acc, field) => {
      if (field.name.includes(".")) {
        // folder name is everything before the first dot
        const folderName = field.name.split(".")[0];
        if (folderName) {
          // create this folder if seen for the first time
          if (!acc[folderName]) {
            acc[folderName] = {
              type: "folder",
              folderType: "personFields",
              id: folderName,
              name: formatAutoFolderName(folderName),
              childrenIds: [],
            };
          }
          // add current field to folder
          acc[folderName]!.childrenIds!.push(
            combineSchemaAndFieldIds(endUsersObjectType, field.name)
          );
        }
      }
      return acc;
    },
    {} as Dictionary<GenericFolder>
  );

  return [allFolder, ...foldersPerObject, ..._.values(servicesFolders)];
};

const formatAutoFolderName = (name: string) => {
  return `${name[0].toUpperCase()}${name.substr(1)}`;
};
