import { observable, runInAction, action } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { toastError } from "../domain/errorHandling/toaster";
import { searchPeople } from "./persistence/persistPeople";
import uuid from "uuid/v4";

// only search every 0.5 seconds (or if the user has stopped typing)
const waitBeforeSearching = 500; // ms

export class PeopleSearchStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  people: IEndUser[] = [];

  @observable
  lastSearchResultsCount?: number;

  @observable
  page: number = 0;

  @action
  nextPage() {
    if (!this.lastQuery) return;
    this.page += 1;
    this.search(this.lastQuery);
  }

  lastSearchRequestId?: string;
  lastQuery?: string;

  // only search every 0.5 seconds (or if the user has stopped typing)
  search: any = _.debounce(
    (searchQuery: string) => {
      this.performSearch(searchQuery);
    },
    waitBeforeSearching,
    {
      trailing: true,
    }
  );
  @action
  private async performSearch(query: string) {
    // if we are searching for a different query, reset the paging
    if (this.lastQuery !== query) {
      this.page = 0;
      this.rootStore.peopleActionStore.setSelectedPeople([]);
    }
    // update the last query
    this.lastQuery = query;
    // make sure the query is long enough
    if (!query || query.length < 2) return;
    // requestId is used to only pay attention to the last search request
    const requestId = uuid();
    try {
      this.rootStore.uiStore.peopleSearch.startLoading();
      this.lastSearchRequestId = requestId;
      const people = await searchPeople(query, this.page);
      // only set people if there were no other requests after this one
      if (this.lastSearchRequestId === requestId) {
        runInAction(() => {
          // overwrite people if getting first page
          if (this.page === 0) {
            this.people = people;
          } else {
            // append people if getting next pages
            this.people = this.people.concat(...people);
          }
          // used to know if there are more people to get
          this.lastSearchResultsCount = people.length;

          this.rootStore.peopleStore.putPeople(people);
        });
      }
    } catch (e) {
      toastError({
        message: "Error while searching for people.",
        extra: e,
      });
    } finally {
      if (this.lastSearchRequestId === requestId) {
        this.rootStore.uiStore.peopleSearch.finishLoading();
      }
    }
  }
  @action
  reset() {
    this.people = [];
    this.lastSearchRequestId = undefined;
    this.lastQuery = undefined;
    this.page = 0;
    this.lastSearchResultsCount = undefined;

    // reset the currently selected people if there were any
    this.rootStore.peopleActionStore.setSelectedPeople([]);
  }
}
