import pLimit, { Limit } from "p-limit";
import _ from "lodash";
import { confirmableAction } from "store/confirmableAction";

interface APIQueueParameters {
  name: string;
  limit?: number;
}
class APIQueue {
  private queue: Limit;
  constructor({
    // by default only allow one operation at a time
    limit = 1,
  }: Pick<APIQueueParameters, "limit">) {
    this.queue = pLimit(limit);
  }
  get activeCount() {
    return this.queue.activeCount;
  }
  get pendingCount() {
    return this.queue.pendingCount;
  }

  /**
   * Add an action to the queue. The `action` modifies `state`,
   * returns a Promise, and is be confirmed by `confirm` function (e.g. an API call).
   *
   */
  addConfirmableAction<S>({
    state,
    performAction,
    confirmErrorMessage,
  }: {
    state: () => S;
    performAction: (state: S) => undefined | (() => Promise<any> | undefined);

    confirmErrorMessage?: string;
  }) {
    // this should perform as much as we can from the action
    // until we encounter an await
    const performConfirmation = confirmableAction(
      state,
      performAction,
      confirmErrorMessage
    );
    // put only the unresolved Promise part in the queue
    this.queue(() => performConfirmation());
  }
  /**
   * Add an `action` to the queue. The action returns a promise and
   * can be reversed by the `reverse` function.
   */
  addReversibleAction(action: () => Promise<any>, reverse: () => void) {
    this.queue(() => {
      return new Promise(async (resolve, reject) => {
        try {
          const result = await action();
          resolve(result);
        } catch (e) {
          reverse();
          reject(e);
        }
      });
    });
  }
}

const queues: Dictionary<APIQueue> = {};
export const APIQueueFactory = ({ name, limit }: APIQueueParameters) => {
  if (!queues[name]) {
    queues[name] = new APIQueue({ limit });
  }
  return queues[name] as APIQueue;
};

// Make sure that all API calls are finished before closing the tab
window.addEventListener("beforeunload", async function (e) {
  const warnBeforeExit = _.reduce(
    queues,
    (acc, queue, _queueName) => {
      if (queue.activeCount > 0 || queue.pendingCount > 0) {
        return true;
      }
      return acc;
    },
    false
  );
  if (warnBeforeExit) {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = "";
  }
});
