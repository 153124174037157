import { Button, Modal } from "reactstrap";
import { observer } from "mobx-react";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { paypalPlans } from "encharge-domain/lib/values/plans";

interface Props {
  email: string;
  peopleUpperLimit?: number;
  onCancel: () => void;
  confirmButtonText?: string;
}

const PaypalPlansForm = observer((props: Props) => {
  const confirmButtonText = props.confirmButtonText || "Upgrade to this plan";
  const peopleUpperLimit = props.peopleUpperLimit || 0;
  const monthlyPlans: JSX.Element[] = [];
  const annualPlans: JSX.Element[] = [];
  const paypalInputs: JSX.Element[] = [];
  paypalPlans.forEach((plan, index) => {
    if (peopleUpperLimit < plan.peopleUpperLimit) {
      const planOption = (
        <option value={plan.name} key={plan.name}>
          {plan.peopleLowerLimit} - {plan.peopleUpperLimit} People: $
          {plan.period === "month" ? plan.price : Math.ceil(plan.price / 12)}{" "}
          per month {plan.period === "year" && "(billed yearly) - 20% OFF"}
        </option>
      );
      if (plan.period === "month") {
        monthlyPlans.push(planOption);
      } else {
        annualPlans.push(planOption);
      }
      paypalInputs.push(
        <input
          key={`${plan.name}-select`}
          type="hidden"
          name={`option_select${index}`}
          value={plan.name}
        />
      );

      paypalInputs.push(
        <input
          key={`${plan.name}-amount`}
          type="hidden"
          name={`option_amount${index}`}
          value={plan.price}
        />
      );
      paypalInputs.push(
        <input
          key={`${plan.name}_period`}
          type="hidden"
          name={`option_period${index}`}
          value={plan.period === "month" ? "M" : "Y"}
        />
      );
    }
  });
  return (
    <Modal isOpen={true} toggle={() => props.onCancel()}>
      <ModalHeader>Upgrade plan</ModalHeader>
      <ModalBody>
        <form
          id="preorder-form"
          action="https://www.paypal.com/cgi-bin/webscr"
          method="post"
          target="_top"
          style={{ height: "100px" }}
        >
          <input type="hidden" name="cmd" value="_xclick-subscriptions" />
          <input type="hidden" name="business" value="kalo@encharge.io" />
          <input type="hidden" name="lc" value="BG" />
          <input type="hidden" name="no_note" value="1" />
          <input type="hidden" name="no_shipping" value="1" />
          <input type="hidden" name="rm" value="1" />
          <input
            type="hidden"
            name="return"
            value="https://api.encharge.io/v1/accounts/payments/paypal/pdt"
          />
          <input type="hidden" name="src" value="1" />
          <input type="hidden" name="currency_code" value="USD" />
          <input
            type="hidden"
            name="bn"
            value="PP-SubscriptionsBF:btn_subscribeCC_LG.gif:NonHosted"
          />
          <input
            type="hidden"
            name="return_url"
            value="https://api.encharge.io/v1/accounts/payments/paypal/pdt"
          />
          <input type="hidden" name="on1" value="Email" />
          <input type="hidden" name="os1" value={props.email || ""} />
          <input type="hidden" name="on0" value="Subscribers" />
          <select
            name="os0"
            className="form-control mt-3 mb-3"
            id="subscribers"
          >
            <optgroup label="Monthly">
              {monthlyPlans}
              {/* {peopleUpperLimit < 2000 && (
                <option value="m0-2000">0 - 2000 People: $49 per month</option>
              )}
              {peopleUpperLimit <= 2000 && (
                <option value="m2001-5000">
                  2001 - 5000 People: $99 per month
                </option>
              )}
              {peopleUpperLimit <= 5000 && (
                <option value="m5001-10000">
                  5001 - 10 000 People: $179 per month
                </option>
              )}
              {peopleUpperLimit <= 10000 && (
                <option value="m10001-15000">
                  10 001 - 15 000 People: $249 per month
                </option>
              )}
              {peopleUpperLimit <= 15000 && (
                <option value="m15001-20000">
                  15 001 - 20 000 People: $299 per month
                </option>
              )} */}
            </optgroup>
            <optgroup label="Annual Plans - 20% OFF">
              {annualPlans}
              {/* {peopleUpperLimit < 2000 && (
                <option value="0-2000">
                  0 - 2000 People: $39 per month (billed yearly) - 20% OFF!
                </option>
              )}
              {peopleUpperLimit <= 2000 && (
                <option value="2001-5000">
                  2001 - 5000 People: $79 per month (billed yearly) - 20% OFF!
                </option>
              )}
              {peopleUpperLimit <= 5000 && (
                <option value="5001-10000">
                  5001 - 10 000 People: $139 per month (billed yearly) - 20%
                  OFF!
                </option>
              )}
              {peopleUpperLimit <= 10000 && (
                <option value="10001-15000">
                  10 001 - 15 000 People: $199 per month (billed yearly) - 20%
                  OFF!
                </option>
              )}
              {peopleUpperLimit <= 15000 && (
                <option value="15001-20000">
                  15 001 - 20 000 People: $239 per month (billed yearly) - 20%
                  OFF!
                </option>
              )} */}
            </optgroup>
          </select>
          <input type="hidden" name="option_select0" value="y0-2000" />
          <input type="hidden" name="option_amount0" value="468.00" />
          <input type="hidden" name="option_period0" value="Y" />
          <input type="hidden" name="option_frequency0" value="1" />
          <input type="hidden" name="option_select1" value="y2001-5000" />
          <input type="hidden" name="option_amount1" value="948.00" />
          <input type="hidden" name="option_period1" value="Y" />
          <input type="hidden" name="option_frequency1" value="1" />
          <input type="hidden" name="option_select2" value="y5001-10000" />
          <input type="hidden" name="option_amount2" value="1668.00" />
          <input type="hidden" name="option_period2" value="Y" />
          <input type="hidden" name="option_frequency2" value="1" />
          <input type="hidden" name="option_select3" value="y10001-15000" />
          <input type="hidden" name="option_amount3" value="2388.00" />
          <input type="hidden" name="option_period3" value="Y" />
          <input type="hidden" name="option_frequency3" value="1" />
          <input type="hidden" name="option_select4" value="y15001-20000" />
          <input type="hidden" name="option_amount4" value="2868.00" />
          <input type="hidden" name="option_period4" value="Y" />
          <input type="hidden" name="option_frequency4" value="1" />
          <input type="hidden" name="option_select5" value="m0-2000" />
          <input type="hidden" name="option_amount5" value="49.00" />
          <input type="hidden" name="option_period5" value="M" />
          <input type="hidden" name="option_frequency5" value="1" />
          <input type="hidden" name="option_select6" value="m2001-5000" />
          <input type="hidden" name="option_amount6" value="99.00" />
          <input type="hidden" name="option_period6" value="M" />
          <input type="hidden" name="option_frequency6" value="1" />
          <input type="hidden" name="option_select7" value="m5001-10000" />
          <input type="hidden" name="option_amount7" value="179.00" />
          <input type="hidden" name="option_period7" value="M" />
          <input type="hidden" name="option_frequency7" value="1" />
          <input type="hidden" name="option_select8" value="m10001-15000" />
          <input type="hidden" name="option_amount8" value="249.00" />
          <input type="hidden" name="option_period8" value="M" />
          <input type="hidden" name="option_frequency8" value="1" />
          <input type="hidden" name="option_select9" value="m15001-20000" />
          <input type="hidden" name="option_amount9" value="299.00" />
          <input type="hidden" name="option_period9" value="M" />
          <input type="hidden" name="option_frequency9" value="1" />
          <input type="hidden" name="option_index" value="0" />
          <Button
            color="link"
            className="mt-3"
            onClick={() => props.onCancel()}
          >
            Cancel
          </Button>{" "}
          <Button color="primary" className="mt-3" type="submit">
            {confirmButtonText}
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
});

export default PaypalPlansForm;
