import request, { AxiosResponse } from "axios";
import { ITeamMember } from "encharge-domain/definitions/TeamMember";
import { apiBase } from "../../constants";

export const sendLogin = async (email: string, password: string) => {
  try {
    const res: AxiosResponse<ITeamMember> = await request({
      url: `${apiBase}/v1/accounts/login/`,
      method: "POST",
      data: {
        email,
        password,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "There was an error while logging you in.";
    throw new Error(msg);
  }
};

export const sendGoogleLogin = async (idToken: string) => {
  try {
    const res: AxiosResponse<ITeamMember> = await request({
      url: `${apiBase}/v1/accounts/login/google`,
      method: "POST",
      data: {
        idToken,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "There was an error while logging you in.";
    throw new Error(msg);
  }
};

/**
 * Checks if the current user is logged in.
 *
 */
export const checkLogin = async () => {
  try {
    await request({
      url: `${apiBase}/v1/heartbeat/authenticated`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "There was an error while logging you in.";
    e.message = msg;
    throw e;
  }
};

/**
 * Request password reset
 *
 */
export const requestResetPassword = async (email: string) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/request-reset-password`,
      method: "POST",
      data: {
        email,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    e.message = msg;
    throw e;
  }
};

/**
 * Reset password
 *
 */
export const resetPassword = async (data: {
  password: string;
  token: string;
}) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/reset-password`,
      method: "POST",
      data,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error";
    e.message = msg;
    throw e;
  }
};
