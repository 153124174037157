import { observer } from "mobx-react";
import uuid from "uuid";
import { useGetListMetricEvents } from "./queries";
import ItemsTable from "components/Items/ItemsTable";
import { useMemo } from "react";
import _ from "lodash";
import { PersonAvatar } from "components/Person/PersonAvatar";
import ItemsSimpleCommon from "components/Items/ItemsSimpleCommon";
import { useStores } from "store/useStore";
import { Link } from "react-router-dom";
import { IMetricsType } from "components/Charts/SingleMetricChart";
import { toFullDateTimeString } from "domain/helpers/asDateTime";
import StepLink from "components/Person/PersonEvent/EventBody/StepLink/StepLink";
import FlowLink from "components/Person/PersonEvent/EventBody/FlowLink/FlowLink";
import Loading from "components/Loading";
import EmailLink from "components/Person/PersonEvent/EventBody/EmailLink";

const extraColumns = {
  ip: {
    id: "ip",
    title: "IP",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.ip}</div>;
    },
  },
  browser: {
    id: "browser",
    title: "Browser",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.browser}</div>;
    },
  },
  clickedUrl: {
    id: "url",
    title: "Clicked Url",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.url}</div>;
    },
  },
  skippedReason: {
    id: "reason",
    title: "Skipped Reason",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.reason}</div>;
    },
  },
  bouncedReason: {
    id: "reason",
    title: "Bounced Reason",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.reason}</div>;
    },
  },
  softBouncedReason: {
    id: "reason",
    title: "Soft Bounced Reason",
    render: ({ row }: any) => {
      const event = row.original as any;
      return <div>{event.reason}</div>;
    },
  },
};

const EmailEventList = observer(
  ({
    emailIds,
    type,
    endDate,
    startDate,
    flowIds,
    broadcastId,
    abTestVariantId,
    stepId,
  }: {
    emailIds?: IEmailContent["id"][];
    type: IMetricsType;
    endDate?: Date;
    startDate?: Date;
    flowIds?: IIntegration["id"][];
    broadcastId?: number;
    abTestVariantId?: string;
    stepId?: number;
  }) => {
    const { emailsStore, flowsStore, uiStore } = useStores();
    const {
      data,
      fetchNextPage,
      hasNextPage,
      error,
      isLoading,
    } = useGetListMetricEvents({
      emailIds,
      type,
      endDate,
      startDate,
      flowIds,
      broadcastId,
      abTestVariantId,
      stepId,
    });
    const events: any[] = _.flatten(data?.pages);
    const extraColumnsByType = useMemo(() => {
      if (type === "click") {
        return [extraColumns.clickedUrl, extraColumns.ip, extraColumns.browser];
      }
      if (type === "open") {
        return [extraColumns.ip, extraColumns.browser];
      }
      if (type === "dropped") {
        return [extraColumns.skippedReason];
      }
      if (type === "bounce") {
        return [extraColumns.bouncedReason];
      }
      if (type === "blocked") {
        return [extraColumns.softBouncedReason];
      }
      return [];
    }, [type]);
    const columns: React.ComponentProps<
      typeof ItemsSimpleCommon
    >["columns"] = useMemo(() => {
      const defaultColumns = [
        {
          id: "fullName",
          title: "Full Name",
          maxWidth: 300,
          expandCell: true,
          disableSortBy: true,
          render: ({ row }: any) => {
            const event = row.original as any;
            const person: IEndUser | undefined = event.EndUser || undefined;

            return (
              <Link to={`/people/${person?.id}`}>
                <PersonAvatar
                  email={person?.email}
                  name={person?.name || person?.firstName || person?.lastName}
                  id={person?.id}
                  size={36}
                  className="mr-2"
                />
                <span className="ml-1">
                  {person?.name ||
                    `${person?.firstName || ""} ${person?.lastName || ""}`}
                </span>
              </Link>
            );
          },
        },
        {
          id: "email",
          title: "Email Address",
          maxWidth: 300,
          expandCell: true,
          render: ({ row }: any) => {
            const event = row.original as any;
            const person: IEndUser | undefined = event.EndUser || undefined;
            return (
              <Link to={`/people/${person?.id}`}>
                <div>
                  <div>{person?.email || ""}</div>
                </div>
              </Link>
            );
          },
        },
        ...extraColumnsByType,
      ];
      if (emailIds?.length !== 1) {
        defaultColumns.push({
          id: "emailId",
          title: "Email",
          /// @ts-ignore
          dataType: "number",
          render: ({ row }: any) => {
            const event = row.original as any;
            return <EmailLink emailId={event.emailId} />;
          },
        });
      }
      if (flowIds?.length) {
        defaultColumns.push({
          id: "flowId",
          title: "Flow",
          /// @ts-ignore
          dataType: "number",
          render: ({ row }: any) => {
            const event = row.original as any;
            return <FlowLink flowId={event.flowId} />;
          },
        });
      }
      if (type === "entered" || type === "reachedGoal") {
        defaultColumns.push({
          id: "stepId",
          title: "Step",
          /// @ts-ignore
          dataType: "number",
          render: ({ row }: any) => {
            const event = row.original as any;
            return <StepLink stepId={event.stepId} flowId={event.flowId} />;
          },
        });
      }
      defaultColumns.push({
        id: "createdAt",
        title: "Date",
        /// @ts-ignore
        dataType: "datetime",
        render: ({ row }: any) => {
          const event = row.original as any;
          const date = toFullDateTimeString(event.createdAt);
          return <div>{date}</div>;
        },
      });

      return defaultColumns;
    }, [emailIds?.length, extraColumnsByType, flowIds?.length, type]);
    const table = (
      <ItemsTable
        columns={columns}
        items={events.map((event) => ({
          id: event?.id || uuid.v4(),
          ...event,
        }))}
        infiniteLoading={{
          loadMoreItems: fetchNextPage,
          isLoading,
          error: (error as any)?.message,
          hasNextPage,
        }}
      />
    );
    return <div className="form-submission-table">{table}</div>;
  }
);

export default EmailEventList;
