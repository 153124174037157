import { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { loadChargebee } from "components/Billing/loadChargebee";
import LoadingCentered from "components/LoadingCentered";
import RegistrationStepPaymentView from "components/Registration/RegistrationStepPayment/RegistrationStepPaymentView";

interface Props {
  submit: () => void;
  loading?: boolean;
  error?: string;
}
const RegistrationStepPayment = (props: Props) => {
  const [hasLoadedChargebee, setHasLoadedChargebee] = useState(false);

  useEffect(() => {
    const onLoad = () => {
      console.log("Chargebee loaded");
      setHasLoadedChargebee(true);
    };
    loadChargebee(onLoad);
  }, []);

  if (!hasLoadedChargebee) {
    return <LoadingCentered />;
  }

  return <RegistrationStepPaymentView {...props} />;
};

export default observer(RegistrationStepPayment);
