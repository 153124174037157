import { getNodeEnv } from "domain/helpers/getNodeEnv";
import { ITeamMember } from "encharge-domain/definitions/TeamMember";

/**
 * Wait for window.analytics to be available
 */
const getAnalytics = async () => {
  if (
    getNodeEnv() !== "production" ||
    // Dont try to load segment for Cypress tests as GTM is disabled in Cypress
    (window as any).Cypress
  ) {
    return undefined;
  }
  const analytics = (window as any).analytics;
  if (analytics) {
    return analytics;
  }
  // try getting window.analytics every 2 seconds, for up to 15 seconds
  return new Promise<any>((resolve) => {
    const interval = setInterval(() => {
      const analytics = (window as any).analytics;
      if (analytics) {
        clearInterval(interval);
        resolve(analytics);
      }
    }, 2000);
    setTimeout(() => {
      clearInterval(interval);
      console.log("Failed to get Segment analytics");
      resolve(undefined);
    }, 15000);
  });
};

export const segmentIdentify = async ({
  accountId,
  teamMemberId,
  traits,
}: {
  teamMemberId?: ITeamMember["id"];
  accountId?: IAccount["id"];
  traits: Dictionary<any>;
}) => {
  const analytics = await getAnalytics();
  return analytics?.identify?.(teamMemberId, { groupId: accountId, ...traits });
};

export const segmentTrack = async ({
  name,
  properties,
}: {
  name: string;
  properties?: Dictionary<any>;
}) => {
  const analytics = await getAnalytics();
  return analytics?.track?.(name, properties);
};
