const ServiceStatusWidget = () => {
  return (
    <div className="status-badge-container">
      <img
        src="https://public-api.freshstatus.io/v1/public/badge.svg/?badge=b933f303-5116-44fb-9600-70a7242eec25"
        alt="Service Status"
      />
    </div>
  );
};
export default ServiceStatusWidget;
