import AIWriterGenerateForm from "components/AIWriter/AIWriterGenerateForm";
import _ from "lodash";
import { observer } from "mobx-react";

interface Props {
  onInsert?: (text: string) => void;
}

const AIWriter = (_props: Props) => {
  return (
    <div className="w-100">
      <div className="step-nav-tab-pane-header py-4 px-2 px-md-3 px-lg-5">
        Magic Writer
      </div>
      <div className="py-4 px-2 px-md-3 px-lg-5 ai-writer h-100 overflow-auto">
        <AIWriterGenerateForm />
      </div>
    </div>
  );
};
export default observer(AIWriter);
