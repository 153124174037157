import { Component } from "react";

class ErrorBoundary extends Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    console.log("In componentDidCatch", error, info);
    // We do nothing here as the error is caught in the window error handler
    // and toaster is triggered from there too.

    // Just force redrawing to restore the UI after error.
    this.forceUpdate();
  }

  render() {
    if (this.state.hasError) {
      console.log("rendering something went wrong");
      // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
