import { useCallback, useState } from "react";
import { Input } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";
import { getUserTimezone } from "domain/helpers/asDateTime";

interface Props {
  submit: (formData: any) => void;
  loading?: boolean;
  error?: string;

  initialName: any;
}
const RegistrationStepName = (props: Props) => {
  const [formData, setFormData] = useState({
    name: props.initialName,
    // Make sure to set the timezone, if the initial registration didn't get it
    timezone: getUserTimezone(),
  });

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const heading = "Welcome! What's your name?";
  const subheading = "We’d love to get to know you.";
  const subsubheading = "";

  return (
    <RegistrationStepFormCommon
      heading={heading}
      subheading={subheading}
      subsubheading={subsubheading}
      onSubmit={onSubmit}
      loading={props.loading}
      error={props.error}
    >
      <Input
        value={formData.name}
        type="text"
        placeholder="Your Name"
        autoComplete="name"
        name="name"
        className="form-control-lg mb-3 mt-2"
        required={true}
        onChange={handleFieldChange}
      />
    </RegistrationStepFormCommon>
  );
};

export default observer(RegistrationStepName);
