import classNames from "classnames";
import { ReactNode, useMemo, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";

interface Props {
  content?: string;
  children: ReactNode;
  placement?: UncontrolledTooltip["props"]["placement"];
  className?: string;
}

const HelpTooltip = (props: Props) => {
  // use state derived ref, so that it causes a rerender which we want
  const [ref, setRef] = useState<any>({ current: null });

  const className = useMemo(() => {
    return classNames("align-middle", props.className);
  }, [props.className]);
  return (
    <>
      <span
        className={className}
        ref={(current) => ref.current !== current && setRef({ current })}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          style={{ verticalAlign: "baseline" }}
          className="help-tooltip"
        >
          <path d="M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,13c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1s1,0.4,1,1 C9,12.6,8.6,13,8,13z M9.5,8.4C9,8.7,9,8.8,9,9v1H7V9c0-1.3,0.8-1.9,1.4-2.3C8.9,6.4,9,6.3,9,6c0-0.6-0.4-1-1-1 C7.6,5,7.3,5.2,7.1,5.5L6.6,6.4l-1.7-1l0.5-0.9C5.9,3.6,6.9,3,8,3c1.7,0,3,1.3,3,3C11,7.4,10.1,8,9.5,8.4z" />
        </svg>
      </span>
      {ref.current && (
        <UncontrolledTooltip
          target={ref.current as any}
          trigger="hover"
          autohide={false}
          placement={props.placement || "top"}
          style={{ zIndex: 100001 }}
          boundariesElement={"viewport"}
        >
          {props.content || props.children}
        </UncontrolledTooltip>
      )}
    </>
  );
};
export default HelpTooltip;
