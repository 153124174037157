import { useState, useCallback } from "react";
import * as React from "react";
import { ReactComponent as EllipsisIcon } from "../../../assets/img/ellipsis.svg";
import Dropdown from "reactstrap/lib/Dropdown";
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import _ from "lodash";

interface Props {
  actions?: {
    type: string;
    title: string | React.FC<{ item: any }>;
    handler: (item: any) => void;
    shouldDisplay?: (arg: { item: any }) => boolean;
  }[];
  item: any;
}

const ActionItems = (props: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = useCallback(() => setIsDropdownOpen(!isDropdownOpen), [
    isDropdownOpen,
  ]);

  const item = props.item;

  const actions = _.reduce(
    props.actions,
    (acc, action) => {
      // if shouldDisplay is present, check it
      if (action.shouldDisplay && !action.shouldDisplay({ item })) {
        return acc;
      }
      const title =
        typeof action.title === "string" ? (
          action.title
        ) : (
          <action.title item={item} />
        );
      const rendered = (
        <DropdownItem
          className="dropdown-menu-standard-sm-nav-item"
          key={action.type}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            action?.handler?.(item);
          }}
          data-testid={`item-action-${action.type}`}
        >
          {title}
        </DropdownItem>
      );
      acc.push(rendered);
      return acc;
    },
    [] as JSX.Element[]
  );
  return (
    <>
      <Dropdown
        isOpen={isDropdownOpen}
        toggle={toggleDropdown}
        direction="down"
      >
        <DropdownToggle
          className="item-actions-dropdown-button"
          tag="div"
          data-toggle="dropdown"
          aria-expanded={isDropdownOpen}
          onClick={toggleDropdown}
          data-testid="itemDropdownToggle"
        >
          <span className="ellipsis-icon">
            <EllipsisIcon />
          </span>
        </DropdownToggle>
        <DropdownMenu
          positionFixed={true}
          className="dropdown-menu-standard-sm-nav"
        >
          {actions}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};
export default ActionItems;
