import { useCallback, useState } from "react";
import { Input } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";

interface Props {
  submit: (formData: any) => void;
  loading?: boolean;
  error?: string;

  name: string;
}
const RegistrationStepPhone = (props: Props) => {
  const [formData, setFormData] = useState({
    phone: "",
  });

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const heading = `Hey ${props.name}! Can we get your phone number?`;
  const subheading = "Just in case you need help on your journey.";
  const subsubheading = "";

  return (
    <RegistrationStepFormCommon
      heading={heading}
      subheading={subheading}
      subsubheading={subsubheading}
      onSubmit={onSubmit}
      loading={props.loading}
      error={props.error}
      disabled={!formData.phone}
    >
      <Input
        value={formData.phone}
        type="text"
        placeholder="Phone"
        autoComplete="phone"
        name="phone"
        className="form-control-lg mb-3 mt-2"
        required={true}
        onChange={handleFieldChange}
      />
    </RegistrationStepFormCommon>
  );
};

export default observer(RegistrationStepPhone);
