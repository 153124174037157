import { createContext, useContext } from "react";
import { DomainStore, domainStore } from "./domainStore";

type Stores = { [K in keyof DomainStore]: DomainStore[K] };

export const StoreContext = createContext<DomainStore>(domainStore);

export function useStores(): Stores {
  return useContext(StoreContext);
}
