import LoadingCentered from "components/LoadingCentered";
import { parseCompanyInfo } from "components/Registration/GenerateFlows/RegistrationStepGenerateFlows";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import OnboardingChecklistView from "components/Onboarding/OnboardingChecklist/OnboardingChecklistView";
import _ from "lodash";

interface Props {}

export const useOnboardingSteps = () => {
  const {
    flowsStore,
    accountStore,
    emailSettingsStore,
    peopleImportsStore,
    emailsStore,
  } = useStores();
  // Wait for the account to load
  const account = accountStore.account;
  if (!account) {
    return null;
  }

  // we only want to show the email step if the user has not created an email manually
  const emails = emailsStore.emailsArray;
  const hasCreatedEmailManually = _.some(emails, (email) => !email.aiGenerated);

  const completedSteps = {
    app: Boolean(
      account.onboarding?.apps || account.activeServices?.length > 1
    ),
    domain: Boolean(
      account.onboarding?.domain || emailSettingsStore.domains.current()?.length
    ),
    email: Boolean(account.onboarding?.email || hasCreatedEmailManually),
    flow: Boolean(
      account.onboarding?.flow || flowsStore.flows?.current()?.length
    ),
    import: Boolean(
      account.onboarding?.import ||
        peopleImportsStore.imports?.current()?.length
    ),
  };

  return completedSteps;
};

const parseAIGeneratedFlows = (
  flows: {
    id: number;
    name: string;
    aiGeneratedEmailText: string;
  }[]
) => {
  return _.map(flows, (flow) => {
    const emailText = flow.aiGeneratedEmailText;
    // find email subject (Subject: ...)
    const subjectIndex = emailText.indexOf("Subject:");
    // find email text (Text: ...)
    const textIndex = emailText.indexOf("Text:");
    const subject = emailText
      .substring(subjectIndex + "Subject:".length, textIndex)
      .trim();
    const text = emailText.substring(textIndex + "Text:".length).trim();
    return {
      id: flow.id,
      name: flow.name,
      subject,
      text,
    };
  });
};

const OnboardingChecklist = observer((_props: Props) => {
  const { accountStore, flowsStore } = useStores();
  const account = accountStore.account;

  const completedSteps = useOnboardingSteps();

  if (!account || !completedSteps) {
    return <LoadingCentered />;
  }
  const name = account.teamMemberName;
  const businessName = account.accountName || "your business";

  const aiGeneratedFlows = flowsStore.aiGeneratedFlows?.current();

  const isExtendedSupportAvailable = accountStore.isExtendedSupportAvailable();
  const { companyInfo, isSaaS, isB2B } = parseCompanyInfo(
    account.companyInfo?.summary || ""
  );
  return (
    <OnboardingChecklistView
      isB2B={isB2B}
      isSaaS={isSaaS}
      businessName={businessName}
      companyInfo={companyInfo}
      isExtendedSupportAvailable={isExtendedSupportAvailable}
      name={name}
      site={account.site}
      completedSteps={completedSteps}
      fromEmail={account.email}
      flows={parseAIGeneratedFlows(aiGeneratedFlows)}
    />
  );
});
export default OnboardingChecklist;
