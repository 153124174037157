import { Badge, Progress } from "reactstrap";
import { Link } from "react-router-dom";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";
import { useOnboardingSteps } from "components/Onboarding/OnboardingChecklist/OnboardingChecklist";

const OnboardingBadge = () => {
  const nextStepData = useNextStep();
  if (!nextStepData) {
    return null;
  }
  const { progress, numSteps, nextStepTitle } = nextStepData;
  return (
    <div className="float-right main-bar-right-section">
      <Link to={"/get-started"}>
        <>
          {/* On large screen display getting started widget */}
          <div className="col d-none d-sm-block" style={{ marginTop: "-6px" }}>
            <small className="text-secondary">Next: {nextStepTitle}</small>

            <Progress
              color="success"
              value={(progress * 100) / (numSteps + 1)}
              style={{ width: "100%", height: "10px", background: "#DEDDDC" }}
            />
          </div>
          {/* On small screens show much smaller badge */}
          <div className="d-inline-block d-sm-none">
            <Badge
              className="d-flex align-items-center"
              color="success"
              style={{ height: "32px" }}
            >
              {progress} / {numSteps}
            </Badge>
          </div>
        </>
      </Link>

      <span className="main-bar-pipe" />
    </div>
  );
};

const useNextStep = () => {
  const { accountStore } = useStores();
  const completedSteps = useOnboardingSteps();
  if (!accountStore.account || !completedSteps) {
    return null;
  }
  const numSteps = 5;
  if (!completedSteps.flow) {
    return {
      progress: 1,
      // nextStepPath: "/start/flow",
      nextStepTitle: "Create Flow",
      numSteps,
    };
  }
  if (!completedSteps.email) {
    return {
      progress: 2,
      // nextStepPath: "/start/flow",
      nextStepTitle: "Create Email",
      numSteps,
    };
  }
  if (
    !completedSteps.import &&
    // if there are people on the account, ignore import
    // there is at least 1 person which is the account owner
    (accountStore?.account?.peopleCount || 0) <= 5
  ) {
    return {
      progress: 3,
      // nextStepPath: "/start/import",
      nextStepTitle: "Import People",
      numSteps,
    };
  }

  if (!completedSteps.app) {
    return {
      progress: 4,
      // nextStepPath: "/start/apps",
      nextStepTitle: "Connect Apps",
      numSteps,
    };
  }
  if (!completedSteps.domain) {
    return {
      progress: 5,
      // nextStepPath: "/settings/email/domains",
      nextStepTitle: "Verify Domain",
      numSteps,
    };
  }
  return null;
};

export default observer(OnboardingBadge);
