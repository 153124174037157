import { observable, action, runInAction, when } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable, ILazyObservable } from "../domain/helpers/lazyLoad";
import _ from "lodash";
import {
  cancelBroadcast,
  createBroadcast,
  deleteBroadcast,
  getBroadcasts,
  sendBroadcast,
  updateBroadcast,
  checkBroadcastsStatus,
  getBroadcastPeopleCount,
} from "./persistence/persistBroadcasts";
import { APIQueueFactory } from "domain/apiQueue";

import humanizeString from "encharge-domain/lib/helpers/humanizeString";
import { getDomainFromEmail } from "encharge-domain/lib/helpers/email_helper";
import { UnreachableCaseError } from "encharge-domain/definitions/ambient/ts-essentials";
import uuid from "uuid/v4";
import { CachedMetric } from "encharge-domain/definitions/Broadcast";

const cachedStatus = [
  "sent",
  "sending",
  "ab-testing",
  "ab-test-sending-winner",
];

export type BroadcastWithLoading = Broadcast & { loading?: boolean };

export class BroadcastsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "broadcasts", limit: 1 });

  private shouldFetchMetric(broadcast: BroadcastWithLoading) {
    return Boolean(
      !broadcast.cachedMetric?.data &&
        cachedStatus.includes(broadcast.status) &&
        broadcast.sendAt
    );
  }

  private async getLiveBroadcastMetrics(broadcasts: BroadcastWithLoading[]) {
    const filterdBroadcasts = broadcasts
      .filter((broadcast) => this.shouldFetchMetric(broadcast))
      .sort((a, b) => {
        if (!a.sendAt) {
          return -1;
        }
        if (!b.sendAt) {
          return 1;
        }
        return new Date(a.sendAt).getTime() - new Date(b.sendAt).getTime();
      });

    const broadcastChunks = _.chunk(filterdBroadcasts, 20);

    for (const broadcastChunk of broadcastChunks) {
      const broadcastIds = broadcastChunk.map((broadcast) => broadcast.id);

      const stats = await this.rootStore.emailsStore.getBroadcastsMetrics({
        startDate: broadcastChunk[0].sendAt as Date,
        metricsPeriod: "allTime",
        broadcastIds: broadcastIds,
        groupByBroadcast: true,
      });

      if (!stats) {
        return;
      }
      this.addBroadcastsMetrics(broadcastIds, stats);
    }
  }

  @observable
  broadcasts: ILazyObservable<BroadcastWithLoading[]> = lazyObservable<
    BroadcastWithLoading[]
  >((sink, onError) => {
    getBroadcasts()
      .then(async (responseBroadcasts) => {
        const broadcasts = responseBroadcasts.map((responseBroadcast) => ({
          ...responseBroadcast,
          loading: this.shouldFetchMetric(responseBroadcast),
        }));
        sink(observable(broadcasts));
        this.getLiveBroadcastMetrics(broadcasts);
        this.checkBroadcastsStatus();
      })
      .catch((e) => {
        toastError({
          message: "Error while loading broadcasts.",
          extra: e,
        });
        onError(e);
        throw e;
      });
  });

  @action
  async create(name?: string, open = true) {
    try {
      this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
      const broadcast = await createBroadcast(name || "My Broadcast");

      runInAction(() =>
        this.broadcasts.current().splice(0, 0, observable(broadcast))
      );
      if (open) {
        this.open(broadcast.id);
      }
      // Add to current folder
      const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
        "broadcasts"
      );
      if (currentFolderId) {
        this.rootStore.foldersStore.pushItemToFolder({
          folderId: currentFolderId,
          itemId: broadcast.id,
          type: "broadcasts",
        });
      }
      return broadcast;
    } catch (e) {
      toastError({
        message: "Error while creating broadcast.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
    }
    return;
  }

  @action
  async update(
    broadcast: BroadcastWithLoading & { id: BroadcastWithLoading["id"] }
  ): Promise<any> {
    if (!broadcast.id) return;
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        const broadcasts = this.broadcasts.current();

        // add this broadcast to the root folder
        const currentIndex = _.findIndex(
          broadcasts,
          (v) => v.id === broadcast.id
        );

        if (currentIndex === -1) return;
        if (_.isEqual(broadcasts[currentIndex], broadcast)) {
          return;
        }
        _.assign(broadcasts[currentIndex], broadcast);

        return async () => {
          this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
          try {
            await updateBroadcast(broadcasts[currentIndex]);
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't save broadcast.",
    });
  }

  get(id: BroadcastWithLoading["id"]) {
    const broadcasts = this.broadcasts.current();
    if (!broadcasts) return;
    return _.find(broadcasts, (broadcast) => broadcast.id === id) as
      | (BroadcastWithLoading & { loading?: boolean })
      | undefined;
  }

  @action
  archive(id: BroadcastWithLoading["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        const broadcasts = this.broadcasts.current();
        const index = _.findIndex(broadcasts, (item) => item.id === id);
        if (index === -1) return;
        broadcasts.splice(index, 1);

        return async () => deleteBroadcast(id);
      },
      confirmErrorMessage: "Couldn't archive broadcast.",
    });
  }
  @action
  addBroadcastsMetrics(
    broadcastIds: BroadcastWithLoading["id"][],
    stats: IEmailsStats
  ) {
    broadcastIds.forEach((broadcastId) => {
      const broadcast = this.get(Number(broadcastId));
      if (!broadcast) {
        return;
      }
      const { click, open, delivered } = stats[broadcastId] || {
        click: [0],
        open: [0],
        delivered: [0],
      };
      const cachedMetric: CachedMetric = {
        data: {
          click: click[0],
          open: open[0],
          delivered: delivered[0],
        },
        isStale: false,
        updatedAt: new Date(),
      };
      broadcast.cachedMetric = cachedMetric;
      broadcast.loading = false;
    });
  }
  async send(id: BroadcastWithLoading["id"]) {
    // Make sure the time is saved
    // if user didnt click save next to time picker, save it from here
    const broadcast = this.get(id);
    if (broadcast && this.rootStore.uiStore.broadcastEdit.timeChanged) {
      await this.update({
        ...broadcast,
        sendAt: this.rootStore.uiStore.broadcastEdit.timeChanged,
      });
      this.rootStore.uiStore.broadcastEdit.timeHasNotChanged();
    }

    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        return async () => {
          try {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
            const broadcast = this.get(id);
            if (broadcast) {
              await this.canStartBroadcast(broadcast);
            }
            const updated = await sendBroadcast(id);
            // update broadcast in store
            const broadcasts = this.broadcasts.current();
            const currentIndex = _.findIndex(
              broadcasts,
              (item) => item.id === id
            );
            if (broadcasts[currentIndex]) {
              runInAction(() => _.assign(broadcasts[currentIndex], updated));
            }
            toastSuccess(
              `🚀 Awesome! Broadcast is ${
                updated.status === "sending" ? "sending" : "scheduled"
              }.`
            );
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't send broadcast.",
    });
  }

  cancel(id: BroadcastWithLoading["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        return async () => {
          this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
          try {
            const updated = await cancelBroadcast(id);

            // update broadcast in store
            const broadcasts = this.broadcasts.current();
            const currentIndex = _.findIndex(
              broadcasts,
              (item) => item.id === id
            );
            if (broadcasts[currentIndex]) {
              runInAction(() => _.assign(broadcasts[currentIndex], updated));
            }
            toastSuccess(`✅ Broadcast has been canceled.`);
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't cancel broadcast.",
    });
  }

  @action
  async duplicate(id: BroadcastWithLoading["id"]) {
    try {
      const source = this.get(id);
      if (!source) {
        return;
      }

      const emptyBroadcast = await this.create(source.name, false);
      if (!emptyBroadcast) return;
      // const emptyBroadcast = await createBroadcast(source.name);
      // runInAction(() =>
      //   this.broadcasts.current().splice(0, 0, observable(emptyBroadcast))
      // );
      const copy = _.cloneDeep(
        _.omit(source, [
          "id",
          "createdBy",
          "createdAt",
          "updatedAt",
          "integrationId",
          "sendAt",
          "time",
          "cachedMetric",
          "abTest.winner",
          "peopleEntered",
          "peopleExited",
        ])
      );

      copy.name = `${copy.name} (Copy)`;

      let emailId;

      if (copy.emailId) {
        const originalEmail = await this.rootStore.emailsStore.getEmailById(
          copy.emailId
        );
        if (!originalEmail) {
          throw new Error("Error when fetching data of email of broadcast");
        }

        const duplicatedEmail = await this.rootStore.emailsStore.duplicateEmail(
          copy.emailId,
          { isStandalone: false, name: copy.name }
        );
        emailId = duplicatedEmail?.id;
      }

      if (copy.abTest && copy.abTest?.variants.length) {
        const newVariants = await Promise.all(
          copy.abTest.variants.map(async (variant) => {
            const newVariant = _.cloneDeep(variant);
            newVariant.variantId = uuid();

            if (newVariant.type === "emailDetails" || !newVariant.emailId) {
              return newVariant;
            }
            const newEmail = await this.rootStore.emailsStore.duplicateEmail(
              newVariant.emailId,
              { isStandalone: false }
            );
            return {
              ...newVariant,
              emailId: newEmail?.id,
            };
          })
        );
        copy.abTest.variants = newVariants;
      }

      await this.update({
        ...emptyBroadcast,
        ...copy,
        id: emptyBroadcast.id,
        status: "draft",
        emailId,
      });
      this.open(emptyBroadcast.id);
    } catch (e) {
      toastError({
        message: `Error while duplicating broadcast.`,
        extra: e,
      });
    }
  }

  open(id: BroadcastWithLoading["id"]) {
    this.rootStore.uiStore.broadcastEdit.open(id);
  }

  // Check if broadcasts which can change, have changed
  async checkBroadcastsStatus() {
    _.map(this.broadcasts.current(), (broadcast) => {
      if (
        [
          "ab-test-sending-winner",
          "ab-testing",
          "scheduled",
          "sending",
        ].includes(broadcast.status)
      ) {
        this.updateBroadcastStatus(broadcast.id);
      }
    });
  }

  // Update status of single broadcast
  async updateBroadcastStatus(id: BroadcastWithLoading["id"]) {
    const result = await checkBroadcastsStatus(id);
    if (!result) return;
    // update broadcast status
    const broadcast = _.find(
      this.broadcasts.current(),
      (item) => item.id === result.id
    );
    if (broadcast && broadcast?.status !== result.status) {
      runInAction(() => (broadcast.status = result.status));
    }
  }

  async canStartBroadcast(broadcast: BroadcastWithLoading): Promise<void> {
    if (!broadcast.emailId) {
      throw new Error("No email selected.");
    }
    // Get verified emails/domains
    _.noop(this.rootStore.emailsStore.emails);
    const promises = [
      when(() => !this.rootStore.emailSettingsStore.domains.isLoading()),
      when(() => !this.rootStore.emailSettingsStore.emails.isLoading()),
      when(() => !this.rootStore.emailsStore.loadingEmails),
    ];
    await Promise.all(promises);

    const emailDomains = this.rootStore.emailSettingsStore.domains.current();
    const validatedEmailAddresses = this.rootStore.emailSettingsStore.emails.current();
    // current email
    const email = this.rootStore.emailsStore.getEmailById(broadcast.emailId);

    const hasVerifiedEmail = _.reduce(
      validatedEmailAddresses,
      (acc, current) => {
        if (
          current.status === "verified" &&
          current.email.toLocaleLowerCase() ===
            email?.fromEmail.toLocaleLowerCase()
        )
          return true;
        return acc;
      },
      false
    );
    const hasVerifiedDomain = _.reduce(
      emailDomains,
      (acc, domain) => {
        if (
          domain.status === "verified" &&
          domain.domain.toLocaleLowerCase() ===
            getDomainFromEmail(email?.fromEmail)?.toLocaleLowerCase()
        )
          return true;
        return acc;
      },
      false
    );

    if (!hasVerifiedDomain && !hasVerifiedEmail) {
      throw new Error(
        `Address ${email?.fromEmail} has not been verified for sending email.`
      );
    }
  }

  broadcastPeopleCountsCache: Dictionary<number> = {};
  async getBroadcastPeopleCount(broadcastId: BroadcastWithLoading["id"]) {
    if (!_.isNil(this.broadcastPeopleCountsCache[broadcastId])) {
      return this.broadcastPeopleCountsCache[broadcastId];
    }
    // Get people count from backend
    try {
      const peopleCount = await getBroadcastPeopleCount(broadcastId);
      this.broadcastPeopleCountsCache[broadcastId] = peopleCount;
    } catch (e) {
      console.log(`Error while fetching broadcast people count: ${e}`);
    }
    return this.broadcastPeopleCountsCache[broadcastId];
  }
}

export const canDeleteBroadcast = (broadcast: BroadcastWithLoading) => {
  return broadcast.status !== "sending";
};

export const canEditBroadcast = (broadcast: BroadcastWithLoading) => {
  return broadcast.status === "draft" || broadcast.status === "scheduled";
};

export const canCancelBroadcast = (broadcast: BroadcastWithLoading) => {
  return (
    broadcast.status === "ab-test-sending-winner" ||
    broadcast.status === "ab-testing" ||
    broadcast.status === "sending" ||
    broadcast.status === "scheduled"
  );
};

export const formatBroadcastStatus = (
  status: BroadcastWithLoading["status"]
) => {
  switch (status) {
    case "sent":
    case "draft":
    case "scheduled":
    case "sending":
    case "canceled":
    case "failed":
      return humanizeString(status);
    case "ab-testing":
      return "A/B Testing";
    case "ab-test-sending-winner":
      return "Sending A/B test winner";
    default:
      throw new UnreachableCaseError(status);
  }
};
export const getBroadcastStatusBadgeColor = (
  status: BroadcastWithLoading["status"]
) => {
  switch (status) {
    case "sent":
      return "success";
    case "ab-testing":
    case "ab-test-sending-winner":
    case "sending":
      return "primary";
    case "draft":
      return "light";
    case "scheduled":
      return "warning";
    case "canceled":
      return "secondary";
    case "failed":
      return "danger";
    default:
      throw new UnreachableCaseError(status);
  }
};
