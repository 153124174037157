import { toast, ToastOptions } from "react-toastify";
import _ from "lodash";
import ToasterMessage from "./ToasterMessage";
import { ReactElement } from "react";
import { notLoggedInErrorMessage } from "encharge-domain/lib/helpers/messages";
import Interweave from "interweave";

const formatToastError = (msg: string | { message: string; extra: string }) => {
  if (typeof msg === "string") {
    return msg;
  }
  return <ToasterMessage msg={msg} />;
};

export const toastError = (
  msg: string | { message: string; extra: any },
  options: ToastOptions = {}
) => {
  // dont show logged out messages (instead we directly redirect to login)
  const msgString: string | undefined =
    // Autorest errors
    (msg as any)?.extra?.details?.error?.message ||
    // Error in extra
    (msg as any)?.extra?.message ||
    // msg is Error
    (msg as any)?.message ||
    // String errors
    msg;
  if (
    msgString?.includes(notLoggedInErrorMessage) ||
    msgString?.includes("no authentication given") ||
    msgString?.includes("Missing OAuth token")
  ) {
    return;
  }

  const message = formatToastError(msg);
  const defaultOptions = {
    className: "toaster error",
    bodyClassName: "toaster-body toaster-error",
  };
  return toast.error(message, _.merge({}, defaultOptions, options));
};
export const toastSuccess = (
  msg: string | ReactElement,
  options: ToastOptions = {}
) => {
  const defaultOptions = {
    className: "toaster success",
    bodyClassName: "toaster-body toaster-success",
    hideProgressBar: false,
  };
  let message = msg;
  if (typeof message === "string") {
    message = <Interweave content={message} />;
  }
  return toast.info(message, _.merge({}, defaultOptions, options));
};

export const toastInfo = (
  msg: string | ReactElement,
  options: ToastOptions = {}
) => {
  const defaultOptions = {
    className: "toaster",
    bodyClassName: "toaster-body toaster-info",
    hideProgressBar: false,
  };
  let message = msg;
  if (typeof message === "string") {
    message = <Interweave content={message} />;
  }
  return toast.info(message, _.merge({}, defaultOptions, options));
};
export const closeToast = (toastId: number) => {
  toast.dismiss(toastId);
};
