import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

export const getRecipes = async () => {
  try {
    const res: AxiosResponse<{ recipes: IRecipe[] }> = await request({
      url: `${apiBase}/v1/recipes`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.recipes;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve templates. ";
    throw new Error(msg);
  }
};
