import request, { AxiosResponse } from "axios";
import { CommunicationPreferencesSettings } from "store/emailSettingsStore";
import { apiBase } from "../../constants";

import { handleForbiddenRead } from "./handleForbidden";

export const getDomains = async () => {
  try {
    const res: AxiosResponse<IEmailDomainsEnvelope> = await request({
      url: `${apiBase}/v1/settings/email/domains`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.domains;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];

    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve email domains. ";
    throw new Error(msg);
  }
};

export const addDomain = async (domain: IEmailDomainCreate) => {
  try {
    const res: AxiosResponse<{ domain: IEmailDomain }> = await request({
      url: `${apiBase}/v1/settings/email/domains`,
      method: "POST",
      data: domain,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.domain;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't add this domain.";
    throw new Error(msg);
  }
};

export const verifyDomain = async (id: IEmailDomain["id"]) => {
  try {
    const res: AxiosResponse<{ domain: IEmailDomain }> = await request({
      url: `${apiBase}/v1/settings/email/domains/${id}/verify`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.domain;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't verify this domain. ";
    throw new Error(msg);
  }
};

export const deleteDomain = async (id: IEmailDomain["id"]) => {
  try {
    const res: AxiosResponse<{ domain: IEmailDomain }> = await request({
      url: `${apiBase}/v1/settings/email/domains/${id}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.domain;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't delete this domain. ";
    throw new Error(msg);
  }
};

export const getCommunicationPreferencesSettings = async () => {
  try {
    const res: AxiosResponse<{
      value: CommunicationPreferencesSettings;
    }> = await request({
      url: `${apiBase}/v1/accounts/config/communication-preferences-snippets`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.value || {};
  } catch (e) {
    if (handleForbiddenRead(e.response)) return {};

    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve Communication Preferences settings. ";
    throw new Error(msg);
  }
};

export const saveCommunicationPreferencesSettings = async (
  settings: CommunicationPreferencesSettings
) => {
  try {
    const res: AxiosResponse<{
      value: CommunicationPreferencesSettings;
    }> = await request({
      url: `${apiBase}/v1/accounts/config/communication-preferences-snippets`,
      method: "POST",
      data: {
        value: settings,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return;

    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save Communication Preferences settings. ";
    throw new Error(msg);
  }
};
