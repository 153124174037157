import { useRef, useState } from "react";
import AppNavDropdown from "./AppNavDropdown";
import { ReactComponent as LiveChatIcon } from "../../assets/img/nav/live-chat.svg";
import { ReactComponent as QuickStartIcon } from "../../assets/img/nav/quickstart.svg";
import { ReactComponent as KnowledgeBaseIcon } from "../../assets/img/nav/knowledge-base.svg";
import { ReactComponent as DeveloperDocsIcon } from "../../assets/img/nav/developer-docs.svg";
import { ReactComponent as ProductUpdatesIcon } from "../../assets/img/nav/product-updates.svg";
import { ReactComponent as RequestFeatureIcon } from "../../assets/img/nav/request-feature.svg";
import { ReactComponent as ReportBugIcon } from "../../assets/img/nav/report-bug.svg";
import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react";
import AppNavIcon from "./AppNavIcon";
import ServiceStatusWidget from "components/AppNav/ServiceStatusWidget";
import GetHelpLabel from "components/AppNav/GetHelpLabel";

const AppNavHelp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [divRef, setDivRef] = useState<HTMLDivElement | null>(null);
  return (
    <>
      {divRef && (
        <UncontrolledTooltip target={divRef} placement={"bottom"} delay={0}>
          Help
        </UncontrolledTooltip>
      )}
      <div
        ref={setDivRef}
        className="sidebar-nav-item cursor-pointer mr-3"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          <AppNavIcon active={false} type="Help" />
          <AppNavDropdown
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            options={[
              {
                contents: (
                  <>
                    <LiveChatIcon className="nav-icon" width="16" height="16" />
                    <GetHelpLabel />
                  </>
                ),
                onClick: () => {
                  // open help docs
                  (window as any)?.openHelpChat?.();
                },
              },
              {
                contents: (
                  <>
                    <QuickStartIcon className="nav-icon" />
                    Quick Start
                  </>
                ),
                to: "/get-started",
              },
              {
                contents: (
                  <>
                    <KnowledgeBaseIcon className="nav-icon" />
                    Knowledge Base
                  </>
                ),
                href: "https://help.encharge.io/",
              },
              {
                contents: (
                  <>
                    <DeveloperDocsIcon className="nav-icon" />
                    Developer Docs
                  </>
                ),
                href: "https://docs.encharge.io/",
              },
              {
                contents: (
                  <>
                    <ProductUpdatesIcon className="nav-icon" />
                    Product Updates
                  </>
                ),
                href: "https://feedback.encharge.io/changelog",
              },
              {
                contents: (
                  <>
                    <RequestFeatureIcon className="nav-icon" />
                    Request a Feature
                  </>
                ),
                href: "https://feedback.encharge.io/feature-requests",
              },
              {
                contents: (
                  <>
                    <ReportBugIcon className="nav-icon" />
                    Report a Bug
                  </>
                ),
                href: `mailto:support@encharge.io?subject=Bug%20Report&body=Hey%20Encharge%20team%2C%20%0D%0AI%20encountered%20the%20following%20bug%3A%0D%0A%0D%0Aat%20this%20page%3A%20${window.location.href}`,
              },
              {
                contents: (
                  <>
                    <ServiceStatusWidget />
                  </>
                ),
                href: "https://status.encharge.io/",
              },
            ]}
          />
        </span>
      </div>
    </>
  );
};
export default observer(AppNavHelp);
