import {
  formatAsReactSelectOptions,
  reactSelectCommonProps,
} from "components/ReactSelect/reactSelectCommon";
import _ from "lodash";
import { useState } from "react";
import { Creatable } from "react-select";

interface Props {
  onChange: (option: string) => void;
}

const tones = [
  "Appreciative",
  "Assertive",
  "Awestruck",
  "Candid",
  "Casual",
  "Cautionary",
  "Compassionate",
  "Convincing",
  "Critical",
  "Earnest",
  "Enthusiastic",
  "Formal",
  "Friendly",
  "Funny",
  "Humble",
  "Humorous",
  "Informative",
  "Inspirational",
  "Joyful",
  "Passionate",
  "Thoughtful",
  "Urgent",
  "Worried",
];

const AIWriterToneSelect = (props: Props) => {
  const [currentValue, setCurrentValue] = useState("Friendly");
  const { currentOption, options } = formatAsReactSelectOptions({
    items: _.map(tones, (tone) => ({ id: tone, label: tone })),
    currentValue,
    idField: "id",
    labelField: "label",
  });

  return (
    <Creatable
      className="ai-writer-select-tone"
      inputId="toneSelect"
      {...reactSelectCommonProps}
      options={options}
      value={currentOption}
      onChange={(option) => {
        const value = (option as any)?.value;
        setCurrentValue(value);
        props.onChange(value);
      }}
      onCreateOption={(inputValue) => {
        tones.push(inputValue);
        setCurrentValue(inputValue);
        props.onChange(inputValue);
      }}
      formatCreateLabel={(inputValue) => `Add ${inputValue} as Tone`}
    />
  );
};
export default AIWriterToneSelect;
