const enchargeColors = require("../../scss/encharge/_export_encharge_colors.module.scss");

const defaultEnchargeColors = enchargeColors as Dictionary<string>;
export { defaultEnchargeColors };
export const segmentColors = {
  New: defaultEnchargeColors["encharge-color-blue"],
  Engaged: defaultEnchargeColors["encharge-color-green"],
  Unsubscribed: defaultEnchargeColors["encharge-color-secondary"],
  "Need some love": defaultEnchargeColors["encharge-color-red"],
};
