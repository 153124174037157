import { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Input } from "reactstrap";

import { getQuery } from "domain/helpers/query";
import ButtonLoader from "components/ButtonLoader";
import { observer } from "mobx-react";
import GoogleRegistrationWidget from "components/Registration/GoogleRegistrationWidget";
import { useStores } from "store/useStore";
import { Link } from "react-router-dom";
import RegisterError from "components/Registration/RegisterError";
import { useScript } from "hooks/useScript";

interface Props {
  submit: () => void;
}

const RegistrationStepEmail = (props: Props) => {
  const {
    registrationStore,
    uiStore: { accountRegister: ui },
  } = useStores();

  const [formData, setFormData] = useState({ email: "" });

  const [botProtectionToken, setBotProtectionToken] = useState("");
  // dont use bot protection on e2e tests
  const isTestAccount = getQuery().istest;
  const turnstileSiteKey = process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITEKEY;
  if (!turnstileSiteKey) {
    console.warn("Missing REACT_APP_CLOUDFLARE_TURNSTILE_SITEKEY");
  }
  if (!isTestAccount && turnstileSiteKey) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const botProtectionScriptStatus = useScript(
      "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (botProtectionScriptStatus === "ready") {
        (window as any).turnstile.render("#cf-turnstile", {
          sitekey: turnstileSiteKey,
          callback: function (token: string) {
            setBotProtectionToken(token);
          },
        });
      }
    }, [botProtectionScriptStatus, turnstileSiteKey]);
  }

  const source = getQuery().ref;

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const onSubmit = useCallback(
    (e) => {
      // get input with name="cf-turnstile-response"
      registrationStore
        .register({ email: formData.email, source, botProtectionToken })
        .then(() => {
          props.submit();
        });
      e.preventDefault();
      return false;
    },
    [registrationStore, formData.email, botProtectionToken, source, props]
  );

  const heading = "Start your 14-day free trial";
  const subheading =
    "Encharge will generate unique emails for your SaaS using ChatGPT.";
  const subsubheading = (
    <>
      Already have an account? <Link to="/login">Login</Link>
    </>
  );

  const queryError = getQuery().error;
  const error = ui.error || queryError;

  const googleRegistrationWidget = useMemo(() => {
    return <GoogleRegistrationWidget source={source} />;
  }, [source]);

  return (
    <div className="max-width-500 ">
      <Form onSubmit={onSubmit}>
        <h1 className="h2 font-weight-bold">{heading}</h1>
        <div className="text-muted h6 mt-3 mb-5">
          {subheading}
          {subsubheading && (
            <>
              <br />
              <div className="additional-cta-section mt-2">{subsubheading}</div>
            </>
          )}
        </div>
        <div className="mt-4 mb-3">{googleRegistrationWidget}</div>
        <div>or</div>

        <Input
          value={formData.email}
          type="email"
          placeholder="Your Email"
          autoComplete="email"
          name="email"
          className="form-control-lg mb-3 mt-2"
          required={true}
          onChange={handleFieldChange}
        />
        {!botProtectionToken && <div id="cf-turnstile"></div>}

        <ButtonLoader
          className="mt-5"
          color="primary"
          size="lg"
          block={true}
          loading={ui.isLoading}
          disabled={!botProtectionToken && !isTestAccount}
          type="submit"
        >
          Sign Up Now
          {/* Convert More Leads */}
        </ButtonLoader>
        {error && <RegisterError error={error} />}
      </Form>
    </div>
  );
};

export default observer(RegistrationStepEmail);
