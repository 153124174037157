import classNames from "classnames";
import { PropsWithChildren } from "react";

interface Props {
  maxHeight?: number | string;
  classNames?: string;
}
export const OnboardingEmailFrame = (props: PropsWithChildren<Props>) => {
  return (
    <div className={classNames("email-preview-frame", props.classNames)}>
      <div className="email-preview-frame-header">
        <svg
          viewBox="0 0 442 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="18.5" cy="15.5" r="5.5" fill="#D15252" />
          <circle cx="31.5" cy="15.5" r="5.5" fill="#FFD522" />
          <circle cx="44.5" cy="15.5" r="5.5" fill="#77D152" />
        </svg>
      </div>

      <div
        className="email-preview-frame-content"
        style={{
          maxHeight: props.maxHeight || 355,
        }}
      >
        <div className="email-preview-frame-content-inner-container background-light">
          <div className="register-generated-text">{props.children}</div>
        </div>
        {typeof props.maxHeight !== "string" && (
          <div className="bottom-gradient" />
        )}
      </div>
    </div>
  );
};
