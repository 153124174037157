import Loading from "components/Loading";
import { useScript } from "hooks/useScript";
import { useEffect, useState } from "react";

interface Props {
  mode: "signin" | "signup";
  onSuccess: (idToken: string) => void;
  onError: (error: Error) => void;
}
const GoogleSigninInit = (props: Props) => {
  const scriptStatus = useScript("https://accounts.google.com/gsi/client");

  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (scriptStatus === "ready" && containerRef) {
      const handleCredentialResponse = (response: any) => {
        if (response?.credential) {
          props.onSuccess(response?.credential);
        } else {
          props.onError(new Error("Didn't receive credentials."));
        }
      };
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      if (!clientId) throw new Error("Missing Google client ID.");

      (window as any)?.google?.accounts.id.initialize({
        client_id: clientId,
        callback: handleCredentialResponse,
        context: props.mode,
        ux_mode: "popup",
        auto_select: "false",
      });
      // Trigger the one tap signin flow.
      (window as any)?.google?.accounts.id.prompt();
      (window as any)?.google?.accounts.id.renderButton(containerRef, {
        type: "standard",
        shape: "rectangular",
        theme: "outline",
        text: props.mode === "signin" ? "signin_with" : "signup_with",
        size: "large",
        logo_alignment: "left",
      });
    }
  }, [scriptStatus, containerRef, props]);

  if (scriptStatus === "loading") {
    return <Loading />;
  }

  return <div id="google-signin-container" ref={(el) => setContainerRef(el)} />;
};

export default GoogleSigninInit;
