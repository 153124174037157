import { OnboardingEmailFrame } from "components/AIOnboarding/OnboardingEmailFrame";
import { Col } from "reactstrap";
import cloudsmithLogo from "../../assets/img/cloudsmith-logo.svg";

interface Props {
  halfPage?: boolean;
}

const AIOnboardingHeroSection = (props: Props) => {
  return (
    <Col
      xs="24"
      sm="24"
      md="24"
      lg={props.halfPage ? "12" : "8"}
      className="ai-onboarding-register-sidebar d-none d-lg-flex flex-column justify-content-center align-items-center m-0 p-0"
    >
      <div className="register-testimonial-text">
        Your account comes with a set of onboarding & lead nurturing emails
        created for your business.
      </div>
      <div className="mx-5 px-5">
        <OnboardingEmailFrame>
          <div>
            Hello <span className="highlighted">Dallas,</span>
            <br />
            <br />
            Welcome aboard! We're thrilled to see you join our growing community
            of{" "}
            <span className="highlighted">
              software developers and teams who put trust in Cloudsmith.
            </span>
            <br />
            <br />
            As the{" "}
            <span className="highlighted">
              gold-standard solution in cloud-native software artifact
              management and supply chain security
            </span>
            , we've been relentless in our quest to build{" "}
            <span className="highlighted">
              the most robust, secure, and developer-friendly tool in the
              industry.{" "}
            </span>
            <br />
            <br />
            Starting today, you have full rein to your{" "}
            <span className="highlighted">
              complimentary trial of Cloudsmith
            </span>{" "}
            - the gateway to upgraded efficiency, superior security, and an
            overall enhanced software development workflow.
            <br />
            <br />
            Throughout this trial period, you're entitled to full access to our
            key features:
            <br />
            1. Cloud-Native Artifact Management: Manage software artifacts
            efficiently across 28 unique formats, streamlining your operations
            and workflows. <br />
            2. Zero Trust Security: Stay a step ahead with our proactive
            approach to mitigating risks before they ever materialize in your
            software supply chain. <br />
            3. Dependency Firewall: Prevent critical issues from creeping into
            your production, enhancing reliability and confidence in your
            deployments.
            <br />
            4. Developer-First Experience: Enjoy globally distributed,
            low-latency artifact management through native tooling for seamless,
            uninterrupted shipping.
          </div>
        </OnboardingEmailFrame>
        <div className="register-email-frame-footer d-flex my-3 flex-row-reverse">
          <div>
            Example generated for{" "}
            <img src={cloudsmithLogo} alt="Cloudsmith Logo" className="pl-2" />{" "}
          </div>
        </div>
      </div>
    </Col>
  );
};
export default AIOnboardingHeroSection;
