import * as React from "react";
import Loading from "components/Loading";
interface Props {
  text?: string;
  overlayColor?: string;
}

export const LoadingOverlay = (props: Props) => {
  const style: React.CSSProperties = props.overlayColor
    ? { backgroundColor: props.overlayColor }
    : {};
  return (
    <div style={style} className="full-screen-loading-overlay">
      <div>
        <h3>{props.text || null}</h3>
      </div>
      <Loading />
    </div>
  );
};

export default LoadingOverlay;
