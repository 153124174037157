import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import MissingPermissionsWarning from "components/Permissions/MissingPermissionsWarning";
import { ReactComponent as BackIcon } from "assets/img/back-icon.svg";
import { getQuery } from "domain/helpers/query";
import Loading from "components/Loading";
import _ from "lodash";
import MetricEventList from "components/Metrics/EmailEvents/MetricEventList";
import MetricEventActions from "components/Metrics/EmailEvents/MetricEventActions";

const FlowEvents = observer(() => {
  const {
    permissionsStore,
    flowsStore,
    uiStore: { flowsMetrics: metricsStore },
  } = useStores();
  const type = getQuery().type;
  const endDate = metricsStore.endDate;
  const startDate = metricsStore.startDate;
  const flowIds = metricsStore.flowsIds;
  const hasLoadedScopes = permissionsStore?.hasLoadedScopes();

  if (hasLoadedScopes && !permissionsStore?.checkScope("emailMetrics:read")) {
    return (
      <MissingPermissionsWarning neededPermission="emailMetrics:read">
        You don't have access to metrics.
      </MissingPermissionsWarning>
    );
  }
  if (flowIds.length && !flowsStore.flows.current()) {
    return <Loading />;
  }
  return (
    <>
      <div className="email-metrics-printable px-5">
        <div className="page-header page-header-light pl-0">
          <div className="page-header-title">
            {type === "entered"
              ? "People who entered flow"
              : "People who reached goal"}
          </div>
        </div>
        <div>
          <div className="d-flex">
            <a
              className="d-flex"
              onClick={() => {
                // go back
                window.history.back();
              }}
              href="#"
            >
              {" "}
              <div className="my-auto mr-1 mb-1">
                <BackIcon className="my-auto mb-1" />{" "}
              </div>{" "}
              <div>All metrics</div>
            </a>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-between">
            <div className="mt-4"></div>
            <div className="mt-3">
              <MetricEventActions
                emailIds={[]}
                flowIds={flowIds}
                type={type}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
          <div>
            <MetricEventList
              emailIds={[]}
              type={type}
              endDate={endDate}
              startDate={startDate}
              flowIds={flowIds}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default FlowEvents;
