interface Props {
  progress: number;
}

const GenerateFlowsLoadingBar = (props: Props) => {
  const totalWidth = 313;
  const progressWidth = totalWidth * props.progress;
  const progressWidthRounded = Math.round(progressWidth);
  const percentage = Math.round(props.progress * 100);
  return (
    <div className="position-relative">
      <div
        style={{
          marginLeft: totalWidth + 15,
          float: "right",
          marginTop: -5,
          position: "absolute",
          color: "#8C8C8C",
          fontSize: 12,
        }}
      >
        {percentage}%
      </div>
      <div
        style={{
          width: totalWidth,
          height: 9,
          left: 0,
          top: 0,
          background: "#EBEAE9",
          borderRadius: 10,
        }}
      />
      <div
        style={{
          width: progressWidthRounded,
          height: 9,
          left: 0,
          top: 0,
          position: "absolute",
          background: "#77D152",
          borderRadius: 10,
        }}
      />
    </div>
  );
};
export default GenerateFlowsLoadingBar;
