import { observable, computed, action } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";

import { toastError, toastSuccess } from "../domain/errorHandling/toaster";

import {
  tagPeople,
  exportPeople,
  archivePeople,
  restorePeople,
  deletePerson,
  restoreAllPeople,
} from "./persistence/persistPeople";

export class PeopleActionStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  selectedPeopleIds: string[] = [];

  @computed
  get selectedPeopleCount() {
    if (this.selectedPeopleIds.length) {
      return this.selectedPeopleIds.length;
    }
    // use total count of people in segment
    // get the people total count
    return (
      this.rootStore.segmentStore?.currentSegment?.preview?.current?.()
        ?.totalCount || 0
    );
  }

  @action
  setSelectedPeople(ids: string[]) {
    this.selectedPeopleIds = ids;
  }

  @action
  selectedPerson(id: string) {
    // add the person only if not selected so far
    if (
      !_.find(
        this.selectedPeopleIds,
        (alreadySelectedPersonId) => alreadySelectedPersonId === id
      )
    ) {
      this.selectedPeopleIds.push(id);
    }
  }

  @action
  unselectedPerson(id: string) {
    // add the person only if not selected so far
    this.selectedPeopleIds = _.filter(
      this.selectedPeopleIds,
      (personId) => personId !== id
    );
  }

  @action
  async tagSelected(tag: string) {
    try {
      // Selected people manually
      if (this.selectedPeopleIds.length) {
        toastSuccess("✅ People will be tagged shortly.");

        // after the request is done, add the tags locally
        this.rootStore.peopleStore.addTagToPeopleLocal(
          this.selectedPeopleIds,
          tag
        );
        return await tagPeople({
          peopleIds: this.selectedPeopleIds,
          tag,
        });
      }

      // tag whole segment
      const segmentId = this.rootStore.segmentStore.currentSegmentId;
      if (!segmentId) return;

      toastSuccess("✅ People in segment will be tagged shortly.");

      // tag current people in segment locally
      const currentSegment = this.rootStore.segmentStore.getSegmentById(
        segmentId
      );
      if (!currentSegment) {
        return;
      }
      this.rootStore.peopleStore.addTagToPeopleLocal(
        currentSegment.people.peopleIds,
        tag
      );
      return await tagPeople({
        segmentId,
        tag,
      });
    } catch (e) {
      toastError({
        message: "Error while adding tags.",
        extra: e,
      });
    }
  }

  @action
  async exportSelected() {
    try {
      const email = this?.rootStore?.accountStore?.account?.email;
      if (!email) {
        throw new Error(
          "Can't send exported people, since you have no email set."
        );
      }
      // Selected people manually
      if (this.selectedPeopleIds.length) {
        toastSuccess(
          `✅ Export of selected people will be emailed to ${email} shortly.`
        );
        return await exportPeople({
          peopleIds: this.selectedPeopleIds,
          notifyEmail: email,
        });
      }

      // tag whole segment
      const segmentId = this.rootStore.segmentStore.currentSegmentId;
      if (!segmentId) return;

      toastSuccess(
        `✅ Export of people in segment will be emailed to ${email} shortly.`
      );

      return await exportPeople({
        segmentId,
        notifyEmail: email,
      });
    } catch (e) {
      toastError({
        message: "Error while exporting people.",
        extra: e,
      });
    }
  }

  @action
  async archiveSelected() {
    try {
      // Selected people manually
      if (this.selectedPeopleIds.length) {
        const people = this.selectedPeopleIds;
        toastSuccess("✅ Selected people will be archived shortly.");
        // remove people locally
        this.rootStore.peopleStore.removePeopleLocal(this.selectedPeopleIds);
        this.setSelectedPeople([]);

        return await archivePeople({
          peopleIds: people,
        });
      }

      // archive whole segment
      const segmentId = this.rootStore.segmentStore.currentSegmentId;
      if (!segmentId) return;

      toastSuccess("✅ People in segment will be archived shortly.");

      // remove people from this segment segment locally
      const currentSegment = this.rootStore.segmentStore.getSegmentById(
        segmentId
      );
      if (!currentSegment) {
        return;
      }
      this.rootStore.peopleStore.removePeopleLocal(
        currentSegment.people.peopleIds
      );
      return await archivePeople({
        segmentId,
      });
    } catch (e) {
      toastError({
        message: "Error while archiving people.",
        extra: e,
      });
    }
  }
  @action
  async restoreSelected() {
    try {
      // Selected people manually
      if (this.selectedPeopleIds.length) {
        const people = this.selectedPeopleIds;
        toastSuccess("✅ Selected people will be restored shortly.");
        // remove people locally
        this.rootStore.peopleStore.removePeopleLocal(this.selectedPeopleIds);
        this.setSelectedPeople([]);

        this.rootStore.segmentStore.clearSegmentsPeopleCache();

        return await restorePeople(people);
      }
    } catch (e) {
      toastError({
        message: "Error while unarchiving people.",
        extra: e,
      });
    }
  }

  @action
  async restoreAll() {
    try {
      this.rootStore.uiStore.restorePeople.startLoading();
      await restoreAllPeople();
      toastSuccess("✅ People will be restored shortly.");
      // restore people locally
      this.rootStore.segmentStore.clearSegmentsPeopleCache();
      this.rootStore.peopleStore.resetPeople();
    } catch (e) {
      toastError({
        message: "Error while unarchiving people.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.restorePeople.finishLoading();
    }
  }

  @action
  async delete(id: string | undefined) {
    if (!id) return;
    this.rootStore.uiStore.personAction.startLoading();
    try {
      await deletePerson(id);
      this.rootStore.peopleStore.removePeopleLocal([id]);
      toastSuccess("✅ Person has been deleted.");
      window.history.back();
    } catch (e) {
      toastError({
        message: "Error while deleting person.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.personAction.finishLoading();
    }
  }
}
