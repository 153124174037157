import request, { AxiosResponse } from "axios";
import { IStep, IStepPeople } from "components/FlowEditor/Step/StepBase";
import { getQueryClient } from "EnchargeQueryClient/getQueryClient";
import _ from "lodash";
import { useInfiniteQuery } from "react-query";
import { getNextPageParamFactory } from "store/react-query/getNextPageParam";
import { handleReadError } from "store/react-query/handleQueryResponse";
import { stepPeopleQueryKeys } from "store/react-query/queryKeys";
import { apiBase } from "../../../constants";

const pageSize = 50;

export const fetchStepPeople = async (
  id: IStep["id"],
  limit: number,
  offset: number,
  state: IStepPeopleState
) => {
  console.log("fetchStepPeople", id, limit, offset, state);
  if (!id) {
    throw new Error("Can't get people for step without ID.");
  }
  const res: AxiosResponse<{
    people: IStepPeople;
  }> = await request({
    url: `${apiBase}/v1/steps/${id}/people?limit=${limit}&offset=${offset}&state=${state}`,
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return res.data?.people?.[state];
};

export function useGetStepPeople({
  stepId,
  state,
}: {
  stepId: NonNullable<IStep["id"]>;
  state: IStepPeopleState;
}) {
  const fetchObjects = async ({ pageParam = 0 }) => {
    return fetchStepPeople(stepId, pageSize, pageParam * pageSize, state);
  };

  const queryKey = stepPeopleQueryKeys.step(stepId, state);

  return useInfiniteQuery(queryKey, fetchObjects, {
    getNextPageParam: getNextPageParamFactory({
      pageSize,
      getPagesUntilEmpty: true,
    }),
    onError: (e: Error) => {
      handleReadError("Error while retrieving people.", e);
    },
  });
}

/**
 * Remove an object from the list of objects in a step.
 */
export function removeObjectFromQueuedStepPeople({
  objectId,
  stepId,
}: {
  stepId: NonNullable<IStep["id"]>;
  objectId: string | number;
}) {
  const queryKey = stepPeopleQueryKeys.step(stepId, "queued");

  // update the cache
  getQueryClient().setQueryData(queryKey, (oldData: any) => {
    if (!oldData) {
      return oldData;
    }
    const newData = _.map(oldData.pages, (page: any) => {
      return _.filter(page, (object: any) => {
        return object.userId !== objectId;
      });
    });
    return { ...oldData, pages: newData };
  });
}
