import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import humanizeString from "encharge-domain/lib/helpers/humanizeString";

export const performServiceOperation = async ({
  serviceId,
  operationName,
  stepId,
  body,
}: {
  serviceId: string;
  operationName: string;
  stepId?: IIntegrationStep["id"];
  body?: any;
}) => {
  try {
    // we need to encode the step name since it includes slashes
    const dynamicURL = `${apiBase}/v1/services/${serviceId}/operations/${encodeURIComponent(
      operationName
    )}?stepId=${stepId || ""}`;
    const res: AxiosResponse<any> = await request({
      url: dynamicURL,
      method: "POST",
      data: body,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const errorMessage = e?.response?.data?.error?.message || e.message;
    throw new Error(
      `We couldn't perform ${humanizeString(operationName)}${
        serviceId === "encharge" ? "" : ` for ${humanizeString(serviceId)} `
      }${errorMessage && `: ${errorMessage}`}`
    );
  }
};
