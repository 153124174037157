import { IConfigureDrawerSize } from "../../components/ConfigureStep/ConfigureDrawer";

const getDrawerSizeClasses = (size: IConfigureDrawerSize) => {
  let drawerSizeClasses = "";
  switch (size) {
    case "md":
      drawerSizeClasses =
        "col-23 col-sm-20 col-md-16 col-lg-14 col-xl-10 col-xxl-9 float-right";
      break;
    case "lg":
      drawerSizeClasses =
        "col-23 col-sm-20 col-md-18 col-lg-16 col-xl-14 col-xxl-11 float-right";
      break;
    case "xl":
      drawerSizeClasses =
        "col-23 col-sm-23 col-md-21 col-lg-18 col-xl-16 col-xxl-13 float-right";
      break;
    case "sm":
    default:
      break;
  }
  return drawerSizeClasses;
};

export default getDrawerSizeClasses;
