import request, { AxiosError, AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { IPersonField } from "encharge-domain/definitions/PersonField";

export const createFields = async (
  objectType: string,
  fields: IPersonField[]
) => {
  try {
    const res: AxiosResponse<{
      items: IPersonField[];
    }> = await request({
      url: `${apiBase}/v1/schemas/${objectType}/fields`,
      method: "POST",
      data: fields,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.items;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't create these fields. ";
    throw new Error(msg);
  }
};

export const editField = async (objectType: string, field: IPersonField) => {
  try {
    const res: AxiosResponse<{
      item: IPersonField;
    }> = await request({
      url: `${apiBase}/v1/schemas/${objectType}/fields/${field.name}`,
      method: "PATCH",
      data: field,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't edit this field.";
    throw new Error(msg);
  }
};

export const deleteField = async (
  objectType: string,
  fieldName: IPersonField["name"]
) => {
  try {
    await request({
      url: `${apiBase}/v1/schemas/${objectType}/fields/${fieldName}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      ((e as AxiosError)?.response?.data as any)?.error?.message ||
      (e as AxiosError).message ||
      "We couldn't delete this field.";
    throw new Error(msg);
  }
};
