import * as React from "react";
import Loading from "components/Loading";

export const LoadingCentered = (
  props: React.ComponentProps<typeof Loading>
) => {
  return (
    <div className="loading-centered">
      <Loading {...props} />
    </div>
  );
};

export default LoadingCentered;
