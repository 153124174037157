import { useState } from "react";
import * as React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";

interface Props {
  logo?: string;
  name?: string;
  site?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  linkToHome?: boolean;

  className?: string;
}

/**
 * Render logo of site with clearbit api.
 */
const AccountLogoView = observer((props: Props) => {
  const { site, name } = props;
  const [logoError, setLogoError] = useState(false);
  let logo = null;
  if (props.logo) {
    logo = <img alt={`${name || site} Logo`} src={props.logo} />;
  } else if (site && !logoError) {
    logo = (
      <img
        alt={`${site} Logo`}
        src={`//logo.clearbit.com/${site}`}
        onError={() => setLogoError(true)}
      />
    );
  } else {
    const accountIdentifier =
      name ||
      site
        ?.replace("http://", "")
        ?.replace("https://", "")
        ?.replace("http://", "")
        ?.replace("www.", "");

    const accountInitials = accountIdentifier
      ? _.capitalize(accountIdentifier.substr(0, 2))
      : "";
    if (accountInitials) {
      logo = (
        <>
          <span className="company-logo-background" />
          <span className="text-muted">{accountInitials}</span>
        </>
      );
    }
  }

  const linkToHome = props.linkToHome !== undefined ? props.linkToHome : true;

  const className = React.useMemo(
    () => `company-logo font-weight-medium ${props.className}`,
    [props.className]
  );
  const link = linkToHome ? (
    <Link
      to="/dashboard"
      className={className}
      onClick={props.onClick || ((e) => e.stopPropagation())}
    >
      {" "}
      {logo}
    </Link>
  ) : (
    <span className={className}>{logo}</span>
  );
  return <div className="app-nav-logo-container">{link}</div>;
});

export default AccountLogoView;
