import _ from "lodash";
import { toJS, runInAction } from "mobx";
import { compare, applyPatch } from "fast-json-patch";
import { IStep } from "../components/FlowEditor/Step/StepBase";
import { toastError } from "../domain/errorHandling/toaster";
import { log } from "../domain/errorHandling/logToBackend";

/**
 * Perform an action that can be confirmed or rejected.
 *
 * @param {*} state
 * @param {() => any} performAction - Function that modifies the state.
 * @param {() => Promise<any>} confirmAction - Function that confirms the modifications to the
 * @param confirmErrorMessage - Error to show if confirmation fails.
 * state if it resolves, or cancels them if it throws.
 */
export const confirmableAction = <S>(
  getStateFn: () => S,
  performAction: (state: S) => undefined | (() => Promise<any> | undefined),
  confirmErrorMessage?: string
) => {
  // Make a reverse patch of the changes we do in the action
  // don't overwrite newer changes if we undo

  // TODO Check - Restoring state doesn't work when toJS is used but works with _.cloneDeep
  const state = getStateFn();
  const prevState = _.cloneDeep(state);
  // Modifies state
  const confirmAction = runInAction(() => performAction(state));
  const newState = _.cloneDeep(state);
  const reversePatch = compare(newState, prevState);

  return async () => {
    try {
      // Confirm the action
      if (confirmAction) {
        const asyncResult = await confirmAction();
        return asyncResult;
      }
    } catch (e) {
      // Reverse the effects of the patch
      console.log("Reversing action", e);
      // Show message to the user
      toastError({
        message: confirmErrorMessage || "Error while saving.",
        extra: e,
      });

      runInAction(() => {
        try {
          const restoredState = applyPatch(state, reversePatch).newDocument;
          _.merge(state, restoredState);
        } catch (e) {
          // Show message to the user
          toastError(
            "There was an error while reversing a failed save. Please refresh the page. "
          );
          log({ message: "Error while reversing action", extra: { error: e } });
          console.log("Error while reversing action", e);
        }
      });
    }
  };
};
