import { Component } from "react";
import MaterialDrawer from "@material-ui/core/Drawer";
import { inject, observer } from "mobx-react";
import UIStore from "../store/uiStore";
import { PaperProps } from "@material-ui/core/Paper";
import getDrawerSizeClasses from "domain/helpers/getDrawerSizeClasses";

const exitDuration = 100; // ms
const enterDuration = 0; // dont animate for now because of weird effect

interface Props {
  paperStyle?: any;
  onClose?: () => void;
  open?: boolean;
  className?: string;
  keepContentMounted?: boolean;

  zIndex?: number;

  canClose?: () => boolean;

  uiStore?: UIStore;
}
interface State {
  isOpen: boolean;
}

@inject("uiStore")
@observer
class Drawer extends Component<Props, State> {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    // We open the drawer once it has mounted, to allow its animation to trigger
    this.open();
  }
  close = () => {
    if (this.props.canClose) {
      if (!this.props.canClose()) {
        return;
      }
    }
    this.setState({
      isOpen: false,
    });
    // Give the drawer time to hide, before propagating the close event
    setTimeout(() => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    }, exitDuration);
  };

  open = () => {
    this.setState({
      isOpen: true,
    });
  };

  toggle = (desiredState?: boolean) => {
    if (desiredState === true) {
      this.open();
    } else if (desiredState === false) {
      this.close();
    } else if (this.state.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  render() {
    const paperStyle: Partial<PaperProps> = this.props.paperStyle || {
      style: {},
    };
    paperStyle.style = {
      overflowY: "hidden",
      color: "inherit",
    };
    if (this.props.className) {
      paperStyle.className = this.props.className;
    } else {
      paperStyle.className = getDrawerSizeClasses("md");
    }
    paperStyle.className += " h-100 over";
    // TODO - enable for no backdrop
    // paperStyle.className += " h-100 over pointer-events-all";

    const closeDrawer = () => this.close();

    // Prefer props, and fallback to state
    const open =
      this.props.open !== undefined ? this.props.open : this.state.isOpen;

    // useful if we want to not rerender content every time
    const keepContentMounted = this.props.keepContentMounted || false;

    return (
      <div>
        <MaterialDrawer
          SlideProps={{ direction: "left" }}
          transitionDuration={{ enter: enterDuration, exit: exitDuration }}
          style={{ overflowY: "scroll", zIndex: this.props.zIndex }}
          // TODO - enable for no backdrop
          /// @ts-ignore - root not declared on classes
          // classes={{ root: "pointer-events-none" }}
          ModalProps={{
            // TODO - enable for no backdrop
            // hideBackdrop: true,
            BackdropProps: {
              classes: {
                root: "drawer-backdrop",
              },
            },
            keepMounted: keepContentMounted,
          }}
          anchor="right"
          open={open}
          onClose={closeDrawer}
          PaperProps={paperStyle}
          // Fixes issues with popups/datetime in drawer
          disableEnforceFocus={true}
          // Disable closing the drawer with Esc key if we have a modal on top
          // Not used currently as the field popup is a drawer itself now
          // disableEscapeKeyDown={this.props.uiStore!.personFieldPopup.isOpen}
        >
          <div className="drawer-content">{this.props.children}</div>
        </MaterialDrawer>
      </div>
    );
  }
}

export default Drawer;
