import request from "axios";
import { getNodeEnv } from "domain/helpers/getNodeEnv";
import { apiBase } from "../../constants";

export const log = async (payload: any) => {
  // Only log to backend in production,
  // since we have the error overlay in dev.
  const env = getNodeEnv();
  if (env === "production" || env === "staging") {
    try {
      await request({
        url: `${apiBase}/v1/logging`,
        method: "POST",
        data: payload,
        withCredentials: true,
        validateStatus: (status) => status < 300,
      });
    } catch (e) {
      console.log("Couldn't log error to backend.");
    }
  }
};
