/// @ts-ignore - not picking up require.context
const reqSvgs = require.context("../../assets/img/toolIcons", true, /\.svg$/);
const toolIcons = reqSvgs.keys().reduce((images: any, path: string) => {
  images[path] = reqSvgs(path)?.default;
  images[
    "./segment-logo.png"
  ] = `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/segment-logo.png`;
  return images;
}, {});
export default toolIcons;
