import _ from "lodash";
import { action, autorun, computed, observable, runInAction, when } from "mobx";
import uuidv1 from "uuid/v1";
import uuidv4 from "uuid/v4";

import { IntegrationStepBase } from "encharge-domain/lib/entities/integrationStepBase";
import {
  integrationStateActive,
  integrationStateDeactivatedByUser,
} from "encharge-domain/lib/helpers/constants";
import { ILink } from "../components/FlowEditor/Link/Link";
import { IStep, StepBase } from "../components/FlowEditor/Step/StepBase";
import { IFlow } from "../components/Flows/FlowRoute";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import {
  makeQuerablePromise,
  QuerablePromise,
} from "../domain/helpers/checkForPendingRequests";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import { shouldSkipResouces } from "../domain/helpers/shouldSkipResouces";
import { redrawStepElement } from "../domain/jointjs/stepElement";
import { DomainStore } from "./domainStore";
import { confirmableActionToDepreciate, findStepByIdOrTempId } from "./helpers";
import {
  createFlow,
  createFlowFromExisting,
  getFlow,
  getFlows,
  updateFlow,
  getFlowsNames,
  getFlowMetricsCount,
  getFlowHistory,
  removeObjectFromFlow,
  removeObjectFromStep,
  skipWaitStep,
  getAIGeneratedFlows,
  getFlowsStepPeopleCount,
  getFlowsUniqueMetrics,
} from "./persistence/persistFlow";
import {
  createStep,
  deleteStep,
  getStep,
  updateStep,
} from "./persistence/persistFlowStep";
import {
  createLink,
  deleteLink,
  deleteLinkBySourceAndTarget,
} from "./persistence/persistLink";
import {
  exportFlowPeopleEvents,
  getStepMetrics,
} from "./persistence/persistMetrics";
import { getPaper } from "domain/jointjs/getJointjsSingletons";
import { Awaited } from "encharge-domain/lib/definitions/ambient/ts-essentials";
import pLimit from "p-limit";
import { getDomainFromEmail } from "encharge-domain/lib/helpers/email_helper";
import { getUserTimezone } from "domain/helpers/asDateTime";
import { performServiceOperation } from "store/persistence/performServiceOperation";
import { FieldsSchema } from "encharge-domain/entities/fields_schema";
import { IntegrationDecoration } from "encharge-domain/definitions/IntegrationDecoration";
import enchargeAPI from "store/persistence/enchargeAPI";
import { removeObjectFromQueuedStepPeople } from "components/ConfigureStep/StepPeople/queries";

export const defaultFlowMetricsLookbackPeriod = 7;

export type FlowMetricsUnique = {
  [date: string]: {
    peopleReachedGoal: number;
    peopleEnteredFlow: number;
  };
};

export class FlowsStore {
  rootStore: DomainStore;
  canEditFlowStore: CanEditFlowStore = new CanEditFlowStore(this);
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;

    // Dont load flows on some routes
    if (shouldSkipResouces()) return;

    autorun(() => {
      this.loadFlows();
    });
  }

  @action
  loadFlows() {
    if (this.rootStore.locationStore.path?.match(/\/flows\/[\d]+/i)) {
      const pathParts = this.rootStore.locationStore.path.split("/");
      const flowId = Number(pathParts[pathParts.length - 1]);
      if (flowId) {
        this.singleFlowId = flowId;
        // trigger loading the flow
        this.flows.current();
        // this.loadFlows(flowId);
        return;
      }
    } else {
      this.removeSingleFlowId();
    }
  }

  @action
  async loadFlowHistory(flowId: number) {
    try {
      const flow = this.getFlowById(flowId);
      if (!flow) {
        return;
      }
      runInAction(() => {
        flow.history = undefined;
      });
      const history = await getFlowHistory(flowId);
      runInAction(() => {
        flow.history = history;
      });
    } catch (e) {
      console.log(e);
      toastError({
        message: "Error while loading history events.",
        extra: e,
      });
    }
  }

  @observable
  singleFlowId: number | undefined = undefined;

  @observable
  isLoadingMetrics: boolean = false;

  @observable
  flowsStepPeopleCounts: {
    [flowId: number]: {
      [stepId: string]: number;
    };
  } = {};

  @action
  removeSingleFlowId() {
    this.singleFlowId = undefined;
  }

  @observable
  // So delete/update can wait until the step has been created
  stepsBeingCreated: NonNullable<IStep["tempId"]>[] = [];

  @observable
  linksBeingCreated: NonNullable<ILink>[] = [];

  @observable
  decorationsBeingCreated: NonNullable<IntegrationDecoration>[] = [];

  @observable
  currentFlowId?: IFlow["id"];

  @observable
  flows = lazyObservable<IFlow[]>((sink, onError) => {
    const readOnlyAuth = this.rootStore.permissionsStore.readOnlyAuthToken;
    (this.singleFlowId ? getFlow(this.singleFlowId, readOnlyAuth) : getFlows())
      .then((items) => {
        // if getting all flows, store them in flow names
        if (!this.singleFlowId) {
          //Todo: Vu map steps to flow
          this.allFlowNames.put(
            _.map(items, (item) => _.pick(item, "id", "name", "steps"))
          );
        }
        sink(observable(items.reverse()));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading your flows.",
          extra: e,
        });
        onError(e);
      });
  });

  @observable
  allFlowNames = lazyObservable<
    { id: IFlow["id"]; name: IFlow["name"]; hasGoals?: Boolean }[]
  >((sink, onError) => {
    getFlowsNames()
      .then((items) => {
        sink(observable(items.reverse()));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading your flows.",
          extra: e,
        });
        onError(e);
      });
  });
  @computed
  get currentFlow() {
    return this.getFlowById(this.currentFlowId);
  }

  getFlowById(id?: IFlow["id"]) {
    if (!id) return undefined;
    return _.find(this.flows.current(), (flow) => flow.id === id);
  }

  @observable
  pendingActions: QuerablePromise<any>[] = [];

  @action
  private addPendingAction(action: QuerablePromise<any>) {
    this.pendingActions.push(action);
  }
  // @action
  // async loadFlows(id?: IFlow["id"]) {
  //   try {
  //     runInAction(() => {
  //       this.rootStore.uiStore.flows.loading = true;
  //     });
  //     let flows: IFlow[];
  //     if (id) {
  //       // Load a single flow
  //       flows = [await getFlow(id)];
  //     } else {
  //       // Load all flows
  //       flows = await getFlows();
  //     }
  //     runInAction(() => {
  //       // reverse puts the newest flows first
  //       this.flows = flows.reverse();

  //       this.rootStore.uiStore.flows.loading = false;
  //       this.rootStore.uiStore.flows.error = undefined;
  //     });
  //   } catch (e) {
  //     runInAction(() => {
  //       this.rootStore.uiStore.flows.loading = false;
  //       this.rootStore.uiStore.flows.error = e.message;
  //     });
  //     toastError({
  //       message: "Error while loading your flows.",
  //       extra: e
  //     });
  //   }
  // }

  @action
  selectFlow(id: IFlow["id"]) {
    if (id !== this.currentFlowId) {
      this.currentFlowId = id;
      // reset other things related to flow
      this.flowChanged();
    }
  }

  @action
  private flowChanged() {
    //  get people counts for steps
    this.getStepsPeopleCounts(this.currentFlowId);

    this.rootStore.uiStore.stepsPreviewStore.showPreview();
    this.canEditFlowStore.resetEditActiveFlowConfirmation();
    this.stepsPeopleCountsQueue.clearQueue();

    this.hasLoadedStepsPeopleCounts = false;
  }

  @action
  async archiveFlow(id: IFlow["id"]) {
    const flow = this.getFlowById(id);
    if (!flow) return;
    try {
      this.rootStore.uiStore.flowDelete.start(id);

      // remove the flow from the local store
      const flowIndex = _.findIndex(
        this.flows.current(),
        (flow) => flow.id === id
      );
      if (flowIndex !== -1) {
        // remove the flow from the array in place
        runInAction(() => {
          this.flows.current().splice(flowIndex, 1);
        });
      }
      await this.updateFlow(_.merge({}, flow, { archived: true }));
    } finally {
      this.rootStore.uiStore.flowDelete.finish(id);
    }
  }

  @action
  async createFlow({
    recipe,
    existingFlowId,
    name,
    targetAccountId,
    addToCurrentFlows,
  }: {
    name?: string;
    recipe?: IRecipe;
    existingFlowId?: IIntegration["id"];
    targetAccountId?: IAccount["id"];
    addToCurrentFlows?: boolean;
  } = {}) {
    try {
      this.rootStore.uiStore.flowCreate.start();
      let flow: IFlow;
      if (recipe === undefined) {
        if (existingFlowId) {
          flow = await createFlowFromExisting({
            existingFlowId,
            readOnlyAuth: this.rootStore.permissionsStore.readOnlyAuthToken,
            targetAccountId,
          });
        } else {
          flow = await createFlow({ name: name || "Unnamed Flow" });
        }
      } else {
        flow = await createFlowFromExisting({
          existingFlowId: recipe.integrationId,
          recipeId: recipe.id,
        });
      }
      if (addToCurrentFlows !== false) {
        // otherwise chokes up when adding steps
        if (_.isArray(flow.steps)) {
          flow.steps = {};
        }
        if (!flow.links) {
          flow.links = [];
        }
        // add the flow on start of flows array
        runInAction(() => {
          (this.flows.current() || []).unshift(flow);
          // Todo: Vu map steps to flow
          this.allFlowNames.put([
            ...(this.allFlowNames.current() || []),
            { id: flow.id, name: flow.name },
          ]);
        });
        // // add created flow to current flows
        // runInAction(() => {
        //   this.flows.put([...this.flows.current(), flow]);
        // });
        await this.rootStore.accountStore.onboardingActionComplete("flow");

        // add newly created email to current folder
        const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
          "flows"
        );
        if (currentFolderId) {
          await this.rootStore.foldersStore.pushItemToFolder({
            folderId: currentFolderId,
            itemId: flow.id,
            type: "flows",
          });
          // wait to make sure the API operations queue is empty,
          // since we are going to navigate away
          await new Promise<void>((resolve) =>
            setTimeout(() => resolve(), 100)
          );
        }
      }

      await this.rootStore.uiStore.flowCreate.closeModal();
      return flow;
    } catch (e) {
      toastError({
        message: "Error while creating flow.",
        extra: e,
      });
    } finally {
      // stop loading
      this.rootStore.uiStore.flowCreate.finish();
    }
    return;
  }

  /**
   * Zoom by a tiny unnoticable amount so that the step tools are properly
   * positioned.
   */
  triggerPaperZoom() {
    setTimeout(() => {
      // wait for a bit for the step to draw
      const paper = getPaper();
      paper && paper.panAndZoom.zoom(paper.panAndZoom.getZoom() + 0.000001);
    }, 50);
  }

  @action
  async newStep(step: IStep) {
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) {
      throw new Error("Can't add Step without selecting flow first.");
    }
    if (!(await this.canEditFlowStore.canEditFlow())) return;
    const action = confirmableActionToDepreciate(
      flow,
      () => {
        const id = step.id || step.tempId;
        flow.steps[id!] = step;
        this.triggerPaperZoom();
      },
      async () => {
        // Add to list of steps pending creation
        runInAction(() => this.stepsBeingCreated.push(step.tempId!));

        try {
          // Persist the step
          const opToInsert = _.merge({}, step, {
            flowId: flow.id,
          });
          const newlyCreatedStep = await createStep(opToInsert);

          // Cleanup after successful persist
          runInAction(() => {
            // Rename the key of this step
            // Make sure it wasn't deleted in the mean time
            if (flow.steps[step.tempId!]) {
              const newId = newlyCreatedStep.id!;
              // We need this, otherwise the update ops won't be able to find
              // the newly created step
              newlyCreatedStep.tempId = step.tempId;
              // Use the server created object
              flow.steps[newId] = newlyCreatedStep;

              // Remove the tempId step
              delete flow.steps[step.tempId!];
            }
          });
          this.updateFlowLastSaved();
        } finally {
          // Mark step as created, allowing update/deletes to flush
          runInAction(() => {
            _.remove(this.stepsBeingCreated, (v) => v === step.tempId);
          });
        }
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
    return action;
  }

  @action
  async updateStep(updatedStep: Partial<IStep>) {
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) {
      throw new Error("Can't modify Step without selecting flow first.");
    }
    let opToUpdate = findStepByIdOrTempId(flow.steps, {
      id: updatedStep.id,
      tempId: updatedStep.tempId,
    });
    if (!opToUpdate) {
      // Maybe it was deleted in the meantime
      return;
    }
    await this.askShouldChangeWaitStepDelay(updatedStep);

    const action = confirmableActionToDepreciate(
      flow,
      () => {
        // Merge values of arrays as atomic values (i.e. overwriting each other)
        const mergeCopyArrays = (objValue: any, srcValue: any, key: string) => {
          if (key === "value" && _.isArray(objValue)) {
            return srcValue;
          }
          // Also merge input and output fields as atomic values,
          // since we get the full object from the from
          if (key === "inputFields" || key === "outputFields") {
            return srcValue;
          }
        };
        _.mergeWith(opToUpdate, updatedStep, mergeCopyArrays);
        // Mark the step as pending update in the UI
        opToUpdate!.pendingUpdate = true;
      },
      async () => {
        // Wait for the step to be created on the server if it isn't
        if (opToUpdate!.tempId) {
          await when(
            () => !this.stepsBeingCreated.includes(opToUpdate!.tempId!)
          );
          // When the step has been created, it's replaced,
          // so we need to reapply our changes
          opToUpdate = findStepByIdOrTempId(flow.steps, {
            id: updatedStep.id,
            tempId: updatedStep.tempId,
          });
          if (!opToUpdate) {
            return;
          }
          runInAction(() => {
            _.merge(opToUpdate, updatedStep);
          });
        }
        // Mark the step as pending update in the UI
        runInAction(() => {
          opToUpdate!.pendingUpdate = true;
        });

        try {
          const serializableOp = _.merge({}, opToUpdate, {
            flowId: flow.id,
          });
          const updated = await updateStep(serializableOp);

          // Merge while replacing inputFields and outputFields.
          // We replace them because of dynamic fields which might disappear
          const customizer = (_objValue: any, srcValue: any, key: any) => {
            if (key === "inputFields" || key === "outputFields") {
              return srcValue;
            }
          };
          runInAction(() => {
            _.mergeWith(opToUpdate, updated, customizer);
          });

          // Redraw the jointjs element to match the updated step
          this.updateFlowLastSaved();
          redrawStepElement(serializableOp.id);
          return updated;
        } finally {
          // Mark the update done in the UI
          runInAction(() => (opToUpdate!.pendingUpdate = false));
        }
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
    return action as Promise<IStep | undefined>;
  }

  @action
  async deleteStep(deleteIds: Pick<IStep, "id" | "tempId">) {
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) {
      throw new Error("Can't modify step without selecting flow first.");
    }

    let opToDelete = findStepByIdOrTempId(flow.steps, deleteIds);
    if (!opToDelete) {
      // Maybe it was deleted in the meantime
      return;
    }
    if (!(await this.canEditFlowStore.canEditFlow())) return;

    const action = confirmableActionToDepreciate(
      flow,
      () => {
        // Remove the step from the state
        this.markStepAsPendingDelete(deleteIds);
      },
      async () => {
        // Wait for the step to be created on the server if it isn't
        if (deleteIds.tempId) {
          await when(() => !this.stepsBeingCreated.includes(deleteIds.tempId!));

          // When the step has been created, it's replaced,
          // so we need to get it again
          opToDelete = findStepByIdOrTempId(
            flow.steps,
            deleteIds
          ) as NonNullable<IStep>;
        }
        if (!opToDelete) {
          throw new Error("Couldn't find step to delete.");
        }
        // Mark step as deleting again
        // because the create step might have unmarked it
        this.markStepAsPendingDelete(_.pick(opToDelete, ["id", "tempId"]));

        // Once the step is created on the server, we can delete it safely
        await deleteStep(opToDelete.id!);
        runInAction(() => {
          // Actually delete from state
          if (
            opToDelete &&
            opToDelete.id !== undefined &&
            flow.steps[opToDelete.id]
          ) {
            delete flow.steps[opToDelete.id];
          }
        });
        this.updateFlowLastSaved();
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
    return action;
  }

  @action
  private markStepAsPendingDelete(deleteIds: Pick<IStep, "id" | "tempId">) {
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) return;
    if (deleteIds.id && flow.steps[deleteIds.id]) {
      flow.steps[deleteIds.id].pendingDelete = true;
      // Delete connected links on the frontend store only,
      // as the backend will take care of deleting them in the DB
      const connectedLinks = this.getLinksByStepId(deleteIds.id);
      connectedLinks.map((link) => this.deleteLocalLink(link));
    } else if (deleteIds.tempId && flow.steps[deleteIds.tempId]) {
      flow.steps[deleteIds.tempId].pendingDelete = true;
    } else {
      throw new Error("Couldn't find step to delete.");
    }
  }

  @action
  async createDecoration(
    decorationData: Omit<IntegrationDecoration, "id" | "integrationId">
  ) {
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) {
      throw new Error("Can't create decoration without selecting flow first.");
    }

    const newDecoration: IntegrationDecoration = {
      id: uuidv4(),
      integrationId: flow.id,
      ...decorationData,
    };
    const action = confirmableActionToDepreciate(
      flow,
      () => {
        // Add the decoration to the state
        if (!flow.decorations) flow.decorations = [];
        flow.decorations.push(newDecoration);
        this.decorationsBeingCreated.push(newDecoration);
        this.triggerPaperZoom();
      },
      async () => {
        await enchargeAPI.createDecoration(newDecoration);

        // Wait for the decoration to be created on the server if it isn't
        this.decorationsBeingCreated = _.filter(
          this.decorationsBeingCreated,
          (pendingDecoration) => pendingDecoration.id !== newDecoration.id
        );
        this.updateFlowLastSaved();
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
    return action;
  }

  @action
  async updateDecoration(decorationData: Partial<IntegrationDecoration>) {
    // update the decoration in the state
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) {
      throw new Error("Can't update decoration without selecting flow first.");
    }

    const decorationToUpdate = _.find(
      flow.decorations,
      (decoration) => decoration.id === decorationData.id
    );
    if (!decorationToUpdate) {
      return;
    }

    const updated = _.merge({}, decorationToUpdate, decorationData);

    const action = confirmableActionToDepreciate(
      flow,
      () => {
        // Update the decoration in the state
        _.merge(decorationToUpdate, decorationData);
      },
      async () => {
        // make sure the decoration is created on the server
        await when(
          () =>
            !this.decorationsBeingCreated.some(
              (decoration) => decoration.id === decorationToUpdate.id
            )
        );
        // Once the decoration is updated on the server, we can update it safely
        await enchargeAPI.updateDecoration(updated.id, updated);
        this.updateFlowLastSaved();
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
    return action;
  }

  @action
  async deleteDecoration(decorationId: string) {
    // delete the decoration in the state
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) {
      throw new Error("Can't delete decoration without selecting flow first.");
    }

    const decorationToDelete = _.find(
      flow.decorations,
      (decoration) => decoration.id === decorationId
    );
    if (!decorationToDelete) {
      return;
    }

    const action = confirmableActionToDepreciate(
      flow,
      () => {
        // Delete the decoration in the state
        _.remove(
          flow.decorations || [],
          (decoration) => decoration.id === decorationId
        );
      },
      async () => {
        // make sure the decoration is created on the server
        await when(
          () =>
            !this.decorationsBeingCreated.some(
              (decoration) => decoration.id === decorationToDelete.id
            )
        );
        // Once the decoration is deleted on the server, we can delete it safely
        await enchargeAPI.deleteDecoration(decorationId);

        this.updateFlowLastSaved();
      }
    );
    this.addPendingAction(makeQuerablePromise(action));
  }

  /**
   * Reload any x-encharge-dynamic schema, that has its dependencies updated.
   */
  @action
  async reloadDynamicInputSchema({
    stepId,
    stepInputValues,
    fieldToRefresh,
  }: {
    stepId: IStep["id"];
    stepInputValues: Dictionary<any>;
    fieldToRefresh: string;
  }) {
    try {
      this.rootStore.uiStore.dynamicStepSchema.startLoading();
      // Get the step from the store
      const flow = this.getFlowById(this.currentFlowId);
      if (!flow) {
        throw new Error("Can't refresh Step without selecting workflow first.");
      }
      const step = findStepByIdOrTempId(flow.steps, { id: stepId });
      if (!step) return;

      // find the fields to refresh
      const inputFieldAll = step?.inputFields?.properties;
      const field = inputFieldAll?.[fieldToRefresh];
      if (!inputFieldAll || !field?.["x-encharge-dynamic"]) {
        throw new Error(
          `Couldn't find dynamic field to refresh: ${fieldToRefresh}`
        );
      }
      // get the dynamic fields operation - inline string or object with operation string
      const dynamicOperation =
        typeof field["x-encharge-dynamic"] === "string"
          ? field["x-encharge-dynamic"]
          : field["x-encharge-dynamic"]?.operation;

      if (!dynamicOperation) {
        throw new Error(
          `Couldn't find dynamic operation for ${fieldToRefresh}`
        );
      }

      const response = await performServiceOperation({
        operationName: dynamicOperation,
        serviceId: step.serviceId,
        body: stepInputValues,
        stepId: step.id,
      });

      if (!response?.[0]) {
        throw new Error(`Received empty response from ${dynamicOperation}`);
      }

      // Preserve the values in the schema
      const schema = new FieldsSchema(response[0]);

      if (stepInputValues[fieldToRefresh]) {
        const skipValidate = step?.inputFields?.["x-encharge-ui"]?.noValidate;
        if (skipValidate) {
          schema.putDeepProperty("value", stepInputValues[fieldToRefresh]);
        } else {
          // putValues always validates
          schema.putValues(stepInputValues[fieldToRefresh]);
        }
      }

      // replace the fields in the step
      runInAction(() => {
        inputFieldAll[fieldToRefresh] = {
          ...schema.schema,
          "x-encharge-dynamic": field["x-encharge-dynamic"],
        };
      });
    } catch (error) {
      console.log(`Error while refreshing dynamic field.`, error);
    } finally {
      this.rootStore.uiStore.dynamicStepSchema.finishLoading();
    }
  }

  @action
  async refreshStep(id: IStep["id"]) {
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) {
      throw new Error("Can't refresh Step without selecting workflow first.");
    }
    const opToRefresh = findStepByIdOrTempId(flow.steps, {
      id,
    });
    if (!opToRefresh) {
      // Maybe it was deleted in the meantime
      return;
    }
    let step: IStep | undefined = undefined;
    try {
      // Wait for any updates to finish
      await when(() => !opToRefresh.pendingUpdate);

      runInAction(() => {
        opToRefresh!.pendingUpdate = true;
      });
      step = await getStep(id);
      runInAction(() => {
        // Merge while replacing inputFields and outputFields.
        // We replace them because of dynamic fields which might disappear
        const customizer = (_objValue: any, srcValue: any, key: any) => {
          if (key === "inputFields" || key === "outputFields") {
            return srcValue;
          }
        };
        _.mergeWith(opToRefresh, step, customizer);
      });
    } catch (e) {
      toastError({
        message: "Error while loading your step.",
        extra: e,
      });
    }
    runInAction(() => {
      opToRefresh!.pendingUpdate = false;
    });

    // Redraw the jointjs element to match the updated step
    redrawStepElement(id);

    return step;
  }
  @action
  async duplicateStep(step: IStep) {
    const newStep = _.cloneDeep(_.omit(step, "flowsStore") as IStep);
    newStep.id = undefined;
    newStep.tempId = uuidv1();
    newStep.position = undefined;
    newStep.pendingDelete = undefined;
    newStep.pendingUpdate = undefined;
    newStep.metrics = undefined;
    newStep.peopleCounts = undefined;
    newStep.ephemeralData = undefined;
    newStep.isDuplicate = true;
    newStep.coordinates = {
      x: newStep.coordinates.x + 20,
      y: newStep.coordinates.y + 20,
    };
    return this.newStep(newStep);
  }

  async waitUntilStepIsCreated(tempId: IStep["tempId"]) {
    if (!tempId) return;
    await when(() => !this.stepsBeingCreated.includes(tempId));
    return;
  }

  stepMetricsCacheKey({
    period,
    endDate,
    startDate,
  }: {
    period: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
  }) {
    return `${period}_${startDate}_${endDate}`;
  }

  @action
  async retrieveStepMetrics({
    stepId,
    stepTempId,
    period,
    endDate,
    startDate,
  }: {
    stepId: IStep["id"];
    stepTempId: IStep["tempId"];
    period: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
  }) {
    if (this.rootStore.permissionsStore.readOnlyFlow) {
      return;
    }
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) {
      throw new Error("No flow selected.");
    }

    const metricsKey = this.stepMetricsCacheKey({
      period,
      endDate,
      startDate,
    });
    this.rootStore.uiStore.stepMetrics.startLoading(metricsKey);
    if (stepTempId) {
      await when(() => !this.stepsBeingCreated.includes(stepTempId!));
    }
    const currentStep = findStepByIdOrTempId(flow.steps, {
      id: stepId,
    });
    if (!currentStep) {
      // Maybe it was deleted in the meantime
      this.rootStore.uiStore.stepMetrics.stopLoading(metricsKey);
      return;
    }
    // Email must have been selected
    const emailId =
      currentStep?.inputFields?.properties?.email?.properties?.id?.value;
    if (!emailId) {
      this.rootStore.uiStore.stepMetrics.stopLoading(metricsKey);
      return;
    }
    try {
      const metrics = await getStepMetrics({
        id: currentStep.id,
        period,
        startDate,
        endDate,
        timezone:
          getUserTimezone() ||
          this.rootStore.accountStore.account?.timezone ||
          "UTC",
      });
      runInAction(() => {
        if (!currentStep.metrics) {
          currentStep.metrics = {};
        }
        currentStep.metrics[metricsKey] = {
          type: "email",
          [period]: {
            periods: metrics.periods,
            metrics: metrics.all,
          },
        };
      });
    } catch (e) {
      toastError({
        message: "Error while retrieving step metrics.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.stepMetrics.stopLoading(metricsKey);
    }
  }

  @action
  updateFlowLastSaved() {
    runInAction(() => {
      const flow = this.getFlowById(this.currentFlowId);

      if (flow) {
        flow.lastSaved = new Date();
      }
    });
  }

  @action
  async changeFlowName(flowId: IFlow["id"], name: string) {
    const flow = this.getFlowById(flowId);
    if (!flow) return;

    try {
      flow.name = name;
      this.rootStore.uiStore.editor.flowName.loading = true;

      await this.updateFlow(flow);
    } finally {
      runInAction(
        () => (this.rootStore.uiStore.editor.flowName.loading = false)
      );
    }
  }

  @action
  async updateFlow(flow: IFlow) {
    try {
      const action = updateFlow(flow);
      this.addPendingAction(makeQuerablePromise(action));
      await action;
      this.updateFlowLastSaved();
    } catch (e) {
      toastError({
        message: "Error while saving your flow.",
        extra: e,
      });
    }
  }

  @action
  async toggleFlowOnOff({
    desiredState,
    flowId,
  }: {
    desiredState?: integrationStateActive | integrationStateDeactivatedGeneric;
    flowId?: IFlow["id"];
  } = {}) {
    const id = flowId || this.currentFlowId;
    const flow = this.getFlowById(id);

    if (!flow) {
      throw new Error("Can't find flow to turn on/off.");
    }

    let prevFlowStatus: IFlow["status"];
    try {
      this.rootStore.uiStore.editor.flowOnOff.loading = true;
      this.rootStore.uiStore.editor.flowOnOff.flowId = flow.id;

      // Wait until all operations on steps are complete
      prevFlowStatus = flow.status;
      if (desiredState) {
        flow.status = desiredState;
      } else {
        if (prevFlowStatus === integrationStateActive) {
          flow.status = integrationStateDeactivatedByUser;
        } else {
          flow.status = integrationStateActive;
        }
      }
      await this.waitForPendingRequest();
      // check if the account is approved
      if (flow.status === integrationStateActive) {
        await this.canActivateFlow(flow);
      }
      const action = updateFlow(flow);
      this.addPendingAction(makeQuerablePromise(action));
      await action;
      runInAction(() => {
        // turn loading off
        this.rootStore.uiStore.editor.flowOnOff.loading = false;
        this.rootStore.uiStore.editor.flowOnOff.flowId = undefined;
        // Show warning dialog on edit flow since current flow is now active
        this.canEditFlowStore.resetEditActiveFlowConfirmation();
      });
      this.updateFlowLastSaved();
      if (flow.status === integrationStateActive) {
        toastSuccess("🚀 Flow activated.");
      } else {
        toastSuccess("✅ Flow deactivated.");
      }
    } catch (e) {
      runInAction(() => {
        // undo state change on error
        if (flow && prevFlowStatus) {
          flow.status = prevFlowStatus;
        }
        // turn loading off
        this.rootStore.uiStore.editor.flowOnOff.loading = false;
        this.rootStore.uiStore.editor.flowOnOff.flowId = undefined;
        toastError((e as any).message);
      });
    }
  }

  /**
   * Check if the flow can be activated based on whether the account is
   * approved.
   */
  async canActivateFlow(flow: IFlow) {
    const account = this.rootStore.accountStore.account;
    if (!account) {
      return false;
    }
    if (!flow) {
      return false;
    }
    // test accounts can always send
    if (account.testAccount) {
      return true;
    }
    // trigger loading emails
    _.noop(this.rootStore.emailsStore.emails);

    // Make sure verified emails and domains have loaded
    await Promise.all([
      when(() => !this.rootStore.emailSettingsStore.domains.isLoading()),
      when(() => !this.rootStore.emailSettingsStore.emails.isLoading()),
      when(() => !this.rootStore.emailsStore.loadingEmails),
    ]);
    const emailDomains = this.rootStore.emailSettingsStore.domains.current();
    const validatedEmailAddresses = this.rootStore.emailSettingsStore.emails.current();

    const hasVerifiedEmail = (email: string) =>
      _.reduce(
        validatedEmailAddresses,
        (acc, current) => {
          if (
            current.status === "verified" &&
            current.email?.toLocaleLowerCase() === email?.toLocaleLowerCase()
          )
            return true;
          return acc;
        },
        false
      );
    const hasVerifiedDomain = (email: string) =>
      _.reduce(
        emailDomains,
        (acc, domain) => {
          if (
            domain.status === "verified" &&
            domain.domain?.toLocaleLowerCase() ===
              getDomainFromEmail(email)?.toLocaleLowerCase()
          )
            return true;
          return acc;
        },
        false
      );

    _.each(flow.steps, (step) => {
      if (
        IntegrationStepBase.isSendEmail({
          operationKey: step.operationKey,
        })
      ) {
        const emailId =
          step?.inputFields?.properties?.email?.properties?.id?.value;
        if (!emailId) return;
        const email = this.rootStore.emailsStore.getEmailById(emailId);
        if (
          email?.fromEmail &&
          !hasVerifiedEmail(email?.fromEmail) &&
          !hasVerifiedDomain(email?.fromEmail)
        ) {
          throw new Error(
            `Address ${email?.fromEmail} has not been verified for sending email.`
          );
        }
      }
    });
    return true;
  }

  /**
   * Returns a promise that resolves when there are no pending requests
   *
   * @returns Promise
   * @memberof FlowsStore
   */
  waitForPendingRequest() {
    // Fire a recursive function, that check every 100ms
    // if there are pending requests
    const checkForPendingRequests = (resolve: () => void) => {
      let noPendingRequests = true;
      _.each(this.pendingActions, (actionPromise) => {
        if (actionPromise.state === "pending") {
          noPendingRequests = false;
        }
      });
      if (noPendingRequests) {
        resolve();
      } else {
        setTimeout(() => checkForPendingRequests(resolve), 100);
      }
    };
    return new Promise<void>((resolve) => {
      checkForPendingRequests(resolve);
    });
  }

  @action
  async newLink(link: ILink) {
    try {
      const flow = this.getFlowById(this.currentFlowId);

      if (!flow) {
        throw new Error("Can't add link without selecting flow first.");
      }
      const sourceStep = findStepByIdOrTempId(flow.steps, {
        id: link.source,
      });
      if (!sourceStep) {
        throw new Error("Can't add link because source step is missing.");
      }

      if (!(await this.canEditFlowStore.canEditFlow())) return;

      runInAction(() => {
        const availableConditions = sourceStep.linkConditions;
        // If there are no conditions, we can save the step right away.
        if (!availableConditions || _.isEmpty(availableConditions)) {
          link.beingCreated = true;
          this.linksBeingCreated.push(link);
          flow.links.push(link);
          const askShouldRetriggerLink = true;
          this.saveLink(link, askShouldRetriggerLink);
        } else {
          link.beingCreated = true;
          flow.links.push(link);
        }
      });
      this.updateFlowLastSaved();
    } catch (e) {
      toastError({
        message: "Error while creating link.",
        extra: e,
      });
    }
  }

  @action
  // A new link that is not persisted to the backend
  newLocalLink(link: ILink) {
    try {
      const flow = this.getFlowById(this.currentFlowId);

      if (!flow) {
        throw new Error("Can't link steps without selecting flow first.");
      }
      link.beingCreated = true;
      flow.links.push(link);
    } catch (e) {
      toastError({
        message: "Error while creating link.",
        extra: e,
      });
    }
  }

  @action
  deleteLocalLink(linkToDelete: ILink) {
    try {
      const flow = this.getFlowById(this.currentFlowId);

      if (!flow) {
        throw new Error("Can't remove link without selecting flow first.");
      }
      const linkToDeleteIndex = _.findIndex(flow.links, (link) =>
        _.isEqual(link, linkToDelete)
      );
      flow.links.splice(linkToDeleteIndex, 1);
    } catch (e) {
      toastError({
        message: "Error while deleting link.",
        extra: e,
      });
    }
  }

  @action
  async saveLink(link: ILink, askShouldRetriggerLink: boolean) {
    const save = async (link: ILink) => {
      runInAction(async () => {
        if (!link.id) {
          try {
            // to hide the link options dialog
            link.beingCreated = false;
            const action = createLink(
              _.omit(link, ["beingCreated", "linkPopupCoordinates"])
            );
            this.addPendingAction(makeQuerablePromise(action));
            const createdLink = await action;
            runInAction(() => {
              _.assign(link, createdLink);
              // remove the link from links being created
              this.linksBeingCreated = _.filter(
                this.linksBeingCreated,
                (pendingLink) =>
                  pendingLink.source !== createdLink.source ||
                  pendingLink.target !== createdLink.target
              ) as ILink[];
            });

            this.updateFlowLastSaved();
          } catch (e) {
            // remove the link as we couldn't create it
            this.deleteLocalLink(link);
            toastError({
              message: "Error while saving link.",
              extra: e,
            });
          }
        }
      });
    };

    if (askShouldRetriggerLink && !this.skipRetriggerLinkDialog) {
      await this.askShouldRetriggerLink(link, save);
    } else {
      await save(link);
    }
  }

  skipRetriggerLinkDialog: boolean = false;

  @action
  setSkipRetriggerLinkDialog(skip?: boolean) {
    this.skipRetriggerLinkDialog = skip ?? true;
  }

  /**
   * Ask the user if they want to retrigger the people
   * in the link source step to the target step.
   */
  @action
  async askShouldRetriggerLink(
    link: ILink,
    save: (link: ILink) => Promise<void>
  ) {
    // get the source step
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) {
      return save(link);
    }
    const sourceStep = findStepByIdOrTempId(flow?.steps, {
      id: link.source,
    });
    if (!sourceStep) {
      return save(link);
    }
    // check if the source step has people in it
    const peopleInStep = sourceStep?.peopleCounts?.entered;
    // we ask if we haven't loaded the people counts
    if (
      !this.hasLoadedStepsPeopleCounts ||
      (peopleInStep && peopleInStep > 0)
    ) {
      // open dialog to ask if we should retrigger the link
      return new Promise((resolve) => {
        this.rootStore.uiStore.retriggerLinkDialog.open({
          additionalData: {
            peopleCount: peopleInStep,
          },
          onConfirmCallBack: (retrigger: boolean) => {
            if (retrigger) {
              if (!link.data) {
                link.data = {};
              }
              link.data.retriggerObjectsUponActivation = true;
            }
            resolve(save(link));
          },
        });
      });
    }

    return save(link);
  }

  @action
  async askShouldChangeWaitStepDelay(step: Partial<IStep>) {
    if (!step || !step.operationKey) return;

    if (IntegrationStepBase.isDelay({ operationKey: step.operationKey })) {
      const peopleInStep = step?.peopleCounts?.queued;
      console.log("peopleInStep", peopleInStep);
      // we ask if we haven't loaded the people counts
      if (
        !this.hasLoadedStepsPeopleCounts ||
        (peopleInStep && peopleInStep > 0)
      ) {
        // open dialog to ask if we should
        return new Promise((resolve) => {
          this.rootStore.uiStore.waitStepRecomputeDelayDialog.open({
            additionalData: {
              peopleCount: peopleInStep,
            },
            onConfirmCallBack: (shouldChange: boolean) => {
              if (shouldChange) {
                step.recomputeDelay = true;
              }
              resolve(step);
            },
          });
        });
      }
    }
  }

  @action
  async deleteLink(link: ILink) {
    if (!(await this.canEditFlowStore.canEditFlow())) return;
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) {
      console.log("No current flow");
      return;
    }

    // hide the link from view
    this.deleteLocalLink(link);
    try {
      let action: any;
      if (link.id) {
        // already created link
        action = deleteLink(link.id!);
      } else {
        // wait for the link to be created
        await when(
          () =>
            !Boolean(
              _.find(
                this.linksBeingCreated,
                (pendingLink) =>
                  pendingLink.source === link.source &&
                  pendingLink.target === link.target
              )
            )
        );
        action = deleteLinkBySourceAndTarget(link.source, link.target);
      }
      this.addPendingAction(makeQuerablePromise(action));
      this.updateFlowLastSaved();
      return action;
    } catch (e) {
      // We couldn't delete the link so add it back
      this.newLocalLink(link);
      toastError({
        message: "Error while deleting link.",
        extra: e,
      });
    }
  }

  getLinksByStepId(id: IStep["id"]): ILink[] {
    if (!id) return [];
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) return [];
    const connectedLinks = _.filter(
      flow.links,
      (link: ILink) => link.source === id || link.target === id
    );
    return connectedLinks;
  }

  @action
  setStepEphemeralData(stepId: IStep["id"], key: string, value: any) {
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow || !stepId) {
      return;
    }
    if (!flow.steps[stepId].ephemeralData) {
      flow.steps[stepId].ephemeralData = {};
    }
    _.set(flow.steps[stepId].ephemeralData as any, key, value);
  }

  stepIsConnected(id?: IStep["id"]) {
    if (!id) return;
    const flow = this.getFlowById(this.currentFlowId);

    if (!flow) return;
    const step = flow.steps[id];
    if (!step) return;
    const connectedLinks = _.filter(flow.links, (link: ILink) => {
      if (step.type === "trigger") {
        return link.source === id;
      } else if (step.type === "filter" || step.type === "action") {
        return link.target === id;
      }
      return false;
    });
    return Boolean(connectedLinks?.length);
  }

  stepsPeopleCountsQueue = pLimit(1);
  flowGoalMetricQueue = pLimit(10);

  hasLoadedStepsPeopleCounts = false;

  @observable
  hasLoadedFlowGoalMetrics = false;

  @observable
  flowMetricsLookBackPeriod() {
    if (
      this.rootStore.accountStore.isLoadingAccount() ||
      this.rootStore.accountStore.account?.peopleCount === undefined
    ) {
      return undefined;
    }
    if (this.rootStore.accountStore.account?.peopleCount > 10000) {
      return defaultFlowMetricsLookbackPeriod;
    }

    return 30;
  }

  erroredFlowsPeopleCounts: IFlow["id"][] = [];

  @action
  async getStepsPeopleCounts(flowId: number | undefined) {
    this.stepsPeopleCountsQueue(async () => {
      if (!flowId) return;
      let flowWithStepCounts: Awaited<ReturnType<typeof getFlowMetricsCount>>;
      if (this.erroredFlowsPeopleCounts.includes(flowId)) {
        // skipping this flow due to previous error
        return;
      }

      try {
        flowWithStepCounts = await getFlowMetricsCount(flowId);
      } catch (e) {
        console.log("Error while getting flow people counts", e);
        if (!this.erroredFlowsPeopleCounts.includes(flowId)) {
          this.erroredFlowsPeopleCounts.push(flowId);
        }
        // this can easily timeout
        // so ignore it in such a case
        return;
      }
      await when(() => !this.flows.isLoading());
      const flow = this.getFlowById(flowId);
      if (!flow) return;
      runInAction(() => {
        this.hasLoadedStepsPeopleCounts = true;
        _.forEach(flowWithStepCounts.steps, (stepWithCounts) => {
          const existingStep = _.find(
            flow!.steps,
            (current) => current.id === stepWithCounts.id
          );
          if (!existingStep) return;
          if (
            !_.isEqual(
              existingStep.peopleCounts,
              stepWithCounts.data.peopleCounts
            )
          ) {
            existingStep.peopleCounts = stepWithCounts.data.peopleCounts;
          }
        });
      });
    });
  }

  @action
  async getFlowMetrics() {
    if (this.hasLoadedFlowGoalMetrics) {
      return;
    }

    this.flows.current();
    await when(() => this.flowMetricsLookBackPeriod() !== undefined);
    await when(() => this.flows.isLoading() === false);

    const flowIds = _.map(this.flows.current(), (flow) => flow.id);

    if (!this.flowMetricsLookBackPeriod()) {
      runInAction(() => {
        this.hasLoadedFlowGoalMetrics = true;
      });
      return;
    }
    // get metrics
    const chunkSize = 50;
    try {
      const chunks = _.chunk(flowIds, chunkSize);
      for (const chunk of chunks) {
        const flowMetrics = await getFlowsStepPeopleCount(
          chunk,
          this.flowMetricsLookBackPeriod() || defaultFlowMetricsLookbackPeriod
        );
        runInAction(() => {
          const flows = this.flows.current();
          this.hasLoadedFlowGoalMetrics = true;
          const ids = Object.keys(flowMetrics);
          ids.forEach((flowId) => {
            const flow = _.find(flows, (f) => f.id === Number(flowId));
            if (!flow) return;
            flow.flowGoalMetrics = flowMetrics[flowId];
          });
        });
      }
    } catch (e) {
      console.log("Error while getting flow goal metrics", e);
      // this can easily timeout
      // so ignore it in such a case
      return;
    }
  }

  @observable
  metricsCache: Dictionary<{
    metrics: FlowMetricsUnique;
    computeStartTime: string;
  }> = {};

  @observable
  isLoadingUniqueMetrics = false;

  // async getUniqueFlowMetricsWithRetry({
  //   metricsPeriod,
  //   startDate,
  //   endDate,
  //   flowIds,
  // }: {
  //   metricsPeriod: IStatsPeriod;
  //   startDate?: Date;
  //   endDate?: Date;
  //   flowIds?: IIntegration["id"][];
  // }) {
  //   const metrics = await this.getFlowUniqueMetrics({
  //       metricsPeriod,
  //       startDate,
  //       endDate,
  //       flowIds,
  //     });
  //     if (!metrics) {

  // }

  private getFlowUniqueMetricsCacheKey({
    metricsPeriod,
    startDate,
    endDate,
    flowIds,
  }: {
    metricsPeriod: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
    flowIds?: IIntegration["id"][];
  }) {
    const idsString = (flowIds || []).sort().join("_");
    return `${metricsPeriod}_${startDate}_${endDate}_${idsString}`;
  }

  @action
  getFlowUniqueMetrics({
    metricsPeriod,
    startDate,
    endDate,
    flowIds,
    forceRefresh,
  }: {
    metricsPeriod: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
    flowIds?: IIntegration["id"][];
    forceRefresh?: boolean;
  }):
    | {
        metrics: FlowMetricsUnique;
        computeStartTime: string;
      }
    | undefined {
    console.log(
      "getFlowUniqueMetrics",
      metricsPeriod,
      startDate,
      endDate,
      flowIds
    );
    if (
      !metricsPeriod ||
      !startDate ||
      !endDate ||
      !metricsPeriod ||
      !flowIds?.length
    ) {
      return { metrics: {}, computeStartTime: new Date().toJSON() };
    }
    const cacheKey = this.getFlowUniqueMetricsCacheKey({
      metricsPeriod,
      startDate,
      endDate,
      flowIds,
    });

    if (this.metricsCache[cacheKey]) {
      return this.metricsCache[cacheKey];
    }

    // make sure we are not already loading some metrics
    when(() => !this.isLoadingUniqueMetrics).then(() => {
      this.isLoadingUniqueMetrics = true;
      // schedule computing the metrics
      getFlowsUniqueMetrics({
        flowIds,
        startDate,
        endDate,
        metricsPeriod,
        forceRefresh,
      })
        .then((result) => {
          // metrics haven't been computed yet
          if (result.pending) {
            return;
          }
          // lets sum up the metrics by date
          // response is:
          // Dictionary<{
          //   [flowId: string]: {
          //     [date: string]: {
          //       peopleReachedGoal: number;
          //       peopleEnteredFlow: number;
          //     };
          //   };
          // }>;

          const summedMetrics: FlowMetricsUnique = {};
          _.forEach(result.metrics, (flowMetrics) => {
            _.forEach(flowMetrics, (metric, date) => {
              if (!summedMetrics[date]) {
                summedMetrics[date] = {
                  peopleReachedGoal: 0,
                  peopleEnteredFlow: 0,
                };
              }
              summedMetrics[date].peopleReachedGoal +=
                metric.peopleReachedGoal || 0;
              summedMetrics[date].peopleEnteredFlow +=
                metric.peopleEnteredFlow || 0;
            });
          });

          runInAction(() => {
            this.metricsCache[cacheKey] = {
              metrics: summedMetrics,
              computeStartTime: result.computeStartTime || new Date().toJSON(),
            };
          });
        })
        .catch((e) => {
          toastError({
            message: "Error while loading flow metrics.",
            extra: e,
          });
        })
        .finally(() => {
          runInAction(() => {
            this.isLoadingUniqueMetrics = false;
          });
        });
    });

    return this.metricsCache?.[cacheKey];
  }

  @action
  refreshFlowUniqueMetrics(args: {
    metricsPeriod: "hour" | "day" | "week" | "month";
    startDate: Date | undefined;
    endDate: Date | undefined;
    flowIds: number[];
  }) {
    const cacheKey = this.getFlowUniqueMetricsCacheKey(args);
    delete this.metricsCache[cacheKey];

    // trigger loading the metrics
    this.getFlowUniqueMetrics({ ...args, forceRefresh: true });
  }

  @action
  async exportFlowPeopleEvents({
    flowIds,
    type,
    startDate,
    endDate,
  }: {
    flowIds: IIntegration["id"][];
    type: "entered" | "reachedGoal";
    startDate?: Date;
    endDate?: Date;
  }) {
    if (!flowIds?.length) {
      return;
    }
    const idsString = (flowIds || []).sort().join("_");
    const cacheKey = `${type}_${startDate}_${endDate}_${idsString}`;

    // make sure we are not already loading some metrics
    try {
      const email = this?.rootStore?.accountStore?.account?.email;
      if (!email) {
        throw new Error(
          "Can't send exported people, since you have no email set."
        );
      }

      await exportFlowPeopleEvents({
        startDate,
        endDate,
        flowIds,
        type,
        notifyEmail: email,
      });
      toastSuccess(`✅ The export will be emailed to ${email} shortly.`);
    } catch (e) {
      toastError({
        message: "Error while exporting people.",
        extra: e,
      });
    }
  }

  /**
   * Get flow use event schema
   */
  getFlowsUseEventSchema(eventSchema: IEventSchema): IFlow[] {
    const flows = this.flows.current() || [];
    console.log("flows", flows);
    return _.filter(flows, (flow: IFlow) => {
      console.log("flow", flow);
      let isUsed = false;
      for (const stepKey in flow.steps) {
        const step = flow.steps[stepKey];
        // We are only checking steps that are email related
        if (step.operationKey === "/event") {
          // find the email used in this step
          const stepFieldsValues = StepBase.getInputFieldsValues(step);
          // Is the email id in used in the step same as current email id
          if (
            eventSchema.name?.toLocaleLowerCase() ===
            stepFieldsValues.name?.toLocaleLowerCase()
          ) {
            isUsed = true;
            break;
          }
        }
      }
      return isUsed;
    });
  }

  /**
   * Remove object from flow
   */
  @action
  async removeObjectFromFlow({
    objectId,
    flowId,
  }: {
    flowId?: number;
    objectId: string | number;
  }) {
    const id = flowId || this.currentFlowId;
    if (!id) return;
    this.rootStore.uiStore.removeObjectFromFlowLoading.startLoading();
    try {
      await removeObjectFromFlow({
        objectId,
        flowId: id,
      });
      toastSuccess("Successfully removed from flow.");
    } catch (e) {
      toastError({
        message: "Error while ending flow.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.removeObjectFromFlowLoading.finishLoading();
    }
  }
  /**
   * Remove object from step
   */
  @action
  async removeObjectFromStep({
    stepId,
    objectId,
  }: {
    stepId?: number;
    objectId: string | number;
  }) {
    if (!stepId) return;
    try {
      removeObjectFromQueuedStepPeople({
        objectId,
        stepId,
      });
      await removeObjectFromStep({
        objectId,
        stepId,
      });
      toastSuccess("Successfully removed from step.");
    } catch (e) {
      toastError({
        message: "Error while removing from step.",
        extra: e,
      });
    }
  }

  /**
   * Skip wait step and proceed to next steps
   */
  @action
  async skipWaitStep({
    stepId,
    objectId,
  }: {
    stepId: number;
    objectId: string | number;
  }) {
    if (!stepId) return;
    try {
      removeObjectFromQueuedStepPeople({
        objectId,
        stepId,
      });
      await skipWaitStep({
        objectId,
        stepId,
      });
      toastSuccess("Successfully skipped waiting.");
    } catch (e) {
      toastError({
        message: "Error while skipping wait step.",
        extra: e,
      });
    }
  }

  getTriggerByStepId(stepId?: number, operationKeys?: string[]) {
    if (!stepId) {
      return [];
    }
    const flow = this.getFlowById(this.currentFlowId);
    if (!flow) return;
    const steps = flow.steps;
    const triggerSteps: IStep[] = [];
    const links = this.getLinksByStepId(stepId);
    _.forEach(links, (link) => {
      const sourceStep = steps[link.source];
      if (
        sourceStep?.type === "trigger" &&
        (!operationKeys?.length ||
          operationKeys.includes(sourceStep.operationKey))
      ) {
        triggerSteps.push(sourceStep);
      }
    });
    return triggerSteps;
  }

  @observable
  aiGeneratedFlows = lazyObservable<
    {
      id: number;
      name: string;
      aiGeneratedEmailText: string;
    }[]
  >((sink, onError) => {
    getAIGeneratedFlows()
      .then((items) => {
        sink(observable(items));
      })
      .catch((e) => {
        onError(e);
      });
  });
}

/**
 * Check if we can edit the current flow.
 * Asks the user (once) if the flow is active.
 *
 * @class CanEditFlowStore
 */
class CanEditFlowStore {
  flowsStore: FlowsStore;
  constructor(flowsStore: FlowsStore) {
    this.flowsStore = flowsStore;
  }
  @observable
  userConfirmedEditActiveFlow: boolean = false;

  @observable
  userCanceledEditActiveFlow: boolean = false;

  get uiStore() {
    return this.flowsStore.rootStore.uiStore;
  }

  async canEditFlow() {
    // If active and user has not confirmed editing active
    if (
      this.flowsStore.currentFlow?.status === integrationStateActive &&
      !this.userConfirmedEditActiveFlow
    ) {
      // Show the modal asking to continue or pause flow
      this.uiStore.activeFlowWarning.showConfirmModal();
      // If the user confirms, return true (to continue action)
      // If the user closes the popup, return false to cancel the action
      return new Promise((resolve) => {
        // We can edit the flow, so return true
        when(
          () => this.userConfirmedEditActiveFlow,
          () => resolve(true)
        );
        // Cancel editing the flow
        when(
          () => this.userCanceledEditActiveFlow,
          () => {
            // reset the flag
            this.userCanceledEditActiveFlow = false;
            // return false to cancel the action waiting on this
            resolve(false);
          }
        );
      });
    }
    // If not active or previously confirmed,
    // return true to continue with the action
    return true;
  }

  @action
  continueWithEditFlow() {
    this.userConfirmedEditActiveFlow = true;
    this.uiStore.activeFlowWarning.closeConfirmModal();
  }
  @action
  cancelEditActiveFlow() {
    this.userCanceledEditActiveFlow = true;
    this.uiStore.activeFlowWarning.closeConfirmModal();
  }

  @action
  resetEditActiveFlowConfirmation() {
    // Reset the confirmation (usually when opening a new flow)
    this.userConfirmedEditActiveFlow = false;
  }
}
