import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import _ from "lodash";
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { observer } from "mobx-react";
interface Props {
  isOpen: boolean;
  title: string | React.ReactNode;
  size?: "sm" | "md" | "lg" | "xl";
  onClose?: () => void;
  buttons?: {
    onClick: () => void;
    label: string;
    color?: string;
  }[];
}

const InfoDialog = (props: PropsWithChildren<Props>) => {
  const onClose = props.onClose;
  const onCloseCallback = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const buttons = useMemo(
    () =>
      props.buttons || [
        {
          onClick: onCloseCallback,
          label: "Close",
          color: "primary",
        },
      ],
    [props.buttons, onCloseCallback]
  );

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={onCloseCallback}
        zIndex={1400}
        size={props.size || "lg"}
      >
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>
          {buttons.map((button) => (
            <Button
              key={button.label}
              color={button.color}
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          ))}{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};
export default observer(InfoDialog);
