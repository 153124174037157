import { useMutation } from "react-query";
import { createSubscription } from "store/persistence/persistAccount";
import { handleMutationError } from "store/react-query/handleQueryResponse";
import { useStores } from "store/useStore";

export function useCreateSubscription() {
  const { accountStore } = useStores();

  return useMutation(
    ({
      token,
      planName,
      planPeople,
    }: {
      token: string;
      planName: string;
      planPeople: number;
    }) => {
      return createSubscription({
        token,
        planName,
        planPeople,
      });
    },
    {
      onError: (e: Error) => {
        handleMutationError("Error while creating subscription.", e);
      },
      onSuccess: async () => {
        accountStore.setAccountFlag("startedTrial", true);
      },
    }
  );
}
