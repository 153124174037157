import { Col, Row } from "reactstrap";
import RegistrationStepName from "components/Registration/RegistrationStepName";
import { useStores } from "store/useStore";
import LoadingCentered from "components/LoadingCentered";
import { observer } from "mobx-react";
import RegistrationStepEmail from "components/Registration/RegistrationStepEmail";
import { useCallback, useState } from "react";
import { RegistrationData } from "store/registrationStore";
import RegistrationStepPhone from "components/Registration/RegistrationStepPhone";
import RegistrationStepCompany from "components/Registration/RegistrationStepCompany";
import { LocationStore } from "store/locationStore";
import RegistrationStepGoals from "components/Registration/RegistrationStepGoals";
import RegistrationStepPassword from "components/Registration/RegistrationStepPassword";
import redirect, { redirectWithReload } from "domain/helpers/redirect";
import { RouteComponentProps } from "react-router-dom";
import { getQuery } from "domain/helpers/query";
import RegistrationStepPayment from "components/Registration/RegistrationStepPayment/RegistrationStepPayment";
import RegisterLogoutButton from "components/Registration/RegisterLogoutButton";
import AIOnboardingHeroSection from "components/AIOnboarding/AIOnboardingHeroSection";
import RegistrationStepSiteAndName from "components/Registration/RegistrationStepSiteAndName";
import { getNodeEnv } from "domain/helpers/getNodeEnv";
import RegistrationStepGenerateFlows from "components/Registration/GenerateFlows/RegistrationStepGenerateFlows";

export const useDeduceRegistrationStep = ({
  step,
  finalRedirect,
}: {
  step?: string;
  finalRedirect?: string;
} = {}) => {
  const { accountStore, locationStore, uiStore } = useStores();
  if (step) {
    return;
  }

  if (accountStore.isLoadingAccount()) {
    return;
  }

  const account = accountStore.account;

  const aiOnboardingCutOff = new Date("2023-08-30T10:00:00.000Z");

  const accountCreationDate = account?.createdAt
    ? new Date(account.createdAt)
    : new Date();

  const afterAOnboardingCutOff = accountCreationDate > aiOnboardingCutOff;

  if (!account || !account.email) {
    redirectToRegistrationStep(locationStore, "email");
    // } else if (
    //   !account.flags?.startedTrial &&
    //   !account.payment &&
    //   (account.createdAt ? new Date(account.createdAt) : new Date()) >
    //     requireTrialCutOff
    // ) {
    //   redirectToRegistrationStep(locationStore, "payment");
    // } else if (!account?.name) {
    //   redirectToRegistrationStep(locationStore, "name");
    // } else if (
    //   !account.industry &&
    //   (account.createdAt ? new Date(account.createdAt) : new Date()) >
    //     requireIndustryAndSiteCutOff
    // ) {
    //   redirectToRegistrationStep(locationStore, "company");
  } else if (!account.flags?.providedSiteAndName && afterAOnboardingCutOff) {
    redirectToRegistrationStep(locationStore, "company");
    // } else if (
    //   !account.flags?.hasSetInitialPassword &&
    //   !account.createdWithGoogleSignin
    // ) {
    //   redirectToRegistrationStep(locationStore, "password");
  } else if (!account.flags?.aiFlowsGenerated && afterAOnboardingCutOff) {
    redirectToRegistrationStep(locationStore, "generate-flows");
  } else {
    if (!uiStore.accountEdit.isLoading && finalRedirect) {
      redirectWithReload(finalRedirect);
    }
  }
};

export const redirectToRegistrationStep = (
  locationStore: LocationStore,
  stepId: string = "",
  push = false
) => {
  const targetPath = `/register/${stepId}`;
  if (window.location.pathname !== targetPath) {
    locationStore.redirect({
      path: targetPath,
      push,
      keepQuery: true,
    });
  }
};

const Registration = (props: RouteComponentProps<{ stepId: string }>) => {
  const {
    accountStore,
    registrationStore,
    locationStore,
    uiStore: { accountRegister: ui },
    uiStore,
  } = useStores();

  // Attempt to load the account
  const [hasLoadedAccount, setHasLoadedAccount] = useState(false);
  // Unlike useEffect, this will fire before the initial render
  if (!hasLoadedAccount) {
    accountStore.loadAccount();
    setHasLoadedAccount(true);
  }

  const name = accountStore.account?.teamMemberName?.split(" ")[0] || "";

  let stepId = props.match.params.stepId;
  const afterRequesterRedirect = getQuery()["after-register"];
  const redirectIfOk = afterRequesterRedirect || "/dashboard?register=1";
  useDeduceRegistrationStep({ step: stepId, finalRedirect: redirectIfOk });

  const onSubmit = useCallback(
    async (registerData?: RegistrationData) => {
      if (registerData) {
        await registrationStore.saveAccountRegistrationData(registerData);
      }
      redirectToRegistrationStep(locationStore, "", true);
    },
    [locationStore, registrationStore]
  );
  if (accountStore.isLoadingAccount()) {
    stepId = "loading";
  } else {
    const account = accountStore.account;
    if (!account || !account.email) {
      redirectToRegistrationStep(locationStore, "email");
    }
  }

  let step: React.ReactElement | null = null;
  let testimonialId = 0;
  let showLogoutButton = true;
  switch (stepId) {
    case "loading":
      step = <LoadingCentered />;
      testimonialId = 0;
      break;
    case "email":
      step = <RegistrationStepEmail submit={onSubmit} />;
      showLogoutButton = false;
      testimonialId = 0;
      break;
    case "payment":
      step = <RegistrationStepPayment submit={onSubmit} />;
      testimonialId = 2;
      break;
    case "name":
      step = (
        <RegistrationStepName
          submit={onSubmit}
          error={ui.error}
          initialName={name}
          loading={uiStore.accountEdit.isLoading}
        />
      );
      testimonialId = 1;
      break;
    case "phone":
      step = (
        <RegistrationStepPhone submit={onSubmit} error={ui.error} name={name} />
      );
      testimonialId = 2;
      break;
    case "company":
      step = (
        <RegistrationStepSiteAndName
          initialSite={accountStore.account?.site}
          initialName={accountStore.account?.teamMemberName}
          submit={onSubmit}
          error={ui.error}
          name={name}
          loading={uiStore.accountEdit.isLoading}
        />
      );
      // step = (
      //   <RegistrationStepCompany
      //     initialSite={accountStore.account?.site}
      //     submit={onSubmit}
      //     error={ui.error}
      //     name={name}
      //   />
      // );
      testimonialId = 3;
      break;

    case "generate-flows":
      return <RegistrationStepGenerateFlows />;
    case "goals":
      step = (
        <RegistrationStepGoals submit={onSubmit} error={ui.error} name={name} />
      );
      testimonialId = 4;
      break;
    case "password":
      step = (
        <RegistrationStepPassword
          submit={onSubmit}
          error={ui.error}
          name={name}
        />
      );
      testimonialId = 5;
      break;
  }

  return (
    <div className="app flex-row align-items-center">
      <div className="container-fluid px-0 full-height-block-wrapper">
        <span
          // href="https://encharge.io"
          className="my-3 px-5 d-block position-absolute register-logo"
        >
          <img
            src="https://encharge.io/wp-content/uploads/2019/07/logo-dark.svg"
            alt="Encharge logo"
          />
        </span>
        <Row className="full-height-block flex-row justify-content-center m-0 p-0">
          <Col
            xs="24"
            sm="24"
            md="24"
            lg="12"
            className="justify-content-center d-flex flex-column registration"
          >
            <div className="max-width-500 px-5 pt-6 pb-4">{step}</div>
          </Col>
          <AIOnboardingHeroSection halfPage />
          {showLogoutButton && <RegisterLogoutButton />}
        </Row>
      </div>
    </div>
  );
};

export default observer(Registration);
