import { PaperWithZoom } from "./graph";

let paper: PaperWithZoom | undefined;
const setPaper = (paperLocal: PaperWithZoom) => {
  paper = paperLocal;
  // for debugging
  /// @ts-ignore
  window.paper = paper;
};

const clearPaper = () => {
  paper = undefined;
};

const getPaper = () => {
  return paper;
};

let graph: joint.dia.Graph | undefined;
const setGraph = (graphLocal: joint.dia.Graph) => {
  graph = graphLocal;
  // for debugging
  /// @ts-ignore
  window.graph = graph;
};

const clearGraph = () => {
  graph = undefined;
};

const getGraph = () => {
  return graph;
};

export { setPaper, getPaper, setGraph, getGraph, clearPaper, clearGraph };
