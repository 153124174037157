import { ReactComponent as SettingsIcon } from "../../assets/img/nav/settings.svg";
import { ReactComponent as SettingsIconActive } from "../../assets/img/nav/settings-active.svg";
import { ReactComponent as AppsIcon } from "../../assets/img/nav/apps.svg";
import { ReactComponent as AppsIconActive } from "../../assets/img/nav/apps-active.svg";
import { ReactComponent as HelpIcon } from "../../assets/img/nav/help.svg";
import { ReactComponent as LiveChatIcon } from "../../assets/img/nav/live-chat.svg";
import { ReactComponent as NavigationIcon } from "../../assets/img/nav/navigation.svg";
import { ReactComponent as DashboardIcon } from "../../assets/img/nav/dashboard.svg";
import { ReactComponent as BroadcastsIcon } from "../../assets/img/nav/broadcasts.svg";
import { ReactComponent as EmailsIcon } from "../../assets/img/nav/emails.svg";
import { ReactComponent as FlowsIcon } from "../../assets/img/nav/flows.svg";
import { ReactComponent as PeopleIcon } from "../../assets/img/nav/people.svg";
import { ReactComponent as CompaniesIcon } from "../../assets/img/nav/companies.svg";
import { ReactComponent as MetricsIcon } from "../../assets/img/nav/metrics.svg";
import { ReactComponent as TagsIcon } from "../../assets/img/nav/tags-outline.svg";
import { ReactComponent as CustomFieldsIcon } from "../../assets/img/nav/custom-fields-outline.svg";
import { ReactComponent as FormIcon } from "../../assets/img/nav/form-tracking.svg";
import { ReactComponent as EventManagementIcon } from "assets/img/nav/event-management.svg";

interface Props {
  type:
    | "Settings"
    | "Live chat"
    | "Help"
    | "Apps"
    | "Navigation"
    | "Dashboard"
    | "Flows"
    | "People"
    | "Companies"
    | "Broadcasts"
    | "Emails"
    | "Email Metrics"
    | "Flow Metrics"
    | "Tags"
    | "Forms"
    | "Events"
    | "Custom Fields";
  active?: boolean;
}

/**
 * Render incons used in app nav
 */
const AppNavIcon = (props: Props) => {
  switch (props.type) {
    case "Settings":
      return props.active ? <SettingsIconActive /> : <SettingsIcon />;
    case "Apps":
      return props.active ? <AppsIconActive /> : <AppsIcon />;
    case "Help":
      return <HelpIcon />;
    case "Navigation":
      return <NavigationIcon />;
    case "Live chat":
      return <LiveChatIcon />;
    case "Dashboard":
      return <DashboardIcon />;
    case "Broadcasts":
      return <BroadcastsIcon />;
    case "Emails":
      return <EmailsIcon />;
    case "Flows":
      return <FlowsIcon />;
    case "People":
      return <PeopleIcon />;
    case "Companies":
      return <CompaniesIcon />;
    case "Flow Metrics":
    case "Email Metrics":
      return <MetricsIcon />;
    case "Tags":
      return <TagsIcon />;
    case "Custom Fields":
      return <CustomFieldsIcon />;
    case "Forms":
      return <FormIcon />;
    case "Events":
      return <EventManagementIcon />;
    default:
      return null;
  }
};
export default AppNavIcon;
