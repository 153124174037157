export const integrationStateActive = "on";
export const integrationStateDeactivatedByUser = "deactivated-user";
export const integrationStateDeactivatedGeneric = "deactivated";
export const integrationStateDeactivatedExpiredAuth =
  "deactivated-expired-auth";

export const authReasonNoCredentials = "no_credentials";
export const authReasonExpired = "expired";
export const authReasonWrongCredentials = "wrong_credentials";

export const emptyServiceCredentials = "empty_credentials";
export const invalidServiceCredentials = "invalid_credentials";

export const masterAccountID = 1;

export const testEmailID = -1;

export const transactionalAdHocEmailID = -12345;

export const peoplePageSize = 100;

export const screenshotsURL = "https://screenshots.encharge.io";

export const emailValidationAPIURL =
  process.env.EMAIL_VALIDATION_API_URL ||
  "https://email-validation.encharge.io";
export const emailValidationAPIKey = process.env.EMAIL_VALIDATION_API_KEY;

export const appURL =
  process.env.APP_URL ||
  // This needs to be in brackets or it will default to staging-app for some reason
  // eslint-disable-next-line no-nested-ternary
  (process.env.NODE_ENV === "staging"
    ? "https://staging-app.encharge.io"
    : process.env.NODE_ENV === "development"
    ? "https://development-app.encharge.io"
    : "https://app.encharge.io");

export const apiURL = process.env.URL_BASE || "https://api.encharge.io";

export const defaultUnsubscribeSuccessURL = `${appURL}/unsubscribed.html`;

export const maxEmailSpamBlockRetries = 2;

export const maxTestEmails = 5;

export const replyDomain = "reply.encharge-mail.com";

export const enchargeSiteTrackingSlug = "encharge-js";

export const requireMailingAddressInEmailsFromAccountId = 11043;

export const requireSpamAssassinCheckFromEmailId = 6750;

export const ourEnchargeAccountId =
  process.env.NODE_ENV === "production" ? 88 : 2;

export const maxPersonFieldLength = 5000;

// How many people can we export at once?
export const maxExportPeopleChunkSize = 50000;

export const nativeFormMarketingConsentField = "nativeFormMarketingConsent";

export const integrationGoalMetricCacheKeyPrefix = `integrationGoalMetric`;

export const defaultControlVariantId = "00000000-0000-0000-0000-000000000000";
