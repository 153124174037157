import AppNav from "./AppNav";
import OnboardingBadge from "../Onboarding/OnboardingBadge/OnboardingBadge";
import AppNavUpgrade from "./AppNavUpgrade";
import AccountLogo from "./Account/AccountLogo";
import AppNavSettings from "./AppNavSettings";
import AppNavProfile from "./AppNavProfile";
import AppNavHelp from "./AppNavHelp";
import AccountWarnings from "./AccountWarnings";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import TopBarReadOnlyFlow from "../CopyFlow/TopBarReadOnlyFlow";

/**
 * Bar shown on top of app.
 */
const TopBar = () => {
  const { accountStore, permissionsStore } = useStores();

  if (permissionsStore.readOnlyFlow) {
    return <TopBarReadOnlyFlow />;
  }

  return (
    <div className="main-bar d-flex main-bar-nav">
      {/* Dont show account on small screens */}
      <div className="d-none d-lg-flex">
        <AccountLogo />
      </div>
      <AppNav />
      {/* Fill up space between items before and after */}
      <div className="flex-grow-1" />
      <OnboardingBadge />
      <AppNavUpgrade />
      <AccountWarnings />
      <AppNavSettings />
      <AppNavHelp />
      <AppNavProfile />
    </div>
  );
};

export default observer(TopBar);
