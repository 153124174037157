import { useCallback, useState } from "react";
import { Alert, Input, Label } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";
import { RegistrationData } from "store/registrationStore";

interface Props {
  submit: (formData: RegistrationData) => void;
  loading?: boolean;
  error?: string;

  name: string;
}
const RegistrationStepGoals = (props: Props) => {
  const [formData, setFormData] = useState<RegistrationData>({
    goals: "",
    previousTool: "",
  });

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const heading = `Almost there! Tell us about your goals.`;
  const subheading = "Let’s grow your business with Encharge.";
  const subsubheading = "";

  return (
    <RegistrationStepFormCommon
      heading={heading}
      subheading={subheading}
      subsubheading={subsubheading}
      buttonText="To Final Step"
      onSubmit={onSubmit}
      loading={props.loading}
      error={props.error}
      disabled={!Boolean(formData.goals)}
    >
      <Label className="mt-3 col-form-label-lg" for="goals">
        What's the #1 goal that you'd like to achieve with Encharge?
      </Label>
      <Input
        value={formData.goals}
        type="textarea"
        placeholder=""
        autoComplete="goals"
        name="goals"
        className="form-control-lg mb-3 mt-2"
        required={true}
        onChange={handleFieldChange}
        id="goals"
        data-testid="goal"
      />
      <Label for="previousTool" className="mt-3 col-form-label-lg">
        Are you coming from another email/marketing automation tool? If so,
        which one? <div className="text-muted small">Optional</div>
      </Label>
      <Input
        value={formData.previousTool}
        type="text"
        placeholder="e.g. Mailchimp"
        autoComplete="previousTool"
        name="previousTool"
        className="form-control-lg mb-3 mt-2"
        onChange={handleFieldChange}
        id="previousTool"
        data-testid="previousTool"
      />
    </RegistrationStepFormCommon>
  );
};

export default observer(RegistrationStepGoals);
