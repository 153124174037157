import { useStores } from "../../../../../store/useStore";
import { observer } from "mobx-react";

interface Props {
  flowId: string | number | undefined | null;
  prependText?: string;
  appendText?: string;
  nullText?: string;
}
const FlowLink = observer((props: Props) => {
  const { flowId } = props;
  const { flowsStore } = useStores();
  const noFlowResult = props.nullText ? <>{props.nullText}</> : null;
  if (!flowId) {
    return noFlowResult;
  }
  const { name: flowName = "" } = flowsStore.getFlowById(Number(flowId)) || {};
  if (!flowName) {
    return noFlowResult;
  }
  return (
    <>
      {props.prependText && <>{props.prependText} </>}
      <a href={`/flows/${flowId}`}>{flowName}</a>
      {props.appendText && <> {props.appendText}</>}
    </>
  );
});

export default FlowLink;
