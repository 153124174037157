import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { IFieldsSchema } from "encharge-domain/definitions/JSONSchema6";

export const getServices = async () => {
  try {
    const res: AxiosResponse<{ services: IService[] }> = await request({
      url: `${apiBase}/v1/services`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.services;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve services. ";
    throw new Error(msg);
  }
};

export const saveServiceOutputFieldsMapping = async (
  serviceId: string,
  mapping: IFieldsSchema
) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/services/${serviceId}/output-fields`,
      method: "POST",
      data: {
        mapping,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save the fields mapping. ";
    throw new Error(msg);
  }
};

export const updateServiceConfig = async (
  serviceId: IService["name"],
  configName: IServiceConfigName,
  configValue: any
) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/services/${serviceId}/${configName}`,
      method: "POST",
      data: {
        config: configValue,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save service config. ";
    throw new Error(msg);
  }
};
export const refreshServiceResponseSchema = async (
  serviceId: IService["name"]
) => {
  try {
    const res: AxiosResponse<{ schema: IFieldsSchema }> = await request({
      url: `${apiBase}/v1/services/${serviceId}/refreshServiceResponseSchema`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data?.schema;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrive service fields. ";
    throw new Error(msg);
  }
};
