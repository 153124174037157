import AIWriterGeneratedContent from "components/AIWriter/AIWriterGeneratedContent";
import LoadingCentered from "components/LoadingCentered";
import _ from "lodash";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";
import { ReactComponent as NoResultsImage } from "../../assets/img/no-results.svg";

const AIWriterFavorites = () => {
  const { aiWriterStore } = useStores();
  const suggestions = aiWriterStore.favoritedSuggestions.current();
  if (aiWriterStore.favoritedSuggestions.isLoading()) {
    return <LoadingCentered />;
  }
  if (!suggestions.length) {
    return (
      <>
        <div className="no-results-placeholder mb-5">
          <NoResultsImage />
          <div className="text-muted">Nothing saved, yet.</div>
        </div>
      </>
    );
  }
  return (
    <AIWriterGeneratedContent
      suggestions={suggestions}
      hideButtons={["more", "favorite"]}
    />
  );
};
export default observer(AIWriterFavorites);
