// Dynamic Form Width

/* eslint-disable */
$.fn.textWidth = function (text, font) {
  if (!$.fn.textWidth.fakeEl)
    $.fn.textWidth.fakeEl = $("<span>").hide().appendTo(document.body);

  $.fn.textWidth.fakeEl
    .text(text || this.val() || this.text() || this.attr("placeholder"))
    .css("font", font || this.css("font"));

  const width = $.fn.textWidth.fakeEl.width();
  $.fn.textWidth.fakeEl.text("");
  return width;
};

$.fn.textWidthHeight = function (text, font) {
  if (!$.fn.textWidthHeight.fakeEl)
    $.fn.textWidthHeight.fakeEl = $("<span>").hide().appendTo(document.body);

  let textContent =
    text !== undefined
      ? text
      : this.val() || this.text() || this.attr("placeholder");
  // if last character is a new line, add another new line
  // to have the correct height
  if (textContent && textContent[textContent.length - 1] === "\n") {
    textContent += "\n";
  }

  $.fn.textWidthHeight.fakeEl
    .text(textContent)
    .css("font", font || this.css("font"))
    .css("white-space", "pre-line");

  const width = $.fn.textWidthHeight.fakeEl.width();
  const height = $.fn.textWidthHeight.fakeEl.height();
  $.fn.textWidthHeight.fakeEl.text("");
  return { width, height };
};
