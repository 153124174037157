import { useCallback, useState } from "react";
import { Alert, Input, Label } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";
import { RegistrationData } from "store/registrationStore";

interface Props {
  submit: (formData: any) => void;
  loading?: boolean;
  error?: string;

  initialSite?: string;
  initialName?: string;

  name: string;
}
const RegistrationStepSiteAndName = (props: Props) => {
  const [formData, setFormData] = useState<RegistrationData>({
    site: props.initialSite || "",
    name: props.initialName || "",
  });
  const [inSiteInput, setInSiteInput] = useState(false);

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const isSiteValid = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,25}(:[0-9]{1,5})?(\/.*)?$/.test(
    formData.site || ""
  );

  const heading = `Almost done!`;
  const subheading =
    "Encharge will analyze your site and create bespoke email flows to help you convert and onboard more customers.";
  const subsubheading = "";

  return (
    <RegistrationStepFormCommon
      heading={heading}
      subheading={subheading}
      subsubheading={subsubheading}
      onSubmit={onSubmit}
      loading={props.loading}
      error={props.error}
      disabled={!Boolean(formData.site && isSiteValid && formData.name)}
      buttonText="Finish Signup and Get My Emails"
    >
      <Label className="mt-2 col-form-label-lg" for="site">
        First Name
      </Label>
      <Input
        value={formData.name}
        type="text"
        placeholder="John"
        autoComplete="name"
        name="name"
        className="form-control-lg mb-3 mt-1"
        required={true}
        onChange={handleFieldChange}
        id="name"
        data-testid="name"
      />
      <Label className="mt-2 col-form-label-lg" for="site">
        Company Site
      </Label>
      <Input
        value={formData.site}
        type="text"
        placeholder="https://encharge.io"
        autoComplete="site"
        name="site"
        className="form-control-lg mb-3 mt-1"
        required={true}
        onChange={handleFieldChange}
        id="site"
        data-testid="site"
        onFocus={() => setInSiteInput(true)}
        onBlur={() => setInSiteInput(false)}
      />
      {formData.site && !isSiteValid && !inSiteInput && (
        <Alert color="warning">Double-check your site.</Alert>
      )}
    </RegistrationStepFormCommon>
  );
};

export default observer(RegistrationStepSiteAndName);
