import { Alert, Button, Col, Row } from "reactstrap";
import { ReactComponent as EnchargeLogoLetter } from "../../../assets/img/brand/encharge-logo-letter.svg";
import _ from "lodash";
import GenerateFlowsCompanyInfo from "components/Registration/GenerateFlows/GenerateFlowsCompanyInfo";
import GenerateFlowsCarousel from "components/Registration/GenerateFlows/GenerateFlowsCarousel";
import GenerateFlowsLoadingBar from "components/Registration/GenerateFlows/GenerateFlowsLoadingBar";
import GenerateFlowsGeneratedEmail from "components/Registration/GenerateFlows/GenerateFlowsGeneratedEmail";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { animated, useSpring } from "react-spring";
import GenerateFlowsNonSaasWarning from "components/Registration/GenerateFlows/GenerateFlowsNonSaasWarning";
import { GeneratedCompanyInfo } from "components/Registration/GenerateFlows/RegistrationStepGenerateFlows";

interface Props {
  title: string;
  subtitle: string;
  site: string;
  isSaaS?: boolean;
  isB2B?: boolean;

  companyInfo?: GeneratedCompanyInfo;
  isGeneratingCompanyInfo?: boolean;
  isGeneratingEmailIndex?: number;
  isCurrentStepCompleted?: boolean;
  isDone?: boolean;
  error?: string;

  totalEmails: number;
  emails: {
    subject: string;
    text: string;
  }[];
  emailsFromName: string;
  emailsFromEmail: string;

  onSkip: (path?: string) => void;
}

const RegistrationStepGenerateFlowsView = (props: Props) => {
  const skipFunction = props.onSkip;
  const onSkip = useCallback(() => {
    skipFunction();
  }, [skipFunction]);
  const onCompleted = useCallback(() => {
    skipFunction("/get-started?register=1");
  }, [skipFunction]);
  const isSaasLabel =
    props.isSaaS === undefined ? "..." : props.isSaaS ? "SaaS" : "Not SaaS";
  const businessModelLabel =
    props.isB2B === undefined ? "..." : props.isB2B ? "B2B" : "B2C";

  const animatedAppear = useSpring({ opacity: 1, from: { opacity: 0 } });
  const proceedButton = (
    <Button
      color="primary"
      className="px-5"
      onClick={props.error ? onSkip : onCompleted}
    >
      {props.error ? "Proceed to app" : "Open account and see emails"}
    </Button>
  );
  const skipWaitingMessage = (
    <div className="mt-3">
      <div className="generate-flows-skip-waiting-main" onClick={onSkip}>
        Skip waiting and go to my account
      </div>
      <div className="generate-flows-skip-waiting-secondary">
        Emails will still be generated for you
      </div>
    </div>
  );
  const footer = (
    <div className="generate-flows-footer">
      {props.isDone ? proceedButton : skipWaitingMessage}
    </div>
  );
  return (
    <div className="app flex-row align-items-center">
      <div className="container-fluid px-0 full-height-block-wrapper">
        <GenerateFlowsNonSaasWarning
          isOpen={props.isSaaS === false}
          onConfirm={props.onSkip}
        />

        {/* <a
          href="https://app.encharge.io"
          className="my-3 px-5 d-block position-absolute register-logo"
        >
          <img
            src="https://encharge.io/wp-content/uploads/2019/07/logo-dark.svg"
            alt="Encharge Logo"
          />
        </a> */}
        <span className="generate-flows-logo my-4 mx-5 px-5 d-block position-absolute register-logo">
          <EnchargeLogoLetter />
        </span>
        <div className="generate-flows-container full-height-block">
          <Row className="flex-row justify-content-center m-0 mt-4 p-5">
            <Col xs="24" sm="24" md="24" lg="12">
              <div className="generate-flows-title-container">
                <div className="generate-flows-title mb-3">{props.title}</div>
                <div className="generate-flows-subtitle">{props.subtitle}</div>
                <div className="mt-4 mb-3 text-center">
                  {props.error && (
                    <Alert className="mt-3" color="danger">
                      {props.error}
                    </Alert>
                  )}
                  {!props.isDone ? (
                    <>
                      <GenerateFlowsLoadingBar
                        progress={
                          (props.emails?.length || 0 + 1) /
                          (props.totalEmails + 1)
                        }
                      />
                      {skipWaitingMessage}
                    </>
                  ) : (
                    proceedButton
                  )}
                </div>
              </div>
              <div className="generate-flows-generating-email-container mt-5">
                {props.isCurrentStepCompleted && (
                  <animated.div
                    className="generate-flows-generating-email-current-completed"
                    style={animatedAppear}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="61"
                      height="61"
                      viewBox="0 0 61 61"
                      fill="none"
                    >
                      <path
                        d="M30.5002 0.083374C13.695 0.083374 0.0834961 13.6948 0.0834961 30.5C0.0834961 47.29 13.695 60.9167 30.5002 60.9167C47.2902 60.9167 60.9168 47.29 60.9168 30.5C60.9168 13.6948 47.2902 0.083374 30.5002 0.083374ZM24.4168 45.7084L9.2085 30.5L13.5125 26.1961L24.4168 37.1005L47.4879 14.0294L51.7918 18.3334L24.4168 45.7084Z"
                        fill="#77D152"
                      />
                    </svg>
                  </animated.div>
                )}
                {props.isGeneratingCompanyInfo && (
                  <GenerateFlowsCompanyInfo
                    businessModelLabel={businessModelLabel}
                    isSaaSLabel={isSaasLabel}
                    site={props.site}
                    companyInfo={props.companyInfo}
                  />
                )}
                {props.isGeneratingEmailIndex !== undefined &&
                  !props.isDone && (
                    <GenerateFlowsGeneratedEmail
                      fromEmail={props.emailsFromEmail}
                      fromName={props.emailsFromName}
                      subject={
                        props.emails?.[props.isGeneratingEmailIndex]?.subject
                      }
                      text={props.emails?.[props.isGeneratingEmailIndex]?.text}
                    />
                  )}
              </div>
            </Col>
          </Row>
          <Row className="flex-row justify-content-center m-0 mt-4 p-0 position-relative flex-grow-1">
            <Col xs="24" sm="24" md="24" lg="24">
              <GenerateFlowsCarousel
                isGeneratingEmailIndex={props.isGeneratingEmailIndex}
                isGeneratingCompanyInfo={props.isGeneratingCompanyInfo}
                isDone={props.isDone}
                totalEmails={props.totalEmails}
                emails={props.emails}
                fromEmail={props.emailsFromEmail}
                fromName={props.emailsFromName}
                companyInfoPreview={
                  !props.isGeneratingCompanyInfo ? (
                    <GenerateFlowsCompanyInfo
                      businessModelLabel={businessModelLabel}
                      isSaaSLabel={isSaasLabel}
                      site={props.site}
                      companyInfo={props.companyInfo}
                      tinyMode={true}
                    />
                  ) : null
                }
                // footer={footer}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default RegistrationStepGenerateFlowsView;
