export const shouldSkipResouces = () => {
  // dont load resources on these pages
  return (
    window.location.pathname === "/login" ||
    window.location.pathname === "/error" ||
    window.location.pathname.includes("/view-form") ||
    window.location.pathname === "/request-reset-password" ||
    window.location.pathname === "/reset-password" ||
    window.location.pathname.startsWith("/register") ||
    window.location.pathname.startsWith("/public/")
  );
};
