import { Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { observer } from "mobx-react";
import { useCallback, useEffect, useState } from "react";
import ButtonLoader from "components/ButtonLoader";
import { loadChargebee } from "components/Billing/loadChargebee";
import { PaymentPlan } from "encharge-domain/definitions/IAccount";

interface Props {
  premiumPlan: PaymentPlan | undefined;
  upgradeToPremium: () => void;
  onClose: () => void;
  hasPaymentDetails: boolean;
  isOpen: boolean;
  isLoading?: boolean;
}

/**
 * Upgrade to premium button
 */
const UpgradeToPremiumDialogView = (props: Props) => {
  const onKeyDownCallback = useCallback((event: any) => {
    // prevent escape key closing other modals/drawers/etc.
    if ((event as KeyboardEvent)?.key?.toLowerCase?.() === "escape") {
      event.stopPropagation();
    }
  }, []);

  const [chargebeeLoaded, setChargebeeLoaded] = useState(false);
  useEffect(() => {
    if (!props.hasPaymentDetails) {
      // allow us to checkout
      const onload = () => setChargebeeLoaded(true);
      loadChargebee(onload);
    }
  });

  const premiumPlan = props.premiumPlan;

  let monthlyPrice = 0;
  if (premiumPlan) {
    monthlyPrice =
      premiumPlan?.period === "month"
        ? premiumPlan.price
        : Math.ceil(premiumPlan?.price / 12);
  }

  return (
    <Modal
      className="upgrade-dialog"
      isOpen={props.isOpen}
      toggle={props.onClose}
      zIndex={2000}
      size={"md"}
      autoFocus={true}
      onKeyDown={onKeyDownCallback}
    >
      <img
        className="upgrade-dialog-header-image"
        src={`${process.env.PUBLIC_URL}/assets/img/upgradeToPremium.png`}
        alt="Upgrade to Premium to unlock all features"
      />
      <ModalHeader toggle={props.onClose}></ModalHeader>
      <ModalBody>
        {!premiumPlan ? (
          <Alert color="danger">
            No premium plan found. Please reach out to{" "}
            <a href="mailto:support@encharge.io">support</a> and we'll get you
            sorted out.
          </Alert>
        ) : (
          <div className="container">
            <div>
              <div className="text-center mt-3">
                <h4>
                  Upgrade to <b>Encharge Premium</b> for ${monthlyPrice}
                  <span className="text-muted small">
                    /month
                    {premiumPlan.period === "year" ? " (billed annually)" : ""}
                  </span>
                </h4>
              </div>
              <div className="upgrade-features mt-4 d-flex justify-content-center">
                <div>
                  <div>
                    <ul>
                      <li>
                        <span>Unlimited flows</span>
                      </li>
                      <li>
                        <span>Unlimited team members</span>
                      </li>
                      <li>
                        <span>Monthly email sends: 12x subscribers limit</span>
                      </li>
                      <li>
                        <span>Live chat support, Slack support</span>
                      </li>
                    </ul>
                  </div>
                  <div className="mt-2 mb-3">
                    <div className="font-weight-medium ml-3 mb-2">
                      All in Growth, plus:
                    </div>
                    <ul>
                      <li>
                        <span>HubSpot integration</span>
                      </li>
                      <li>
                        <span>Salesforce integration</span>
                      </li>
                      <li>
                        <span>Segment.com integration</span>
                      </li>
                      <li>
                        <span>Transactional emails</span>
                      </li>
                      <li>
                        <span>Event-based segmentation</span>
                      </li>
                      <li>
                        <span>Custom objects</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-5 mb-4 text-center">
          {!props.hasPaymentDetails && !chargebeeLoaded ? null : (
            <ButtonLoader
              loading={props.isLoading}
              color="primary"
              onClick={props.upgradeToPremium}
            >
              Upgrade your marketing game for just ${monthlyPrice}/month
            </ButtonLoader>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default observer(UpgradeToPremiumDialogView);
