import { observable } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError } from "../domain/errorHandling/toaster";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import { getRecipes } from "./persistence/persistRecipes";

export class RecipeStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  recipes = lazyObservable<IRecipe[]>((sink, onError) => {
    getRecipes()
      .then((recipes) => {
        sink(observable(recipes));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading templates.",
          extra: e,
        });
        onError(e);
      });
  });
}
