// needs to be before all
import "./domain/helpers/debug/whyDidYouRender";

import * as ReactDOM from "react-dom";
import { Provider as MobxProvider } from "mobx-react";

import { configure as MobxConfigure } from "mobx";
MobxConfigure({
  enforceActions: "observed",
});

/* eslint import/first: 0 */

import "./polyfills/polyfill";
// SCSS generated styles (bootstrap + encharge custom)
import "./scss/style.css";

// Person fields icons
import "./assets/css/field-icons.css";
// JointJS css
import "jointjs/css/layout.css";

import "./domain/errorHandling/windowErrorHandling";

/// @ts-ignore
window.$ = window.jQuery = require("jquery");

// Axios defaults muse be defined before any requests
import "./domain/helpers/setAxiosDefaults";

import App from "./App";
// disable ServiceWorker
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();

import { domainStore, DomainStore } from "./store/domainStore";
import { StoreContext } from "./store/useStore";

require("./domain/helpers/jqueryTextDimensions");

const render = (Component: any, domainStore: DomainStore) => {
  ReactDOM.render(
    <StoreContext.Provider value={domainStore}>
      <MobxProvider {...domainStore}>
        <Component />
      </MobxProvider>
    </StoreContext.Provider>,
    document.getElementById("root") as HTMLElement
  );
};
render(App, domainStore);

declare const module: any;
function registerHMR() {
  if (module.hot) {
    module.hot.accept(["./store/domainStore"], () => {
      console.log("Store reloaded 🤘");
      // Store definition changed, recreate a new one from old state
      const NextApp = require("./App").default;
      const domainStore = require("./store/domainStore").domainStore;
      render(NextApp, domainStore);
    });
    module.hot.accept("./App", () => {
      console.log("App reloaded 🤘");

      const NextApp = require("./App").default;
      render(NextApp, domainStore);
    });
  }
}
registerHMR();
