import { getNodeEnv } from "domain/helpers/getNodeEnv";

if (getNodeEnv() === "development") {
  // const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });
}

export {};
