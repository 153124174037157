import AIWriter from "components/AIWriter/AIWriter";
import AIWriterTabsNav from "components/AIWriter/AIWriterTabsNav";
import { IConfigureDrawerSize } from "components/ConfigureStep/ConfigureDrawer";
import ConfigureStepTabNav from "components/ConfigureStep/ConfigureStepTabs/ConfigureStepTabNav";
import Drawer from "components/Drawer";
import getDrawerSizeClasses from "domain/helpers/getDrawerSizeClasses";
import { observer } from "mobx-react";
import { useCallback } from "react";
import { useStores } from "store/useStore";

interface Props {
  size?: IConfigureDrawerSize;
}

const AIWriterDrawer = (props: Props) => {
  const { uiStore, accountStore } = useStores();
  const close = useCallback(() => {
    uiStore.aiWriter.close();
  }, [uiStore]);
  // if (!accountStore.account?.flags?.canAccessAIWriter) {
  //   return null;
  // }
  return (
    <Drawer
      open={uiStore.aiWriter.isOpen}
      onClose={close}
      className={getDrawerSizeClasses(props.size || "xl")}
      zIndex={1310}
    >
      <button
        type="button"
        className="btn btn-no-focus position-absolute btn-step-nav-tab-pane-close"
        aria-label="Close"
        onClick={close}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <AIWriterTabsNav />
    </Drawer>
  );
};
export default observer(AIWriterDrawer);
