import { IStepTool } from "../FlowEditor/Toolbox/IStepTool";
import { domainStore } from "../../store/domainStore";
import _ from "lodash";
import toolIcons from "./toolIcons";

import ingestedEventIcon from "../../assets/img/ingested-event.svg";
import siteTrackingEventIcon from "../../assets/img/site-tracking-event.svg";
import zapierIcon from "../../assets/img/zapier.svg";
import { enchargeSiteTrackingSlug } from "encharge-domain/helpers/constants";

const currentTools: Overwrite<IStepTool, { service?: IService }>[] = [
  // TRIGGERS SECTION
  {
    display: {
      label: "Time Trigger",
      operationIcon: toolIcons["./delay-dark.svg"],
      operationIconToolbox: toolIcons["./delay-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/time",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601804e86867724dfc6ee0ff",
      markdown: `The __Time Trigger__ allows you to start a flow on a specific time.

On that time, people who are in the selected segment will proceed to the next steps.

For example, you can use it to send an email to your subscribers on Monday morning at 10 AM. 

***
*Notes:*

- *People can pass through a __Time Trigger__ only once. If you want to change the date of a __Time Trigger__ that has already run, it's recommended to create a new __Time Trigger__.*

- *The selected time can not be in the past.*
`,
    },
  },
  {
    display: {
      label: "Recurring Time Trigger",
      operationIcon: toolIcons["./delay-dark.svg"],
      operationIconToolbox: toolIcons["./delay-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/recurring-time-trigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "642ed0241b7ba105b02ef7b7",
    },
  },
  {
    display: {
      label: "Entered Segment",
      operationIcon: toolIcons["./segment-dark.svg"],
      operationIconToolbox: toolIcons["./segment-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/entered-segment",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "60180b7f6867724dfc6ee143",
    },
  },
  {
    display: {
      label: "Left Segment",
      operationIcon: toolIcons["./segment-dark.svg"],
      operationIconToolbox: toolIcons["./segment-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/left-segment",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "60180e96a4cefb30ae5c5e8c",
    },
  },
  {
    display: {
      label: "Email Activity",
      operationIcon: toolIcons["./email-aux-dark.svg"],
      operationIconToolbox: toolIcons["./email-aux-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/email-trigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6018103c1f25b9041beb9f5a",
    },
    isHidden: true,
  },
  {
    display: {
      label: "Email Activity",
      operationIcon: toolIcons["./email-aux-dark.svg"],
      operationIconToolbox: toolIcons["./email-aux-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/email-trigger-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6018103c1f25b9041beb9f5a",
    },
  },
  {
    display: {
      label: "Tag Added",
      operationIcon: toolIcons["./tag-dark.svg"],
      operationIconToolbox: toolIcons["./tag-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/added-tag",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6018288ea4cefb30ae5c5f9e",
    },
  },
  {
    display: {
      label: "Tag Removed",
      operationIcon: toolIcons["./tag-dark.svg"],
      operationIconToolbox: toolIcons["./tag-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/removed-tag",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "601901406867724dfc6ee67f",
    },
  },
  {
    display: {
      label: "Field Changed",
      operationIcon: toolIcons["./change-field-dark.svg"],
      operationIconToolbox: toolIcons["./change-field-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/change-field-trigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "65084f57790a583e6b3b3baf",
    },
  },
  {
    display: {
      label: "Event",
      operationIcon: toolIcons["./event-dark.svg"],
      operationIconToolbox: toolIcons["./event-light.svg"],
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "encharge",
    operationKey: "/event",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6019027cac2f834ec5384299",
    },
  },
  {
    display: {
      label: "Segment.com Event",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/segment-logo-dark.svg`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/segment-logo-light.svg`,
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "segment",
    operationKey: "/event",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "601911192042ff6d1b2a98d1",
    },
  },
  {
    display: {
      label: "Date Field Trigger",
      operationIcon: toolIcons["./date-field-trigger.svg"],
      operationIconToolbox: toolIcons["./date-field-trigger-toolbox.svg"],
    },
    serviceId: "encharge",
    operationKey: "/dateFieldTrigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6131ef8012c07c18afdd0b5e",
    },
  },
  {
    display: {
      label: "Birthday/Annual Trigger",
      operationIcon: toolIcons["./date-field-trigger.svg"],
      operationIconToolbox: toolIcons["./date-field-trigger-toolbox.svg"],
    },
    serviceId: "encharge",
    operationKey: "/annualTrigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "613226c76c65aa15b87d7054",
    },
  },
  {
    display: {
      label: "Receive Webhook",
      operationIcon: toolIcons["./webhook-dark.svg"],
      operationIconToolbox: toolIcons["./webhook-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/webhook-trigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "60191718ac2f834ec53842f4",
    },
  },
  {
    display: {
      label: "Encharge Form Submitted",
      operationIcon: toolIcons["./submitted-form-dark.svg"],
      operationIconToolbox: toolIcons["./submitted-form-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/native-form-submitted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6401bd6b85990362ed9afbe1",
    },
  },
  {
    display: {
      label: "Tracked Form Submitted",
      operationIcon: toolIcons["./submitted-form-dark.svg"],
      operationIconToolbox: toolIcons["./submitted-form-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/site-form-submitted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "611df581f886c9486f8da4b7",
    },
  },
  {
    display: {
      label: "External Form Submitted",
      operationIcon: toolIcons["./submitted-form-dark.svg"],
      operationIconToolbox: toolIcons["./submitted-form-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/submitted-form",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "601923256867724dfc6ee732",
    },
  },
  {
    display: {
      label: "Row Added in Google Sheets",
      operationIcon: toolIcons["./google-sheets.svg"],
      operationIconToolbox: toolIcons["./google-sheets-toolbox.svg"],
    },
    serviceId: "googlesheets",
    operationKey: "/sheets/row-added",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "66f24480c263c66781cab012",
    },
  },
  {
    display: {
      label: "Facebook Lead Ad Submitted",
      operationIcon: toolIcons["./facebook-dark.svg"],
      operationIconToolbox: toolIcons["./facebook-light.svg"],
    },
    serviceId: "facebook",
    operationKey: "/lead-forms/leads",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "671657299901d46da1e05509",
    },
  },
  {
    display: {
      label: "Typeform Submitted",
      operationIcon: toolIcons["./typeform-dark.svg"],
      operationIconToolbox: toolIcons["./typeform-light.svg"],
    },
    serviceId: "typeform",
    operationKey: "/entries",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "601935331f25b9041beba5c5",
    },
  },

  {
    display: {
      label: "Page Visited",
      operationIcon: toolIcons["./page-visited.svg"],
      operationIconToolbox: toolIcons["./page-visited-toolbox.svg"],
    },
    serviceId: "encharge",
    operationKey: "/page-visited-trigger",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "60195f9bac2f834ec53844fb",
    },
  },
  {
    display: {
      label: "New Mailchimp Subscriber",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
    },
    serviceId: "mailchimp",
    operationKey: "/subscribers",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "601a5b681f25b9041bebada7",
    },
  },
  {
    display: {
      label: "New Mailchimp Unsubscriber",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
    },
    serviceId: "mailchimp",
    operationKey: "/unsubscribers",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "601a9e0dac2f834ec5384cfe",
    },
  },
  {
    display: {
      label: "New Intercom Lead",
      operationIcon: toolIcons["./intercom.svg"],
      operationIconToolbox: toolIcons["./intercom-toolbox.svg"],
    },
    serviceId: "intercom",
    operationKey: "/leads",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601aa5d61f25b9041bebaf45",
    },
  },
  {
    display: {
      label: "New Intercom User",
      operationIcon: toolIcons["./intercom.svg"],
      operationIconToolbox: toolIcons["./intercom-toolbox.svg"],
    },
    serviceId: "intercom",
    operationKey: "/users",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601aaec92042ff6d1b2aa35a",
    },
  },
  {
    display: {
      label: "Tagged in Intercom",
      operationIcon: toolIcons["./intercom.svg"],
      operationIconToolbox: toolIcons["./intercom-toolbox.svg"],
    },
    serviceId: "intercom",
    operationKey: "/added-tag",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab14b1f25b9041bebaf98",
    },
  },

  {
    display: {
      label: "New Stripe Payment",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/payments",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
    isHidden: true,
  },
  {
    display: {
      label: "Trial Started",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/trialStarted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
    isHidden: true,
  },
  {
    display: {
      label: "Canceled Subscription",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionCanceled",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
    isHidden: true,
  },
  {
    display: {
      label: "Past Due Subscription",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionPastDue",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
    isHidden: true,
  },
  {
    display: {
      label: "Activated Subscription",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionActive",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
    isHidden: true,
  },
  {
    display: {
      label: "All Stripe Events",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Trial Started",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/trialStarted-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Trial Ends",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "encharge",
    serviceIdForFiltering: "stripe",
    operationKey: "/trialEndsStripe",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Trial Converted",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/trialConverted-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Trial Expired",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/trialExpired-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Activated",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionActive-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Past Due",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionPastDue-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Reactivated",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionReactivated-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Scheduled Subscription Cancellation",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/scheduledCancellation-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Canceled",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionCanceled-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Expanded",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionExpanded-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "Subscription Contracted",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/subscriptionContracted-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      // configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "New Stripe Payment",
      operationIcon: toolIcons["./stripe-dark.svg"],
      operationIconToolbox: toolIcons["./stripe-light.svg"],
    },
    serviceId: "stripe",
    operationKey: "/payments-v2",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601ab7e2ac2f834ec5384dc9",
    },
  },
  {
    display: {
      label: "New HubSpot Contact",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/contacts",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbd341f25b9041bebb5b0",
    },
  },
  {
    display: {
      label: "HubSpot Contact Field Match",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/contacts-updated",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bc0d31f25b9041bebb5c2",
    },
  },
  {
    display: {
      label: "HubSpot Deal Stage Match",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/deals/stage-match",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bc449ac2f834ec53853ae",
    },
  },
  {
    display: {
      label: "Added to List in HubSpot",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/lists/added",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7a318a552811521e5955",
    },
  },
  {
    display: {
      label: "HubSpot Form Submitted",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/forms/submissions",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7b2f8a552811521e5957",
    },
  },
  {
    display: {
      label: "Meeting Booked in HubSpot",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/meetings/booked",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7b434d805871ceaa3c38",
    },
  },
  {
    display: {
      label: "New Salesforce Lead",
      operationIcon: toolIcons["./salesforce-dark.svg"],
      operationIconToolbox: toolIcons["./salesforce-light.svg"],
    },
    serviceId: "salesforce",
    operationKey: "/new-leads",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bd8f3fb34b55df443d39f",
    },
  },
  {
    display: {
      label: "New Salesforce Contact",
      operationIcon: toolIcons["./salesforce-dark.svg"],
      operationIconToolbox: toolIcons["./salesforce-light.svg"],
    },
    serviceId: "salesforce",
    operationKey: "/new-contacts",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bdba01f25b9041bebb665",
    },
  },
  {
    display: {
      label: "Salesforce Field Match",
      operationIcon: toolIcons["./salesforce-dark.svg"],
      operationIconToolbox: toolIcons["./salesforce-light.svg"],
    },
    serviceId: "salesforce",
    operationKey: "/field-match",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bc7f21f25b9041bebb5ec",
    },
  },
  // Chargebee
  {
    display: {
      label: "All Chargebee Events",
      operationIcon: toolIcons["./chargebee-logo.svg"],
      operationIconToolbox: toolIcons["./chargebee-logo-toolbox.svg"],
    },
    serviceId: "chargebee",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Chargebee Trial Ends",
      operationIcon: toolIcons["./chargebee-logo.svg"],
      operationIconToolbox: toolIcons["./chargebee-logo-toolbox.svg"],
    },
    serviceId: "encharge",
    serviceIdForFiltering: "chargebee",
    operationKey: "/trialEndsChargebee",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb48fac2f834ec5385361",
    },
  },
  {
    display: {
      label: "Chargebee Trial Started",
      operationIcon: toolIcons["./chargebee-logo.svg"],
      operationIconToolbox: toolIcons["./chargebee-logo-toolbox.svg"],
    },
    serviceId: "chargebee",
    operationKey: "/trialStarted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb48fac2f834ec5385361",
    },
  },
  {
    display: {
      label: "Activated Subscription",
      operationIcon: toolIcons["./chargebee-logo.svg"],
      operationIconToolbox: toolIcons["./chargebee-logo-toolbox.svg"],
    },
    serviceId: "chargebee",
    operationKey: "/subscriptionActivated",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb48fac2f834ec5385361",
    },
  },
  {
    display: {
      label: "Canceled Subscription",
      operationIcon: toolIcons["./chargebee-logo.svg"],
      operationIconToolbox: toolIcons["./chargebee-logo-toolbox.svg"],
    },
    serviceId: "chargebee",
    operationKey: "/subscriptionCanceled",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb48fac2f834ec5385361",
    },
  },
  // Paddle
  {
    display: {
      label: "All Paddle Events",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo-toolbox.png`,
    },
    serviceId: "paddle",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Trial Ends",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo-toolbox.png`,
    },
    serviceId: "encharge",
    serviceIdForFiltering: "paddle",
    operationKey: "/trialEndsPaddle",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb9226867724dfc6ef76a",
    },
  },
  {
    display: {
      label: "Trial Started",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo-toolbox.png`,
    },
    serviceId: "paddle",
    operationKey: "/trialStarted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb9226867724dfc6ef76a",
    },
  },
  {
    display: {
      label: "Activated Subscription",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo-toolbox.png`,
    },
    serviceId: "paddle",
    operationKey: "/subscriptionActivated",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb9226867724dfc6ef76a",
    },
  },
  {
    display: {
      label: "Canceled Subscription",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/paddle-logo-toolbox.png`,
    },
    serviceId: "paddle",
    operationKey: "/subscriptionCanceled",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bb9226867724dfc6ef76a",
    },
  },
  // Recurly
  {
    display: {
      label: "All Recurly Events",
      operationIcon: toolIcons["./recurly-logo.svg"],
      operationIconToolbox: toolIcons["./recurly-logo-toolbox.svg"],
    },
    serviceId: "recurly",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Recurly Trial Ends",
      operationIcon: toolIcons["./recurly-logo.svg"],
      operationIconToolbox: toolIcons["./recurly-logo-toolbox.svg"],
    },
    serviceId: "encharge",
    serviceIdForFiltering: "recurly",
    operationKey: "/trialEndsRecurly",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbbf2fb34b55df443d2ee",
    },
  },
  {
    display: {
      label: "Recurly Trial Started",
      operationIcon: toolIcons["./recurly-logo.svg"],
      operationIconToolbox: toolIcons["./recurly-logo-toolbox.svg"],
    },
    serviceId: "recurly",
    operationKey: "/trialStarted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbbf2fb34b55df443d2ee",
    },
  },
  {
    display: {
      label: "Activated Subscription",
      operationIcon: toolIcons["./recurly-logo.svg"],
      operationIconToolbox: toolIcons["./recurly-logo-toolbox.svg"],
    },
    serviceId: "recurly",
    operationKey: "/subscriptionActivated",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbbf2fb34b55df443d2ee",
    },
  },
  {
    display: {
      label: "Canceled Subscription",
      operationIcon: toolIcons["./recurly-logo.svg"],
      operationIconToolbox: toolIcons["./recurly-logo-toolbox.svg"],
    },
    serviceId: "recurly",
    operationKey: "/subscriptionCanceled",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbbf2fb34b55df443d2ee",
    },
  },
  // Chargify
  {
    display: {
      label: "All Chargify Events",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo-toolbox.png`,
    },
    serviceId: "chargify",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Chargify Trial Ends",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo-toolbox.png`,
    },
    serviceId: "encharge",
    serviceIdForFiltering: "chargify",
    operationKey: "/trialEndsChargify",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbc331f25b9041bebb5a9",
    },
  },
  {
    display: {
      label: "Chargify Trial Started",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo-toolbox.png`,
    },
    serviceId: "chargify",
    operationKey: "/trialStarted",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbc331f25b9041bebb5a9",
    },
  },
  {
    display: {
      label: "Activated Subscription",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo-toolbox.png`,
    },
    serviceId: "chargify",
    operationKey: "/subscriptionActivated",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbc331f25b9041bebb5a9",
    },
  },
  {
    display: {
      label: "Canceled Subscription",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/chargify-logo-toolbox.png`,
    },
    serviceId: "chargify",
    operationKey: "/subscriptionCanceled",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bbc331f25b9041bebb5a9",
    },
  },
  // Calendly
  {
    display: {
      label: "All Calendly Events",
      operationIcon: toolIcons["./calendly-logo.svg"],
      operationIconToolbox: toolIcons["./calendly-logo-toolbox.svg"],
    },
    serviceId: "calendly",
    operationKey: "/allEvents",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    isHidden: true,
  },
  {
    display: {
      label: "Booked Calendly Meeting",
      operationIcon: toolIcons["./calendly-logo.svg"],
      operationIconToolbox: toolIcons["./calendly-logo-toolbox.svg"],
    },
    serviceId: "calendly",
    operationKey: "/bookedMeeting",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601bebf4ac2f834ec5385480",
    },
  },
  {
    display: {
      label: "Canceled Calendly Meeting",
      operationIcon: toolIcons["./calendly-logo.svg"],
      operationIconToolbox: toolIcons["./calendly-logo-toolbox.svg"],
    },
    serviceId: "calendly",
    operationKey: "/canceledMeeting",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "601beff6a4cefb30ae5c7586",
    },
  },
  {
    display: {
      label: "Trial Ends",
      operationIcon: toolIcons["./trial-ends.svg"],
      operationIconToolbox: toolIcons["./trial-ends-toolbox.svg"],
    },
    serviceId: "encharge",
    operationKey: "/trialEnds",
    type: "trigger",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "602551f88502d1120e90684e",
    },
  },
  // END OF TRIGGERS SECTION

  // ACTIONS
  {
    display: {
      label: "Send Email",
      operationIcon: toolIcons["./email-dark.svg"],
      operationIconToolbox: toolIcons["./email-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/send",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "601c0380ac2f834ec53854fa",
    },
  },
  {
    display: {
      label: "Wait",
      operationIcon: toolIcons["./wait-dark.svg"],
      operationIconToolbox: toolIcons["./wait-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/delay",
    type: "action",
    inputFields: {},
    mustConfig: {
      configStep: true,
    },
    outputFields: {},
    isHidden: true,
  },
  {
    display: {
      label: "Wait",
      operationIcon: toolIcons["./wait-dark.svg"],
      operationIconToolbox: toolIcons["./wait-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/delay-v2",
    type: "action",
    inputFields: {},
    mustConfig: {
      configStep: false,
    },
    outputFields: {},
    helpDocs: {
      helpScoutID: "601d052cac2f834ec5385b58",
    },
  },
  {
    display: {
      label: "Change Field",
      operationIcon: toolIcons["./change-field-dark.svg"],
      operationIconToolbox: toolIcons["./change-field-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/change-field",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "602132866867724dfc6f0ac0",
    },
  },
  {
    display: {
      label: "Change Numeric Field",
      operationIcon: toolIcons["./change-numeric-field-dark.svg"],
      operationIconToolbox: toolIcons["./change-numeric-field-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/change-numeric-field",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "63bea9ac523e535f3403a456",
    },
  },
  {
    display: {
      label: "Add Tag",
      operationIcon: toolIcons["./tag-dark.svg"],
      operationIconToolbox: toolIcons["./tag-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/add-tag",
    type: "action",
    inputFields: {},
    mustConfig: {
      configStep: true,
    },
    outputFields: {},
    helpDocs: {
      helpScoutID: "601d1027ac2f834ec5385b85",
    },
  },
  {
    display: {
      label: "Remove Tag",
      operationIcon: toolIcons["./tag-dark.svg"],
      operationIconToolbox: toolIcons["./tag-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/remove-tag",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "601d13931f25b9041bebbd70",
    },
  },
  {
    display: {
      label: "Goal",
      operationIcon: toolIcons["./flow-goal.svg"],
      operationIconToolbox: toolIcons["./flow-goal.svg"],
    },
    serviceId: "encharge",
    operationKey: "/flow-goal",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
  },
  {
    display: {
      label: "End Flow",
      operationIcon: toolIcons["./end-flow.svg"],
      operationIconToolbox: toolIcons["./end-flow-tool.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/end-flow",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {},
    isHidden: true,
  },
  {
    display: {
      label: "End Flow",
      operationIcon: toolIcons["./end-flow.svg"],
      operationIconToolbox: toolIcons["./end-flow-tool.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/end-flow-v2",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {},
    helpDocs: {
      helpScoutID: "6024ea49a4cefb30ae5c9d0b",
    },
  },
  {
    display: {
      label: "Lead Score",
      operationIcon: toolIcons["./lead-score.svg"],
      operationIconToolbox: toolIcons["./lead-score-toolbox.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/lead-score",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023ee33fb34b55df443f63e",
    },
  },
  {
    display: {
      label: "A/B Test",
      operationIcon: toolIcons["./ab-test-dark.svg"],
      operationIconToolbox: toolIcons["./ab-test-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/ab-test",
    type: "action",
    inputFields: {},
    mustConfig: {
      configStep: false,
    },
    outputFields: {},
    isHidden: false,
    helpDocs: {
      helpScoutID: "602241766867724dfc6f106a",
    },
  },
  {
    display: {
      label: "Send Email Notification",
      operationIcon: toolIcons["./email-notification.svg"],
      operationIconToolbox: toolIcons["./email-notification-toolbox.svg"],
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "encharge",
    operationKey: "/email-notification",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "60214ae1a4cefb30ae5c86f7",
    },
  },
  {
    display: {
      label: "Send Slack Notification",
      operationIcon: toolIcons["./slack.svg"],
      operationIconToolbox: toolIcons["./slack-toolbox.svg"],
      operationIconApps: toolIcons["./slack-fullcolor.svg"],
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "slack",
    operationKey: "/post-message",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "602258dcac2f834ec5386c7f",
    },
  },
  {
    display: {
      label: "Add a Row in Google Sheets",
      operationIcon: toolIcons["./google-sheets.svg"],
      operationIconToolbox: toolIcons["./google-sheets-toolbox.svg"],
    },
    serviceId: "googlesheets",
    operationKey: "/sheets/rows",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "66f2448ef6180530271e2664",
    },
  },
  {
    display: {
      label: "Send Webhook",
      operationIcon: toolIcons["./webhook-dark.svg"],
      operationIconToolbox: toolIcons["./webhook-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/send-webhook",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mayMapOutputFields: true,
    },
    helpDocs: {
      helpScoutID: "60228e6da4cefb30ae5c8df5",
    },
  },

  {
    display: {
      label: "Send SMS",
      operationIcon: toolIcons["./twilio.svg"],
      operationIconToolbox: toolIcons["./twilio-toolbox.svg"],
    },
    serviceId: "twilio",
    operationKey: "/send-sms",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "60b4e51cafcffb241935fd15",
    },
  },
  {
    display: {
      label: "Send In-App Message",
      operationIcon: toolIcons["./intercom.svg"],
      operationIconToolbox: toolIcons["./intercom-toolbox.svg"],
    },
    serviceId: "intercom",
    operationKey: "/messages",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6022a16fac2f834ec5386ead",
    },
  },
  {
    display: {
      label: "Add Intercom Tag",
      operationIcon: toolIcons["./intercom.svg"],
      operationIconToolbox: toolIcons["./intercom-toolbox.svg"],
    },
    serviceId: "intercom",
    operationKey: "/add-tag",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6022b1afa4cefb30ae5c8f5f",
    },
  },
  {
    display: {
      label: "Add to Audience",
      operationIcon: toolIcons["./facebook-dark.svg"],
      operationIconToolbox: toolIcons["./facebook-light.svg"],
    },
    serviceId: "facebook",
    operationKey: "/add-to-audience",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "602399d86867724dfc6f1a34",
    },
  },
  {
    display: {
      label: "Remove from Audience",
      operationIcon: toolIcons["./facebook-dark.svg"],
      operationIconToolbox: toolIcons["./facebook-light.svg"],
    },
    serviceId: "facebook",
    operationKey: "/remove-from-audience",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
    },
    helpDocs: {
      helpScoutID: "6023bbf41f25b9041bebd84c",
    },
  },

  {
    display: {
      label: "Subscribe to Mailchimp",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
    },
    serviceId: "mailchimp",
    operationKey: "/add-subscriber",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6022b4dda4cefb30ae5c8f82",
    },
  },
  {
    display: {
      label: "Unsubscribe from Mailchimp",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
    },
    serviceId: "mailchimp",
    operationKey: "/unsubscribe-subscriber",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023913d6867724dfc6f1a05",
    },
  },
  {
    display: {
      label: "Remove from Mailchimp",
      operationIcon: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
      operationIconToolbox: `${process.env.PUBLIC_URL}/assets/img/operationTypeIcons/mailchimp-logo-dark.png`,
    },
    serviceId: "mailchimp",
    operationKey: "/remove-subscriber",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023964da4cefb30ae5c9587",
    },
  },
  {
    display: {
      label: "Create HubSpot Record",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "hubspot",
    operationKey: "/objects/create",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7bac8a552811521e5959",
    },
  },
  {
    display: {
      label: "Update HubSpot Record",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
      drawerSize: {
        setup: "lg",
      },
    },
    serviceId: "hubspot",
    operationKey: "/objects/update",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7bb99471985a5ac52b0f",
    },
  },
  {
    display: {
      label: "Create HubSpot Deal",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/deals",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023d2fc1f25b9041bebd89d",
    },
  },
  {
    display: {
      label: "Change HubSpot Deal Stage",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/deals/change-stage",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023dc52ac2f834ec53876fa",
    },
  },
  {
    display: {
      label: "Create Task in HubSpot",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/create-task",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023e205a4cefb30ae5c973d",
    },
  },
  {
    display: {
      label: "Add to List in HubSpot",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/lists/add",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023e205a4cefb30ae5c973d",
    },
  },
  {
    display: {
      label: "Remove from List in HubSpot",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/lists/remove",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "634c7bd1de258f5018eb4c42",
    },
  },
  {
    display: {
      label: "Create Lead/Contact",
      operationIcon: toolIcons["./salesforce-dark.svg"],
      operationIconToolbox: toolIcons["./salesforce-light.svg"],
    },
    serviceId: "salesforce",
    operationKey: "/create-lead-contact",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6023e900ac2f834ec538774e",
    },
  },
  {
    display: {
      label: "Archive Person",
      operationIcon: toolIcons["./archive.svg"],
      operationIconToolbox: toolIcons["./archive-tool.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/archive",
    type: "action",
    inputFields: {},
    outputFields: {},
    mustConfig: {},
    helpDocs: {
      helpScoutID: "6024f8cfac2f834ec5387d14",
    },
  },
  // END OF ACTIONS SECTION

  // FILTERS ACTIONS SECTION
  {
    display: {
      label: "Check Email Activity",
      operationIcon: toolIcons["./email-aux-dark.svg"],
      operationIconToolbox: toolIcons["./email-aux-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/email-activity",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {},
    helpDocs: {
      helpScoutID: "602504d424d2d21e45ed5561",
    },
  },
  {
    display: {
      label: "Check Condition",
      operationIcon: toolIcons["./change-field-dark.svg"],
      operationIconToolbox: toolIcons["./change-field-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/check-field",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "602531d8661b720174a6c275",
    },
  },
  {
    display: {
      label: "Is in Segment",
      operationIcon: toolIcons["./segment-dark.svg"],
      operationIconToolbox: toolIcons["./segment-light.svg"],
    },
    serviceId: "encharge",
    operationKey: "/segment-filter",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "60253e470a2dae5b58faeb9f",
    },
  },
  {
    display: {
      label: "Has Visited Page",
      operationIcon: toolIcons["./page-visited.svg"],
      operationIconToolbox: toolIcons["./page-visited-toolbox.svg"],
    },
    serviceId: "encharge",
    operationKey: "/page-visited-filter",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "6025401d8502d1120e9067ab",
    },
  },
  {
    display: {
      label: "Has Tag",
      operationIcon: toolIcons["./tag-dark.svg"],
      operationIconToolbox: toolIcons["./tag-light.svg"],
      size: "small",
    },
    serviceId: "encharge",
    operationKey: "/has-tag",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "602543560a2dae5b58faebcb",
    },
  },
  {
    display: {
      label: "Has Booked Meeting",
      operationIcon: toolIcons["./calendly-logo.svg"],
      operationIconToolbox: toolIcons["./calendly-logo-toolbox.svg"],
    },
    serviceId: "calendly",
    operationKey: "/hasBookedMeeting",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: false,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "60254538b3ebfb109b5815a1",
    },
  },
  {
    display: {
      label: "Check HubSpot Record",
      operationIcon: toolIcons["./hubspot.svg"],
      operationIconToolbox: toolIcons["./hubspot-toolbox.svg"],
    },
    serviceId: "hubspot",
    operationKey: "/objects/check",
    type: "filter",
    inputFields: {},
    outputFields: {},
    mustConfig: {
      configStep: true,
      mapOutputFields: false,
    },
    helpDocs: {
      helpScoutID: "63738935190e8f786b4446bf",
    },
  },
  // END OF FILTERS SECTION

  // DECORATIONS SECTION
  {
    display: {
      label: "Note",
      operationIcon: toolIcons["./note.svg"],
      operationIconToolbox: toolIcons["./note.svg"],
    },
    serviceId: "encharge",
    operationKey: "/note",
    type: "decoration",
    inputFields: {},
    outputFields: {},
    // helpDocs: {
    //   helpScoutID: "60254a0d24d2d21e45ed557b",
    // },
  },
  {
    display: {
      label: "Annotation",
      operationIcon: toolIcons["./annotation.svg"],
      operationIconToolbox: toolIcons["./annotation.svg"],
    },
    serviceId: "encharge",
    operationKey: "/annotation",
    type: "decoration",
    inputFields: {},
    outputFields: {},
    // helpDocs: {
    //   helpScoutID: "60254a0d24d2d21e45ed557b",
    // },
  },
  // {
  //   display: {
  //     label: "Contacts from Google Sheets",
  //     operationIcon:
  //       "https://s3.amazonaws.com/static.encharge.io/common/img/logo-googlesheets.png"
  //   },
  //   serviceId: "typeform",
  //   operationKey: "triggers.endUserFromCreatedRow",
  //   type: "trigger",
  //   inputFields: {},
  //   outputFields: {}
  // }
];

export const getAllTools = () => {
  const services = domainStore.servicesStore.services.current();
  if (!services) return undefined;
  return currentTools.map((tool) => {
    // find this tool's service
    tool.service = _.find(services, (service) => service.id === tool.serviceId);
    return tool as IStepTool;
  });
};

export const getTool = (
  serviceId: IService["id"],
  operationKey: IIntegrationStep["data"]["operationKey"]
) => {
  for (const tool of currentTools) {
    if (tool.serviceId === serviceId && tool.operationKey === operationKey) {
      return tool;
    }
  }
  return undefined;
};

export const getServiceIcon = (serviceId: IService["id"]) => {
  if (serviceId === "segment") {
    return toolIcons["./segment-logo.png"];
  }
  if (serviceId === "zapier") {
    return zapierIcon;
  }
  if (serviceId === enchargeSiteTrackingSlug) {
    return siteTrackingEventIcon;
  }
  if (serviceId === "ingested-event") {
    return ingestedEventIcon;
  }

  for (const tool of currentTools) {
    if (tool.serviceId === serviceId) {
      return tool.display.operationIconApps || tool.display.operationIcon;
    }
  }
  return undefined;
};
