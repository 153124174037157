import HelpTooltip from "components/Common/HelpTooltip";
import { TeamMemberScopeTypes } from "encharge-domain/definitions/TeamMember";
import _ from "lodash";
import { PropsWithChildren } from "react";
import { Alert, Container } from "reactstrap";
import { teamMembersScopesDisplay } from "store/permissionsStore";

interface Props {
  neededPermission?: TeamMemberScopeTypes;
}

const MissingPermissionsWarning = (props: PropsWithChildren<Props>) => {
  const neededPermissionsName = _.find(teamMembersScopesDisplay, {
    id: props.neededPermission,
  })?.name;
  return (
    <Container
      className="permissions-warning-container"
      data-testid="missing-permissions"
    >
      <Alert color="warning" className="mt-5">
        {props.children}{" "}
        <HelpTooltip>
          Contact your account's owner to request access.
          {neededPermissionsName && (
            <span>
              <br />
              Required permission: {neededPermissionsName}
            </span>
          )}
        </HelpTooltip>
      </Alert>
    </Container>
  );
};
export default MissingPermissionsWarning;
