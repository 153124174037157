import GenerateFlowsCarouselGeneratedItem from "components/Registration/GenerateFlows/GenerateFlowsCarouselGeneratedItem";
import GenerateFlowsGeneratedEmail from "components/Registration/GenerateFlows/GenerateFlowsGeneratedEmail";
import GenerateFlowsCompanyInfo from "components/Registration/GenerateFlows/GenerateFlowsCompanyInfo";
import { observer } from "mobx-react";
import generatedEmailsBackground from "../../../assets/img/blue-gradient.jpeg";
import { ReactComponent as ImportPeopleIcon } from "../../../assets/img/nav/import-people-nav.svg";
import { ReactComponent as ConnectAppIcon } from "../../../assets/img/nav/apps.svg";
import { ReactComponent as CreateEmailIcon } from "../../../assets/img/nav/broadcasts.svg";
import { ReactComponent as CreateFlowIcon } from "../../../assets/img/nav/flows.svg";
import { ReactComponent as VerifyDomainIcon } from "../../../assets/img/nav/site-tracking-nav.svg";
import classNames from "classnames";
import { useState } from "react";
import redirect from "domain/helpers/redirect";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { getQuery } from "domain/helpers/query";
import Confetti from "components/Confetti";

interface Props {
  businessName?: string;
  name?: string;
  site?: string;
  fromEmail?: string;
  companyInfo?: { title: string; description: string }[];
  isB2B?: boolean;
  isSaaS?: boolean;
  isExtendedSupportAvailable?: boolean;
  completedSteps: {
    [key in "import" | "flow" | "email" | "app" | "domain"]: boolean;
  };
  flows: {
    id: number;
    name: string;
    subject: string;
    text: string;
  }[];
}

const OnboardingChecklistView = observer((props: Props) => {
  const [showVideo, setShowVideo] = useState(false);
  const justRegistered = getQuery().register;

  const completed = props.completedSteps;

  const helpCenterLink = (
    <a
      href="https://help.encharge.io/"
      target="_blank"
      rel="noopener noreferrer"
    >
      Help Center
    </a>
  );
  const caret = (
    <div className="checklist-item-caret">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="17"
        viewBox="0 0 10 17"
      >
        <path
          d="M2.36706 16.8191L0.764221 15.5171L7.19777 8.40915L0.764221 1.30115L2.36706 -0.000854492L9.3898 7.75915C9.72874 8.13315 9.72874 8.68615 9.3898 9.06015L2.36706 16.8191Z"
          fill="#1868FB"
        />
      </svg>
    </div>
  );

  const generatedFlows = _.map(props.flows, (flow, index) => {
    return (
      <GenerateFlowsCarouselGeneratedItem key={index} title={""} subtitle={""}>
        <div className="generated-flow-overlay">
          <Link to={`/flows/${flow.id}`} className="mx-4">
            <Button color="primary">{flow.name}</Button>
          </Link>
        </div>
        <GenerateFlowsGeneratedEmail
          subject={flow.subject}
          text={flow.text}
          fromEmail={props.fromEmail || ""}
          fromName={props.name || "You"}
          tinyMode={true}
        />
      </GenerateFlowsCarouselGeneratedItem>
    );
  });

  const welcomeTitle = (
    <div className="welcome-title">
      Welcome to Encharge, {props.name}
      <div className="ml-3 d-inline-block welcome-hand text-center">👋</div>
    </div>
  );

  const hasGeneratedFlows = Boolean(generatedFlows?.length);

  return (
    <div className="onboarding-checklist">
      {hasGeneratedFlows ? (
        <div className="generate-flows-carousel position-relative">
          <img
            className="generate-flows-carousel-background"
            src={generatedEmailsBackground}
            alt="background"
          />
          <div className="onboarding-title-container">
            <div className="pb-5 text-center">
              {welcomeTitle}
              <div className="welcome-subtitle">
                Encharge has generated a unique flow with emails personalized
                for {props.businessName}. Explore them below.
              </div>
            </div>
            <div className="generate-flows-carousel-scroll-container">
              <GenerateFlowsCarouselGeneratedItem
                title={"Business information"}
                subtitle={""}
                showPlaceholder={"company"}
              >
                <GenerateFlowsCompanyInfo
                  businessModelLabel={props.isB2B ? "B2B" : "B2C"}
                  isSaaSLabel={props.isSaaS ? "SaaS" : "Not SaaS"}
                  site={props.site}
                  companyInfo={props.companyInfo}
                  tinyMode={true}
                />
              </GenerateFlowsCarouselGeneratedItem>
              {generatedFlows}
            </div>
          </div>
        </div>
      ) : null}
      <div className="container onboarding-checklist-list-container py-5">
        <div className="welcome-title text-center">
          {hasGeneratedFlows ? "Get started with your account" : welcomeTitle}
        </div>
        <div className="welcome-subtitle text-center">
          Follow these 5-easy steps and start creating amazing marketing
          automations from scratch
        </div>
        <div className="get-started-info mt-5 row">
          <div className="col-24 col-md-14 mb-5">
            {showVideo ? (
              <iframe
                title="Get Started Video"
                className="col"
                height="476"
                src="https://www.youtube-nocookie.com/embed/8XQof6YjTs8?autoplay=1"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                frameBorder="0"
              />
            ) : (
              <img
                className="get-started-video-thumbnail"
                src={`${process.env.PUBLIC_URL}/assets/img/get-started-video-checklist.png`}
                alt="Get Started Video"
                onClick={() => setShowVideo(true)}
              />
            )}
          </div>
          <div className="col-24 col-md-10 col-xxl-7">
            <div className="get-started-info-item">
              <div className="get-started-info-item-title">Need some help?</div>
              <div className="get-started-info-item-subtitle">
                {props.isExtendedSupportAvailable ? (
                  <span>
                    <a
                      href="#"
                      onClick={(e) => {
                        (window as any).openHelpChat();
                        e.preventDefault();
                        return false;
                      }}
                    >
                      Chat with us
                    </a>{" "}
                    or visit the {helpCenterLink}.
                  </span>
                ) : (
                  <span>Visit the {helpCenterLink}.</span>
                )}
              </div>
            </div>
            {props.isExtendedSupportAvailable && (
              <div className="get-started-info-item">
                <div className="get-started-info-item-title">
                  Book a demo call
                </div>
                <div className="get-started-info-item-subtitle">
                  <a
                    href="https://encharge.io/demo/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Book a success call
                  </a>{" "}
                  now.
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="checklist-items mt-5">
          <div
            className="checklist-item"
            onClick={() => {
              const userGuiding = (window as any).userGuiding;
              if (userGuiding) {
                return userGuiding.previewGuide(66077, { checkHistory: false });
              }
            }}
          >
            <div
              className={classNames(
                "checklist-item-icon",
                completed.flow && "complete"
              )}
            >
              <CreateFlowIcon />
            </div>
            <div className="checklist-item-title">1. Create a flow</div>
            {caret}
          </div>
          <div
            className="checklist-item"
            onClick={() => {
              const userGuiding = (window as any).userGuiding;
              if (userGuiding) {
                userGuiding.previewGuide(65999, { checkHistory: false });
              } else {
                redirect("/emails");
              }
            }}
          >
            <div
              className={classNames(
                "checklist-item-icon",
                completed.email && "complete"
              )}
            >
              <CreateEmailIcon />
            </div>
            <div className="checklist-item-title">
              2. Create your first email
            </div>
            {caret}
          </div>
          <div className="checklist-item">
            <div
              className={classNames(
                "checklist-item-icon",
                completed.import && "complete"
              )}
              onClick={() => {
                const userGuiding = (window as any).userGuiding;
                if (userGuiding) {
                  return userGuiding.previewGuide(96696, {
                    checkHistory: false,
                  });
                }
                redirect("/people/imports");
              }}
            >
              <ImportPeopleIcon />
            </div>
            <div className="checklist-item-title">3. Import People</div>
            {caret}
          </div>
          <div
            className="checklist-item"
            onClick={() => {
              redirect("/apps");
            }}
          >
            <div
              className={classNames(
                "checklist-item-icon",
                completed.app && "complete"
              )}
            >
              <ConnectAppIcon
                width={20}
                height={20}
                className="connect-app-icon"
              />
            </div>
            <div className="checklist-item-title">4. Connect an app</div>
            {caret}
          </div>
          <div
            className="checklist-item"
            onClick={() => {
              redirect("/settings/email/domains");
            }}
          >
            <div
              className={classNames(
                "checklist-item-icon",
                completed.domain && "complete"
              )}
            >
              <VerifyDomainIcon />
            </div>
            <div className="checklist-item-title">5. Verify domain name</div>
            {caret}
          </div>
        </div>
      </div>
      {justRegistered && <Confetti />}
    </div>
  );
});
export default OnboardingChecklistView;
