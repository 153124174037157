import {
  photoFromEmail,
  onErrorSetDefaultAvatar,
} from "../../domain/helpers/photoFromEmail";

interface Props {
  email?: string;
  name?: string;
  id?: string;
  className?: string;
  size?: number;
}
export const PersonAvatar = ({ email, name, id, className, size }: Props) => {
  return (
    <img
      alt={name || email || ""}
      title={name || email || ""}
      src={photoFromEmail(email, name, id, size || 64)}
      onError={(e) => onErrorSetDefaultAvatar(e, size || 64)}
      className={`rounded-circle ${className}`}
      style={{ width: size }}
    />
  );
};
