import { useState, useEffect } from "react";
import { ReactComponent as Expired } from "../assets/img/loading-expired.svg";
import classNames from "classnames";

const defaultTimeout = 30000; // 30 seconds
interface Props {
  size?: "tiny" | "small" | "normal" | "large";
  color?: "white" | "blue" | "gray";
  className?: string;
  timeout?: number;
  disableTimeout?: boolean;
}

export const Loading = (
  {
    size = "normal",
    color = "gray",
    timeout = defaultTimeout,
    className = "",
    disableTimeout,
  }: Props = {
    size: "normal",
    color: "gray",
    timeout: defaultTimeout,
    className: "",
  }
) => {
  // If the component is not unmounted in 30 seconds, display expiration icon
  const [expired, setExpired] = useState(false);
  useEffect(() => {
    if (disableTimeout) return;
    // Start the timer
    const loadingExpireTimer = window.setTimeout(
      () => setExpired(true),
      timeout
    );
    // this will clear Timeout when component unmount like in willComponentUnmount
    return () => {
      window.clearTimeout(loadingExpireTimer);
    };
  }, [timeout, disableTimeout]); // useEffect will run only one time

  // setTimeout(() => {
  //   this.setState({ expired: true });
  // }, props.timeout);
  // normal size is with empty sizeClass
  let sizeClass = "";
  if (size === "tiny") {
    sizeClass = "-tiny";
  } else if (size === "small") {
    sizeClass = "-small";
  } else if (size === "large") {
    sizeClass = "-large";
  }

  if (expired) {
    return (
      <div className={classNames("loading", className)}>
        {/* <div className={[`spinner${sizeClass}`, colorClass].join(" ")}> */}
        <div
          title="Loading took too long. Please refresh the page"
          className={[
            "spinner",
            "expired",
            `spinner${sizeClass}`,
            `${color}`,
          ].join(" ")}
        >
          <Expired />
        </div>
      </div>
    );
  }

  // gray color is with empty colorClass
  let colorClass = "";
  if (color === "white") {
    colorClass = "bubble-white";
  } else if (color === "blue") {
    colorClass = "bubble-blue";
  }
  // return <div className="loading-centered" />;
  return (
    <div className={classNames("loading", className)}>
      <div className={`spinner${sizeClass}`}>
        <div className={`bubble-1${sizeClass} ${colorClass}`} />
        <div className={`bubble-2${sizeClass} ${colorClass}`} />
      </div>
    </div>
  );
};

export default Loading;
