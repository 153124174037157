import { observer } from "mobx-react";
import { PropsWithChildren, useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useStores } from "store/useStore";

interface Props {}

const RouterToMobx = (props: PropsWithChildren<RouteComponentProps>) => {
  const { locationStore } = useStores();
  useEffect(() => {
    locationStore.initHistory({
      history: props.history,
      force: Boolean((module as any).hot),
    });
  }, [locationStore, props.history]);
  return <>{props.children}</>;
};
export default withRouter(observer(RouterToMobx));
