import { Props as SelectProps } from "react-select/lib/Select";
import _ from "lodash";

export interface Option {
  value: number | undefined;
  label: string | React.ReactElement;
}

export const reactSelectCommonProps: SelectProps<Option> = {
  menuPortalTarget: document.body,
  menuPlacement: "auto",
  styles: {
    menuPortal: (styles) => ({
      ...styles,
      position: "absolute",
      zIndex: 1400, // should be >= dialog's and < (drawer, sidebar)'s z-index
    }),
  },
  isSearchable: true,
  classNamePrefix: "react-select",
};

export const formatAsReactSelectOptions = <T extends any>({
  items,
  currentValue,
  idField,
  labelField,
  emptyOptionLabel,
  formatLabel,
  formatLabelForSearch,
  addEmptyOption,
  disabledField,
}: {
  items: T[];
  currentValue: any;
  idField: string;
  labelField: string;
  formatLabel?: (item: T) => Option["label"];
  formatLabelForSearch?: (item: T) => string;
  addEmptyOption?: boolean;
  emptyOptionLabel?: string;
  disabledField?: string;
}) => {
  const getLabel = (item: T) => {
    if (formatLabel) return formatLabel(item);
    return _.get(item, labelField);
  };
  const itemsArray: Option[] = _.map(items || [], (item) => ({
    value: _.get(item, idField),
    label: getLabel(item),
    searchLabel: formatLabelForSearch
      ? formatLabelForSearch(item)
      : _.get(item, labelField),
    isDisabled: disabledField ? _.get(item, disabledField) : false,
  }));
  // The option for no no mapping
  const empty: Option = {
    value: undefined,
    // This label enables the option to be full height, instead of squeezed
    label: emptyOptionLabel || "\u200B",
  };
  const options = addEmptyOption ? [empty, ...itemsArray] : itemsArray;
  // Find the currently selected option
  let currentOption = _.find(
    options,
    (option) => option.value === currentValue
  );
  // If the currently selected option can not be found
  if (!currentOption) {
    currentOption = empty;
  }
  return { currentOption, options };
};
