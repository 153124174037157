import { objectsPageSize } from "../../constants";

/**
 * Get the next page param for the given react-query query
 */
export const getNextPageParam = (
  lastPage: any[] | undefined,
  pages: any[] | undefined,
  pageSize?: number,
  getPagesUntilEmpty = false
) => {
  const pageSizeLocal = pageSize || objectsPageSize;
  if (!lastPage?.length) {
    // return undefined to indicate there is no next page
    return undefined;
  }
  if (lastPage.length < pageSizeLocal && !getPagesUntilEmpty) {
    // return undefined to indicate there is no next page
    return undefined;
  }
  // next page number is the current number of pages, since we are 0 based in page numbers
  return pages?.length || 0;
};
export const getNextPageParamFactory = ({
  pageSize,
  getPagesUntilEmpty,
}: {
  pageSize: number;
  getPagesUntilEmpty?: boolean;
}) => (lastPage: any[] | undefined, pages: any[] | undefined) =>
  getNextPageParam(lastPage, pages, pageSize, getPagesUntilEmpty);
