import { toastError, toastSuccess } from "domain/errorHandling/toaster";
import { parseAPIErrorMessage } from "store/persistence/enchargeAPI";

export const handleMutationError = (errorMsg: string, error: any) => {
  const msg = parseAPIErrorMessage(error);
  toastError({
    message: `${errorMsg}`,
    extra: error,
  });
};

export const handleReadError = handleMutationError;

export const handleMutationSuccess = (successMsg: string) => {
  toastSuccess(successMsg);
};
