import { useState, useEffect } from "react";

const useDisableScrolling = () => {
  // https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  const [containerRef, setContainerRef] = useState(
    null as HTMLDivElement | null
  );
  useEffect(() => {
    if (containerRef && containerRef) {
      containerRef.addEventListener(
        "touchmove",
        (e: any) => {
          e.preventDefault();
        },
        { passive: false }
      );
    }
  }, [containerRef]);
  return setContainerRef;
};

export default useDisableScrolling;
