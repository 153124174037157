import { useState, useCallback } from "react";
import * as React from "react";
import ActionItems from "./ActionItems";
import _ from "lodash";
import { CellProps } from "react-table";

interface Props extends CellProps<object> {
  actions?: {
    type: string;
    title: string | React.FC<{ item: any }>;
    handler: (item: any) => void;
    shouldDisplay?: (arg: { item: any }) => boolean;
  }[];
}

const ItemActionsView = (props: Props) => {
  return <ActionItems actions={props.actions} item={props.row.original} />;
};
export default ItemActionsView;
