import { observable } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { getAllFormEvents } from "./persistence/persistEvents";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import {
  formHash,
  normalizeFormURL,
} from "encharge-domain/lib/helpers/formTracking";

interface IFormEvent {
  url: string;
  data?: {
    form?: any;
  };
  formHash: string;
}

export class FormTrackingStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  allFormEvents = lazyObservable<IFormEvent[]>((sink) => {
    getAllFormEvents().then((events) => {
      // remove events from same URL
      const eventsGrouped = _.reduce(
        // start with older events, so that they are overwritten by newer ones
        _.reverse(events),
        (acc, event) => {
          if (!event.url || !event.data || !event.data.form) return acc;
          // remove query and hash from url
          const normalizedURL = normalizeFormURL(event.url);
          // create a unique hash for this form by taking the flattened keys
          acc[normalizedURL] = _.merge({}, _.pick(event, "data"), {
            url: normalizedURL,
            formHash: formHash(event.data.form),
          });
          return acc;
        },
        {} as Dictionary<IFormEvent>
      );
      sink(_.values(eventsGrouped));
    });
  });
}
