import { Fragment } from "react";
import * as React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody, Form } from "reactstrap";
import ButtonLoader from "../ButtonLoader";
import _ from "lodash";
interface Props {
  onClose: () => void;
  isOpen?: boolean;
  header?: React.ReactElement | string;
  body?: React.ReactElement | string;
  footer?: React.ReactElement;
  size?: "sm" | "md" | "lg" | "xl";
  buttons?: {
    onClick: () => void;
    label: string;
    color?: string;
    loading?: boolean;
    type?: "button" | "submit" | "reset";
    skipAutofocus?: boolean;
    disabled?: boolean;
  }[];
  skipAutofocus?: boolean;
}
const ConfirmDialog = (props: React.PropsWithChildren<Props>) => {
  const footerButtons = _.map(props.buttons, (button) => (
    <Fragment key={button.label}>
      <ButtonLoader
        key={button.label}
        color={button.color || "link"}
        onClick={() => button.onClick()}
        data-testid={`dialog-button-${button.label}`}
        type={
          button.type
            ? button.type
            : button.color === "primary"
            ? "submit"
            : "button"
        }
        autoFocus={
          !button.skipAutofocus &&
          (button.type === "submit" || button.color === "primary")
        }
        disabled={button.disabled}
        loading={button.loading}
      >
        {button.label}
      </ButtonLoader>{" "}
    </Fragment>
  ));
  const isOpen = props.isOpen !== undefined ? props.isOpen : true;

  const primaryButton = _.find(
    props.buttons,
    (current) => current.type === "submit" || current.color === "primary"
  );
  const body = props.body || props.children;
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => props.onClose()}
      zIndex={1300}
      size={props.size}
      autoFocus={false}
      onKeyDown={(event) => {
        // prevent escape key closing other modals/drawers/etc.
        if (event?.key?.toLowerCase?.() === "escape") {
          event.stopPropagation();
        }
      }}
    >
      {props.header && (
        <ModalHeader toggle={() => props.onClose()}>{props.header}</ModalHeader>
      )}
      <Form
        onSubmit={(e) => {
          primaryButton?.onClick?.();
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {body && <ModalBody>{body}</ModalBody>}
        <ModalFooter>{props.footer ? props.footer : footerButtons}</ModalFooter>
      </Form>
    </Modal>
  );
};

export default ConfirmDialog;
