import { StepBase } from "components/FlowEditor/Step/StepBase";
import { IFlow } from "components/Flows/FlowRoute";
import _ from "lodash";

/**
 * Find email ids used in "Send Email" steps in `flows`
 *
 */
export const getEmailsUsedInFlows = (flows: IFlow[]) => {
  return _.reduce(
    flows,
    (acc, flow) => {
      const emailsUsedInFlow = _.reduce(
        flow.steps,
        (acc, step) => {
          if (StepBase.isSendEmail(step)) {
            const emailId = StepBase.getInputFieldsValues(step)?.email?.id;
            if (emailId) {
              acc.push(emailId);
            }
          }
          return acc;
        },
        [] as IEmailContent["id"][]
      );
      acc.push(...emailsUsedInFlow);
      return acc;
    },
    [] as IEmailContent["id"][]
  );
};
