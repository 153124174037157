// Pattern from https://tkdodo.eu/blog/effective-react-query-keys

import { IMetricsType } from "components/Charts/SingleMetricChart";

export const customObjectQueryKeys = {
  all: ["custom_objects"] as const,
  count: (objectName: string, segmentId?: number) =>
    [...customObjectQueryKeys.all, objectName, "count", segmentId] as const,
  allLists: (objectName: string) =>
    [...customObjectQueryKeys.all, objectName, "list"] as const,
  list: (objectName: string, filters: any = {}) =>
    [...customObjectQueryKeys.allLists(objectName), filters] as const,
  segment: (objectName: string, segmentId: number, filters: any = {}) =>
    [
      ...customObjectQueryKeys.allLists(objectName),
      segmentId,
      filters,
    ] as const,
  details: (objectName: string) =>
    [...customObjectQueryKeys.all, objectName, "detail"] as const,
  detail: (objectName: string, id: number) =>
    [...customObjectQueryKeys.details(objectName), id] as const,
};

export const customObjectAssociationsQueryKeys = {
  all: ["custom_objects_associations"] as const,
  allLists: () => [...customObjectAssociationsQueryKeys.all, "list"] as const,
  list: (associationId: number, objectId: number | string, filters: any = {}) =>
    [
      customObjectAssociationsQueryKeys.all,
      "list",
      associationId,
      objectId,
      filters,
    ] as const,
};

export const syncQueryKeys = {
  all: ["sync"] as const,
  allForService: (serviceId: string) =>
    [...syncQueryKeys.all, serviceId] as const,
  enable: (serviceId: string) =>
    [...syncQueryKeys.all, serviceId, "enable"] as const,
  health: (serviceId: string) =>
    [...syncQueryKeys.all, serviceId, "health"] as const,
};

export const formQueryKeys = {
  all: ["form"] as const,
  detail: (id: string) => [...formQueryKeys.all, id] as const,
  formWithAccount: (id: string) => ["formWithAccount", id] as const,
  share: (id: string) => ["share", id] as const,
  accountInfo: (id: string) => ["formAccountInfo", id] as const,
  formSubmissions: (id: string) => ["formSubmissions", id] as const,
  isFormChanged: (id: string) => ["isFormChanged", id] as const,
};

export const eventManagementKeys = {
  all: ["event_management"] as const,
  allWithCount: ["event_management_count"] as const,
  detail: (id?: number) => [...eventManagementKeys.all, id] as const,
  allMetrics: ["event_management_metric"] as const,
  endUserEvents: (id: number) =>
    ["event_management_end_user_event", id] as const,
};

export const hubSpotListsQueryKey = (hubSpotObjectType: string) =>
  ["hubspot", "lists", hubSpotObjectType] as const;

export const stepPeopleQueryKeys = {
  all: ["step-people"] as const,
  step: (stepId: number, type: string) =>
    [...stepPeopleQueryKeys.all, stepId, type] as const,
};

export const metricsEventsKeys = {
  events: ({
    emailIds,
    type,
    endDate,
    startDate,
    flowIds,
    broadcastId,
    abTestVariantId,
    stepId,
  }: {
    emailIds?: IEmailContent["id"][];
    type: IMetricsType;
    endDate?: Date;
    startDate?: Date;
    flowIds?: IIntegration["id"][];
    broadcastId?: number;
    abTestVariantId?: string;
    stepId?: number;
  }) =>
    [
      "metricsEvents",
      emailIds,
      type,
      endDate,
      startDate,
      flowIds,
      broadcastId,
      abTestVariantId,
      stepId,
    ] as const,
};
