import AccountPicker from "components/AppNav/Account/AccountPicker";
import { useRef } from "react";
import AccountLogo from "./AccountLogo";
import AccountName from "./AccountName";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const AppNavAccountInfo = (props: Props) => {
  // use ref to display dropdown on the left
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className="account-switcher-container d-flex align-items-center"
      onClick={() => {
        props.setIsOpen(!props.isOpen);
      }}
      ref={ref}
    >
      <AccountLogo />
      <div className="account-switcher-account-name">
        <AccountName />
      </div>
      <div className="flex-grow-1" />
      {ref.current && (
        <span className="account-picker-container">
          <AccountPicker
            isOpen={props.isOpen}
            setIsOpen={props.setIsOpen}
            dropdownRef={ref.current}
          />
        </span>
      )}
    </div>
  );
};
export default AppNavAccountInfo;
