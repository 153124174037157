import { action, observable, runInAction, when } from "mobx";
import { DomainStore } from "./domainStore";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "../domain/errorHandling/toaster";
import {
  generateContentSuggestions,
  favoriteSuggestion,
  getFavoritedSuggesions,
} from "./persistence/persistAIWriter";

import {
  AIContentSuggestion,
  AIContentSuggestionAutocomplete,
  AIContentSuggestionGenerateParameters,
  AIContentSuggestionImproveParagraph,
  AIContentSuggestionWriteParagraph,
} from "encharge-domain/definitions/AIContentSuggestion";
import { lazyObservable } from "domain/helpers/lazyLoad";

export class AIWriterStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  minEditorSelectionLength = 30;
  maxEditorSelectionLength = 300;

  @observable
  contentSuggestions: AIContentSuggestion[] = [];

  /**
   * User favorited suggestions
   */
  @observable
  favoritedSuggestions = lazyObservable<AIContentSuggestion[]>(
    (sink, onError) => {
      getFavoritedSuggesions()
        .then((suggestions) => {
          sink(observable(suggestions));
        })
        .catch((e) => {
          toastError({
            message: "Error while loading your saved content.",
            extra: e,
          });
          onError(e);
          throw e;
        });
    }
  );

  /**
   * Generate content suggestions for a given use case
   */
  @action
  generateContentSuggestions = async (
    parameters: AIContentSuggestionGenerateParameters,
    storeSuggestions: boolean = true
  ) => {
    try {
      this.rootStore.uiStore.aiWriterLoading.startLoading();
      const suggestions = await generateContentSuggestions(parameters);
      if (storeSuggestions) {
        runInAction(() => {
          this.contentSuggestions.push(...suggestions);
        });
      }
      return suggestions;
    } catch (e) {
      toastError(e.message);
    } finally {
      this.rootStore.uiStore.aiWriterLoading.finishLoading();
    }
    return [];
  };

  /**
   * Edit a content suggestion (this session only)
   */
  @action
  updateContentSuggestion(
    id: AIContentSuggestion["id"],
    content: AIContentSuggestion["content"]
  ) {
    const suggestion = this.contentSuggestions.find((s) => s.id === id);
    if (suggestion) {
      suggestion.content = content;
    }
  }

  /**
   * Favorite a suggestion
   */
  @action
  async favoriteContentSuggestion(suggestion: AIContentSuggestion) {
    favoriteSuggestion(suggestion.id);
    toastSuccess("Saved ❤️");

    // Add to current suggestions:
    // start loading suggestions
    this.favoritedSuggestions.current();
    await when(() => !this.favoritedSuggestions.isLoading());
    this.favoritedSuggestions.current().push(suggestion);
  }

  /**
   * Generate suggestions for the text editor
   */
  @action
  async textEditorSuggestions(
    generationParameters:
      | AIContentSuggestionWriteParagraph
      | AIContentSuggestionImproveParagraph
      | AIContentSuggestionAutocomplete
  ) {
    if (
      generationParameters.parameters.text.length <
        this.minEditorSelectionLength ||
      generationParameters.parameters.text.length >
        this.maxEditorSelectionLength
    ) {
      return "";
    }

    const storeSuggestions = false;
    const suggestions = await this.generateContentSuggestions(
      generationParameters,
      storeSuggestions
    );
    return suggestions?.[0]?.content;
  }
}
