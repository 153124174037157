import { action, computed, observable, runInAction, when } from "mobx";
import { DomainStore } from "./domainStore";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "../domain/errorHandling/toaster";

import { lazyObservable } from "domain/helpers/lazyLoad";
import enchargeAPI from "store/persistence/enchargeAPI";
import _ from "lodash";
import {
  CustomObjectSchema,
  CustomObjectSchemaAssociation,
} from "encharge-domain/definitions/CustomObjectSchema";

export class CustomObjectsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  schemas = lazyObservable<CustomObjectSchema[]>((sink, onError) => {
    enchargeAPI
      .getCustomObjectsSchema()
      .then((items) => {
        sink(observable(items.objects as CustomObjectSchema[]));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading object schemas.",
          extra: e,
        });
        onError(e);
      });
  });

  isLoadingSchemas() {
    return this.schemas.isLoading();
  }

  getObjectSchema(objectName: string) {
    const schemas = this.schemas.current();
    return _.find(schemas, (schema) => schema.name === objectName);
  }

  getFieldByObjectAndName(objectName: string, fieldName: string) {
    const schema = this.getObjectSchema(objectName);
    return _.find(schema?.fields, (field) => field.name === fieldName);
  }

  getAssociationId(
    sourceObjectName: string,
    targetObjectName: string,
    name?: string
  ) {
    const sourceSchema = this.getObjectSchema(sourceObjectName);
    return _.find(sourceSchema?.associations, (association) => {
      const objectMatches =
        association.fromObject === sourceObjectName
          ? association.toObject === targetObjectName
          : association.fromObject === targetObjectName;
      const nameMatches = name ? association.name === name : true;
      return objectMatches && nameMatches;
    });
  }

  @action
  async createSchema({
    name,
    displayNamePlural,
    displayNameSingular,
    description,
  }: {
    name: string;
    /** Description of the custom object */
    description?: string;
    /** The display name of one instance the custom object. E.g. "Invoice" */
    displayNameSingular: string;
    /** The display name of multiple instances the custom object. E.g. "Invoices" */
    displayNamePlural: string;
  }) {
    try {
      await enchargeAPI.createCustomObjectSchema({
        name,
        description,
        displayNameSingular,
        displayNamePlural,
      });
      toastSuccess("Object created successfully.");
      this.schemas.refresh();
    } catch (e) {
      toastError({
        message: "Error while creating object.",
        extra: e,
      });
    }
  }

  @action
  async createSchemasAssociation(
    association: Omit<CustomObjectSchemaAssociation, "id">
  ) {
    try {
      const res = await enchargeAPI.defineCustomObjectsAssociationSchema(
        association
      );
      toastSuccess("Association created successfully.");
      this.schemas.refresh();
      return res.association;
    } catch (e) {
      toastError({
        message: "Error while creating association.",
        extra: e,
      });
    }
    return;
  }
}
