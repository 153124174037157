import Interweave from "interweave";
import { Link } from "react-router-dom";
import { UncontrolledAlert } from "reactstrap";

interface Props {
  error: string;
}

const RegisterError = (props: Props) => {
  const error = props.error;
  // Scroll to error div in case it's outside the view

  return (
    <div ref={(ref) => ref && window.scrollTo(0, (ref as any).offsetTop)}>
      <UncontrolledAlert className="mt-4" color="danger">
        <Interweave content={error} />
        {/* Show login link if user tried to register with existing account */}
        {error.match(/This email is already registered/) && (
          <>
            <br />
            <Link to="/login">Login instead?</Link>
          </>
        )}
      </UncontrolledAlert>
    </div>
  );
};
export default RegisterError;
