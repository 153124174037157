import { useDeduceRegistrationStep } from "components/Registration/Registration";
import { observer } from "mobx-react";
import { useStores } from "store/useStore";

const RegistrationFinishedCheck = () => {
  const { accountStore } = useStores();
  if (accountStore.account && accountStore.account.version === 2) {
    return <RegistrationRouteInner />;
  }
  return null;
};
const RegistrationRouteInner = () => {
  // Redirect to registration if the user hasn't completed it
  // or do nothing
  useDeduceRegistrationStep();
  return null;
};
export default observer(RegistrationFinishedCheck);
