import { FlowsStore } from "../../store/flowsStore";
import _ from "lodash";
import { fromPromise, IPromiseBasedObservable } from "mobx-utils";

export type QuerablePromise<T> = IPromiseBasedObservable<T>;

// Allows us to check the state of the promise
export function makeQuerablePromise<T>(promise: Promise<T>) {
  return fromPromise(promise);
}
let hasSetupCheckForPending = false;

export const setupCheckForPending = (flowStore: FlowsStore) => {
  // only add one beforeunload listener
  if (hasSetupCheckForPending) return;
  hasSetupCheckForPending = true;
  // tslint:disable-next-line: ter-prefer-arrow-callback
  window.addEventListener("beforeunload", async function (e) {
    let warnBeforeExit = false;
    _.each(flowStore.pendingActions, (actionPromise) => {
      if (actionPromise.state === "pending") {
        warnBeforeExit = true;
      }
    });
    if (warnBeforeExit) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
    }
  });
};
