import ButtonLoader from "components/ButtonLoader";
import {
  formatAsReactSelectOptions,
  reactSelectCommonProps,
} from "components/ReactSelect/reactSelectCommon";
import _ from "lodash";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import Select from "react-select";
import { Button, Collapse, Form, FormGroup, Input } from "reactstrap";
import { Label } from "reactstrap";
import { useStores } from "store/useStore";
import {
  AIContentSuggestionGenerateParameters,
  AIWriterUseCase,
} from "encharge-domain/definitions/AIContentSuggestion";
import AIWriterToneSelect from "components/AIWriter/AIWriterToneSelect";
import AIWriterGeneratedContent from "components/AIWriter/AIWriterGeneratedContent";

const aiWriterUseCases: {
  id: AIWriterUseCase;
  name: string;
  description: string;
}[] = [
  {
    id: "writeEmail",
    name: "Write Email",
    description: "Write an email based on a few key points.",
  },
  {
    id: "emailOutline",
    name: "Email Outline",
    description: "Generate general outline for an email.",
  },
  {
    id: "subjectLine",
    name: "Subject Lines",
    description: "Create engaging click-worthy subject line.",
  },
  {
    id: "rewriteSubjectLine",
    name: "Rewrite Subject Line",
    description: "Rewrite your subject line to be more engaging.",
  },
];

const AIWriterGenerateForm = () => {
  const { aiWriterStore, uiStore, accountStore } = useStores();

  const [selectedUseCase, setSelectedUseCase] = useState<AIWriterUseCase>(
    "subjectLine"
    // TODO enable this when we have approval
    // "writeEmail"
  );
  const { currentOption, options } = formatAsReactSelectOptions({
    items: aiWriterUseCases,
    currentValue: selectedUseCase,
    idField: "id",
    labelField: "name",
  });

  const currentOptionObject = _.find(aiWriterUseCases, {
    id: selectedUseCase,
  });

  const [emailAbout, setEmailAbout] = useState("");
  const [emailAboutSubject, setEmailAboutSubject] = useState("");
  const [emailAboutOutline, setEmailAboutOutline] = useState("");
  const [emailOriginalSubject, setЕmailOriginalSubject] = useState("");

  const [tone, setTone] = useState("Friendly");
  const [companyName, setCompanyName] = useState(
    accountStore.account?.accountName
  );
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const toggleAdvancedSettings = useCallback(() => {
    setShowAdvancedSettings(!showAdvancedSettings);
  }, [showAdvancedSettings]);

  const disabled =
    (selectedUseCase === "writeEmail" && !emailAbout) ||
    (selectedUseCase === "subjectLine" && !emailAboutSubject) ||
    (selectedUseCase === "emailOutline" && !emailAboutOutline);

  const generateSamples = (example?: string) => {
    const parameters = {
      useCase: selectedUseCase,
      parameters: {
        companyName: companyName || "n/a",
        tone,
        example,
      },
    } as AIContentSuggestionGenerateParameters;
    if (parameters.useCase === "writeEmail") {
      parameters.parameters.emailAbout = emailAbout;
    } else if (parameters.useCase === "subjectLine") {
      parameters.parameters.emailAbout = emailAboutSubject;
    } else if (parameters.useCase === "emailOutline") {
      parameters.parameters.emailAbout = emailAboutOutline;
    } else if (parameters.useCase === "rewriteSubjectLine") {
      parameters.parameters.subject = emailOriginalSubject;
    } else {
      // We shouldn't get here, but just in case
      return;
    }
    aiWriterStore.generateContentSuggestions(parameters);
  };

  const filteredSuggestions = _.filter(
    aiWriterStore.contentSuggestions,
    (suggestion) => suggestion.useCase === selectedUseCase
  );

  return (
    <>
      <Form
        className="mb-5"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          generateSamples();
          return false;
        }}
      >
        <FormGroup>
          <Label for="useCaseSelect" className="mb-2">
            Use case:
          </Label>
          <Select
            className="ai-writer-select"
            inputId="useCaseSelect"
            {...reactSelectCommonProps}
            options={options}
            value={currentOption}
            onChange={(option) => setSelectedUseCase((option as any)?.value)}
            formatOptionLabel={(option) => {
              const useCase = _.find(aiWriterUseCases, {
                id: option.value,
              });
              return (
                <div>
                  {option.label}
                  <div className="small">{(useCase as any)?.description}</div>
                </div>
              );
            }}
            // enable all options
            // isOptionDisabled={(option) => {
            //   return (option?.value as any) !== "subjectLine";
            // }}
          />
          <div className="small text-muted">
            {currentOptionObject?.description}
          </div>
        </FormGroup>
        <FormGroup>
          {selectedUseCase === "writeEmail" && (
            <div>
              <Label for="useCaseSelect" className="mb-2">
                What is the email about?
              </Label>
              <Input
                type="textarea"
                name="emailAbout"
                id="emailAbout"
                rows="5"
                placeholder={`Big news!\nEncharge launched new AI Writing assistant\nWrite emails 10 times faster\nAny questions`}
                value={emailAbout}
                onChange={(e) => setEmailAbout(e.target.value)}
              />
            </div>
          )}
          {selectedUseCase === "subjectLine" && (
            <div>
              <Label for="useCaseSelect" className="mb-2">
                What is the email about?
              </Label>
              <Input
                type="text"
                name="emailAboutSubject"
                id="emailAboutSubject"
                rows="5"
                placeholder={`50% off offer for Mother's Day`}
                value={emailAboutSubject}
                onChange={(e) => setEmailAboutSubject(e.target.value)}
              />
            </div>
          )}
          {selectedUseCase === "emailOutline" && (
            <div>
              <Label for="useCaseSelect" className="mb-2">
                What is the email about?
              </Label>
              <Input
                type="text"
                name="emailAboutOutline"
                id="emailAboutOutline"
                rows="5"
                placeholder={`50% off offer for Mother's Day`}
                value={emailAboutOutline}
                onChange={(e) => setEmailAboutOutline(e.target.value)}
              />
            </div>
          )}
          {selectedUseCase === "rewriteSubjectLine" && (
            <div>
              <Label for="useCaseSelect" className="mb-2">
                Subject:
              </Label>
              <Input
                type="text"
                name="emailOriginalSubject"
                id="emailOriginalSubject"
                rows="5"
                placeholder={`50% off offer for Mother's Day`}
                value={emailOriginalSubject}
                onChange={(e) => setЕmailOriginalSubject(e.target.value)}
              />
            </div>
          )}
        </FormGroup>
        <FormGroup>
          <Button
            className="pl-0"
            color="link"
            onClick={toggleAdvancedSettings}
          >
            {/* Adjust Tone & Language */}
            Adjust Tone & other settings
          </Button>
          <Collapse isOpen={showAdvancedSettings}>
            <div style={{ maxWidth: "300px" }} className="mt-2 mb-3">
              <Label for="toneSelect" className="mb-2">
                Tone
              </Label>
              <AIWriterToneSelect onChange={setTone} />
            </div>
            <div className="mb-3">
              <Label for="toneSelect" className="mb-2">
                Company/Product Name
              </Label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Your company name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </Collapse>
        </FormGroup>
        <FormGroup>
          <ButtonLoader
            type="submit"
            disabled={disabled}
            color="primary"
            onClick={() => generateSamples()}
            loading={uiStore.aiWriterLoading.isLoading}
          >
            Generate
          </ButtonLoader>
        </FormGroup>
      </Form>
      <AIWriterGeneratedContent
        suggestions={filteredSuggestions}
        generateSamples={(example) => generateSamples(example)}
      />
    </>
  );
};
export default observer(AIWriterGenerateForm);
