import { Button } from "reactstrap";
import { useStores } from "store/useStore";

const RegisterLogoutButton = () => {
  const { authStore } = useStores();
  // place a floating button in the upper right corner of the registration page
  // which logs the user out using authStore
  return (
    <div className="register-logout-button">
      <Button
        data-testid="logout-button"
        color="link"
        onClick={() => {
          authStore.logOut();
        }}
      >
        Log out
      </Button>
    </div>
  );
};
export default RegisterLogoutButton;
