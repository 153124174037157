import { observer } from "mobx-react";
import { FrontendAccount } from "store/accountStore";
import { useStores } from "store/useStore";

/**
 * Render name of current account
 */
const AccountName = observer(() => {
  const { accountStore } = useStores();
  const account = accountStore.account;
  if (!account) return null;
  const accountName = getAccountName({
    id: account.accountId,
    name: account.accountName,
    site: account.site,
  });
  return <div className="">{accountName}</div>;
});

export const getAccountName = (
  account: FrontendAccount["allAccounts"][number]
) => {
  let domain =
    account.site &&
    // domain will be null if no match is found
    account.site.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i)?.[1];

  return account.name || domain || account.site || `Account ${account.id}`;
};
export default AccountName;
