import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { EmailContentCreate } from "encharge-domain/lib/definitions/api/EmailContentCreate";
import { EmailContentUpdate } from "encharge-domain/lib/definitions/api/EmailContentUpdate";
import { handleForbiddenRead } from "./handleForbidden";
import { IFlow } from "components/Flows/FlowRoute";

export const getEmails = async () => {
  try {
    const res: AxiosResponse<{ emails: IEmailContent[] }> = await request({
      url: `${apiBase}/v1/emails`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.emails;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];

    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your emails. ";
    throw new Error(msg);
  }
};

export const getEmail = async (emailId: IEmailContent["id"]) => {
  try {
    const res: AxiosResponse<{ email: IEmailContent }> = await request({
      url: `${apiBase}/v1/emails/${emailId}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.email;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your email. ";
    throw new Error(msg);
  }
};

export const createEmail = async (email: EmailContentCreate) => {
  try {
    const res: AxiosResponse<{ email: IEmailContent }> = await request({
      url: `${apiBase}/v1/emails`,
      method: "POST",
      data: email,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    console.log("response here", res.data);
    return res.data.email;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create your email. ";
    throw new Error(msg);
  }
};

export const updateEmail = async (email: EmailContentUpdate) => {
  try {
    const res: AxiosResponse<{ email: IEmailContent }> = await request({
      url: `${apiBase}/v1/emails/${email.id}`,
      method: "PATCH",
      data: email,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.email;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't update your email. ";
    throw new Error(msg);
  }
};

export const sendTestEmail = async (
  email: Partial<IEmailContent>,
  sendTo: string
) => {
  try {
    const res: AxiosResponse<{
      success: boolean;
      message: string;
    }> = await request({
      url: `${apiBase}/v1/emails/test`,
      method: "POST",
      data: { email, sendTo },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "Error while sending your email. ";
    throw new Error(msg);
  }
};

export const copyEmailToAnotherAccount = async (data: {
  emailId: IEmailContent["id"];
  targetAccountId: IAccount["id"];
}) => {
  try {
    const res: AxiosResponse<{ email: IEmailContent }> = await request({
      url: `${apiBase}/v1/emails/duplication`,
      method: "POST",
      data,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.email;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't copy your email. ";
    throw new Error(msg);
  }
};
