import { observable, runInAction, action, toJS } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import {
  getPerson,
  updatePerson,
  removeTag,
  tagPeople,
  addTag,
} from "./persistence/persistPeople";
import { getPersonEvents } from "./persistence/persistEvents";
import { lazyObservable } from "../domain/helpers/lazyLoad";

export class PeopleStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  people: { [id: string]: IEndUser } = {};

  @action
  resetPeople() {
    this.people = {};
  }

  @action
  // store the new people
  putPeople(newPeople: IEndUser[]) {
    _.forEach(newPeople, (newPerson) => {
      if (!newPerson.id) return;
      // add lazy loader for events
      newPerson.events = this.lazyLoadablePersonEvents(newPerson.id);
      // retain tags if refreshing person
      if (this?.people?.[newPerson.id]?.tags && !newPerson.tags) {
        newPerson.tags = this.people[newPerson.id].tags;
      }
      // index by user ID for easy retrieval
      this.people[newPerson.id] = newPerson;
    });
  }

  @action
  patchPeopleLocal(newPeople: IEndUser[]) {
    _.forEach(newPeople, (newPerson) => {
      if (!newPerson.id) return;
      // add lazy loader for events
      newPerson.events = this.lazyLoadablePersonEvents(newPerson.id);
      // index by user ID for easy retrieval
      this.people[newPerson.id] = _.merge(this.people[newPerson.id], newPerson);
    });
  }

  // get people by ids
  getPeople(ids: string[]) {
    return _.reduce(
      ids,
      (acc, id) => {
        const person = this.people[id];
        if (person) {
          acc.push(person);
        }
        return acc;
      },
      [] as IEndUser[]
    );
  }

  @action
  removePeopleLocal(ids: string[]) {
    _.map(ids, (id) => {
      try {
        delete this.people[id];
      } catch (e) {}
    });
  }

  // get person by id
  getPerson(id: string) {
    return this.people[id];
  }

  // get the person from the backend
  @action
  async refreshPerson(id: string) {
    this.rootStore.uiStore.personRetrive.startPersonRetrieve();
    try {
      const person = await getPerson(id);
      if (person) {
        this.putPeople([person]);
      } else {
        throw new Error("Person does not exist.");
      }
      runInAction(() => {
        this.rootStore.uiStore.personRetrive.stopPersonRetrive();
      });
      return person;
    } catch (e) {
      runInAction(() => {
        this.rootStore.uiStore.personRetrive.stopPersonRetrive();
        this.rootStore.uiStore.personRetrive.error = (e as any).message;
      });
      toastError({
        message: "Error while loading this person.",
        extra: e,
      });
    }
    return undefined;
  }

  // get the person from the backend
  @action
  async updatePerson(person: IEndUser) {
    this.rootStore.uiStore.personUpdate.startPersonRetrieve();
    try {
      const updatedPerson = await updatePerson(person);
      if (!updatedPerson) {
        throw new Error("Person couldn't be updated.");
      }
      this.putPeople([updatedPerson]);
      toastSuccess("✅ Person updated");

      // If this was a new person, refresh all segments
      if (!person.id) {
        this.rootStore.segmentStore.refreshSegments();
      }

      runInAction(() => {
        this.rootStore.uiStore.personUpdate.stopPersonRetrive();
      });
      return updatedPerson;
    } catch (e) {
      runInAction(() => {
        this.rootStore.uiStore.personUpdate.stopPersonRetrive();
        this.rootStore.uiStore.personUpdate.error = (e as any).message;
      });
      toastError({
        message: "Error while updating this person.",
        extra: e,
      });
      return;
    }
  }

  @action
  async deleteTag(tag: string, userId: string) {
    try {
      if (!tag) throw new Error("Missing tag name.");
      if (!userId) throw new Error("Missing user .");
      const result = await removeTag(tag, userId);
      runInAction(() => {
        const person = this.getPerson(userId);
        person.tags = _.filter(
          person.tags.split(","),
          (existingTag) => existingTag !== tag
        ).join(",");
        this.patchPeopleLocal([_.pick(person, "id", "tags")]);
      });
      return result;
    } catch (e) {
      toastError({
        message: "Couldn't remove tag.",
        extra: e,
      });
    }
    return;
  }

  @action
  async addTag({ tag, personId }: { tag: string; personId: string }) {
    try {
      if (!tag) throw new Error("Missing tag name.");
      if (!personId?.length) throw new Error("Missing users.");
      const result = await addTag(tag, personId);
      this.addTagToPeopleLocal([personId], tag);
      toastSuccess("✅ People successfully tagged.");
      return result;
    } catch (e) {
      toastError({
        message: "Couldn't add tag.",
        extra: e,
      });
    }
    return;
  }

  @action
  addTagToPeopleLocal(peopleIds: string[], tag: string) {
    _.map(peopleIds, (personId) => {
      const person = this.getPerson(personId);
      const existingTags = person.tags ? person.tags.split(",") : [];
      if (!_.find(existingTags, (existingTag) => existingTag === tag)) {
        existingTags.push(tag);
      }
      const newTags = existingTags.join(",");
      this.patchPeopleLocal([{ id: personId, tags: newTags }]);
    });
  }

  lazyLoadablePersonEvents(userId: string) {
    return lazyObservable<IPersonEvent[]>((sink, onError, pagination) => {
      getPersonEvents(userId, pagination?.cursor)
        .then(({ events, cursor }) => {
          // append newly retrieved events to person events
          const currentPerson = this.getPerson(userId);
          const currentPersonEvents: IPersonEvent[] =
            currentPerson?.events?.current?.() || [];
          sink(toJS(currentPersonEvents).concat(...events), { cursor });
        })
        .catch((e) => {
          toastError({
            message: "Error while loading person events.",
            extra: e,
          });
          onError(e);
        });
    });
  }
}
