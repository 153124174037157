import { domainStore } from "../../store/domainStore";

export default function redirect(
  path: string,
  options = {
    push: true,
    keepQuery: false,
  } as {
    push?: boolean;
    keepQuery?: boolean | string[];
  }
) {
  const { push, keepQuery } = options;
  return domainStore.locationStore.redirect({
    path,
    push,
    keepQuery,
  });
}

export const redirectWithReload = (path: string) => {
  window.location.href = path;
};
