import { action, observable, runInAction, when } from "mobx";
import { DomainStore } from "./domainStore";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";
import { Tag } from "encharge-domain/definitions/Tag";
import { formatTag } from "encharge-domain/lib/helpers/tag_helper";
import { ILazyObservable, lazyObservable } from "domain/helpers/lazyLoad";
import { toastError, toastSuccess } from "domain/errorHandling/toaster";
import enchargeAPI from "./persistence/enchargeAPI";

export class TagsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "tagsManagement", limit: 1 });

  @observable
  tags: ILazyObservable<Tag[]> = lazyObservable<Tag[]>((sink, onError) => {
    enchargeAPI
      .getAccountTags()
      .then((res) => {
        sink(observable(res.tags));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading tags.",
          extra: e,
        });
        onError(e);
        throw e;
      });
  });

  async loadTags() {
    if (this.tags.current()) return;
    await when(() => !this.tags.isLoading());
  }

  @action
  async addTagsLocal(newTags: Tag[], currentTags?: Tag[]) {
    await this.loadTags();
    runInAction(() => {
      const currentAccountTags = currentTags || this.tags.current();
      if (!currentAccountTags) return;
      _.each(newTags, (newTag) => {
        if (
          !_.find(currentAccountTags, (current) => current.tag === newTag.tag)
        ) {
          currentAccountTags.push(newTag);
        }
      });
    });
  }

  @action
  async addTags({
    tags,
    placeInCurrentFolder,
  }: {
    tags: (string | Tag)[];
    placeInCurrentFolder?: boolean;
  }) {
    await this.loadTags();
    this.queue.addConfirmableAction({
      state: () => this.tags.current(),
      performAction: (existingTags) => {
        // Convert strings to `Tag`
        const tagsToAdd = _.map(tags, (tag) => {
          if (typeof tag === "string") {
            return { tag: formatTag(tag) };
          }
          return { ...tag, tag: formatTag(tag.tag) };
        });
        // add tags locally
        this.addTagsLocal(tagsToAdd, existingTags);

        // Add newly created tags in current folder
        // Note: this is done here to speed up UI. It can lead to children in
        // folder that dont exist (if creation fails). However, folders
        // ignore unexisting children, so this is ok.
        if (placeInCurrentFolder) {
          const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
            "tags"
          );
          if (currentFolderId) {
            _.map(tagsToAdd, (tag) =>
              this.rootStore.foldersStore.pushItemToFolder({
                folderId: currentFolderId,
                itemId: tag.tag,
                type: "tags",
              })
            );
          }
        }
        // persist new tag
        return async () => {
          await enchargeAPI.createAccountTags({ tags: tagsToAdd });
        };
      },
      confirmErrorMessage: "Couldn't create tag.",
    });
  }

  /**
   *  Modify existing tag.
   */
  @action
  async editTag(tagId: Tag["tag"], tag: Tag) {
    this.rootStore.uiStore.tagEditLoading.startLoading();
    await this.loadTags();
    try {
      await enchargeAPI.updateAccountTag(tagId, tag);

      runInAction(() => {
        const existingTags = this.tags.current();
        const tagToEditIndex = _.findIndex(
          existingTags,
          (current) => current.tag === tagId
        );
        // make sure tag exists
        if (tagToEditIndex === -1) return;
        // modify by index
        existingTags[tagToEditIndex] = tag;

        // locally modify tag in current folder
        if (tagId !== tag.tag) {
          const selectedFolderId = this.rootStore.foldersStore.getSelectedFolder(
            "tags"
          );
          const selectedFolder = this.rootStore.foldersStore.getFolderById(
            selectedFolderId
          );
          if (selectedFolder) {
            selectedFolder.childrenIds = selectedFolder.childrenIds = _.map(
              selectedFolder.childrenIds,
              (childId) => (childId === tagId ? tag.tag : childId)
            );
          }
        }
      });
      toastSuccess(
        "✅ Tag has been saved. <br/>Please refresh to see changes."
      );
    } catch (e) {
      toastError({
        message: "Error while editing tag.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.tagEditLoading.finishLoading();
    }
  }

  /**
   * Delete tag
   */
  @action
  async deleteTag(tag: Tag["tag"]) {
    this.queue.addConfirmableAction({
      state: () => this.tags.current(),
      performAction: (existingTags) => {
        const tagToRemoveIndex = _.findIndex(
          existingTags,
          (current) => current.tag === tag
        );
        // if not found, exit
        if (tagToRemoveIndex === -1) return;
        // remove locally
        existingTags.splice(tagToRemoveIndex, 1);
        // persist
        return () => enchargeAPI.deleteAccountTag(tag);
      },
      confirmErrorMessage: "Couldn't delete tag.",
    });
  }

  /**
   *  Retrieve a tag by name if it exists in this account
   */
  getTag(tag: string) {
    return _.find(this.tags.current(), (current) => current.tag === tag);
  }

  /**
   * Get counts for each tag.
   */
  @action
  async getTagCounts() {
    try {
      await this.loadTags();
      const { tagCounts } = await enchargeAPI.getTagCounts();
      runInAction(() => {
        const allTags = this.tags.current();
        _.map(tagCounts, (tagCount) => {
          const tag = _.find(allTags, (tag) => tag.tag === tagCount.tag);
          if (tag) {
            tag.count = tagCount.count;
          }
        });
      });
    } catch (e) {
      console.log("Error while getting tag counts", e);
    }
  }
}

export { formatTag };
