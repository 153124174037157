import qs from "qs";

/**
 * Get query as object
 *
 * @param {string} [queryString]
 * @returns {Dictionary<any>}
 */
export const getQuery = (queryString?: string): Dictionary<any> => {
  let query = {};
  // We need to remove the leading ? from the query string
  query = qs.parse((queryString || window.location.search).replace(/^\??/, ""));
  return query;
};
