import { useCallback, useState, useEffect } from "react";
import { observer } from "mobx-react";
import _ from "lodash";
import { useStores } from "store/useStore";
import TagEditPopupView from "./TagEditPopupView";
import { toastSuccess } from "domain/errorHandling/toaster";
import { Tag } from "encharge-domain/definitions/Tag";
import { formatTag } from "store/tagsStore";

interface Props {
  onClose?: () => void;
}

// The id when we are creating a new tag
export const newTagId = "---new---";

const TagEditPopup = observer((props: Props) => {
  const {
    tagsStore,
    uiStore: { tagEditPopup, tagEditLoading },
  } = useStores();

  // Local state to track input changes
  const [tag, setTag] = useState<Tag | undefined>();

  // Once the popup is opened (tag id is set/changed),
  // populate data about the tag we are editting/creating
  const tagId = tagEditPopup.value;
  useEffect(() => {
    // try getting existing tag
    let current = tagsStore.getTag(tagId);
    // or if it's a new tag
    if (tagId === newTagId) {
      // if we set initial values (i.e. duplicating tag) - use them,
      // othwerise use default values.
      current = {
        tag: "",
        description: "",
      };
    }
    // keep in local state the new/edited tag
    setTag(current);
  }, [tagId, tagsStore]);

  // close popup
  const onCloseProp = props.onClose;
  const onClose = useCallback(() => {
    // clear local state and URL state
    setTag(undefined);
    tagEditPopup.close();
    onCloseProp?.();
  }, [onCloseProp, tagEditPopup]);

  // Create/edit tag
  const applyTag = useCallback(async () => {
    if (!tag || !tagId) return;
    // create new tag
    if (tagId === newTagId) {
      await tagsStore.addTags({
        tags: [{ tag: formatTag(tag.tag), description: tag.description }],
        // place tag in the currently selected folder if any
        placeInCurrentFolder: true,
      });
      toastSuccess("✅ Tag created.");
    } else {
      // editing existing
      await tagsStore.editTag(tagId, { ...tag, tag: formatTag(tag.tag) });
    }
    // cleanup
    tagEditPopup.close();
    setTag(undefined);
  }, [tag, tagEditPopup, tagId, tagsStore]);

  // make sure we are editting/creating, otherwise don't show
  if (!tagId || !tag) return null;

  // Check if the current tag id is already used (by a different tag),
  // to display a warning
  const isTagTaken = () => {
    // Treat empty names as not taken
    if (!tag?.tag) {
      return false;
    }
    // Try to find a tag with the same name
    const currentTagName = formatTag(tag.tag);
    const tagWithSameName = _.find(
      tagsStore.tags.current(),
      (current) => current.tag === currentTagName
    );
    // If we didn't find tag with same name, the name is not taken
    if (!tagWithSameName) {
      return false;
    }

    // we found the same tag we are editing, so no conflict
    if (tagId === tagWithSameName.tag) {
      return false;
    }

    // if we got here, there is a conflict
    return true;
  };

  return (
    <TagEditPopupView
      mode={tagId === newTagId ? "create" : "edit"}
      closeModal={onClose}
      tag={tag.tag}
      description={tag.description}
      isLoading={tagEditLoading.isLoading}
      onConfirm={applyTag}
      isTagTaken={isTagTaken()}
      onChangeTag={(tagId) => {
        setTag({
          ...tag,
          tag: tagId,
        });
      }}
      onChangeDescription={(description) => {
        setTag({ ...tag, description });
      }}
    />
  );
});

export default TagEditPopup;
