import { useStores } from "../../../../store/useStore";
import { observer } from "mobx-react";

interface Props {
  emailId: string | number | undefined | null;
  prependText?: string;
  appendText?: string;
  nullText?: string;
}
const EmailLink = observer((props: Props) => {
  const { emailId } = props;
  const { emailsStore, uiStore } = useStores();
  const noEmailResult = props.nullText ? <>{props.nullText}</> : null;
  if (!emailId) {
    return noEmailResult;
  }
  const email = emailsStore.getEmailById(Number(emailId));
  if (!email?.name) {
    return noEmailResult;
  }
  return (
    <>
      {props.prependText && <>{props.prependText} </>}
      <div
        onClick={() => {
          uiStore.emailEditor.openEmailEditor({
            emailId: email?.id,
            type: "preview",
          });
        }}
        className="link-text"
      >
        {email?.name}
      </div>
      {props.appendText && <> {props.appendText}</>}
    </>
  );
});

export default EmailLink;
