import { DateTime, DateTimeFormatOptions } from "luxon";

export default function asDateTime(datetime: string | Date) {
  return DateTime.fromJSDate(new Date(datetime));
}

/**
 * Format date as "Friday, October 14, 1983, 13:30:23"
 */
export const toFullDateTimeString = (datetime: string | Date) =>
  asDateTime(datetime).toLocaleString({
    ...DateTime.DATETIME_HUGE_WITH_SECONDS,
    timeZoneName: undefined,
  });

export const toDateTimeString = (
  datetime: string | Date,
  formatOptions: DateTimeFormatOptions
) =>
  asDateTime(datetime).toLocaleString({
    ...formatOptions,
    timeZoneName: undefined,
  });

export const getUserTimezone = () => DateTime.local().toFormat("z");

/**
 * Detect if the user uses 12 hour time format
 */
export const is12HourTime = () => {
  const time = DateTime.local()
    .toLocaleString(DateTime.TIME_SIMPLE)
    .toLowerCase();
  return time.includes("am") || time.includes("pm");
};
